/* ###### 7.9 Signin  ###### */
.az-signin-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  background-image: url(https://did1d8jg9ar6w.cloudfront.net/images/welcome_bg.png);
  background-repeat: no-repeat;
  flex-direction: column;
  background-size: cover;
  background-position: center 47%, center 100%;

  @media (max-width:767px) {
    
    background-size: initial;
    background-position: center;
    justify-content: center;
  
  }

  
  // &::before {
  //     position: absolute;
  //     top: 0;
  //     background-color: $az-black-color;
  //     height: 100%;
  //     content: '';
  //     width: 100%;
  // }
  .az-copyright { 
      z-index: 0;
    p {
       color: $az-white;
       font-size: $az-font-16;
      //  font-family: $az-heading-two;
       
      @media (max-width:767px) {
          margin-bottom: 0;
          padding: 30px 0 10px;
      }
    }
}

}

.az-card-signin {
  
  padding: 0px 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  height: calc(100vh - 40px);
  position: relative;

    @media (max-width:767px) {
    
       padding: 0px 15px 0 !important;
       height: calc(100% - 0px);
    }


  .az-logo {
          img {
            display: block;
            margin: 0 auto 45px auto;

            @media (max-width:1440px) {
              
              margin: 10px auto 15px auto;
            }
            @media (max-width:1366px) {

              margin: 0 auto 15px auto;
            }

            @media (max-width:1280px) {

              margin: 0 auto 15px auto;
            }

            @media (max-width:1199px) {
  
               margin: 0 auto 15px auto;
            }
            @media (max-width:991px) {
    
               margin: 0 auto 45px auto;
            }

            @media (max-width:767px) {
    
             margin: 0 auto 20px;
             }
           
            
          }
          h2 {
               @include h2;
               margin-bottom: 45px;

               @media (max-width:1440px) {

                margin-bottom: 15px;

               }
            
             @media (max-width:1366px) {

                margin-bottom: 15px;
              }
  
              @media (max-width:1280px) {
  
                margin-bottom: 15px;
                font-size: 30px;
              }

              @media (max-width:1199px) {
  
                margin-bottom: 15px;
                font-size: 30px;
              }
              @media (max-width:991px) {
      
                margin-bottom: 45px;
                font-size: 30px;
              }

              @media (max-width:767px) {
                    font-size: $az-font-30;
                    margin-bottom: 20px;
                  
                }
   
         }
    }

}
.az-signin-header {
        width: 408px;
        background-color: #fff;
        padding: 40px 35px 28px;
        border-radius: 2px;
        height: 442px;

        @media (max-width:1366px) {
          padding: 10px 35px 10px;
          height: 340px;
        }

        @media (max-width:1280px) {
          padding: 15px 35px 10px;
          height: 385px;
        }

        @media (max-width:1199px) {
          padding: 15px 35px 25px;
          height: 340px;
        }
        @media (max-width:1024px) {
          height: 390px;
        }
 

        @media (max-width:480px) {
                 padding: 30px 20px;
                 width: 320px;
                 margin: auto;
                 height: auto;
              }
        @media (max-width:320px) {
                padding: 30px 20px;
                width: 280px;
                margin: auto;   
                height: auto; 
            }

       h3 {

            @include h3
       }
        .p-dark { 

              @include p-dark ;

              @media (max-width:1366px) {

                margin-bottom: 0;
              }
  
              @media (max-width:1280px) {
  
                margin-bottom: 0;
              }

              @media (max-width:1199px) {
  
                margin-bottom: 0;
              }
              @media (max-width:991px) {
  
                margin-bottom: 0;
              }

             
  
        }

        form {
              margin-top: 10px;

              @media (max-width:1366px) {

                margin-top: 5px;
              }
  
              @media (max-width:1280px) {
  
                margin-top: 20px;
              }
              @media (max-width:1199px) {
  
                margin-top: 10px;
              }
              @media (max-width:767px) {
  
                margin-top: 10px;
              }

          .form-group { 
                        margin-bottom: 32px;

                        @media (max-width:1366px) {

                           margin-bottom: 10px;
                        }
            
                        @media (max-width:1280px) {
            
                           margin-bottom: 25px;
                        }
                        @media (max-width:1199px) {
            
                          margin-bottom:32px;
                       }
                       @media (max-width:767px) {
            
                        margin-bottom: 15px;
                     }

                  input {
                    
                       border-left: none !important;
                       border-right: none !important;
                       border-top: none !important;
                       border-bottom: 1px solid $az-black !important;
                       padding: 15px 0px;
                       font-size: $az-font-16 !important;
                       color: $az-black !important;
                       border-radius: 0 !important;
                       background-color: $az-white !important;
  
                       &:hover {
                          border-bottom: 1px solid $az-black !important;
                       }
                       &:focus {
                        border-bottom: 2px solid $az-black !important;
                       }
                       &:active {
                        border-bottom: 2px solid $az-black !important;
                       }
                    
                    
                     &::placeholder {
                      color: $az-placeholder !important;
                      font-weight: normal !important;
                     
                   }
                     
                    
                  }
                
             }

             .button-green {
                        @include green-button;
                        min-width: 100%;
                        max-width: 100%;
                        height: auto;
                        margin-top: 32px;
                        padding: 7px 10px;
                        &:focus {
                          box-shadow: none;
                          background: $az-black !important;
                          color: $az-white !important;
                          border: 2px solid $az-black !important;
                        }
                        &:hover {
                          box-shadow: none;
                          background: $az-black !important;
                          color: $az-white !important;
                          border: 2px solid $az-black!important;
                        }
                        &:active {
                          box-shadow: none !important;
                          background: $az-black !important;
                          color: $az-white !important;
                          border: 2px solid $az-black !important;
                        }
                        span{
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }

                        @media (max-width:1366px) {

                          margin-top: 10px;
                       }
           
                       @media (max-width:1280px) {
           
                           margin-top: 10px;
                       }
                       @media (max-width:767px) {
           
                        margin-top: 16px;
                    }
               }

              .az-signin-footer {
                    //  position:absolute;
                      p {

                        @media (max-width:480px) {
                          margin-bottom: 0px;
                         
                       }
          
                       @media (max-width:320px) {
                        margin-bottom: 0px;
                       
                     }
                      a {
                         color: $az-black;
                         font-family: $az-heading-two;
                         font-size: $az-font-16;
                         text-decoration: underline;
                         text-underline-offset: 0.2em;
                         font-weight: 400;
                        //  letter-spacing: .05em;
                      }
                      &:hover {

                        color: $az-black;
                    }
              }
            }
        }

    .alert {
      margin-bottom: 0 !important;
    }

}

