/* ###### 4.8 Nav   ###### */

.az-nav {
  @include media-breakpoint-up(md) { align-items: center; }

  .nav-link {
    display: block;
    color: $gray-700;
    padding: 0;
    position: relative;
    line-height: normal;

    @include hover-focus() {
      color: $gray-900;
    }

    + .nav-link {
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px dotted $gray-500;

        @include media-breakpoint-up(md) {
          padding-top: 0;
          margin-top: 0;
          border-top: 0;
          padding-left: 15px;
          margin-left: 15px;
          border-left: 1px dotted $gray-500;
        }
    }

    &.active { color: $az-color-primary; }
  }
}

.az-nav-column {
  flex-direction: column;

  .nav-link {
    padding: 0;
    height: $az-height-base;
    color: $az-gray;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      font-size: 24px;
      line-height: 0;
      width: 24px;
      margin-right: 12px;
      text-align: center;
      @include transition($transition-base);

      &:not([class*=' tx-']) { color: $gray-600; }

      &.typcn { line-height: .9; }
    }

    span {
      font-weight: 400;
      font-size: 11px;
      color: $gray-500;
      margin-left: auto;
    }

    &:hover,
    &:focus {
      color: $gray-900;
      i:not([class*=' tx-']) { color: $gray-900; }
    }

    &.active {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        bottom: 6px;
        left: -28px;
        width: 3px;
        background-color: $indigo;
        @include border-radius();
        display: none;
      }

      &,
      &:hover,
      &:focus {
        color: $indigo;
        i { color: $indigo; }
      }
    }

    + .nav-link { border-top: 1px dotted $gray-400; }
  }

  &.sm {
    .nav-link {
      font-size: $font-size-base;
      font-weight: 400;
      padding: 10px 0;

      i { font-size: 21px; }
    }
  }
}

.az-nav-dark {
  .nav-link {
    color: rgba(#fff, .7);
    @include hover-focus() { color: #fff; }

    + .nav-link { border-color: $gray-700; }
    &.active { color: $az-color-primary; }
  }
}

.az-nav-colored-bg {
  .nav-link {
    + .nav-link { border-color: rgba(#fff, .4); }
    &.active { color: #fff; }
  }
}

.az-nav-line {
  position: relative;

  .nav-link {
    padding: 0;
    color: $az-gray;
    position: relative;
    font-family: $az-heading-one;

    @include hover-focus() { color: $az-gray; }

    + .nav-link {
      margin-top: 15px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
        margin-left: 30px;
      }
    }

    &.active {
      color: $az-black !important;
      font-family: $az-heading-one;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -30px;
        width: 2px;
        background-color: $az-eage-color;

        @include media-breakpoint-up(md) {
          top: auto;
          bottom: -7px !important;
          left: 0;
          right: 0;
          height: 3px;
          width: auto;
          z-index: 2;
        }
      }
    }
  }

  &.az-nav-dark {
    .nav-link {
      color: rgba(#fff, .7);
      @include hover-focus() { color: #fff; }

      &.active {
        color: #fff;
        &::before { background-color: #fff; }
      }
    }
  }
}

.az-nav-tabs {
  padding: 15px 15px 0;
  background-color: $gray-300;

  .lSSlideOuter {
    position: relative;
    padding-left: 32px;
    padding-right: 35px;
  }

  .lSSlideWrapper { overflow: visible; }

  .lSAction {
    > a {
      display: block;
      height: 40px;
      top: 16px;
      opacity: 1;
      background-color: $gray-400;
      background-image: none;

      @include hover-focus() { background-color: darken($gray-400, 5%); }

      &::before {
        font-family: 'Ionicons';
        font-size: 18px;
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.lSPrev {
        left: -32px;
        &::before { content: '\f3cf'; }
      }

      &.lSNext {
        right: -35px;
        &::before { content: '\f3d1'; }
      }

      &.disabled {
        background-color: $gray-200;
        color: #fff;
      }
    }
  }

  .lightSlider { display: flex; }

  .tab-item {
    flex-shrink: 0;
    display: block;
    float: none;
    min-width: 150px;
  }

  .tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    line-height: 1.428;
    color: $gray-700;
    white-space: nowrap;
    background-color: $gray-200;

    @include hover-focus() { background-color: $gray-100; }

    &.active {
      background-color: #fff;
      color: $gray-900;
      font-weight: 500;
    }
  }
}

.az-tab-pane {
  display: none;

  &.active { display: block; }
}

.adminHeadertop {
                  background-color: $az-white;
                  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                  -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                  -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                  -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
                  position: relative;
                  z-index: 9;
        .naBar { 
                justify-content: space-between;
              }
         .mobView {
              min-width: 170px;

             
          @media (max-width:1199px) {
            display: flex;
            max-width: 40%;
            width: 40%;
            // order: 1;
            justify-content: center;
            padding: 0;
            min-width: 125px;
          }

          @media (max-width:991px) {
            display: flex;
            max-width: 16%;
            width: 16%;
            // order: 1;
            justify-content: center;
            padding: 0;
            min-width: 125px;
          }

           @media (max-width:767px) {
            display: flex;
            max-width: 25%;
            width: 25%;
            order: 1;
            justify-content: center;
            padding: 0;
            min-width: 145px;
          }
          @media (max-width:375px) { 
            min-width: 130px;
          }
          @media (max-width:360px) { 
            min-width: 110px;
          }
         .navbar-brand {

          @media (max-width:991px) {
            margin: 0;
          }
          @media (max-width:767px) {
                margin: 0;
          }
        }
        }
         .leftNavbar{
                width: 100%;
                  #basic-navbar-nav{
                    a {
                      position: relative;
                      padding: 0px 30px;
                      display: block;
                      height: 100%;
                       @include navFont;
                       &:active{
                        color: $az-black !important;
                       }
                      &:hover{
                        color: $az-black !important;
                        &:after { position: absolute; content: ''; bottom: -22px; border-bottom: 2px solid $az-black !important; width: 95%;  left: 0;}
                      }
                      &:first-child { padding: 0; margin-right: 30px;

                        @media (max-width:991px) { 
                          padding: 5px 30px;
                          &:hover{
                            &:after { display: none;}
                          }
                        }
                        @media (max-width:767px) {
                          padding: 5px 30px !important;
                         }
                      
                      }
                    
                      @media (max-width:991px) { 
                        padding: 5px 30px;
                        &:hover{
                          &:after { display: none;}
                        }
                      }
                      @media (max-width:767px) {
                        padding: 5px 30px !important;
                       }
                    
                  }

                  .active{
                     color: $az-black !important;
                     position: relative;
                    &:active{
                      color: $az-black !important;
                     }
                    &:hover{
                    color: $az-black !important;
                    &:after { position: absolute; content: ''; bottom: -22px; border-bottom: 2px solid $az-black; width: 95%;  left: 0;
                    
                      @media (max-width:991px) { 
                        display: none;
                       }
                       @media (max-width:767px) {
                         display: none;
                        }
                    }

                  } 
                  &:after { position: absolute; content: ''; bottom: -22px; border-bottom: 2px solid $az-black; width: 95%;  left: 0;

                    @media (max-width:991px) { 
                     display: none;
                    }
                    @media (max-width:767px) {
                      display: none;
                     }
                  }
                }
                  
              }
              
           @media (max-width:991px) {
                  max-width: inherit;
                  width: auto;
                  order: 1;
                  display: block;
                  flex: inherit;
                  justify-content: flex-start;
                  padding: 0;
                  margin: 0;
                  position: relative;
                }
            @media (max-width:767px) {
                max-width: none;
                width: none;
                order: 1;
                }
                button {

                  &:active { outline: none; border: none;}
                  &:hover { outline: none; border: none;}
                  &:focus { outline: none; border: none;}
                }
                .collapse {
                  transition: inherit;
                 
                  @media (max-width:991px) { 
                    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.08);
                    position: absolute;
                    left: -15px;
                    top: 54px;
                    background: #fff;
                    padding: 15px;
                    z-index: 1;

                  }

                  @media (max-width:767px) { 
                    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.08);
                    position: absolute;
                    left: -15px;
                    top: 55px;
                    background: #fff;
                    padding: 15px;
                    z-index: 1;
                  }

                }
                .collapsing {
                  height: auto;
                  position: absolute;
                  left: -15px;
                  top: 60px;
                  background: #fff;
                  padding: 15px;
                  transition: inherit;
                  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.08);
                }
            }
               
         .rightNavbar{
           position: inherit;
            .dropdown {
              figure{
                  margin:0;
                    display: block;
                    width: 28px;
                    height: 28px;
                    border: 2px solid #333;
                    border-radius: 50%;
                    @media (max-width:991px) {
                      display: none;
                      margin-top:0px ;
                    }
                    @media (max-width:767px) {
                        display: none;
                        margin-top:2px ;
                      }
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100%;
                      }
                  }
               .profileContent { 
                    display: block;

                    b{ @include p-normal;}
                    i { font-style: normal; @include light-gray-font;}
                   
                    @media (max-width:991px) {
                      display: none !important;
                    }

                    @media (max-width:767px) {
                      display: none !important;
                    }
              }
              .dropdown-menu { 
                right: 0;
                transform: translate(0px, 60px) !important;
                left: auto;
                top: -6px;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
                border: none;
                border-radius: 0px 0px 2px 2px;

                @media (max-width:991px) {
                  width: 200px;
                  top: -6px;
                  z-index: 9;
                  position: absolute;
                }
                @media (max-width:767px) {
                  width: 200px;
                  top: -6px;
                  z-index: 9;
                  position: absolute;
                }

                a {

                  font-family: $az-heading-two;
                  color: $az-black;
                  font-size: $az-font-14;
                  &:first-child {
                         display: none !important;
                  figure{
                        display: none !important;

                        @media (max-width:991px) {
                          display: block !important;
                          width: 42px;
                          height: 42px;
                          border: 3px solid $az-purple;
                          border-radius: 50%;
                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100%;
                          }

                        }

                        @media (max-width:767px) {
                          display: block !important;
                          width: 42px;
                          height: 42px;
                          border: 3px solid $az-purple;
                          border-radius: 50%;
                          img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100%;
                          }
                        }
                    }

                    .profileContent { 
                      display: none !important;

                      @media (max-width:991px) {
                        display: flex !important;

                      }

                      @media (max-width:767px) {
                        display: flex !important;

                      }

                    }

                    @media (max-width:991px) {
                      display: none !important;

                    }

                    @media (max-width:767px) {
                      display: none !important;

                    }
                  }
                  &:hover{
                    background-color: $az-black;
                    color: $az-white;
                  }
                  &:active {
                    background-color:  $az-black;
                    color: $az-white;
                  }
                  &:focus {
                    background-color:  $az-black;
                    color: $az-white;
                  }
                  
                  &:last-child {
                    &:hover {
                      border-radius: 0px 0px 2px 2px;
                    }
                   
                  }
                  }
               }
            }
            @media (max-width:991px) {
              width: auto;
              max-width: initial;
              order: 3;
              flex: 0 0 0;
              padding: 0;
              position: relative;
              z-index: 9;
              }
            @media (max-width:767px) {
              width: 33.33%;
              max-width: 33.33%;
              order: 3;
              position: relative;
              z-index: 9;
              }
               .globalsearchbar {
                 position:relative;
                  z-index: 1 !important;

                  span{
                    @media (max-width:360px) { 
                        margin-right: 7px !important;
                        margin-top: -5px;
                    }
                    img {
                      filter: contrast(0) brightness(0.3);
                      padding-top: 0px;
                      width: 18px;
                      margin-right: 4px;
                      margin-top: 10px;

                      @media(max-width: 991px) {
                        margin-top: 10px;
                        filter: contrast(0) brightness(0.1);
                      }
                     
                      @media(max-width: 767px) {
                        margin-top: 10px;
                        filter: contrast(0) brightness(0.1);
                      }

                      @media(max-width: 360px) {
                        margin-top: 15px;
                        filter: contrast(0) brightness(0.1);
                      }

                    }
                  }  

                  // button {
                  //   background: none;
                  //   padding: 0;
                  //   min-width: 0;
                  //   line-height: 0;
                  //   z-index: 9;
                  //   &:focus, &:hover, &:active{
                  //     background: none;
                  //     border: none;
                  //     outline: none;
                  //     box-shadow: none !important;
                  //   }
                       
                  // }

                  .dropdown-menu {
                    width: calc(100% - 520px);
                    min-width: calc(100% - 520px);
                    top: 0px;
                    top: 10px;
                    right: 180px;
                    background: $az-white;
                    border: 0;
                    z-index: 0;
                    a:first-child {
                    display: block !important;
                   }
                 }
                 .css-g1d714-ValueContainer {
                   .css-1wa3eu0-placeholder {
                      top: 55% !important;
                      margin-left: 0px !important;
                   }
                   .css-1uccc91-singleValue { 
                     top: 55% !important;
                     margin-left: 0px !important;

                   }
                 }
                 .css-b8ldur-Input {
                      width: 90% !important;

                      input#react-select-2-input {
                        width: 100% !important;
                        min-width: 100% !important;
                        max-width: 100% !important;
                        div { 
                          &:first-child { width: 100% !important;}
                        }
                      }
                 }
               }


               .searchb { 
                display: flex;
                flex-wrap: wrap;
                border: 1px solid $az-border-color;
                width: 100%;
                border-radius: 2px;
            
                
                .az-header-center {
                  max-width: 150px;
                  flex: 0 0 150px;
                  margin: 0;
                  border: 1px solid $az-border-color;
                  border-right: 0 !important;
            
                  &:first-child {
                    max-width: 270px;
                    flex: 0 0 270px;
                    margin: 0;
                    border: 1px solid $az-border-color;
                    border-right: 0 !important;
              
                    .multiselect .css-qg19kw-control {
                      border-radius: 2px 0 0 2px;
                      border: none !important;
                    }
            
                    .multiselect .css-1bd6tu-control {
                       border: none !important;
                    &:hover {
                      border: none !important;
                    }
                  }
                  }
                  &:last-child {
                    position: relative;
                    max-width: calc(100% - 270px) !important;
                    flex: 0 0 100% !important;
                    border: 1px solid $az-border-color;
                    border-left: 0 !important;
                    border-right:  1px solid $az-border-color !important;
            
                    input {
                      border: none !important;
                      border-radius: 0;
                      position: relative;
            
                     
                    }
            
                    &::before {
            
                      position: absolute;
                      content: '';
                      background: $az-border-color;
                      left: 0;
                      z-index: 1;
                      padding: 12px 0.5px;
                      top: 18%;
                    }
                  }
                }
              }

              .navMob {
                figure {

                  @media (max-width:991px) { 
                       display: block;
                  }
                  @media (max-width:767px) { 
  
                      display: block;
                  }
                  @media (max-width:360px) { 
                    width: 35px;
                    height: 35px;
                }
                }
              }

              // global searchbar
              .searchbar {
                 position: relative;
                  .serach-select {
                    display: flex;
                    position: absolute;
                    right: 25%;
                    top: -1px;

                    @media (max-width: 1366px) {
                      right: 25%;
                    }
                    @media (max-width: 1199px) {
                      right: 25%;
                    }
                    @media (max-width: 767px) {
                      right: inherit;
                      left: 0;
                      top: 54px;
                      transform: inherit;
                      z-index: 1;
                      flex-direction: column;
                      width: 100%;
                      background: $az-white;
                      position: fixed;
                    }
                    @media (max-width: 580px) {
                      right: inherit;
                      left: 0;
                      top: 54px;
                      transform: inherit;
                      z-index: 1;
                      flex-direction: column;
                      width: 100%;
                      background: $az-white;
                      position: fixed;
                    }

                    @media (max-width: 375px) {
                      right: inherit;
                      left: 0;
                      top: 54px;
                      transform: inherit;
                      z-index: 1;
                      flex-direction: column;
                      width: 100%;
                      background: $az-white;
                      position: fixed;
                    }
                    .az-header-center {
                      margin: 0px -1px;
                      .multiselect {
                        .css-qg19kw-control {
                          min-width:180px;
                          height: 42px;
                          scrollbar-width: 3px;
                          scrollbar-color: #b7b7b7 #e9e9e9;
                          -webkit-scrollbar-width: 3px;
                          -moz-scrollbar-width: 3px;

                          @media (max-width: 1366px) {
                            min-width:180px;
                          }
                          @media (max-width: 1199px) {
                            min-width:180px;
                          }
                          @media (max-width: 1023px) {
                            min-width:160px;
                          }
                          @media (max-width: 991px) {
                            min-width:130px;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                          @media (max-width: 767px) {
                            min-width:110px;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                          @media (max-width: 580px) {
                            min-width:130px;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                          @media (max-width: 375px) {
                            min-width:110px;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                        }

                      
                       .css-qg19kw-control::-webkit-scrollbar {
                          width: 3px;
                          height: 5px;
                        }
                       .css-qg19kw-control::-webkit-scrollbar-track {
                          background-clip: content-box;
                          border: 0px solid transparent;
                          width: 3px;
                          background-color: #e9e9e9;
                        }
                       .css-qg19kw-control::-webkit-scrollbar-thumb {
                          background-color: #b7b7b7;
                        }
                       .css-qg19kw-control::-webkit-scrollbar-thumb:hover {
                          background-color: #b7b7b7;
                        }
                       .css-qg19kw-control::-webkit-scrollbar-corner,
                       .css-qg19kw-control::-webkit-scrollbar-track {
                          background-color: #e9e9e9;
                          width: 3px;
                        }
                        [class^="message-container"] {
                          /* height: 200px; */
                          overflow-y: scroll;
                          width: 3px;
                        }
                        [class^="message-container"] div {
                          /* height: 400px;
                        width: 200%;
                        padding: 4rem;
                        background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
                        }

                        .css-1bd6tu-control {
                          min-width:180px;
                          height: 42px;
                          scrollbar-width: 3px;
                          scrollbar-color: #b7b7b7 #e9e9e9;
                          -webkit-scrollbar-width: 3px;
                          -moz-scrollbar-width: 3px;

                          @media (max-width: 1366px) {
                            min-width:180px;
                          }
                          @media (max-width: 1199px) {
                            min-width:130px;
                          }
                          @media (max-width: 1023px) {
                            min-width:150px;
                          }
                          @media (max-width: 991px) {
                            min-width:130px;
                          }
                          @media (max-width: 767px) {
                            min-width:110px;
                          }
                          @media (max-width: 580px) {
                            min-width:130px;
                          }
                          @media (max-width: 375px) {
                            min-width:110px;
                          }

                           .css-1bd6tu-control::-webkit-scrollbar {
                            width: 3px;
                            height: 5px;
                          }
                          .css-1bd6tu-control::-webkit-scrollbar-track {
                            background-clip: content-box;
                            border: 0px solid transparent;
                            width: 3px;
                            background-color: #e9e9e9;
                          }
                          .css-1bd6tu-control::-webkit-scrollbar-thumb {
                            background-color: #b7b7b7;
                          }
                          .css-1bd6tu-control::-webkit-scrollbar-thumb:hover {
                            background-color: #b7b7b7;
                          }
                          .css-1bd6tu-control::-webkit-scrollbar-corner,
                          .css-1bd6tu-control::-webkit-scrollbar-track {
                            background-color: #e9e9e9;
                            width: 3px;
                          }
                          [class^="message-container"] {
                            /* height: 200px; */
                            overflow-y: scroll;
                            width: 3px;
                          }
                          [class^="message-container"] div {
                            /* height: 400px;
                          width: 200%;
                          padding: 4rem;
                          background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
                          }
                        }
                       
                      }
                      &:last-child {
                        width: 100%;
                        input {
                          min-width: 890px !important;
                          padding-right: 45px;
                          height: 42px !important;
                          @media (max-width: 1680px) {
                            min-width: 750px !important;
                          }

                          @media (max-width: 1540px) {
                            min-width: 650px !important;
                          }

                          @media (max-width: 1366px) {
                            min-width: 450px !important;
                          }
                          @media (max-width: 1199px) {
                            min-width: 280px !important;
                          }
                          @media (max-width: 1023px) {
                            min-width: 200px !important;
                          }
                          @media (max-width: 991px) {
                            min-width: 250px !important;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                          @media (max-width: 767px) {
                            min-width: 350px !important;
                            padding-right: 10px;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                          @media (max-width: 580px) {
                            min-width: 300px !important;
                            padding-right: 10px;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                          @media (max-width: 375px) {
                            min-width: 210px !important;
                            padding-right: 10px;
                            border-bottom: 1px solid $az-border-color !important;
                            border-top: none !important;
                            border-left: none !important;
                            border-right: none !important;
                          }
                        }
                      }

                      @media (max-width: 991px) {
                        display: block;
                      }
                      @media (max-width: 767px) {
                        display: block;
                      }
                    }
                  }
                  .az-notification-list {
                    position: absolute;
                    right: 25%;
                    top: 47px;
                    min-width: 890px !important;
                    background: #fff;
                    .media {
                      &:hover {
                         &::before {
                          display: none;
                        }
                        background: $az-light-gray;
                      }
                      a{
                        color: $az-black;
                        font-family: $az-heading-two;
                        font-size: $az-font-14;
                      }
                      span {
                        color: $az-gray;
                        font-family: $az-heading-two;
                        font-size: $az-font-12;
                      }
                    }

                    @media (max-width: 1366px) {
                      right: 25%;
                      min-width: 550px !important;
                    }
                    @media (max-width: 1199px) {
                      right: 25%;
                      min-width: 400px !important;
                    }
                    @media (max-width: 1023px) {
                      right: 25%;
                      min-width: 350px !important;
                    }
                    @media (max-width: 991px) {
                      right: 25%;
                      min-width: 250px !important;
                    }
                    @media (max-width: 767px) {
                      right: auto;
                      left: 100%;
                      top: 97px;
                      transform: translate(-48%, 0%);
                      min-width: 350px !important;
                  
                    }
                    @media (max-width: 580px) {
                      right: auto;
                      left: 100%;
                      top: 97px;
                      transform: translate(-36%, 0%);
                      min-width: 300px !important;
                    }

                    @media (max-width: 375px) {
                      right: auto;
                      left: 100%;
                      top: 96px;
                      transform: translate(-39%, 0%);
                      min-width: 315px !important;
                      z-index: 0;
                    }
                  }
              }
               // end global searchbar
           }

         .dropdown-toggle {
          &::after { display: none;}
         }

         .navbar {
          padding: 0px;
          min-height: 65px;

          .navbar-brand{

            img{

              width: 85px;
            }
          }

          @media (max-width:1199px) {
            padding: 0px 60px 0 0;
          }
          @media (max-width:767px) {
            padding: 0px 60px 0 0;
          }

          @media (max-width:360px) {
            padding: 0px 45px 0 0;
          }
         }
         
}

.spinnerLoader {
  @include spinnerloaderonpage;
}

.backpage { @include breadcrumbs;}

.serach-select {
  input {
    @media (max-width: 767px) {
     width: 100% !important;
     min-width: 100% !important;
    }
 }
}

.search_pop_align{
  margin-top: -8px;
  @media (max-width:1440px) {
    margin-top: -5px;
  }
  @media (max-width:991px) {
    margin-top: 0;
  }
}