main { @include dashboard-background; 
  .scrolling-carousel {
  
    @media (max-width:767px) {
      padding-left: 0px;
      margin-top: 0px;
      padding-right: 0;
    }
    @media (max-width:360px) { 
      padding-left: 0px;
    }
  }
 
  .Listingview {
    @media (max-width:767px) {
      padding-left: 0px;
      padding-right: 0;
    }

  }

  .scroll-slide {
    @media (max-width:767px) { 
      padding-left: 15px;
      padding-right: 0px;
    
    }
  }
  
  }
  .mg-t-46 { margin-top: $az-dash-mt;}
  aside { @include aside;}
  .accordion{ @include accordion-nav;}
  
  table{ @include mastertable;}
  button.btn-block {
      @include add-button;
    }
  
  span.text-danger {
  color: $az-black !important;
  }


.master {
 @include materalltables;
}