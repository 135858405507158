////////////////////////////////////////////////////////////
//     							                          //
//  Program: calendar.scss                                //
//  Application: scss                                     //
//  Option: Used for calendar design                      //
//  Developer: NP 						                  //
//  Date: 2022-05-03                                      //
//                                                        //
////////////////////////////////////////////////////////////

// .md-timeline-height .mbsc-timeline-resource, .md-timeline-height .mbsc-timeline-row { max-height: 120px; }

.mbsc-ios.mbsc-schedule-event-title, .mbsc-ios.mbsc-schedule-event-range {
    color: black;
}
// .mbsc-timeline-resource-title{
//     font-size: 20px;
// }
.mbsc-timeline-resource-title {
    // display: -webkit-box;
    // max-height: 75px;
    display: inline-block;
    max-height: 70px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: normal !important;

    @media (max-width: 767px) {
        max-height: 300px !important;
        -webkit-line-clamp: 5 !important;
    }
}
.mbsc-font{
    font-family: Lato-Regular !important;
    font-size: 14px !important;
}
.mbsc-windows.mbsc-popup-header{
    margin-right: 50px;
}
// .mbsc-timeline-resource .mbsc-ios .mbsc-ltr{
//  height: 400px;
// }
// .calendar-container{
//     max-height: fit-content !important;
//     // width: calc(100% - 330px) !important;
//     // max-width: calc(100% - 330px) !important;
// }
.mbsc-popup-content{
    font-family: Lato-Regular;
    font-weight: normal;
    text-align: left;
}
.mbsc-popup-content p {
    font-family: Lato-Regular;
    font-weight: normal;
    text-align: left;
    color: #000;
}
.popupClose {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
}
.green{
    // color:#62c92b;  //color on calendar
    // color:#62c92b;
}
.yellow{
    // color: #f4d100;   //color on calendar
    // color : #f4d100;
}
.popup p{
    text-align: left;
}
@media (prefers-color-scheme: dark) {
    .dark-mode {
      background-color: black !important;
      color: white !important;
    }
 }

//added for reservation calendar (mobicroll)
.md-timeline-template .mbsc-schedule-event.mbsc-ltr {
    height: auto !important;
}

.md-timeline-template-event {
    border: 1px solid transparent;
    margin: 2px 0;
}

.md-timeline-template-event-cont {
    background: rgba(255, 255, 255, .8);
    font-size: 15px;
    height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px;
    padding-top: 4px;
}

.md-timeline-template-event-cont .mbsc-icon {
    padding: 5px;
    box-sizing: content-box;
    // background-color: #ff3960 !important;
    margin-right: 5px;
}
.md-timeline-template-event-cont .mbsc-icon img {
    width: 37px !important;
    margin-left: -8px !important;
    margin-top: -5px;
    filter: brightness(8);
}

.mbsc-timeline-event-start .md-timeline-template-event,
.mbsc-timeline-event-start .md-timeline-template-event-cont,
.mbsc-timeline-event-start .md-timeline-template-event-cont .mbsc-icon {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.mbsc-timeline-event-end .md-timeline-template-event,
.mbsc-timeline-event-end .md-timeline-template-event-cont,
.mbsc-timeline-event-end .md-timeline-template-event-cont .mbsc-icon {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.md-timeline-template-event-cont .mbsc-icon:before {
    color: #fff;
    font-size: 18px;
    display: none;
}

.md-timeline-template-time {
    margin: 0 10px;
}

.md-timeline-template-title {
    color: black;
    // font-weight: bold;
    font-family: Lato-medium-bold !important;
}

.md-timeline-template-time{
    color: black;
    font-weight: bold;
}

.md-timeline-template .mbsc-timeline-column,
.md-timeline-template .mbsc-timeline-header-column {
    min-width: 100px;
}

.md-timeline-template .mbsc-timeline-resource,
.md-timeline-template .mbsc-timeline-row {
    min-height: 80px !important;

    @media(max-width: 767px) {
        min-height: 140px !important;
    }
}

//added for housekeeping calendar (mobicroll)
.md-custom-event-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.md-custom-event-cont {
    display: flex;
    align-items: center;
    padding-top: 0px;
    font-size: 14px;
    font-family: 'Lato-medium-bold';
}

.md-custom-event-btn,
.md-custom-event-btn.mbsc-button {
    position: absolute;
    right: 10px;
    bottom: 2px;
    line-height: 20px;
}

.md-custom-event .mbsc-material.mbsc-event-time {
    margin-top: 5px;
}
// .pac-container:after {
//     content: "Powered by Google";
//     font-size: 10px;
// }

//added for tooltip
.md-tooltip .mbsc-popup-content {
    padding: 0;
}

.md-tooltip {
    font-size: 15px;
    font-weight: 600;
}

.md-tooltip-header {
    padding: 10px;
    color: black;
}

.md-tooltip-time,
.md-tooltip-status-button {
    float: right;
}

.md-tooltip-title {
    margin-bottom: 15px;
}

.md-tooltip-text {
    font-weight: 300;
}

.md-tooltip-time, .md-tooltip-status-button{
    float: none !important;
}
.mbsc-popup-body-round {
    background: #fff;
    min-width: 300px;
    padding: 0 15px !important;
    max-width: 350px;
}
.mbsc-popup-wrapper {
    pointer-events: initial !important;
    -webkit-user-select: initial !important;
    -ms-user-select: initial !important;
    user-select: initial !important;
}
.mbsc-popup {
    pointer-events: initial !important;
    -webkit-user-select: initial !important;
    -ms-user-select: initial !important;
    user-select: initial !important;
}
.mbsc-popup-header {
    font-family: OrpheusPro;
    color: #000000;
    font-size: 24px;
    text-transform: inherit;
    margin-bottom: -5px !important;
    border-bottom: none !important;
    margin-right: 20px !important;
    padding-left: 10px !important;
    white-space: inherit !important;
    padding-right: 0 !important;
}


.mbsc-popup-content .az-profile-name-text {
    font-size: 14px !important;
    color: #606060 !important;
    font-family: Lato-Regular;
    font-weight: normal;
    margin-bottom: 0;
}

.mbsc-popup-content .media-body {
    font-size: 15px;
    color: #000000;
    font-family: Lato-Regular;
    font-weight: normal;
    padding-bottom: 6px;
    margin-bottom: 1rem;
}

.mbsc-popup-content  .popupClose {
    position: absolute;
    right: 10px;
    top: 10px;
}

.mbsc-popup-content a  {
    color: initial !important;
}
.mbsc-popup-content  .dark-mode {
    background-color: inherit !important;
    color: inherit !important;
}
.mbsc-ios.mbsc-event.mbsc-list-item {
    font-family: Lato-Regular;
}

.mbsc-ios.mbsc-event-day.mbsc-list-header {
    background: #eeedeb !important;
    font-family: Lato-Regular;
    font-size: 14px;
    color: #000000;
    font-weight: normal !important;
    padding-top: 12px !important;
    padding-left: 8px !important;
    padding-bottom: 12px !important;
}
.mbsc-timeline-grid-scroll {
    font-family: Lato-Regular;
}
.mbsc-event-content.mbsc-ios {
    div{
        &:first-child {
            font-size: 14px;
            margin-bottom: 5px;
            font-family: 'Lato-medium-bold';
        }
    }
}

.mbsc-timeline-resource-title {
    font-family: Lato-medium-bold;
}

.mbsc-event .mbsc-event-time.mbsc-ios.mbsc-ltr {
    min-width: 160px;
    display: flex;
    justify-content: flex-end;
    margin-right: -2px;
}
.mbsc-event-start.mbsc-ios {
    padding: 0 2px;
    font-size: 14px;
    margin-bottom: 5px;
    font-family: Lato-Regular;
}

.mbsc-event-end.mbsc-ios {
    padding: 0 4px;
    font-size: 14px;
    margin-bottom: 5px;
    font-family: Lato-Regular;
}
.mbsc-ios.mbsc-event-time{
    font-size: 14px !important;
}
.mbsc-ios.mbsc-event-list-empty{
    font-size: 14px !important;
    color: black;
    font-family: Lato-Regular;
}
.mbsc-windows-dark.mbsc-button-secondary.mbsc-button-outline{
    padding: 0px 10px 1px !important;
}
.md-tooltip .mbsc-popup .mbsc-popup-body {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}
label.mbsc-segmented-item { margin-bottom: 0px;

    .mbsc-segmented-selectbox {
        padding: inherit !important;
    }
}
.mbsc-ios.mbsc-calendar-controls {
    padding: 8px 0 8px 15px !important;
    justify-content: space-between;
    position: relative;
}
.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-title {
    padding-left: 0 !important;
    font-size: 14px !important;
    font-family: Lato-Regular !important;
}
.mbsc-ios.mbsc-schedule-date-header-text{
    background: #eeedeb!important;
    color: #000;
    font-family: Lato-Regular;
    font-size: 18px !important;
    font-weight: normal !important;
}
.mbsc-schedule-date-header .mbsc-schedule-date-header-text{
    text-align: left !important;
    color: #000 !important;
    font-family: Lato-Regular !important;
    font-size: 14px !important;
    font-weight: normal !important;
    padding-top: 12px !important;
    padding-left: 8px !important;
    padding-bottom: 12px !important;
}
//for custom week/day view header
.md-custom-header-view {
    display: flex;
    order: 2;
    width: auto;
    position: absolute;
    left: 50%;
}
.md-custom-header-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 3;
}

.md-custom-header-nav,
.md-custom-header-view {
    width: auto;
}

.md-custom-header-button.mbsc-button {
    font-size: 20px;
    height: auto;
    padding: 0;
    margin: 0;
}

.md-custom-header .mbsc-segmented {
    width: 110px;
    float: right;
    margin-top: 0;
    margin-bottom: 0;
}

.md-custom-header .mbsc-material.mbsc-segmented,
.md-custom-header .mbsc-windows.mbsc-segmented {
    padding: 0;
}

.md-custom-header .mbsc-segmented .mbsc-segmented-button {
    font-size: 20px;
    height: 32px;
    padding: 0;
}
//for custom view
.md-event-listing-cont {
    position: relative;
    padding-left: 50px;
}

.md-event-listing-avatar {
    position: absolute;
    max-height: 50px;
    max-width: 50px;
    top: 21px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 20px;
}

.md-event-listing-name {
    font-size: 16px;
}

.md-event-listing-title {
    font-size: 12px;
    margin-top: 5px;
}

.md-event-listing .mbsc-segmented {
    max-width: 350px;
    margin: 0 auto;
    padding: 1px;
}

.md-event-listing-picker {
    flex: 1 0 auto;
}

// .md-event-listing-nav {
//     width: 200px;
// }

/* material header order */

.mbsc-material.md-event-listing-prev {
    order: 1;
}

.mbsc-material.md-event-listing-next {
    order: 2;
}

.mbsc-material.md-event-listing-nav {
    order: 3;
}

.mbsc-material .md-event-listing-picker {
    order: 4;
}

.mbsc-material .md-event-listing-today {
    order: 5;
}

/* windows header order */

.mbsc-windows.md-event-listing-nav {
    order: 1;
}

.mbsc-windows.md-event-listing-prev {
    order: 2;
}

.mbsc-windows.md-event-listing-next {
    order: 3;
}

.mbsc-windows .md-event-listing-picker {
    order: 4;
}

.mbsc-windows .md-event-listing-today {
    order: 5;
}
.mbsc-ios .mbsc-segmented{
    margin: auto !important;
}

//listing calendar colorful
// .mbsc-datepicker .md-book-rental-bg-off.vacation-check-in.mbsc-ltr {
//     background: linear-gradient( to left top, #ffbaba80 50%, #b2f1c080 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-pre.vacation-check-in.mbsc-ltr {
//     background: linear-gradient( to left top, #ffbaba80 50%, #a3cdff80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-in.vacation-check-in.mbsc-ltr {
//     background: linear-gradient( to left top, #ffbaba80 50%, #f7f7bb80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-off.vacation-check-out.mbsc-ltr {
//     background: linear-gradient( to left top, #b2f1c080 50%, #ffbaba80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-pre.vacation-check-out.mbsc-ltr {
//     background: linear-gradient( to left top, #a3cdff80 50%, #ffbaba80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-in.vacation-check-out.mbsc-ltr {
//     background: linear-gradient( to left top, #f7f7bb80 50%, #ffbaba80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-off.vacation-check-in.mbsc-rtl {
//     background: linear-gradient( to right bottom, #ffbaba80 50%, #b2f1c080 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-pre.vacation-check-in.mbsc-rtl {
//     background: linear-gradient( to right bottom, #ffbaba80 50%, #a3cdff80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-in.vacation-check-in.mbsc-rtl {
//     background: linear-gradient( to right bottom, #ffbaba80 50%, #f7f7bb80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-off.vacation-check-out.mbsc-rtl {
//     background: linear-gradient( to right bottom, #b2f1c080 50%, #ffbaba80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-pre.vacation-check-out.mbsc-rtl {
//     background: linear-gradient( to right bottom, #a3cdff80 50%, #ffbaba80 50%) no-repeat;
// }

// .mbsc-datepicker .md-book-rental-bg-in.vacation-check-out.mbsc-rtl {
//     background: linear-gradient( to right bottom, #f7f7bb80 50%, #ffbaba80 50%) no-repeat;
// }

// .mbsc-datepicker .vacation-check-out,
// .mbsc-datepicker .vacation-check-in {
//     color: initial;
// }

// .mbsc-datepicker .vacation-check-in.mbsc-calendar-cell.mbsc-calendar-day-empty,
// .mbsc-datepicker .vacation-check-out.mbsc-calendar-cell.mbsc-calendar-day-empty {
//     background: transparent;
// }

// .md-book-rental-header {
//     display: flex;
//     align-items: center;
//     justify-content: end;
//     margin-left: auto;
//     flex: 1 1;
//     overflow: hidden;
// }

// .md-book-rental-zone {
//     font-size: 12px;
//     padding: 3px 6px;
//     margin: 0 5px;
//     border-radius: 16px;
//     color: #1e1e1ecc;
// }

// .md-book-rental .mbsc-calendar-label-text {
//     text-align: center;
//     font-weight: bold;
// }

// .md-book-rental-in {
//     background: #f7f7bb80;
// }

// .md-book-rental-pre {
//     background: #a3cdff80;
// }

// .md-book-rental-off {
//     background: #b2f1c080;
// }

// .md-book-rental-booked {
//     background: #ffbaba80;
// }

//for click to create event
.event-color-c {
    display: flex;
    margin: 16px;
    align-items: center;
    cursor: pointer;
}

.event-color-label {
    flex: 1 0 auto;
}

.event-color {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin-right: 10px;
    margin-left: 240px;
    background: #5ac8fa;
}

.crud-color-row {
    display: flex;
    justify-content: center;
    margin: 5px;
}

.crud-color-c {
    padding: 3px;
    margin: 2px;
}

.crud-color {
    position: relative;
    min-width: 46px;
    min-height: 46px;
    margin: 2px;
    cursor: pointer;
    border-radius: 23px;
    background: #5ac8fa;
}

.crud-color-c.selected,
.crud-color-c:hover {
    box-shadow: inset 0 0 0 3px #007bff;
    border-radius: 48px;
}

.crud-color:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    color: #f7f7f7;
    font-size: 20px;
    text-shadow: 0 0 3px #000;
    display: none;
}

.crud-color-c.selected .crud-color:before {
    display: block;
}
.status-tab{
    margin-top: 30px !important;
}

.mbsc-popup-content {
    font-size: 14px !important;
    font-family: Lato-Regular !important;
}
.mbsc-calendar-popup {
.mbsc-popup-body-round {
    min-width: 240px !important;
    max-width: 240px !important;
}
}
.mbsc-timeline-header-date-text {
    font-size: 14px !important;
    font-family: Lato-Regular !important;
}

.md-resource-header-template-cont {

    .md-resource-header-template-name {
        font-size: 14px !important;
        font-family: Lato-Regular !important;
        
        &:first-child {
            font-size: 14px !important;
            font-family: Lato-medium-bold !important;
        }   
        
    }
}

.md-timeline-template-event-cont {
    font-size: 14px !important;
    font-family: Lato-Regular !important;
}

.mbsc-ios.mbsc-icon-button {
.mbsc-icon {
    svg {width: 18px; }
 }
}
.md-event-listing-nav {
    .mbsc-button-flat {
        width: 120px;
    }
    
}
.md-event-listing-prev, .md-event-listing-next {
    padding: 0 !important;
    width: 15px !important;
}

.mbsc-ios.mbsc-button-secondary.mbsc-button-outline {
    color: gray !important;

    &:hover, &:focus, &:active {
        color: #000 !important;
        border-color: #000;
    }
}
.md-meeting-planner-header {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.md-meeting-planner-zone {
    font-size: 12px;
    padding: 3px 6px;
    margin: 0 5px;
    border-radius: 16px;
    color: #888;
}
.md-meeting-planner-yellow {
    background: #f7f7bb4d;
}
.md-meeting-planner-blue {
    background: #a5ceff4d;
}
.md-meeting-planner-red {
    background: #ffbaba4d;
}
.md-meeting-planner-green {
    background: #e0f4d5;
}
.md-timeline-height .mbsc-timeline-resource,
.md-timeline-height .mbsc-timeline-row {
    min-height: 120px !important;
}

.md-price{
    top: unset !important;
    bottom: -10px !important;
    margin-left: 5px;
    z-index: 1;
}
.md-rs{
    position: relative;
    top: -44px;
}
