aside {
  text-align: left;
  font-family: $az-heading-para-light;
  background: none;

  .topHead {
    padding: 14px 20px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    background: $az-white;
    border-radius: 8px;
    margin-bottom: 22px;

    .az-img-user {
      width: 42px;
      height: 42px;
      border: 2px solid $az-border-color;
      border-radius: 50%;

      &::after {
        right: 0px;
        top: 5px;
        display: none;
      }
    }

    .propname {
      margin-left: 8px;

      h5 {
        margin: 0;
        font-size: $az-font-16 !important;
        color: $az-black;
        letter-spacing: 0.19px;
        font-family: $az-heading-two;
      }

      ul {
        margin: 0;
        list-style: none;
        display: inline-flex;
        padding: 0;
        align-items: center;

        li {
          font-family: $az-heading-two;
          font-size: $az-font-14 !important;
          color: $az-gray;

          &:nth-child(2) {
            margin: 0 9px;
            background: $az-gray;
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .card {
    background: none;
    margin-bottom: 12px !important;

    .card-header {
      button {
        font-size: $az-font-16 !important;
        letter-spacing: 0.32px;

        i {
          font-size: $az-font-16;
        }
      }
    }

    .collapse {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 8px;

      .card-body {
        border: none;
        padding-top: 24px;

        .nav-link {
          font-size: $az-font-16 !important;
          font-weight: normal;
          padding: 14px 34px;
          font-family: $az-heading-two;

          &:focus {
            color: $az-white;
          }
        }
      }
    }

    .collapsing {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 8px;

      .card-body {
        border: none;
        padding-top: 24px;

        .nav-link {
          font-size: $az-font-16 !important;
          font-weight: normal;
          padding: 14px 34px;
          font-family: $az-heading-two;

          &:focus {
            color: $az-purple;
          }
        }
      }
    }
  }
}

.unitTable {
  padding: 24px 15px !important;
  @include tableformate
}

.scrolling-carousel {
  @include scrolling-carousel-swiper;

  .btn-success-outline-small {
    @include btn-success-outline-small;
  }

  .tab-content {
    // margin-top: 23px;

    .btn-success-outline-small {
      @include btn-success-outline-small;
    }

    @include viewavtar;

    .avatar-squre-listing {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }


      figure {
        margin: 0;
        max-width: 100%;
        max-height: 177px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        border: 1px solid $az-border-color;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        background: #fff;
        min-height: 177px;
        text-align: center;

        @media (max-width:1680px) {
          max-width: 100%;
          max-height: 180px;

        }

        @media (max-width:1280px) {
          max-width: 100%;
          max-height: 180px;
          min-height: 180px;
        }

        @media (max-width:1199px) {
          max-width: 100%;
          max-height: 180px;
          min-height: 180px;
        }

        @media (max-width:1024px) {
          max-width: 100%;
          max-height: 180px;
        }

        @media (max-width:991px) {
          max-width: 100%;
          max-height: 180px;
        }

        @media (max-width:767px) {
          max-width: 100%;
          max-height: 180px;
        }

        img {
          max-width: 100%;
          max-height: 180px;

          @media (max-width:1280px) {
            max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:1199px) {
            max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:767px) {
            max-width: 100%;
            max-height: 180px;
          }

        }

        &:hover {
          span {
            height: 100%;
            width: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.6);
            z-index: 1;
            left: 0;
            top: 0;
            display: block;
            opacity: 1;

            img {
              width: 18px;
              height: 18px;
              left: auto;
              top: 10px;
              right: 15px;
              transform: translate(0, 0);
              filter: grayscale(1) brightness(2);
            }

            &::before {
              content: '';
              position: absolute;
              width: 24px;
              height: 24px;
              left: 50%;
              top: 50%;
              background-image: url('../../images/zoom.svg');
              transform: translate(-50%, -50%);
              background-repeat: no-repeat;
            }
          }

          .del-img {
            width: 18px;
            height: 18px;
            left: auto;
            top: 10px;
            right: 15px;
            transform: translate(0, 0);
            filter: grayscale(1) brightness(2);
            position: absolute;
            display: block;
            z-index: 2;
            cursor: pointer;
          }
        }

        .del-img {
          width: 18px;
          height: 18px;
          left: auto;
          top: 10px;
          right: 15px;
          transform: translate(0, 0);
          filter: grayscale(1) brightness(2);
          position: absolute;
          display: none;
          z-index: 2;
          cursor: pointer;
        }

        span {
          height: 100%;
          width: 100%;
          position: absolute;
          background: rgba(0, 0, 0, 0.6);
          z-index: 1;
          left: 0;
          top: 0;
          display: block;
          opacity: 0;

          img {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        a.overlay {}

      }

    }

    .thmbImg {
      margin: 0;
      width: 170px;
      height: 170px;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #72818e33;
      position: relative;

      @media (max-width:767px) {
        margin: auto;
      }

      img {
        max-width: 100%;
        width: 170px;
        height: 170px;
        object-fit: cover;
      }

      a {
        span {

          &:hover {
            height: 100%;
            width: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.6);
            z-index: 1;
            left: 0;
            top: 0;
            display: block;
            opacity: 1;

            img {
              width: 18px;
              height: 18px;
              left: auto;
              top: 10px;
              right: 15px;
              transform: translate(0, 0);
              filter: grayscale(1) brightness(2);
            }

            &::before {
              content: '';
              position: absolute;
              width: 24px;
              height: 24px;
              left: 50%;
              top: 50%;
              background-image: url('../../images/zoom.svg');
              transform: translate(-50%, -50%);
              background-repeat: no-repeat;
            }
          }
        }

        .del-img {
          width: 18px;
          height: 18px;
          left: auto;
          top: 10px;
          right: 15px;
          transform: translate(0, 0);
          filter: grayscale(1) brightness(2);
          position: absolute;
          display: block;
          z-index: 2;
          cursor: pointer;
        }
      }

      .del-img {
        width: 18px;
        height: 18px;
        left: auto;
        top: 10px;
        right: 15px;
        transform: translate(0, 0);
        filter: grayscale(1) brightness(2);
        position: absolute;
        display: none;
        z-index: 2;
        cursor: pointer;
      }

      span {
        height: 100%;
        width: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1;
        left: 0;
        top: 0;
        display: block;
        opacity: 0;

        img {
          width: 24px;
          height: 24px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      a.overlay {}
    }

  }

  .dashboardRightcard {
    padding: 0px 0px !important; //updated 24px to 0px as per RA sir comment to reduce space from header in all screens(31-08-2022)

    .propname {
      margin-left: 15px;
      margin-bottom: 32px;

      h5 {
        margin: 0;
        font-size: $az-font-18 !important;
        color: $az-black;
        letter-spacing: 0.19px;
        font-family: $az-heading-one;

      }

      ul {
        margin: 0;
        list-style: none;
        display: inline-flex;
        padding: 0;
        align-items: center;

        li {
          font-family: $az-heading-two;
          font-size: $az-font-14 !important;
          color: $az-gray;

          &:first-child {
            color: $az-gray;
          }

          &:nth-child(even) {
            margin: 0 9px;
            background: $az-gray;
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }
        }
      }
    }

    @include viewavtar;

    @media (max-width:767px) {
      padding: 0px 0 0 0px !important;
    }

    .btn-success-outline-small-link {
      @include btn-success-outline-small-link;
    }

  }

  .EditListingimg {
    padding: 0px !important;
    margin: 0 -5px;
  }

  .formcheck-section {
    list-style: none;
    padding: 0;

    // display: inline-flex;
    @media (max-width:767px) {
      width: 100%;
    }

    li {
      display: table-cell;
      margin-right: 0px;
      flex-direction: column;
      max-width: 200px;
      min-width: 200px;
      margin-bottom: 10px;

      @media(max-width: 767px) {
        display: block;
        float: left;
        width: 50%;
      }

      &:last-child {
        margin-right: 0;
      }

      label {
        margin-right: 0px;
        margin-bottom: 0;
      }
    }
  }

  .addCheckmob {
    list-style: none;
    padding: 0;
    // display: inline-flex;

    @media(max-width: 1280px) {
      display: flex;
    }

    li {
      display: inline-block;
      align-items: flex-start;
      margin-right: 0px;
      flex-direction: column;
      max-width: 200px;
      min-width: 200px;
      margin-bottom: 10px;

      @media(max-width: 1280px) {
        display: flex;
      }

      @media(max-width: 767px) {
        max-width: 160px;
        min-width: 160px;
      }

      &:last-child {
        margin-right: 0;
      }

      label {
        margin-right: 0px;
        margin-bottom: 0;
      }
    }

  }

  .addcheckproperty {
    li {
      @media(max-width:767px) {
        min-width: 100% !important;
        max-width: 100% !important;
      }
    }

  }

  .noisebutton {
    top: -162px;
  }

  .keycafebtn {
    top: -177px;
  }

}

.propSection {
  h3 {
    font-family: $az-heading-one;
    color: $az-black;
    font-size: $az-font-24;
    text-align: left;
    margin-bottom: 22px;
    text-transform: capitalize;
  }

  .dashboardRightcard {
    padding: 24px 36px;
    margin-top: 22px;

    .btn-success-outline-small {
      @include btn-success-outline-small;
      font-size: 18px;
      padding: 8px 15px;
    }

    @media (max-width:767px) {
      padding: 24px 9px;
    }
  }
}

.aminitesSection {
  .dashboardRightcard {
    padding: 24px 36px;

    hr {
      width: 100%;
      max-width: 100%;
    }

    .col-md-12 {
      .w-100 {
        .col-md-12 {
          h5 {
            margin: 0;
            font-size: $az-font-16;
            letter-spacing: 0.19px;
            font-family: $az-heading-two;
            color: $az-gray;
            padding-left: 30px;
          }

          &:last-child {
            h5 {
              padding-left: 57px;
            }
          }
        }
      }
    }

    @include aminitiescard;

    @media (max-width:767px) {
      padding: 24px 9px;
    }

    ul.attraction {
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0 !important;
        // max-width: 23%;
        // flex: 0 0 23%;

        // @media(max-width:1440px){
        //   max-width: 30%;
        //   flex: 0 0 30%;
        // }
        // @media(max-width:1360px){
        //   max-width: 30%;
        //   flex: 0 0 30%;
        // }
        // @media(max-width:1199px){
        //   max-width: 30%;
        //   flex: 0 0 30%;
        // }
      }
    }
  }
}

.ck-editor {
  .ck-editor__top {
    .ck-toolbar_grouping {
      border: 1px solid $az-border-color !important;
      border-bottom: none !important;
    }
  }

  .ck-editor__main {
    .ck-editor__editable {
      border: 1px solid $az-border-color !important;
    }
  }
}

.btn-success-outline {
  @include btn-success-outline;
}

// button.btn {
//   @include add-button;
// }

.dashboardRightcard {
  @include tableformate;

  .m-0 {
    .row {
      border-bottom: 1px solid $az-border-color;
      margin-left: 0;
      margin-left: 0;

      &:last-child {
        border-bottom: none;
      }

      .multiImgsection {
        margin: 0;

        .row {
          margin: 0 -15px;
          border-bottom: none;

          figure {
            max-width: 120px;
            margin-bottom: 0;
            // overflow: hidden;
            height: 120px;
            min-height: 120px;


            img {
              max-width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 4px;
              border: 1px solid $az-border-color;
              width: 100%;
            }
          }

          textarea {
            resize: none;
            height: 100%;
            border-radius: 2px;
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-black;

            &:active {
              border: 1px solid $az-border-color;
            }

            &:focus {
              border: 1px solid $az-border-color;
            }

            &::placeholder {
              color: $az-placeholder ;
            }
          }

          .btn-multilist-outline {
            @include btn-multilist-outline;
          }

          .btn-multilist-outline-danger {
            @include btn-multilist-outline-danger;
          }

          span.danger {
            font-size: $az-font-12;
            font-family: $az-heading-two;
            color: $az-inactive-status-bg;
            text-transform: uppercase;
            text-align: center;
            line-height: 0.5;

            b {
              font-weight: normal;
              display: block;
            }
          }

          span.add {
            font-size: $az-font-12;
            font-family: $az-heading-two;
            color: $az-black;
            text-transform: uppercase;
            text-align: center;
            line-height: 0.5;

            b {
              font-weight: normal;
              display: block;
            }
          }

          &:last-child {
            padding-bottom: 0 !important;

          }

          &:first-child {
            border-top: 1px solid $az-border-color;
            padding-top: 20px;
          }

        }

        li {
          border: none !important;
          padding-left: 0;
          padding-right: 0;

          &:first-child {
            .row {
              border-top: none !important;
            }
          }
        }
      }

      .border-none {
        border: none !important;
      }

      .viewfile {
        border-left: 1px solid $az-border-color;
        padding-left: 15px;
        margin-left: 15px;

        a {
          color: $az-black;
          font-size: $az-font-16;
        }
      }
    }
  }

  @include fileUploadeditpage;

  .btn-success-outline-x-small {
    @include btn-success-outline-x-small;
  }

  .btn-danger-outline-x-small {
    @include btn-danger-outline-x-small;
  }

  .btn-success {
    @include common-green-btn
  }

  .pet-f {
    @media(max-width:767px) {
      overflow: auto;

      .col-md-12 {
        width: 1000px;
      }
    }
  }


  .upload {
    margin: 0;

    .form-group {
      display: flex;
      align-items: center;

      .multiImgBuuton {
        max-width: calc(100% - 70px) !important;
      }

      .container {
        margin-top: 0 !important;
        max-width: 70px;

        .gallery {
          display: flex;
          width: 100%;
          grid-template-columns: inherit;
          grid-gap: inherit;

          .gallery-item {
            max-width: 70px;
            margin-bottom: 30px;
            min-height: 50px;
            max-height: 50px;

            .title {
              display: none;
            }

            span {
              display: none;
            }
          }
        }
      }
    }

    .viewfile {
      margin-left: 50px !important;
    }
  }

  .as-react-table {
    .asrt-table-head {
      .col-md-6 {
        &:first-child {
          bottom: -9px;
        }
      }
    }

    .asrt-table-foot {
      .col-md-6 {
        &:first-child {
          bottom: -2px;

          @media (max-width: 767px) {
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: 20px !important;
          }
        }

        &:last-child {
          bottom: -25px;
        }
      }
    }

  }

}

.Affordable-table {

  th,
  td {
    padding: 10px 16px !important;
  }

  @include tableformate;
}

.back-parent {
  img {
    filter: contrast(1.5);
  }

  a {
    color: $az-black;
    font-family: $az-heading-two;
    font-size: $az-font-13;
    text-transform: uppercase;
  }
}

.ck.ck-button {
  z-index: 0;
}

.border-top {
  border-top: 1px solid $az-border-color;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  box-shadow: none !important;
}

.description {
  max-width: 50%;
  width: 50%;
  // p{
  //   @include para;
  // }
}

.noImage {
  min-height: 300px;
  background: $az-light-gray;
}

.multiImgBuuton {

  .btn-multiupload-outline {
    @include btn-multiupload-outline;

  }

}

.propertytable {
  td.status_id {
    b {
      @include inactivestatus;
      margin-left: 0px !important;
    }

    b.active {
      @include activestatus;
      margin-left: 0px !important;
    }
  }
}


.proplistimg {
  // position: fixed;
  // overflow: unset !important;
  // width: 100%;
}

.proplistimg.modal-open {
  position: relative;
}

.proplistimg .modal.show {
  overflow-x: hidden;
  overflow-y: auto;
}

.pet-policy {

  label {

    @media (max-width: 1366px) {
      font-size: $az-font-13;
    }

    @media (max-width: 1280px) {
      font-size: $az-font-13;
    }

  }

  padding: 24px 18px !important;

  @media (max-width: 1366px) {

    padding: 24px 18px !important;

  }

  @media (max-width: 1280px) {

    padding: 24px 15px !important;

  }
}

.showmore {
  text-decoration: underline;
  text-underline-offset: 0.1em;
  font-family: $az-heading-para;
}

.dashboardRightcard {
  .app-contacts {
    .az-content-left-contacts {
      h2 {
        font-family: $az-heading-one;
        font-size: $az-font-24;
        color: $az-black;
        letter-spacing: 1.5px;
        text-transform: capitalize;
        margin-bottom: 0;
      }

      span {
        font-family: $az-heading-para;
        font-size: $az-font-16;
        color: $az-black;
        margin-bottom: 5px;
        display: block;
        text-transform: capitalize;
      }

      .az-contacts-list {
        .az-contact-item {
          //  border-bottom: 1px solid #72818e33;

          &:last-child {
            border-bottom: none !important;
          }

          &:before {
            content: "";
            position: absolute;
            top: -1px;
            right: 0;
            border-top: 1px solid #72818e33;
            width: 75%;
          }

          &:hover {
            background: $az-black;
            color: $az-white;

            .az-img-user {
              // border: 1px solid #f2f2f2;
              border: none
            }

            h6 {
              color: $az-white !important;
            }
          }

          .az-img-user {
            outline: 1px solid rgba(114, 129, 142, .2);
          }

          .az-contact-body {
            h6 {
              font-size: $az-font-14;
              font-family: $az-heading-para;
              color: $az-black;
            }

            span.email {
              font-size: $az-font-13;
              font-family: $az-heading-two;
              color: $az-gray;
              margin-bottom: 0;
              text-transform: lowercase;
            }
          }

        }

        .az-contact-item.selected {
          background: $az-eage-color;
          border-left: 2px solid $az-black;

          &:before {
            border-color: transparent;
          }

          &:hover {
            background: $az-black;
            color: $az-white;

            h6 {
              color: $az-white;
            }

            &:before {
              content: "";
              top: 0
            }

          }

        }


      }
    }

    .az-contact-item_two {
      &::before {
        width: 88% !important;
      }
    }


    .az-content-body-contacts {
      .media-body {
        h4 {
          font-family: $az-heading-para;
          font-size: $az-font-18;
          color: $az-black;
          text-transform: capitalize;
        }
      }

      @include dashbaordprofilecontent;
    }
  }


  .addmultiImages {
    .list-section {
      list-style: none !important;
      padding-left: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      padding-right: 0;

      .list-img {
        // padding: 10px;
        width: calc(768px / 3);
        max-width: calc(768px / 3);
        margin: 0 15px 14px;
        position: relative;
        list-style: none !important;
        cursor: grab;
        @include addmultiImages;

        .label-img {
          position: absolute;
          bottom: 25px;
          left: 10px;
          z-index: 1;

          img {
            background: #fff;
            width: 21px;
            height: 21px;
            padding: 4px;
            border-radius: 50%;
            outline: 1px solid #ddd;
            cursor: default;

            &:first-child {
              margin-right: 7px;
              background: #fff;
              width: 21px;
              height: 21px;
              padding: 4px;
              border-radius: 50%;
              outline: 1px solid #ddd;
            }
          }
        }

        @media(max-width:3040px) {
          width: calc(11% - 10px);
          max-width: calc(11% - 10px);
          margin: 0 9px 5px 10px;
        }

        @media(max-width:2879px) {
          width: calc(13% - 10px);
          max-width: calc(13% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:2440px) {
          width: calc(13% - 10px);
          max-width: calc(13% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1920px) {
          width: calc(19% - 10px);
          max-width: calc(19% - 10px);
          margin: 0 10px 5px;
        }


        @media(max-width:1825px) {
          width: calc(19% - 10px);
          max-width: calc(19% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1640px) {
          width: calc(19% - 10px);
          max-width: calc(19% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1580px) {
          width: calc(24% - 10px);
          max-width: calc(24% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1440px) {
          width: calc(24% - 10px);
          max-width: calc(24% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1420px) {
          width: calc(23% - 10px);
          max-width: calc(23% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1380px) {
          width: calc(30% - 10px);
          max-width: calc(30% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1366px) {
          width: calc(31% - 10px);
          max-width: calc(31% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1280px) {
          width: calc(31% - 10px);
          max-width: calc(31% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1199px) {
          width: calc(23% - 10px);
          max-width: calc(23% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:1024px) {
          width: calc(30% - 10px);
          max-width: calc(30% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:991px) {
          width: calc(29% - 10px);
          max-width: calc(29% - 10px);
          margin: 0 10px 5px;
        }

        @media(max-width:767px) {
          width: calc(31% - 5px);
          max-width: calc(31% - 5px);
          flex: inherit;
          margin: auto;
          margin-bottom: 10px;
          position: relative;
        }

        @media(max-width:700px) {
          width: calc(32% - 5px);
          max-width: calc(32% - 5px);
          flex: inherit;
          margin: auto;
          margin-bottom: 10px;
          position: relative;
        }

        @media(max-width:650px) {
          width: calc(46% - 5px);
          max-width: calc(46% - 5px);
          flex: inherit;
          margin: auto;
          margin-bottom: 10px;
          position: relative;
        }

        @media(max-width:414px) {
          width: 69%;
          max-width: 69%;
          flex: inherit;
          margin: auto;
          margin-bottom: 10px;
          position: relative;
        }

        // @media(max-width:1440px){

        //   width: calc(768px / 3);
        //   max-width: calc(768px / 3);
        //   flex: inherit;

        // }

        // @media(max-width:1360px){

        //   width: calc(768px / 3);
        //   max-width: calc(768px / 3);
        //   flex: inherit;

        // }
        // @media(max-width:1280px){

        //   width: calc(768px / 3);
        //   max-width: calc(768px / 3);
        //   flex: inherit;

        // }
        // @media(max-width:1024px){

        //   width: calc(501px / 2);
        //   max-width: calc(501px / 2);
        //   flex: inherit;

        // }
        // @media(max-width:991px){

        //   width: calc(508px / 2);
        //   max-width: calc(508px / 2);
        //   flex: inherit;

        // }
        // @media(max-width:767px){

        //   width: calc(445px / 2);
        //   max-width: calc(445px / 2);
        //   flex: inherit;
        //   margin: auto;
        //   margin-bottom: 40px;
        //   position: relative;
        // }
        .addImg {
          .dropdown {
            position: relative;

            .dropdown-toggle {
              position: absolute;
              top: 5px;
              right: 6px;
              z-index: 1;

              &::after {
                display: none;
              }

              img {
                width: 30px;
              }
            }

            .dropdown-menu.show {
              transform: translate(0px, 48px) !important;
              right: auto !important;
              left: auto !important;
              border-radius: 8px !important;
              .dropdown-item{
                border-radius: 8px !important;
                &:hover{
                  border-radius: 8px !important;
                }
                &:active{
                  background-color: $az-black !important;
                  color: $az-white !important;
                }
              }
            }


          }

          h5 {
            position: absolute;
            bottom: -15px;
            width: 100%;
          }
        }

        .addImg.uploaded {
          figure {
            min-height: 180px;
          }
        }

      }
    }
  }

  .overview-image {

    figure {
      position: relative;
      margin: auto;
      text-align: center;
      // max-width: fit-content;
      // min-width: 850px;
      // max-width: 850px;
      max-width: 100%;
      max-height: 640px;
      // min-height: 640px;
      overflow: hidden;
      // border: 1px solid $az-border-color;

      @media (max-width:1440px) {
        max-width: 100%;
        max-height: 640px;
        // min-height: 640px;
      }

      @media (max-width:1366px) {
        max-width: 100%;
        max-height: 640px;
        // min-height: 640px;
      }

      @media (max-width:1280px) {
        max-width: 100%;
        max-height: 560px;
        // min-height: 560px;
      }

      img {
        max-width: 100%;
        max-height: 640px;

        @media (max-width:1366px) {
          max-width: 100%;
          max-height: 640px;
        }

        @media (max-width:1280px) {
          max-width: 100%;
          max-height: 560px;
        }
      }


      // &::after {
      //       position: absolute;
      //       content: '';
      //       bottom: -33px;
      //       width: 100%;
      //       height: 100px;
      //      background-image: url(../../images/prop-overview-img-shadow.png);
      //      background-repeat: no-repeat;
      //      background-position: 100%;
      //      background-size: 100%;
      //      z-index: 0;
      //      left: 0;

      // }

    }

    .overview {}
  }

  .addmultiImages {
    .ReactGridGallery {
      list-style: none;
      padding-left: 3px;
      padding-right: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: -10px;
      // justify-content: space-between;

      .ReactGridGallery_tile {
        padding: 0px !important;
        width: calc(768px / 3);
        max-width: calc(768px / 3);
        margin: 0 !important;
        float: inherit !important;
        margin: 0 15px 30px !important;

        // @media(max-width:1440px){

        //   width: calc(768px / 3);
        //   max-width: calc(768px / 3);
        //   flex: inherit;

        // }

        // @media(max-width:1360px){

        //   width: calc(768px / 3);
        //   max-width: calc(768px / 3);
        //   flex: inherit;

        // }
        // @media(max-width:1280px){

        //   width: calc(768px / 3);
        //   max-width: calc(768px / 3);
        //   flex: inherit;

        // }
        // @media(max-width:1024px){

        //   width: calc(501px / 2);
        //   max-width: calc(501px / 2);
        //   flex: inherit;

        // }
        // @media(max-width:991px){

        //   width: calc(508px / 2);
        //   max-width: calc(508px / 2);
        //   flex: inherit;

        // }
        // @media(max-width:767px){

        //   width: calc(445px / 2);
        //   max-width: calc(445px / 2);
        //   flex: inherit;
        //   margin: auto;
        // }

        @media(max-width:3040px) {
          width: calc(11% - 10px);
          max-width: calc(11% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:2879px) {
          width: calc(13% - 10px);
          max-width: calc(13% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:2440px) {
          width: calc(12% - 10px);
          max-width: calc(12% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1920px) {
          width: calc(16% - 10px);
          max-width: calc(16% - 10px);
          margin: 0 10px 21px !important;
        }


        @media(max-width:1825px) {
          width: calc(17% - 10px);
          max-width: calc(17% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1640px) {
          width: calc(19% - 10px);
          max-width: calc(19% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1580px) {
          width: calc(21% - 10px);
          max-width: calc(24% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1440px) {
          width: calc(24% - 10px);
          max-width: calc(24% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1420px) {
          width: calc(23% - 10px);
          max-width: calc(23% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1380px) {
          width: calc(26% - 10px);
          max-width: calc(30% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1366px) {
          width: calc(26% - 10px);
          max-width: calc(31% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1280px) {
          width: calc(29% - 10px);
          max-width: calc(30% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1199px) {
          width: calc(23% - 10px);
          max-width: calc(23% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:1024px) {
          width: calc(23% - 10px);
          max-width: calc(23% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:991px) {
          width: calc(23% - 10px);
          max-width: calc(23% - 10px);
          margin: 0 10px 21px !important;
        }

        @media(max-width:767px) {
          width: calc(31% - 5px);
          max-width: calc(31% - 5px);
          flex: inherit;
          margin: auto !important;
          margin-bottom: 10px !important;
          position: relative;
        }

        @media(max-width:700px) {
          width: calc(32% - 5px);
          max-width: calc(32% - 5px);
          flex: inherit;
          margin: auto !important;
          margin-bottom: 10px !important;
          position: relative;
        }

        @media(max-width:650px) {
          width: calc(39% - 5px);
          max-width: calc(39% - 5px);
          flex: inherit;
          margin: auto !important;
          margin-bottom: 10px !important;
          position: relative;
        }

        @media(max-width:512px) {
          width: calc(48% - 5px);
          max-width: calc(48% - 5px);
          flex: inherit;
          margin: auto !important;
          margin-bottom: 10px !important;
          position: relative;
        }

        @media(max-width:414px) {
          width: 60%;
          max-width: 60%;
          flex: inherit;
          margin: auto !important;
          margin-bottom: 10px !important;
          position: relative;
        }

        .ReactGridGallery_tile-viewport {
          margin: 0;
          max-width: 100%;
          max-height: 180px;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          border: 1px solid $az-border-color;
          background: #fff;
          min-height: 180px;
          text-align: center;

          @media (max-width:1680px) {
            max-width: 100%;
            max-height: 180px;

          }

          @media (max-width:1280px) {
            max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }

          @media (max-width:1199px) {
            max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }

          @media (max-width:1024px) {
            max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:991px) {
            max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:767px) {
            max-width: 100%;
            max-height: 180px;
          }

          img {
            // max-width: 100%;
            max-height: 180px;

            @media (max-width:1280px) {
              // max-width: 100%;
              max-height: 180px;
            }

            @media (max-width:1199px) {
              // max-width: 100%;
              max-height: 180px;
            }

            @media (max-width:767px) {
              // max-width: 100%;
              max-height: 180px;
            }

          }
        }

        .ReactGridGallery_tile-bottom-bar {
          bottom: inherit !important;
        }

        &:hover {
          .ReactGridGallery_tile-bottom-bar {
            position: relative;
            bottom: inherit !important;

            div {
              &:first-child {
                span {
                  padding: 0 !important;
                  background: none !important;

                  img {
                    width: 18px;
                    height: 18px;
                    right: 30px;
                    top: 10px;
                    right: 30px;
                    transform: translate(0, 0);
                    filter: grayscale(1) brightness(2);
                    position: absolute;
                    z-index: 1;
                    cursor: pointer;
                    bottom: inherit;
                  }
                }
              }

              &:last-child {
                span {
                  padding: 0 !important;
                  background: none !important;

                  img {
                    width: 18px;
                    height: 18px;
                    right: 30px;
                    top: 10px;
                    right: 60px;
                    transform: translate(0, 0);
                    filter: grayscale(1) brightness(2);
                    position: absolute;
                    z-index: 1;
                    cursor: pointer;
                    bottom: inherit;
                  }
                }
              }

            }

          }

          .ReactGridGallery_tile-viewport {
            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.6);
              left: 0;
              top: 0;
              cursor: pointer;
            }

            &::after {
              content: '';
              position: absolute;
              width: 24px;
              height: 24px;
              left: 50%;
              top: 50%;
              background-image: url('../../images/zoom.svg');
              transform: translate(-50%, -50%);
              background-repeat: no-repeat;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .documnet-section {
    position: relative;
    z-index: 1;
  }

  .documnet-section-upload {
    position: relative;
    z-index: 0;
  }
}

.propfees {


  // @media (max-width:1366px) {
  //   padding-right: 0 !important;
  // }

  // @media (max-width:1280px) {
  //   padding-right: 0 !important;
  // }

  // .add-delete {
  //   margin-top: -15px ;
  // }

  // .colmn2 {
  //   flex-direction: column;
  //   justify-content: start !important;
  //   padding-top: 13px;
  //   label {
  //     width: 100% !important;
  //   }
  //   input {
  //       width: 25% !important;
  //       margin-left: 0 !important;

  //     }
  // }

  // .colmn3 {

  //   @media (max-width:1366px) {
  //   max-width: 16.66667% !important;
  //   flex: 0 0 16.66667% !important;
  //   }
  //   @media (max-width:1280px) {
  //     max-width: 16.66667% !important;
  //     flex: 0 0 16.66667% !important;
  //   }
  //   @media (max-width:1200px) {
  //     max-width: 16.66667% !important;
  //     flex: 0 0 16.66667% !important;
  //   }
  // }

  // .colmn1 {

  //      max-width: 115px !important;

  //     @media (max-width:1366px) {
  //       .colmn1 {
  //         max-width: 95px !important;
  //       }
  //       .colmn2 {
  //         max-width: 70px !important;
  //         flex: 0 0 70px !important;
  //         input {
  //             width: 25%;
  //         }
  //       }
  //     }

  //     @media (max-width:1280px) {
  //       .colmn1 {
  //         max-width: 95px !important;
  //       }
  //       .colmn2 {
  //         max-width: 70px !important;
  //         flex: 0 0 70px !important;
  //         input {
  //           width: 25%;
  //         }
  //       }
  //     }
  //     @media (max-width:1200px) {
  //       .colmn1 {
  //         max-width: 95px !important;
  //       }
  //       .colmn2 {
  //         max-width: 70px !important;
  //         flex: 0 0 70px !important;
  //         input {
  //           width: 25%;
  //         }
  //       }
  //     }
  // }
}

.pet {

  @media (max-width:1540px) {
    padding-right: 0;
  }

  @media (max-width:1366px) {
    padding-right: 0;
  }

  @media (max-width:1280px) {
    padding-right: 0;
  }

  @media (max-width:1200px) {
    padding-right: 0;
  }

}

// .colmn4 {

//   @media (max-width:1366px) {
//     max-width: 93px !important;
//     flex: 0 0 93px !important;
//   }
//   @media (max-width:1280px) {
//     max-width: 93px !important;
//     flex: 0 0 93px !important;
//   }
//   @media (max-width:1200px) {
//     max-width: 93px !important;
//     flex: 0 0 93px !important;
//   }
// }


// .colmn5 {
//   @media (max-width:1366px) {
//     max-width: 110px !important;
//     flex: 0 0 110px !important;
//     margin-left: 33px;
//     margin-right: -13px;
//   }
//   @media (max-width:1280px) {
//     max-width: 110px !important;
//     flex: 0 0 110px !important;
//     margin-left: 33px;
//     margin-right: -13px;
//   }
//   @media (max-width:1200px) {
//     max-width: 110px !important;
//     flex: 0 0 110px !important;
//     margin-left: 33px;
//     margin-right: -13px;
//   }
// }

// .colmn6 {
//   @media (max-width:1366px) {
//     max-width: 110px !important;
//     flex: 0 0 110px !important;
//     margin-left: 30px;
//     margin-right: -13px;
//   }
//   @media (max-width:1280px) {
//     max-width: 110px !important;
//     flex: 0 0 110px !important;
//     margin-left: 30px;
//     margin-right: -13px;
//   }
//   @media (max-width:1200px) {
//     max-width: 110px !important;
//     flex: 0 0 110px !important;
//     margin-left: 30px;
//     margin-right: -13px;
//   }
// }

.petFeeSection2 {

  padding: 0 30px !important;

  @media (max-width:1540px) {
    padding: 0 !important;
  }

  @media (max-width:1366px) {
    padding: 0 !important;
  }

}

.petFees {

  padding: 0 15px !important;

  @media (max-width:1540px) {
    padding: 0 15px !important;
  }

  @media (max-width:1366px) {
    padding: 0 15px !important;
  }

}

.petFeeSection,
.FloorFeeSection {

  padding: 0 !important;

  @media (max-width:1540px) {
    padding: 0 !important;
  }

  @media (max-width:1366px) {
    padding: 0 !important;
  }


  .petForm {

    thead {
      tr {
        &:hover {
          background: none !important;
        }

        th {
          padding: 16px 4px 0 !important;

          @media (max-width:1280px) {
            padding: 16px 4px 0 !important;
          }

          @media (max-width:1200px) {
            padding: 16px 4px 0 !important;
          }

          &:first-child {
            padding-left: 0 !important;
          }

          label {

            @media (max-width:1540px) {
              margin-bottom: 5px !important;
            }

            @media (max-width:1280px) {
              margin-bottom: 5px !important;
            }

            @media (max-width:1200px) {
              margin-bottom: 5px !important;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background: none !important;
        }

        td {
          padding: 0px 0px 5px !important;

          @media (max-width:1280px) {
            padding: 0px 0px 5px !important;
          }

          @media (max-width:1200px) {
            padding: 0px 0px 5px !important;
          }

          &:first-child {
            padding-left: 0 !important;
          }

          button {
            padding: 9px 5px !important;
            border: none !important;

            @media (max-width:1540px) {
              padding: 9px 5px !important;
              border: none !important;

              &:hover {
                background: none !important;
                border: none !important;

                img {
                  filter: inherit !important;
                }
              }
            }

            @media (max-width:1280px) {
              padding: 9px 5px !important;
              border: none !important;

              &:hover {
                background: none !important;
                border: none !important;

                img {
                  filter: inherit !important;
                }
              }
            }

            @media (max-width:1200px) {
              padding: 9px 5px !important;
              border: none !important;

              &:hover {
                background: none !important;
                border: none !important;

                img {
                  filter: inherit !important;
                }
              }
            }

            &:hover {
              background: none !important;
              border: none !important;

              img {
                filter: inherit !important;
              }
            }
          }
        }
      }
    }

    .w-1 {

      width: 185px;
      min-width: 185px;
      max-width: 185px;

      @media (max-width:1540px) {
        width: 135px;
        min-width: 135px;
        max-width: 135px;
      }

      @media (max-width:1280px) {
        width: 135px;
        min-width: 135px;
        max-width: 135px;
      }

      @media (max-width:1200px) {
        width: 93px;
        min-width: 93px;
        max-width: 93px;
      }
    }

    .w-2 {

      width: 150px;
      min-width: 150px;
      max-width: 150px;

      @media (max-width:1540px) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }

      @media (max-width:1280px) {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }

      @media (max-width:1200px) {
        width: 90px;
        min-width: 90px;
        max-width: 90px;
      }
    }

    .w-3 {
      width: 90px;
      min-width: 90px;
      max-width: 90px;

      @media (max-width:1540px) {
        width: 75px;
        min-width: 75px;
        max-width: 75px;
      }

      @media (max-width:1280px) {
        width: 75px;
        min-width: 75px;
        max-width: 75px;
      }

      @media (max-width:1200px) {
        width: 75px;
        min-width: 75px;
        max-width: 75px;
      }
    }

    .w-4 {

      width: 200px;
      min-width: 200px;
      max-width: 200px;

      @media (max-width:1540px) {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
      }

      @media (max-width:1280px) {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
      }

      @media (max-width:1200px) {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
      }
    }

    .multiselect {

      .css-1r5gbi5-menu {
        display: block !important;
        width: 175px !important;
        max-width: 175px !important;
        position: absolute;
        left: 0;
      }
    }

    // .input-group {
    //   .css-1r5gbi5-menu {
    //     display: block;
    //     width: 100% !important;
    //   }
    // }
  }

  table {
    width: 100%;
  }
}

.floorPlan {
  padding-right: 0 !important;

  @media (max-width:1366px) {
    padding: 0 !important;
  }

  .FloorFeeSection {

    padding: 0 0px !important;

    @media (max-width:1366px) {
      padding: 0 !important;
    }

    .petForm {

      thead {
        tr {
          &:hover {
            background: none !important;
          }

          th {
            padding-bottom: 0 !important;

            &:first-child {
              padding-left: 0 !important;
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background: none !important;
          }

          td {
            padding-top: 0 !important;
            width: calc(100% / 4);

            @media (max-width:1366px) {
              width: calc(100% / 4);
            }

            &:first-child {
              padding-left: 0 !important;
            }
          }
        }
      }

    }

    table {
      width: 100%;
    }
  }

  // .fplan {
  //   padding: 0 0px;
  //   @media (max-width:1366px){
  //     padding: 0 15px;
  //   }
  // }
}

.checklist-table {

  button.btn-success-outline-x-small {
    //  @include btn-multilist-outline;
    background: none !important;
    border: none !important;
    padding: 0;

    img {
      filter: initial;
    }

    &:hover,
    &:focus,
    &:active {
      background: none !important;
      border: none !important;

      img {
        filter: initial;
      }
    }
  }

  button.btn-danger-outline-x-small {
    //  @include btn-multilist-outline-danger;
    background: none !important;
    border: none !important;
    padding: 0;

    img {
      filter: initial;
    }

    &:hover,
    &:focus,
    &:active {
      background: none !important;
      border: none !important;

      img {
        filter: initial;
      }
    }
  }
}

.InspectionView {
  .tableFormatesimple {
    .az-inspection-blue {
      color: #0069c0;
    }

    .az-inspection-red {
      color: #de0000;
    }

    caption {
      font-family: $az-heading-two;
      text-transform: inherit;
      font-size: $az-font-14;
      color: $az-black;
      font-weight: normal;
      background: $az-eage-color;
      width: 100%;
      padding: 10px 15px;
      display: block;
    }

    thead {
      th {
        border: none;
      }
    }

    tbody {
      tr {
        &:nth-child(2) {
          td {
            border: none;
          }

        }

        td {
          vertical-align: inherit;

          img {
            margin-bottom: 0;
          }

          label {
            margin-bottom: 0;
          }
        }

      }
    }
  }
}

.dashbaord-pageHeadertitle {
  h4 {
    @include heading4;
  }

}

.child-menu-section {

  .deskchildMenu {
    display: block;

    @media (max-width:1199px) {
      display: none;
    }

    @media (max-width:767px) {
      display: none;
    }
  }

  .mobchildMenu {
    display: none;

    @media (max-width:1199px) {
      display: block;
    }

    @media (max-width:767px) {
      display: block;
    }

    .submenu {
      // background: $az-black;

      .dropdown {
        width: 100%;

        @media(max-width:1199px) {
          position: fixed;
          top: 65px;
          z-index: 10;

        }

        @media(max-width:991px) {
          position: fixed;
          top: 108px;
          z-index: 10;

        }

        @media(max-width:767px) {
          position: fixed;
          top: 110px;
          z-index: 10;

        }

        span {
          color: $az-white;

          @media(max-width:1199px) {
            color: $az-black;
          }

          @media(max-width:991px) {
            color: $az-black;
          }

          @media(max-width:767px) {
            color: $az-black;
          }


        }

        button {
          background-color: $az-black !important;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &::after {
            display: none;
          }

          &:focus,
          &:hover,
          &:active {
            box-shadow: none;
            border: none;
            outline: none;
          }
        }

        .dropdown-menu.show {
          width: 100%;
          margin-top: -3px !important;
          height: 100vh;
          z-index: -1;


          @media(max-width:1199px) {
            margin-top: 0px !important;
            padding-top: 15px;
            height: calc(100vh - 99px);
            overflow: auto;
          }

          @media(max-width:767px) {
            margin-top: -2px !important;
            padding-top: 15px;
            height: calc(100vh - 146px);
            overflow: auto;
          }
        }
      }
    }
  }

}

// Calendar custom design start

.react-datepicker__tab-loop {
  @include calendarNewformate;
}

// Calendar custom design End

.mroComment {
  max-height: 700px;
  overflow: auto;
  padding-right: 30px;
  min-height: 700px;

  p {
    padding: 10px 15px;
    background: $az-input-bg-color;
    margin: 5px 0;
    // border-radius: 50px;
  }

  b {
    margin-left: 15px;
    font-weight: normal;
  }

  i {
    font-style: normal;
    font-family: $az-heading-para;
    margin-left: 5px;
  }

  .deleteicon {
    img {
      width: 14px;
      margin-top: -2px;
    }
  }
}

.dashboardRightcard {
  .viewfile-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .viewfile {
      border: none !important;
      padding: 15px 0 !important;
      margin: 0 !important;
      display: block;

      img {
        max-width: 40px;
      }

      p {
        margin-bottom: 0;
      }

      a {
        display: flex;
        align-items: center;
      }
    }
  }

  .upload-files-section {
    width: 100%;

    .image-upload-wrap {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      border: 2px dashed $az-form-input-bdr !important;

      .drag-text {
        h5 {
          text-transform: none !important;
        }
      }
    }

    .container {
      margin: 0;
      padding: 0;
      max-width: 100% !important;

      .gallery {
        grid-template-columns: none;
        grid-gap: unset;
        flex-direction: row;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 15px;

        .gallery-item {
          box-shadow: none;
          padding: 0 10px;
          overflow: inherit;

          .gallery-image {
            transition: none;
            background: transparent;

            &:hover {
              transform: none;
            }
          }

          .gallery-image-div {
            .button {
              background: rgba(255, 255, 255, 0.7);
              width: 15px;
              height: 15px;
              line-height: 15px;
              border-radius: 50%;
              left: -6px;
              right: inherit;
              top: -8px;
              z-index: 0;

              span {
                color: $az-black;
                display: block;
                font-size: $az-font-17;
                line-height: unset;
                text-align: center;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .form-group {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

    }
  }

  .uploadSection {
    border-left: 1px solid #72818e33;

    @media(max-width: 767px) {
      border: none;
    }

  }

  .min-rent {
    position: relative;
    top: -26px;
    margin-bottom: -20px;

    @media (max-width: 1440px) {
      margin-top: 0;
      position: inherit;
    }
  }

  .min-rent-last {
    position: relative;
    top: -26px;
    margin-bottom: -20px;

    @media (max-width: 1440px) {
      margin-top: 0;
      position: relative;
    }
  }
}

.floorplanImage {
  padding: 0px 0px 24px 8px !important;
}

// gallery

.list-img {
  // padding: 10px;
  width: 256px;
  max-width: 256px;
  margin: 0 15px 14px;
  position: relative;
  list-style: none !important;
  cursor: grabbing;
  @include addmultiImages;

  .label-img {
    position: absolute;
    bottom: 25px;
    left: 10px;
    z-index: 1;

    img {
      margin-right: 0px;
      background: #fff;
      width: 21px;
      height: 21px;
      padding: 4px;
      border-radius: 50%;
      outline: 1px solid #ddd;
      cursor: default;

      &:first-child {
        margin-right: 7px;
        background: #fff;
        width: 21px;
        height: 21px;
        padding: 4px;
        border-radius: 50%;
        outline: 1px solid #ddd;
      }
    }
  }


  @media(max-width:1440px) {

    width: calc(768px / 3);
    max-width: calc(768px / 3);
    flex: inherit;

  }

  @media(max-width:1360px) {

    width: calc(768px / 3);
    max-width: calc(768px / 3);
    flex: inherit;

  }

  @media(max-width:1280px) {

    width: calc(768px / 3);
    max-width: calc(768px / 3);
    flex: inherit;

  }

  @media(max-width:1024px) {

    width: calc(501px / 2);
    max-width: calc(501px / 2);
    flex: inherit;

  }

  @media(max-width:991px) {

    width: calc(508px / 2);
    max-width: calc(508px / 2);
    flex: inherit;

  }

  @media(max-width:767px) {

    width: calc(445px / 2);
    max-width: calc(445px / 2);
    flex: inherit;
    margin: auto;
    margin-bottom: 40px;
    position: relative;
  }

  .addImg {
    .dropdown {
      position: relative;

      .dropdown-toggle {
        position: absolute;
        top: 5px;
        right: 6px;
        z-index: 1;

        &::after {
          display: none;
        }

        img {
          width: 30px;
        }
      }

      .dropdown-menu.show {
        transform: translate(0px, 48px) !important;
        right: auto !important;
        left: auto !important;
        border-radius: 8px !important;
      }
    }

    h5 {
      position: absolute;
      bottom: -15px;
      width: 100%;
    }
  }

  .addImg.uploaded {
    figure {
      min-height: 180px;
    }
  }
}

.addNewimage {
  width: 256px;
  max-width: 256px;
  margin: 0 15px 30px;
  position: relative;
  list-style: none !important;
  cursor: pointer;
  border: 1px dashed #333;
  border-radius: 8px;
  height: 180px;

  span {
    font-family: $az-heading-two;
    color: $az-black;
    font-size: $az-font-14;
    margin-top: 10px;
  }

  @media(max-width:3040px) {
    width: calc(11% - 10px);
    max-width: calc(11% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:2879px) {
    width: calc(13% - 10px);
    max-width: calc(13% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:2440px) {
    width: calc(13% - 10px);
    max-width: calc(13% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1920px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }


  @media(max-width:1825px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1640px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1580px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1440px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1420px) {
    width: calc(23% - 10px);
    max-width: calc(23% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1380px) {
    width: calc(30% - 10px);
    max-width: calc(30% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1366px) {
    width: calc(31% - 10px);
    max-width: calc(31% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1280px) {
    width: calc(31% - 10px);
    max-width: calc(31% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1199px) {
    width: calc(23% - 10px);
    max-width: calc(23% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1024px) {
    width: calc(30% - 10px);
    max-width: calc(30% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:991px) {
    width: calc(29% - 10px);
    max-width: calc(29% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:767px) {
    width: calc(31% - 5px);
    max-width: calc(31% - 5px);
    flex: inherit;
    margin: auto;
    margin-bottom: 10px;
    position: relative;
  }

  @media(max-width:700px) {
    width: calc(32% - 5px);
    max-width: calc(32% - 5px);
    flex: inherit;
    margin: auto;
    margin-bottom: 10px;
    position: relative;
  }

  @media(max-width:650px) {
    width: calc(46% - 5px);
    max-width: calc(46% - 5px);
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }

  @media(max-width:414px) {
    width: 69%;
    max-width: 69%;
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }
}

.cardsection {
  .dashboardRightcard.overview {
    padding: 0 !important;

    h3 {
      margin-bottom: 0;
    }
  }
}

//add Lease Save btn flash
.dashboardRightcard {
  @include flashSavebtn;
}


//  Message & notification style

// .message .leftSection .listItem,
// .message .middleSection .chatSection .chatList,
// .addContacts .addContactsList .listSetction,
// .showquickreplyModal .listSetction {
//   scrollbar-width: 3px;
//   scrollbar-color: $az-eage-color #e9e9e9;
//   -webkit-scrollbar-width: 3px;
//   -moz-scrollbar-width: 3px;
// }

// .message .leftSection .listItem::-webkit-scrollbar,
// .message .middleSection .chatSection .chatList::-webkit-scrollbar,
// .addContacts .addContactsList .listSetction::-webkit-scrollbar,
// .showquickreplyModal .listSetction::-webkit-scrollbar {
//   width: 3px;
//   height: 5px;
// }

// .message .leftSection .listItem::-webkit-scrollbar-track,
// .message .middleSection .chatSection .chatList::-webkit-scrollbar-track,
// .addContacts .addContactsList .listSetction::-webkit-scrollbar-track,
// .showquickreplyModal .listSetction::-webkit-scrollbar-track {
//   background-clip: content-box;
//   border: 0px solid transparent;
//   width: 3px;
//   background-color: #e9e9e9;
// }

// .message .leftSection .listItem::-webkit-scrollbar-thumb,
// .message .middleSection .chatSection .chatList::-webkit-scrollbar-thumb,
// .addContacts .addContactsList .listSetction::-webkit-scrollbar-thumb,
// .showquickreplyModal .listSetction::-webkit-scrollbar-thumb {
//   background-color: $az-eage-color;
// }

// .message .leftSection .listItem::-webkit-scrollbar-thumb:hover,
// .message .middleSection .chatSection .chatList::-webkit-scrollbar-thumb:hover,
// .addContacts .addContactsList .listSetction::-webkit-scrollbar-thumb:hover,
// .showquickreplyModal .listSetction::-webkit-scrollbar-thumb:hover {
//   background-color: $az-eage-color;
// }

// .message .leftSection .listItem::-webkit-scrollbar-corner,
// .message .leftSection .listItem::-webkit-scrollbar-track,

// .message .middleSection .chatSection .chatList::-webkit-scrollbar-corner,
// .message .middleSection .chatSection .chatList::-webkit-scrollbar-track,

// .addContacts .addContactsList .listSetction:-webkit-scrollbar-corner,
// .addContacts .addContactsList .listSetction:-webkit-scrollbar-track,

// .showquickreplyModal .listSetction::-webkit-scrollbar-corner,
// .showquickreplyModal .listSetction::-webkit-scrollbar-track {
//   background-color: #e9e9e9;
//   width: 3px;
// }

[class^="message-container"] {
  /* height: 200px; */
  overflow-y: scroll;
  width: 3px;
}

[class^="message-container"] div {
  /* height: 400px;
width: 200%;
padding: 4rem;
background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
}

.message {

  .nav.nav-tabs {
    background: none;
    padding: 15px 0 0;

    .nav-link {
      margin: 0;
      border: none;
      background-color:$az-light-white;
      color: $az-black;

      &:hover,
      &:focus {
        background: $az-black ;
        color: $az-white;
      }

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }

    .nav-link.active {
      background: $az-eage-color ;
      letter-spacing: inherit;

      &:hover,
      &:focus {
        background: $az-black ;
        color: $az-white;
      }
    }
  }

  .addContact {
    position: absolute;
    right: 0;
    top: 18px;
  }

  .dashboardRightcard {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0px 10px 15px;
    height: 77vh;

    @media (max-width:1440px) {
      margin-top: 0 !important;
    }

    @media (max-width:991px) {
     height: 100%;
    }

    .col-xxl-3 {
      flex: 0 0 20%;
      max-width: 20%;

      @media(max-width: 1280px) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      @media(max-width: 1199px) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      @media(max-width: 1023px) {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

    .col-xxl-6 {
      flex: 0 0 60%;
      max-width: 60%;

      @media(max-width: 1280px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media(max-width: 1199px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media(max-width: 1023px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    .leftSection {
      width: 100%;
      // min-height: auto;
      min-height: 100%;
      .header {
        margin-bottom: 10px;
        position: relative;
        margin-top: 5px;

        .searchSection {
          display: flex;
          position: relative;
          width: 100%;

          input {
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-black !important;
            background: $az-light-bg !important;
            border: none !important;
            box-shadow: none !important;
            padding-right: 42px;
            border-radius: 4px !important;

            &:hover,
            &:focus {
              border: none !important;
              box-shadow: none !important;
            }

          }

          span {
            width: 42px;
            height: 42px;
            display: block;
            text-align: center;
            line-height: 42px;
            position: absolute;
            right: 0;

          }
        }

        .listSetction {
          background: $az-light-bg !important;
          box-shadow: 0px 1px 2px #ddd !important;
          margin-top: 2px;
          position: absolute;
          top: 42px;
          width: 100%;

          ul {
            list-style: none;
            padding: 0;

            li {
              padding: 10px 15px;

              a {
                text-decoration: none;
                color: $az-black;
                display: flex;

                figure {
                  margin: 0;
                  display: block;
                  width: 32px;
                  height: 32px;
                  // border: 2px solid #000;
                  border-radius: 50%;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 100%;
                  }
                }

                .content {
                  width: calc(100% - 32px);
                  padding:0 0 0 10px !important;
                  display: flex;
                  align-items: center;

                  b {
                    font-family: $az-heading-para;
                    font-weight: normal;
                    font-size: $az-font-14;

                  }

                }

              }

              &:hover,
              &:active {
                background: $az-white;
              }
            }
          }
        }
      }

      .scroll_btm_box{
        width: 100%;
        height: 40px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        font-size: $az-font-14 !important;
        display: grid;
        place-content: center;
        place-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        right: 0px;
        background: #fff;
        cursor: pointer;
        span{
          font-family: $az-heading-para;
          font-size: $az-font-14 !important;
        }
        @media (max-width:767px) {
          bottom: 0 !important;
        }
      }


      .listItem {
        margin-top: 2px;
        overflow-x: auto;
        // max-height: 630px;
        height: 62vh;
        position: relative;


      

        // @media (max-width:1400px) {
        //   max-height: 480px;
        // }

        // @media (max-width:1360px) {
        //   max-height: 440px;
        // }

        // @media (max-width:1280px) {
        //   max-height: 400px;
        // }

        ul {
          list-style: none;
          padding: 0;
          margin-right: 5px;

          li {
            padding: 0;
            border-bottom: 1px solid $az-border-color;
            margin: 0;
            a {
              text-decoration: none;
              color: $az-black;
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 10px 10px 15px 10px;

              .side_message {
                margin-top: 5px;
                p{
                  margin: 0 !important;
                  text-align: center !important;
                  font-size: $az-font-10 !important;
                  padding: 0 !important;
                }

              figure {
                margin: 0;
                display: block;
                width: 32px;
                height: 32px;
                // border: 2px solid #000;
                border-radius: 50%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 100%;
                }
              }

            }

              .content {
                width: calc(100% - 32px);
                padding:0 0 0 8px !important;
                display: flex;
                justify-content: space-between;
                position: relative;
                border-left: 1px solid $az-light-white;
                margin-left: 8px;
                place-items: center;

                b {
                  font-family: $az-heading-two;
                  font-weight: bold !important;
                  font-size: $az-font-14 !important;
                  color: $az-light-black !important;
                }

                span {
                  b {
                    font-weight: normal;
                    color: $az-black;

                  }

                  background: transparent;
                  font-size: $az-font-13 !important;
                  margin-bottom: 0px;
                  color: $az-gray;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  position: relative;
                  width: 300px;

                  @media (max-width:1710px) {
                    width: 230px;
                  }

                  @media (max-width:1365px) {
                    width: 220px;
                  }
                }

                span.recive_msg{
                  font-weight: bold;
                  color: $az-light-black;
                }

                p {
                  font-size: $az-font-13 !important;
                  margin-bottom: 5px;
                  color: $az-gray;
                }

                i {
                  font-style: normal;
                  text-align: right;
                  font-family: $az-heading-two;
                  font-weight: normal;
                  font-size: $az-font-12 !important;
                  color: $az-gray;
                  // text-transform: lowercase;
                  position: absolute;
                  bottom: -12px;
                  width: max-content;
                }

                b.aleart {
                  background: $az-eage-color;
                  color: $az-black;
                  text-align: center;
                  height: 18px;
                  width: 18px;
                  line-height: 18px;
                  border-radius: 50%;
                  font-size: $az-font-10 !important;
                  font-family: $az-heading-para;

                }


                b.aleart_msg {
                  background: $az-bg-danger;
                  color: $az-black;
                  text-align: center;
                  height: 12px;
                  width: 12px;
                  line-height: 18px;
                  border-radius: 50%;
                  font-size: $az-font-10 !important;
                  font-family: $az-heading-para;
                  position: absolute;
                  top: 0%;
                }

                .chatmsgcontent {
                  align-items: flex-start;
                }

              }

              &:hover,
              &:active {
                background: $az-light-white;
                .content{
                  border-color:$az-eage-color ;
                }
                li{
                  border-color: $az-eage-color;
                }
              }

            }

            a.active {
              background:$az-light-white;
            }

            &:hover,
            &:active {
              background: $az-light-bg;
              border-color:$az-eage-color ;
              .content{
                border-color:$az-eage-color ;
              }
            }
          }
        }
      }
    }

    .middleSection {
      width: 100%;
      min-height: auto;
      padding: 0 10px;
      border-left: 1px solid $az-eage-color;
      border-right: 1px solid $az-eage-color;

      @media (max-width:767px) {
        padding: 0 10px;
        margin-top: 50px;
      }

      .header {
        margin-bottom: 0px;
        position: relative;
        border-bottom: 1px solid $az-eage-color;
        display: flex;
        place-items: center;
        justify-content: space-between;

        figure {
          margin: 0;
          display: block;
          width: 32px;
          height: 32px;
          // border: 2px solid #000;
          border-radius: 50%;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
            margin-top: 5px;
          }
        }

        h6 {
          font-family: $az-heading-two;
          font-size: $az-font-18;
          color: $az-black;
          padding:10px 0;
          margin-bottom: 0;

        }
      }

      .chatSection {
        // min-height: 645px;
        height: 69vh;
        position: relative;
        // max-height: 645px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        place-content: end;

        // @media (max-width:1400px) {
        //   max-height: 500px;
        //   min-height: 500px;
        // }

        // @media (max-width:1360px) {
        //   max-height: 455px;
        //   min-height: 455px;
        // }

        // @media (max-width:1280px) {
        //   max-height: 415px;
        //   min-height: 415px;
        // }

        .status {
          font-family: $az-heading-two;
          font-size: $az-font-12 !important;
          color: $az-gray;
          padding: 25px 0;
          width: 100%;
          display: flex;
          text-align: center;
          justify-content: center;
        }

        .chatList {
          overflow-x: auto;
          // max-height: 575px;
          height: 100%;
          margin: 10px 0;

          // @media (max-width:1400px) {
          //   max-height: 100%;
          // }

          .listItem {
            margin-top: 2px;

            // max-height: 487px;
            ul {
              list-style: none;
              padding: 0 10px 0 0;

              li {
                padding: 5px 0;
                // border-bottom: 1px solid $az-border-color;
                width: 100%;
                margin: 0;
                font-size:  $az-font-14 !important;
                font-family: $az-heading-two !important;
                &:last-child {
                  border-bottom: none;
                }

                .chatContent {
                  text-decoration: none;
                  color: $az-black;
                  display: flex;
                  justify-content: space-between;
                  width: 100%;

                  figure {
                    margin: 0;
                    display: block;
                    width: 32px;
                    height: 32px;
                    // border: 2px solid #000;
                    border-radius: 50%;
                    display: none;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: 100%;
                    }
                  }

                  .content {
                    width: 100%;
                    padding:0 0 0 10px !important;
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;

                    .top {
                      display: flex;
                      align-items: baseline;
                      justify-content: space-between;

                      b {
                        font-family: $az-heading-two;
                        font-weight: normal;
                        font-size: $az-font-15 !important;

                        i {
                          font-style: normal;
                          font-family: $az-heading-two;
                          font-size: $az-font-12;
                          font-size: $az-font-12 !important;
                          color: $az-gray;
                          border-left: 1px solid $az-border-color;
                          padding-left: 5px;
                          margin-left: 5px;
                          padding:0 0 0 5px !important;
                          margin-left:0 0 0 5px !important;
                        }
                      }

                      .chatNav {
                        display: none;

                        button {
                          background: none !important;
                          box-shadow: none;
                          padding: 0;
                          line-height: 0;
                          min-height: auto;

                          &::after {
                            display: none;
                          }
                        }

                        .dropdown-menu.show {
                          border: 0;
                          box-shadow: 0px 4px 10px 0px #dfdfdf;
                          position: relative;
                          margin-top: 30px !important;
                          right: 0 !important;
                          left: inherit !important;
                          transform: inherit !important;

                          .dropdown-item {
                            &:hover {
                              background: $az-black;
                              color: $az-white;
                            }

                          }

                          &::before {
                            position: absolute;
                            content: '';
                            border-top: 15px solid transparent;
                            border-right: 15px solid $az-white;
                            top: -6px;
                            right: 3px;
                            transform: rotate(225deg);
                            box-shadow: 4px 3px 5px 0px #e1e1e1;
                            z-index: -1;

                          }
                        }
                      }
                    }

                    .chatmessage {
                      // background: $az-btn-green;
                      // background-color: $az-light-gray !important;
                      background: $az-eage-color !important;
                      padding: 3px 8px 2px;
                      margin-top: 2px;
                      border-radius: 0 10px 10px 10px;


                      .ReactGridGallery_tile-viewport{
                        height: auto !important;
                      }

                      span {
                        font-size: $az-font-14 !important;
                        margin-bottom: 0px;
                        color: $az-black;
                        width: 100%;
                        display: block;
                        background: transparent; 
                        font-family: $az-heading-two !important;
                      }

                      p {
                        font-size: $az-font-13;
                        margin: 5px 0 0;
                        color: $az-black;
                        text-align: right;

                        i {
                          font-style: normal;
                          padding-left: 5px;
                          margin-left: 3px;
                          border-left: 1px solid $az-border-color;
                          color: $az-black;
                        }
                      }
                      img{
                        float: left;
                        max-height: 50px;
                        margin: 5px !important;
                      }
                    }

                    .chatmessage_sender {
                      background: $az-btn-green;


                      span {
                        color: $az-white;
                      }
                    }

                    i {
                      font-style: normal;
                      text-align: right;
                      font-family: $az-heading-two;
                      font-weight: normal;
                      font-size: $az-font-12;
                      color: $az-gray;
                      display: block;
                      text-align: right;
                      text-transform: lowercase;
                    }

                    b.aleart {
                      background: $az-light-black;
                      color: $az-white;
                      text-align: center;
                      height: 18px;
                      width: 18px;
                      line-height: 18px;
                      border-radius: 50%;
                      font-size: $az-font-10;

                    }

                    .chatmsgcontent {
                      align-items: baseline;
                    }

                  }

                }

              }

                li.main_user{
                  float: left;
                  .chatContent{
                    flex-direction: initial;

                  }
                  p.message_name{
                    margin-bottom: 0;
                    margin-top: 10px;
                    color: $az-black;
                    font-size: $az-font-16;
                  }
                  .content {
                    padding-left: 0px;
                    padding: 0 10px 0 0 !important;
                    justify-content: start;
                    .top{
                      flex-direction: row-reverse;
                      b{
                        display: flex;
                        flex-direction: inherit;
                        place-items: center;

                        i{
                          border-right: 1px solid #72818e33 !important;
                          border-left: 0 !important;
                          padding-right: 5px;
                          margin-right: 5px;
                        }
                      }

                    }
                    .chatmessage{
                      border-radius:10px;
                      display: flex;
                      background-color: $az-light-gray !important;
                      color: $az-black !important;
                      display: block;
                      .chat_para{
                        color: $az-black;
                        width: 100%;
                        display: block;
                        text-align: left;
                        background: transparent;
                      }
                      img{
                        float: right;
                        max-height: 50px;
                        margin: 10px ;
                      }
                    }
                  }

                  .chatmsgcontent {
                    align-items: flex-end;
                  }
                }




            }
          }
        }

        .Chatbox {
          background: $az-light-bg;
          padding: 10px;
          display: flex;
          position: relative;
          bottom: 0;
          width: 100%;
          align-items: end;

          span {
            display: flex;
            align-items: center;
            // justify-content: space-around;
            width: auto;
            // margin-right: 5px;
            padding-left: 25px;

            .fileUpload {
              width: 22px;
              margin: 0 5px;

              img {
                width: 22px;
              }

              input[type=file] {
                display: contents;
              }

              label {
                margin-bottom: 0;
                color: $az-black;

                img {
                  margin-right: 5px;
                }
              }
            }

            button {
              border: none;
              padding: 0;
              margin: 3px 10px 0 10px;

              img {
                width: 28px;
              }
            }

            button.emoji {
              border: none;
              padding: 0;
              margin: 0 6px;

              img {
                width: 25px;
              }
            }

            .dropdown {
              margin-right: 5px;
              margin-top: 5px;

              span {
                padding: 10px 6px 10px;
                border-bottom: 1px solid $az-border-color;
                margin: 0 10px 0px;

                &:last-child {
                  border-bottom: none;
                }
              }

              .dropdown-menu.show {
                transform: translate(-35px, -51px) !important;
                border: none;
                box-shadow: -1px 1px 7px -3px #8d8d8d;
              }

            }


          }

          textarea {
            // width: calc(100% - 110px) !important;
            width: calc(100% - 0px) !important;
            padding: 12px 15px 15px;
            min-height: 45px;
            max-height: 200px;
            overflow-y: auto;

            @media (max-width:1440px) {
              max-height: 150px;
            }


            &:hover,
            &:focus,
            &:visited,
            &:active {
              border: none;
              box-shadow: none;
              outline: none;
            }
          }

          button.post {
            background: $az-black;
            border-radius: 50%;
            // width: 55px;
            min-width: 48px;
            max-width: 55px;
            height: 48px;
            margin-left: 10px;
            padding: 0 5px 0 9px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;

            &:hover,
            &:focus,
            &:visited,
            &:active {
              border: none;
              box-shadow: none;
              outline: none;
            }

            img {
              width: 20px;
            }
          }

          button.qickReplyicon {
            border: none;
            box-shadow: none;
            outline: none;
            background: none;
            margin: -17px 10px 0 10px;

            &:hover,
            &:focus,
            &:visited,
            &:active {
              border: none;
              box-shadow: none;
              outline: none;
              background: none;
            }
          }

          .dropdown-toggle {
            position: relative;
            background: none;
            border: none;
            box-shadow: none;
            outline: none;
            min-height: inherit;
            margin: 0;
            left: -20px;
            top: -24px;

            &:hover,
            &:focus,
            &:visited,
            &:active {
              border: none;
              box-shadow: none;
              outline: none;
            }



            &::after {
              position: absolute;
              content: '';
              left: 0;
              top: 0;
              background-image: url(../../images/add-file.svg);
              width: 20px;
              height: 20px;
              border: none;
              background-repeat: no-repeat;
              background-size: 100%;
              margin: 0;
            }


          }

          .tumbImg {

            position: absolute;
            top: -64px;
            left: 12px;

            ul {
              padding: 0;
              list-style: none;
              margin-bottom: 0;
              display: flex;

              li {
                width: 60px;
                height: 60px;
                background: $az-light-bg;
                border-radius: 8px;

                figure {
                  overflow: hidden;
                  background: #ffffff;
                  text-align: center;
                  min-height: 60px;
                  position: relative;
                  max-width: 100%;
                  max-height: 60px;

                  img {
                    max-height: 60px;

                  }
                }

                button {
                  border: none;
                  outline: none;
                  box-shadow: none;
                  background: none;
                  position: absolute;
                  top: -13px;
                  right: -11px;

                  &:hover,
                  &:focus,
                  &:visited,
                  &:active {
                    border: none;
                    box-shadow: none;
                    outline: none;
                  }


                  img {
                    width: 16px;
                  }


                }

                margin-right: 10px;
                position: relative;
              }
            }
          }

          #emoji {
            position: absolute;
            bottom: 70px;
            left: 45px;
          }


          .emojidropdown {
            position: relative;
            bottom: 17px;

            button.dropdown-toggle {
              background: none;
              border: none;
              box-shadow: none;
              outline: none;
              min-height: inherit;
              margin: 0;
              left: 0px;
              top: 0px;
              padding-right: 15px;

              &::after {
                position: absolute;
                content: '';
                left: 0;
                top: 0px;
                background-image: url(../../images/emoji.svg);
                width: 25px;
                height: 25px;
                border: none;
                background-repeat: no-repeat;
                background-size: 100%;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .rightSection {
      width: 100%;
      // min-height: 645px;
      // max-height: 645px;
      height: 70vh;
      position: relative;

      // @media (max-width:1400px) {
      //   max-height: 400px;
      //   min-height: 400px;
      // }

      // @media (max-width:1360px) {
      //   max-height: 360px;
      //   min-height: 360px;
      // }

      @media (max-width:991px) {
        max-height: 100%;
        min-height: 100%;
      }


      .recipt_close {
        top: 5px;
        position: absolute;
        right: -8px;
        display: block;
        z-index: 111;
        font-size: inherit !important;
      }

      // overflow-x: auto;
      .reservationInfo {
        // border-bottom: 1px solid $az-eage-color;
        // padding-bottom: 20px;
        margin-bottom: 15px;

        .header {
          position: relative;
          display: flex;
          justify-content: space-between;
          place-items: center;
          border-bottom: 1px solid $az-eage-color;

          h6 {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: $az-black;
            padding: 10px 0;
            margin-bottom: 0;
          }


        }

        .mainContent {
          text-decoration: none;
          color: $az-black;
          display: flex;
          justify-content: space-between;
          width: 100%;

          figure {
            margin: 0;
            display: block;
            width: 32px;
            height: 32px;
            // border: 2px solid #000;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 100%;
            }
          }

          .content {
            // width: calc(100% - 32px);
            width: 100%;
            padding-left: 0px;
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            .top {
              display: flex;
              align-items: baseline;
              justify-content: space-between;

              b {
                font-family: $az-heading-two;
                font-weight: normal;
                font-size: $az-font-15;

              }
            }

            .info {
              padding: 0px;
              margin-top: 0px;

              span {
                font-size: $az-font-14;
                margin-bottom: 0px;
                color: $az-gray;
                display: block;
              }

              p {
                font-size: $az-font-13;
                margin: 0;
                color: $az-black;
                text-align: right;

                i {
                  font-style: normal;
                  padding-left: 5px;
                  margin-left: 3px;
                  border-left: 1px solid $az-border-color;
                  color: $az-black;
                }
              }

            }

            .persona-detail-side {
              width: 30%;
              display: grid;
              place-items: center;

              @media (max-width:1370px) {
                width: 30%;
              }

              .channel_img {
                figure {
                  min-height: 21px;
                  max-height: 21px;
                  width: auto;
                  margin: 0;
                  margin-top: 10px;
                  float: left;

                  img {
                    object-fit: scale-down;
                    width: 100% !important;
                    height: 100% !important;
                    border-radius: 0;

                    @media (max-width:1370px) {
                      width: 90% !important;
                      height: 90% !important;
                    }
                  }
                }

              }
            }
          }

        }

        .contentfooter {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin-top: 20px;

          p {
            color: $az-gray;
            margin: 0;
          }

          b {
            font-size: 18px;
            font-weight: normal;
            color: #000;
          }
        }
      }


      .reservation_box{
        border-bottom: 1px solid #e1ccbe;
        padding-bottom: 10px;
        margin-bottom: 10px;

        .col-12 {
          padding: 0;
        }

        .resr_info {
          padding-right: 10px;

          label {
            margin-bottom:0px !important;
          }
          p {
            font-weight: 600;
            margin:0 0 5px 0 !important;
            color: $az-light-black;
            padding: 0 !important;
            font-size: $az-font-14 !important;
            font-family: $az-heading-two !important; 
            a {
              color: $az-black;

              img {
                width: 18px
              }

              // i {
              //   font-size: $az-font-18;
              //   line-height: initial;
              // }
            }

            .housemanual_link {
              color: $az-black;
              text-decoration: underline;
            }
          }

          @media (max-width:767px) {
            padding: 0;
          }

          .channel_img {
            figure {
              max-height: 20px;
              min-height: 20px;

              img {
                width: 80px;
              }
            }
          }
        }

        .header {
          margin-bottom: 13px;
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-right: 15px;
          place-items: center;
          h6 {
            font-family: $az-heading-two !important;
            font-size: $az-font-18;
            color: $az-black;
            padding: 0px;
            margin-bottom: 0;
          }
          .btn_send_mail{
            background: $az-black !important;
            color: $az-white !important;
            border: 0 !important;
          }
        }
      }


      .guestpaid,
      .otherinfo {
        border-bottom: 1px solid $az-eage-color;
        padding-bottom: 0px;
        // margin-bottom: 20px;

        .header {
          margin-bottom: 13px;
          position: relative;

          h6 {
            font-family: $az-heading-two;
            font-size: $az-font-18;
            color: $az-black;
            padding: 0px;
            margin-bottom: 0;
          }

        }

        .mainContent {
          text-decoration: none;
          color: $az-black;
          display: flex;
          justify-content: space-between;
          width: 100%;

          ul {
            padding: 0;
            list-style: none;
            display: block;
            width: 100%;

            li {
              display: flex;
              justify-content: space-between;
              margin: 7px 0;

              i {
                font-style: normal;
                padding-left: 0px;
                margin-left: 0px;
                color: $az-gray;
                font-size: $az-font-14;
              }

              b {
                font-family: $az-heading-two;
                font-weight: normal;
                font-size: $az-font-14;
                color: $az-light-black;
              }
              &:last-child{
                i{
                  font-size: $az-font-16
                }
                b{
                  font-size: $az-font-16;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .otherinfo {
        border-bottom: 0;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
    }
  }

  .newMsg {
    min-width: 120px !important;
    max-width: 120px !important;
  }


}

.dashbaord-pageHeadertitle {
  .newMsgBtn {
    min-width: 120px;
    max-width: 120px;

  }
}

// Modalbox messages
.addContacts {
  .modal-content {
    min-height: 400px;

    .addContactsList {
      .header {
        margin-bottom: 3px;
        position: relative;

        .searchSection {
          display: flex;
          position: relative;

          input {
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-black !important;
            background: $az-light-bg !important;
            border: none !important;
            box-shadow: none !important;
            padding-right: 42px;
            border-radius: 4px !important;

            &:hover,
            &:focus {
              border: none !important;
              box-shadow: none !important;
            }

          }

          span {
            width: 42px;
            height: 42px;
            display: block;
            text-align: center;
            line-height: 42px;
            position: absolute;
            right: 0;

            img {
              margin-bottom: 0;
              width: 18px;
            }
          }
        }

      }

      .listSetction {
        // background: $az-light-bg !important;
        // box-shadow: 0px 1px 2px #ddd !important;
        margin-top: 2px;
        position: relative;
        top: 0;
        width: 100%;
        overflow-x: auto;
        max-height: 600px;
        height: 600px;

        @media (max-width:1366px) {
          max-height: 490px;
          height: 490px;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 10px 15px;
            background: $az-white;
            border-bottom: 1px solid $az-border-color;

            a {
              text-decoration: none;
              color: $az-black;
              display: flex;

              figure {
                margin: 0;
                display: block;
                width: 32px;
                height: 32px;
                // border: 2px solid #000;
                border-radius: 50%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 100%;
                }
              }

              .profile_prop{
                text-align: center;
                p{
                  margin: 0;
                  font-size: 10px;
                }
              }

              .content {
                width: calc(100% - 32px);
                padding:0 0 0 8px !important;
                display: flex;
                align-items: center;
                border-left: 1px solid $az-light-white;
                margin-left: 8px;

                b {
                  font-family: $az-heading-para;
                  font-weight: normal;
                  font-size: $az-font-14;
                  display: block;
                  color: $az-light-black !important;
                }

              }

            }

            &:hover,
            &:active {
              background: $az-light-bg;
              border-color:$az-eage-color ;
              .content{
                border-color:$az-eage-color ;
              }
            }

          }
        }
      }
    }

    .modal-body {
      padding: 15px !important;

      span {
        .closeicon {
          width: 26px !important
        }
      }

      button {
        padding: 9px 15px !important;
      }
    }
  }
}

.showquickreplyModal {

  @include modalbox;

  .modal-body {
    padding: 32px 2px 32px 24px !important;
  }

  .listSetction {
    max-height: 485px;
    overflow-x: auto;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        border-bottom: 1px solid $az-border-color;
        margin-bottom: 15px;
        padding-bottom: 25px;

        .content {
          b {
            font-family: $az-heading-para;
            font-size: $az-font-16;
            font-weight: normal;
            text-align: left;
            color: $az-black;
            display: block;
          }

          p {
            font-family: $az-heading-para;
            font-size: $az-font-14;
            text-align: left;
            color: $az-black;
            margin-bottom: 20px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          button {
            min-width: 115px !important;
            max-width: 115px !important;
            margin-right: 0 !important;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

}

.importModalbox {
  form {
    label {
      border: 2px dashed #a9a9a9;
      display: flex;
      height: 196px;
      width: 196px;
      margin: auto;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 60px;
      margin-bottom: 10px !important;

      .sc-dkrFOg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: $az-heading-two;
        position: relative;

        span {
          font-size: $az-font-14;
          text-decoration: none;
          color: $az-black;
        }

        &::after {
          position: absolute;
          background-image: url(../../images/import-file.png);
          height: 60px;
          width: 40px;
          content: '';
          top: -15px;
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }

      svg {
        display: none;
      }
    }

    .text-danger {
      text-align: center;
    }
  }
}

.download-sample {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px 0px 0px 0;

  a {
    text-decoration: underline;
    color: $az-black;
    font-family: $az-heading-two;
  }
}

.listingImagsection {
  .list-section {
    list-style: none !important;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    padding-right: 0;

    .list-img {
      // padding: 10px;
      width: calc(768px / 3);
      max-width: calc(768px / 3);
      margin: 0 15px 14px;
      position: relative;
      list-style: none !important;
      cursor: grab;
      @include addmultiImages;

      .label-img {
        position: absolute;
        bottom: 25px;
        left: 10px;
        z-index: 1;

        img {
          background: #fff;
          width: 21px;
          height: 21px;
          padding: 4px;
          border-radius: 50%;
          outline: 1px solid #ddd;
          cursor: default;

          &:first-child {
            margin-right: 7px;
            background: #fff;
            width: 21px;
            height: 21px;
            padding: 4px;
            border-radius: 50%;
            outline: 1px solid #ddd;
          }
        }
      }

      @media(max-width:3040px) {
        width: calc(11% - 10px);
        max-width: calc(11% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:2879px) {
        width: calc(13% - 10px);
        max-width: calc(13% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:2440px) {
        width: calc(13% - 10px);
        max-width: calc(13% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1920px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 10px 5px;
      }


      @media(max-width:1825px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1640px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1580px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1440px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1420px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1380px) {
        width: calc(30% - 10px);
        max-width: calc(30% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1366px) {
        width: calc(31% - 10px);
        max-width: calc(31% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1280px) {
        width: calc(31% - 10px);
        max-width: calc(31% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1199px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1024px) {
        width: calc(30% - 10px);
        max-width: calc(30% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:991px) {
        width: calc(29% - 10px);
        max-width: calc(29% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:767px) {
        width: calc(31% - 5px);
        max-width: calc(31% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:700px) {
        width: calc(32% - 5px);
        max-width: calc(32% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:650px) {
        width: calc(46% - 5px);
        max-width: calc(46% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:512px) {
        width: 95%;
        max-width: 95%;
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:414px) {
        width: 95%;
        max-width: 95%;
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      // @media(max-width:1440px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }

      // @media(max-width:1360px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1280px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1024px){

      //   width: calc(501px / 2);
      //   max-width: calc(501px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:991px){

      //   width: calc(508px / 2);
      //   max-width: calc(508px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:767px){

      //   width: calc(445px / 2);
      //   max-width: calc(445px / 2);
      //   flex: inherit;
      //   margin: auto;
      //   margin-bottom: 40px;
      //   position: relative;
      // }
      .addImg {
        .dropdown {
          position: relative;

          .dropdown-toggle {
            position: absolute;
            top: 5px;
            right: 6px;
            z-index: 1;

            &::after {
              display: none;
            }

            img {
              width: 30px;
            }
          }

          .dropdown-menu.show {
            transform: translate(0px, 48px) !important;
            right: auto !important;
            left: auto !important;
            border-radius: 8px !important;
          }


        }

        h5 {
          position: absolute;
          bottom: -15px;
          width: 100%;
        }
      }

      .addImg.uploaded {
        figure {
          min-height: 180px;
        }
      }

    }
  }

}

.listingImagsection {
  .ReactGridGallery {
    list-style: none;
    padding-left: 3px;
    padding-right: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    // justify-content: space-between;

    .ReactGridGallery_tile {
      padding: 0px !important;
      width: calc(768px / 3);
      max-width: calc(768px / 3);
      margin: 0 !important;
      float: inherit !important;
      margin: 0 15px 30px !important;

      // @media(max-width:1440px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }

      // @media(max-width:1360px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1280px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1024px){

      //   width: calc(501px / 2);
      //   max-width: calc(501px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:991px){

      //   width: calc(508px / 2);
      //   max-width: calc(508px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:767px){

      //   width: calc(445px / 2);
      //   max-width: calc(445px / 2);
      //   flex: inherit;
      //   margin: auto;
      // }

      @media(max-width:3040px) {
        width: calc(10% - 10px);
        max-width: calc(10% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:2879px) {
        width: calc(12% - 10px);
        max-width: calc(12% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:2440px) {
        width: calc(13% - 10px);
        max-width: calc(13% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1920px) {
        width: calc(16% - 10px);
        max-width: calc(16% - 10px);
        margin: 0 10px 21px 10px !important;
      }


      @media(max-width:1825px) {
        width: calc(18% - 10px);
        max-width: calc(18% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1640px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1580px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1440px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1420px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1380px) {
        width: calc(30% - 10px);
        max-width: calc(30% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1366px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1280px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1199px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1024px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:991px) {
        width: calc(30% - 10px);
        max-width: calc(30% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:767px) {
        width: calc(31% - 5px);
        max-width: calc(31% - 5px);
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      @media(max-width:700px) {
        width: calc(32% - 5px);
        max-width: calc(32% - 5px);
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      @media(max-width:650px) {
        width: calc(44% - 5px);
        max-width: calc(44% - 5px);
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      @media(max-width:414px) {
        width: 69%;
        max-width: 69%;
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      .ReactGridGallery_tile-viewport {
        margin: 0;
        max-width: 100%;
        max-height: 180px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        border: 1px solid $az-border-color;
        background: #fff;
        min-height: 180px;
        text-align: center;

        @media (max-width:1680px) {
          max-width: 100%;
          max-height: 180px;

        }

        @media (max-width:1280px) {
          max-width: 100%;
          max-height: 180px;
          min-height: 180px;
        }

        @media (max-width:1199px) {
          max-width: 100%;
          max-height: 180px;
          min-height: 180px;
        }

        @media (max-width:1024px) {
          max-width: 100%;
          max-height: 180px;
        }

        @media (max-width:991px) {
          max-width: 100%;
          max-height: 180px;
        }

        @media (max-width:767px) {
          max-width: 100%;
          max-height: 180px;
        }

        img {
          // max-width: 100%;
          max-height: 180px;

          @media (max-width:1280px) {
            // max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:1199px) {
            // max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:767px) {
            // max-width: 100%;
            max-height: 180px;
          }

        }
      }

      .ReactGridGallery_tile-bottom-bar {
        bottom: inherit !important;
      }

      &:hover {
        .ReactGridGallery_tile-bottom-bar {
          position: relative;
          bottom: inherit !important;

          div {
            &:first-child {
              span {
                padding: 0 !important;
                background: none !important;

                img {
                  width: 18px;
                  height: 18px;
                  right: 30px;
                  top: 10px;
                  right: 30px;
                  transform: translate(0, 0);
                  filter: grayscale(1) brightness(2);
                  position: absolute;
                  z-index: 1;
                  cursor: pointer;
                  bottom: inherit;
                }
              }
            }

            &:last-child {
              span {
                padding: 0 !important;
                background: none !important;

                img {
                  width: 18px;
                  height: 18px;
                  right: 30px;
                  top: 10px;
                  right: 60px;
                  transform: translate(0, 0);
                  filter: grayscale(1) brightness(2);
                  position: absolute;
                  z-index: 1;
                  cursor: pointer;
                  bottom: inherit;
                }
              }
            }

          }

        }

        .ReactGridGallery_tile-viewport {
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            left: 0;
            top: 0;
            cursor: pointer;
          }

          &::after {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            left: 50%;
            top: 50%;
            background-image: url('../../images/zoom.svg');
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.listingImagviewsection {
  .list-section {
    list-style: none !important;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    padding-right: 0;

    .list-img {
      // padding: 10px;
      width: calc(768px / 3);
      max-width: calc(768px / 3);
      margin: 0 15px 14px;
      position: relative;
      list-style: none !important;
      cursor: grab;
      @include addmultiImages;

      .label-img {
        position: absolute;
        bottom: 25px;
        left: 10px;
        z-index: 1;

        img {
          background: #fff;
          width: 21px;
          height: 21px;
          padding: 4px;
          border-radius: 50%;
          outline: 1px solid #ddd;
          cursor: default;

          &:first-child {
            margin-right: 7px;
            background: #fff;
            width: 21px;
            height: 21px;
            padding: 4px;
            border-radius: 50%;
            outline: 1px solid #ddd;
          }
        }
      }

      @media(max-width:3040px) {
        width: calc(10% - 10px);
        max-width: calc(10% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:2879px) {
        width: calc(12% - 10px);
        max-width: calc(12% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:2440px) {
        width: calc(13% - 10px);
        max-width: calc(13% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1920px) {
        width: calc(16% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }


      @media(max-width:1825px) {
        width: calc(16% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1640px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1580px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1440px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1420px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1380px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1366px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1280px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1199px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1024px) {
        width: calc(30% - 10px);
        max-width: calc(30% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:991px) {
        width: calc(29% - 10px);
        max-width: calc(29% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:767px) {
        width: calc(31% - 5px);
        max-width: calc(31% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:700px) {
        width: calc(32% - 5px);
        max-width: calc(32% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:650px) {
        width: calc(46% - 5px);
        max-width: calc(46% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:414px) {
        width: 70%;
        max-width: 70%;
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      // @media(max-width:1440px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }

      // @media(max-width:1360px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1280px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1024px){

      //   width: calc(501px / 2);
      //   max-width: calc(501px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:991px){

      //   width: calc(508px / 2);
      //   max-width: calc(508px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:767px){

      //   width: calc(445px / 2);
      //   max-width: calc(445px / 2);
      //   flex: inherit;
      //   margin: auto;
      //   margin-bottom: 40px;
      //   position: relative;
      // }
      .addImg {
        .dropdown {
          position: relative;

          .dropdown-toggle {
            position: absolute;
            top: 5px;
            right: 6px;
            z-index: 1;

            &::after {
              display: none;
            }

            img {
              width: 30px;
            }
          }

          .dropdown-menu.show {
            transform: translate(0px, 48px) !important;
            right: auto !important;
            left: auto !important;
            border-radius: 8px !important;
          }


        }

        h5 {
          position: absolute;
          bottom: -15px;
          width: 100%;
        }
      }

      .addImg.uploaded {
        figure {
          min-height: 180px;
        }
      }

    }
  }
}

.addlistingNewimage {
  width: 256px;
  max-width: 256px;
  margin: 0 15px 30px;
  position: relative;
  list-style: none !important;
  cursor: pointer;
  border: 1px dashed #333;
  border-radius: 8px;
  height: 180px;

  span {
    font-family: $az-heading-two;
    color: $az-black;
    font-size: $az-font-14;
    margin-top: 10px;
  }

  @media(max-width:3040px) {
    width: calc(10% - 10px);
    max-width: calc(10% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:2879px) {
    width: calc(12% - 10px);
    max-width: calc(12% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:2440px) {
    width: calc(13% - 10px);
    max-width: calc(13% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1920px) {
    width: calc(16% - 10px);
    max-width: calc(16% - 10px);
    margin: 0 10px 5px;
  }


  @media(max-width:1825px) {
    width: calc(16% - 10px);
    max-width: calc(16% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1640px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1580px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1440px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1420px) {
    width: calc(23% - 10px);
    max-width: calc(23% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1380px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1366px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1280px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1199px) {
    width: calc(23% - 10px);
    max-width: calc(23% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1024px) {
    width: calc(30% - 10px);
    max-width: calc(30% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:991px) {
    width: calc(29% - 10px);
    max-width: calc(29% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:767px) {
    width: calc(31% - 5px);
    max-width: calc(31% - 5px);
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }

  @media(max-width:700px) {
    width: calc(32% - 5px);
    max-width: calc(32% - 5px);
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }

  @media(max-width:650px) {
    width: calc(46% - 5px);
    max-width: calc(46% - 5px);
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }

  @media(max-width:414px) {
    width: 69%;
    max-width: 69%;
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }
}

.floorplanImagsection {
  .ReactGridGallery {
    list-style: none;
    padding-left: 1px;
    padding-right: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    // justify-content: space-between;

    .ReactGridGallery_tile {
      padding: 0px !important;
      width: calc(768px / 3);
      max-width: calc(768px / 3);
      margin: 0 !important;
      float: inherit !important;
      margin: 0 15px 30px !important;

      // @media(max-width:1440px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }

      // @media(max-width:1360px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1280px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1024px){

      //   width: calc(501px / 2);
      //   max-width: calc(501px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:991px){

      //   width: calc(508px / 2);
      //   max-width: calc(508px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:767px){

      //   width: calc(445px / 2);
      //   max-width: calc(445px / 2);
      //   flex: inherit;
      //   margin: auto;
      // }

      @media(max-width:3040px) {
        width: calc(10% - 10px);
        max-width: calc(10% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:2879px) {
        width: calc(12% - 10px);
        max-width: calc(12% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:2440px) {
        width: calc(13% - 10px);
        max-width: calc(13% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1920px) {
        width: calc(16% - 10px);
        max-width: calc(16% - 10px);
        margin: 0 10px 21px 10px !important;
      }


      @media(max-width:1825px) {
        width: calc(18% - 10px);
        max-width: calc(18% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1640px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1580px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1440px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1420px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1380px) {
        width: calc(30% - 10px);
        max-width: calc(30% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1366px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1280px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1199px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:1024px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:991px) {
        width: calc(31% - 10px);
        max-width: calc(31% - 10px);
        margin: 0 10px 21px !important;
      }

      @media(max-width:767px) {
        width: calc(31% - 5px);
        max-width: calc(31% - 5px);
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      @media(max-width:700px) {
        width: calc(32% - 5px);
        max-width: calc(32% - 5px);
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      @media(max-width:650px) {
        width: calc(44% - 5px);
        max-width: calc(44% - 5px);
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      @media(max-width:414px) {
        width: 69%;
        max-width: 69%;
        flex: inherit;
        margin: auto !important;
        margin-bottom: 10px !important;
        position: relative;
      }

      .ReactGridGallery_tile-viewport {
        margin: 0;
        max-width: 100%;
        max-height: 180px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        border: 1px solid $az-border-color;
        background: #fff;
        min-height: 180px;
        text-align: center;

        @media (max-width:1680px) {
          max-width: 100%;
          max-height: 180px;

        }

        @media (max-width:1280px) {
          max-width: 100%;
          max-height: 180px;
          min-height: 180px;
        }

        @media (max-width:1199px) {
          max-width: 100%;
          max-height: 180px;
          min-height: 180px;
        }

        @media (max-width:1024px) {
          max-width: 100%;
          max-height: 180px;
        }

        @media (max-width:991px) {
          max-width: 100%;
          max-height: 180px;
        }

        @media (max-width:767px) {
          max-width: 100%;
          max-height: 180px;
        }

        img {
          // max-width: 100%;
          max-height: 180px;

          @media (max-width:1280px) {
            // max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:1199px) {
            // max-width: 100%;
            max-height: 180px;
          }

          @media (max-width:767px) {
            // max-width: 100%;
            max-height: 180px;
          }

        }
      }

      .ReactGridGallery_tile-bottom-bar {
        bottom: inherit !important;
      }

      &:hover {
        .ReactGridGallery_tile-bottom-bar {
          position: relative;
          bottom: inherit !important;

          div {
            &:first-child {
              span {
                padding: 0 !important;
                background: none !important;

                img {
                  width: 18px;
                  height: 18px;
                  right: 30px;
                  top: 10px;
                  right: 30px;
                  transform: translate(0, 0);
                  filter: grayscale(1) brightness(2);
                  position: absolute;
                  z-index: 1;
                  cursor: pointer;
                  bottom: inherit;
                }
              }
            }

            &:last-child {
              span {
                padding: 0 !important;
                background: none !important;

                img {
                  width: 18px;
                  height: 18px;
                  right: 30px;
                  top: 10px;
                  right: 60px;
                  transform: translate(0, 0);
                  filter: grayscale(1) brightness(2);
                  position: absolute;
                  z-index: 1;
                  cursor: pointer;
                  bottom: inherit;
                }
              }
            }

          }

        }

        .ReactGridGallery_tile-viewport {
          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            left: 0;
            top: 0;
            cursor: pointer;
          }

          &::after {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            left: 50%;
            top: 50%;
            background-image: url('../../images/zoom.svg');
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            cursor: pointer;
          }
        }
      }
    }
  }
}


.floorplanImagviewsection {
  .list-section {
    list-style: none !important;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    padding-right: 0;

    .list-img {
      // padding: 10px;
      width: calc(768px / 3);
      max-width: calc(768px / 3);
      margin: 0 15px 14px;
      position: relative;
      list-style: none !important;
      cursor: grab;
      @include addmultiImages;

      .label-img {
        position: absolute;
        bottom: 25px;
        left: 10px;
        z-index: 1;

        img {
          background: #fff;
          width: 21px;
          height: 21px;
          padding: 4px;
          border-radius: 50%;
          outline: 1px solid #ddd;
          cursor: default;

          &:first-child {
            margin-right: 7px;
            background: #fff;
            width: 21px;
            height: 21px;
            padding: 4px;
            border-radius: 50%;
            outline: 1px solid #ddd;
          }
        }
      }

      @media(max-width:3040px) {
        width: calc(10% - 10px);
        max-width: calc(10% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:2879px) {
        width: calc(12% - 10px);
        max-width: calc(12% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:2440px) {
        width: calc(13% - 10px);
        max-width: calc(13% - 10px);
        margin: 0 10px 5px;
      }

      @media(max-width:1920px) {
        width: calc(16% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }


      @media(max-width:1825px) {
        width: calc(16% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1640px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1580px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1440px) {
        width: calc(19% - 10px);
        max-width: calc(19% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1420px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1380px) {
        width: calc(24% - 10px);
        max-width: calc(24% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1366px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1280px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1199px) {
        width: calc(23% - 10px);
        max-width: calc(23% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:1024px) {
        width: calc(30% - 10px);
        max-width: calc(30% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:991px) {
        width: calc(29% - 10px);
        max-width: calc(29% - 10px);
        margin: 0 9px 5px 10px;
      }

      @media(max-width:767px) {
        width: calc(31% - 5px);
        max-width: calc(31% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:700px) {
        width: calc(32% - 5px);
        max-width: calc(32% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:650px) {
        width: calc(46% - 5px);
        max-width: calc(46% - 5px);
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      @media(max-width:414px) {
        width: 69%;
        max-width: 69%;
        flex: inherit;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
      }

      // @media(max-width:1440px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }

      // @media(max-width:1360px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1280px){

      //   width: calc(768px / 3);
      //   max-width: calc(768px / 3);
      //   flex: inherit;

      // }
      // @media(max-width:1024px){

      //   width: calc(501px / 2);
      //   max-width: calc(501px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:991px){

      //   width: calc(508px / 2);
      //   max-width: calc(508px / 2);
      //   flex: inherit;

      // }
      // @media(max-width:767px){

      //   width: calc(445px / 2);
      //   max-width: calc(445px / 2);
      //   flex: inherit;
      //   margin: auto;
      //   margin-bottom: 40px;
      //   position: relative;
      // }
      .addImg {
        .dropdown {
          position: relative;

          .dropdown-toggle {
            position: absolute;
            top: 5px;
            right: 6px;
            z-index: 1;

            &::after {
              display: none;
            }

            img {
              width: 30px;
            }
          }

          .dropdown-menu.show {
            transform: translate(0px, 48px) !important;
            right: auto !important;
            left: auto !important;
            border-radius: 8px !important;
          }


        }

        h5 {
          position: absolute;
          bottom: -15px;
          width: 100%;
        }
      }

      .addImg.uploaded {
        figure {
          min-height: 180px;
        }
      }

    }
  }
}

.addfloorplangNewimage {
  width: 256px;
  max-width: 256px;
  margin: 0 15px 30px;
  position: relative;
  list-style: none !important;
  cursor: pointer;
  border: 1px dashed #333;
  border-radius: 8px;
  height: 180px;

  span {
    font-family: $az-heading-two;
    color: $az-black;
    font-size: $az-font-14;
    margin-top: 10px;
  }

  @media(max-width:3040px) {
    width: calc(10% - 10px);
    max-width: calc(10% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:2879px) {
    width: calc(12% - 10px);
    max-width: calc(12% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:2440px) {
    width: calc(13% - 10px);
    max-width: calc(13% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1920px) {
    width: calc(16% - 10px);
    max-width: calc(16% - 10px);
    margin: 0 10px 5px;
  }


  @media(max-width:1825px) {
    width: calc(16% - 10px);
    max-width: calc(16% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1640px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1580px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1440px) {
    width: calc(19% - 10px);
    max-width: calc(19% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1420px) {
    width: calc(23% - 10px);
    max-width: calc(23% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1380px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1366px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1280px) {
    width: calc(24% - 10px);
    max-width: calc(24% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1199px) {
    width: calc(23% - 10px);
    max-width: calc(23% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:1024px) {
    width: calc(30% - 10px);
    max-width: calc(30% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:991px) {
    width: calc(29% - 10px);
    max-width: calc(29% - 10px);
    margin: 0 10px 5px;
  }

  @media(max-width:767px) {
    width: calc(31% - 5px);
    max-width: calc(31% - 5px);
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }

  @media(max-width:700px) {
    width: calc(32% - 5px);
    max-width: calc(32% - 5px);
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }

  @media(max-width:650px) {
    width: calc(46% - 5px);
    max-width: calc(46% - 5px);
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }

  @media(max-width:414px) {
    width: 69%;
    max-width: 69%;
    flex: inherit;
    margin: 0 auto;
    position: relative;
  }
}

.w-75-custom {
  width: 65%;

  @media (max-width:1800px) {
    width: 100%;
  }
}

.w-25-custom {
  width: 35%;

  @media (max-width:1280px) {
    width: 70%;
  }
}


.top_filter {
  background-color: #fff;
  padding: 15px 15px 5px 15px;
  width: 100%;
  overflow: auto;
  display: none !important;

  .propert_filter {
    width: 70px;
    text-align: center;
    margin: 0 5px;

    figure {
      width: 60px;
      height: 60px;
      background: linear-gradient(134.3deg, #E1CCBE 30.62%, #02343A 97.66%);
      border-radius: 50px;
      overflow: hidden;
      margin: 0 auto;

      img {
        max-height: 60px;
        min-height: 60px;
        width: 100% !important;
      }
    }

    p {
      text-align: center;
      text-transform: uppercase !important;
      padding-top: 5px;
      margin: 0 !important;
      color: $az-black;
      font-size: $az-font-12 !important;
    }
  }

  .propert_filter.active {
    figure {
      outline: 1px dashed $az-btn-green;
      outline-offset: 3px;
    }
  }
}

.recipt_scroll{
  overflow-x: auto;

  // @media (max-width:1400px) {
  //   max-height: 540px;
  // }

  @media (max-width:991px) {
    margin-top: 50px;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

// .recipt_scroll::-webkit-scrollbar {
//   width: 3px;
//   height: 3px;
// }

// .recipt_scroll::-webkit-scrollbar-track {
//   background-clip: content-box;
//   border: 0px solid transparent;
//   width: 3px;
//   background-color: transparent;
// }

// .recipt_scroll::-webkit-scrollbar-thumb {
//   background-color: $az-eage-color;
// }

// .recipt_scroll::-webkit-scrollbar-thumb:hover {
//   background-color: $az-eage-color;
// }

// .recipt_scroll::-webkit-scrollbar-corner,
// .recipt_scroll::-webkit-scrollbar-track {
//   background-color: $az-border-base;
//   width: 3px;
// }


.message_popup {
  .modal-dialog {
    max-width: 800px !important;
    min-height: 100%;

    @media (max-width:1366px) {
      max-width: 650px !important;
    }

    .modal-content {
      min-height: 680px !important;

      @media (max-width:1366px) {
        min-height: 600px !important;
      }
    }
  }
}

.flex-place-self{
  place-self: baseline;
}

.message_mode_ration {
  .col-lg-6 {
    @media (max-width:1460px) {
      flex: 0 0 40%;
      max-width: 40%;
      padding: 0 5px;
    }

    @media (max-width:991px) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;
    }

    @media (max-width:767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 5px;
    }


  }

  .col-lg-3 {
    @media (max-width:1460px) {
      flex: 0 0 30%;
      max-width: 30%;
    }

    @media (max-width:991px) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 15px;
    }

    @media (max-width:767px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 5px;
    }

  }
}

.messges-alert {
  img {
    // max-width: 30px;
    // -webkit-transform: translate3d(0,0,0);
    // shape-rendering:geometricPrecision;
    // margin-top: 8px;
    @media (max-width:991px) {
      margin-top: 0px !important;
    }
  }

}

.user_time_inside {
  text-transform: uppercase !important;
  display: inline-block !important;
}

.user_name_inside {
  font-weight: 400 !important;
  text-transform: capitalize !important;
  position: relative;
  padding:0 14px 0 0 !important;
  
  &:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    background: gray;
    right: 4px;
    border-radius: 50%;
    top: 6px;
    display: none;
  }
}

.message_mode_ration {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.message_box_show {
  flex: 0 0 75%;
  max-width: 75%;

  @media (max-width:1460px) {
    flex: 0 0 70% !important;
    max-width: 70% !important;
    padding: 0 5px;
  }

  @media (max-width:991px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  @media (max-width:767px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }


}

.recipt_btn_ham {
  font-size: inherit !important;
  img {
    max-height: 24px;
  }
}

.keycafebtn {
  button {

    @media(max-width: 767px) {
      margin-top: 25px;
      margin-left: 15px;
    }
  }
}

// Arrival instruction modalbox

.arrivalinstructionmodalbox {
  .instruction_key_cafe {
    padding-right: 0 !important;

    @media(max-width: 991px) {
      padding-left: 15px !important;
    }

    @media(max-width: 767px) {
      padding-left: 15px !important;
    }

    .instructionbody {
      max-height: 500px;
      overflow: auto;
      padding-right: 15px;


      @media(max-width: 767px) {
        video {
          width: 100% !important;
        }
      }
    }
  }

  .modal-dialog {
    max-width: 600px !important;
  }

}

// End arrival instruction modalbox

.reservationheadermobile {



  .bothBtn {
    @media(max-width: 767px) {
      span.deleteicon {
        order: 0;
        margin: 5px 10px !important;
      }

      button.btn-block {
        order: 2;
        margin: 5px 10px !important;
      }

      a button.editbtn {
        order: 1;
        margin: 5px 10px !important;
      }

      button.msgbtn {
        order: 3;
        margin: 5px 10px !important;
      }
    }
  }
}

.dashbaord-pageHeadertitle h3 {

  @media(max-width: 767px) {
    margin-top: 10px;
  }
}

.cleaningpastview {

  @media(max-width: 767px) {
    padding-left: 20px;
  }
}

.dashboardRightcard .addmultiImages.cleaingimage {
  .ReactGridGallery_tile {
    @media(max-width: 767px) {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }

  }
}

.deskchildMenu {
  nav.nav {
    ul li ul li {
      // border-top: 1px solid rgba(114, 129, 142, 0.2);
    }
  }
}

.az-profile-name-text.problemlable {
  padding-left: 30px !important;

  @media(max-width: 767px) {
    padding-left: 15px !important;
  }
}

.addmultiImages.ReactGridGallery {
  padding-left: 0px !important;

  @media(max-width: 767px) {
    padding-left: 0px !important;
  }
}

.unitmappingbtn {
  top: -168px !important;

  @media(max-width: 767px) {
    top: -100px !important;
  }

}

.instructbtn {
  top: -182px !important;

  @media(max-width: 767px) {
    top: -115px !important;
  }
}

.keycafemappingtitle {

  @media(max-width: 991px) {
    padding: 15px !important;
  }

  @media(max-width: 767px) {
    padding: 15px 15px 60px !important;
  }

}


.message_empty_box {
  background: $az-white;

  @media (max-width:1460px) {
    flex: 0 0 70%;
    max-width: 70%;
  }

  @media (max-width:991px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
  }

  @media (max-width:767px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 5px;
  }

  .empt_box {
    height: 100%;
    display: grid;
    place-content: center;

    img {
      @media (max-width:1460px) {
        max-height: 250px;
      }

      @media (max-width:991px) {
        max-height: 150px;
      }

      @media (max-width:991px) {
        margin: 50px auto;
      }

    }
  }
}


.meesgae_front_loader {
  position: absolute;
  z-index: 999;
  width: 99%;
  // height: calc(100% - 30px );
  place-content: center;
  display: grid;
  background: #fff;

}

.multiselect.roleselect {
  // .css-18az9an-control {
  //   .css-g1d714-ValueContainer {
  //     .css-17wyvtj-multiValue {
  //       background: transparent !important;
  //       padding-right: 3px !important;
  //       margin-right: 0;
  //       .css-9vi63f {
  //         display: none;
  //       }
  //     }
  //   }
  //   .css-1hb7zxy-IndicatorsContainer {
  //     background: inherit !important;
  //   }

  //   .css-b8ldur-Input {
  //     input:read-only {
  //       background-color: #f2f2f2 !important;
  //     }
  //   }
  // }
  // div {
  //   &:first-child {
  //       div {
  //         &:first-child {
  //             padding: 0 5px !important;
  //             div {

  //               &:first-child {
  //                 background: transparent !important;
  //                 padding-right: 3px !important;
  //                 margin-right: 0;
  //               }
  //               &:last-child {
  //                 svg.css-19bqh2r {
  //                   display: none;
  //                 }

  //               }
  //               &:last-child {
  //                   div {
  //                     &:first-child {
  //                       background: transparent !important;
  //                       margin-right: 0;

  //                       input:read-only {
  //                         background-color: #f2f2f2 !important;
  //                       }


  //                     }
  //                   }
  //               }
  //             }

  //         }
  //         &:last-child {
  //             width: inherit !important;
  //             background: inherit !important;
  //             span {
  //               display: none;
  //             }
  //         }
  //       }
  //   }
  // }


  div {
    &:first-child {
      div {
        &:first-child {

          // padding: 0 5px !important;
          div {
            background: #f2f2f2 !important;

            &:first-child {
              background: #f2f2f2 !important;
              padding-right: 0px !important;
              margin-right: 0;
            }

            &:last-child {
              background: none !important;

              svg.css-19bqh2r {
                // display: none;
              }

            }

            &:last-child {
              div {
                &:first-child {
                  background: transparent !important;
                  margin-right: 0;

                  input:read-only {
                    background-color: #f2f2f2 !important;
                  }


                }
              }
            }
          }

        }

        &:last-child {
          width: inherit !important;
          // background: inherit !important;

          span {
            display: none;
          }
          div {
           
              padding: 0 !important;
            
          }
        }
      }
    }
  }
}

.problemsection {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-left: 30px;
  border-bottom: 1px solid #72818e33;

  .row.mg-back-30 {
    margin-left: -30px;
  }

  &:last-child {
    border-bottom: none;
  }

}

.aminitiesupload {

  .hand-cursor {
    img {
      max-width: 42px !important;
      min-width: 42px !important;
      max-height: 42px !important;
      min-height: 42px !important;
    }
  }
}

button.messagebtn {

  &:focus,
  &:active {
    border: 2px solid #e1ccbe !important;
    color: #000 !important;
    background-color: transparent !important;
  }
}

.msg_filter_btn {
  background: $az-light-bg ;
  border: none;
  box-shadow: none;
  padding: 10px;
  border-radius: 4px;
  margin-left: 5px;
}

.meesgae_front_loader{
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  place-content: center;
  display: grid;
  background: #fff;
  @media (max-width:1280px) {
    width: 100%;
    height: 100%;
  }
}
.brdr-rgh-r {
  border-right: 1px solid $az-eage-color;
  padding: 0;
  margin-right: 10px;
}
.msg_srl_sec{
  min-height: auto !important;
}

.unifiedtitle {
  h3 {
    @media(max-width: 767px) {
      margin-top: 0 !important;
    }
  }
}

.unifiedblock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  margin: 0;
  min-height: 124px;

  @media(max-width: 991px) {
    padding-left: 0px;
  }

  @media(max-width: 767px) {
    padding-left: 0px;
  }

  // .pdd-left {

  //   @media(max-width: 991px) {
  //     padding-left: 0px;
  //   }

  //   @media(max-width: 767px) {
  //     padding-left: 0px;
  //   }
  // }
  .ckbox {

    @media(max-width: 991px) {
      width: 33.33%;
      margin: 5px 0;
    }
    @media(max-width: 767px) {
      width: 50%;
      margin: 5px 0;
    }
  }

  @media(max-width: 991px) {
    margin: 15px 0 0;
  }
}


.subheadblock {
  margin: 30px 0;
}

.Leasingcalendarsubhead {
  padding-left: 30px;
  margin:  20px 0;

  @media(max-width: 991px) {
    padding-left: 0px;
  }

  @media(max-width: 767px) {
    padding-left: 0px;
  }
}

.pdd-left {

  padding-left: 0px;

  @media(max-width: 991px) {
    padding-left: 0px;
  }

  @media(max-width: 767px) {
    padding-left: 0px;
  }
}
.reserheading {
  h3 {
    @media(max-width: 767px) {
     margin-top: 0px;
    }
  }
}
.custm-filter{
  .filter_bg_cust{
    span{
    background-color: #E7E7E7;
    color: #333;
    padding: 5px 10px;
    margin: 2px;
    display: inline-block;
    }
  }

  // .btn-fliter-clear {
  //   background: transparent;
  //   color: #3366FF;
  //   border: 0;
  // }
}
.filter_count{
  border: 1px solid $az-gray;
  display: flex;
  place-items: center;
  justify-content: center;
  margin-top: 2px;
  height: 41px !important;

  span{
    color:$az-gray;
    font-size: $az-font-16;
    font-family: $az-heading-para;
    padding-left: 10px;
  }

  img{
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
  }

}

.rating_list{
  margin: 5px 0;
  li{
    padding: 0 !important;
    span{
      font-family: $az-heading-para !important;
      display: inline !important;
      width: auto !important;
    }
  }
}

.msg_srl_sec{
  span{
    font-size: inherit !important;
  }
}

.az-header-menu > .nav span.menu_heading_top{
  font-size: 14px !important;
}

.az-header-menu > .nav .nav-item {
  margin: 0 !important;
  font-size: 14px !important;
}

body.modal-open{
  padding: 0 !important;
  font-size: $az-font-14 !important;
}

.az-header-menu .nav-link .rightArrow{
  font-size: inherit !important;
}

.dashbaord-pageHeadertitle h3{
  padding-top: 0 !important;
  font-weight: unset !important;
}

.property_menu p{
  padding:0 0 0 10px !important; 
  font-size: $az-font-14 !important;
}

.az-header-menu .nav-link .rightArrow {
  font-size: inherit !important;
}

.showquickreplyModal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        h3{
          font-weight: unset !important;
        }
      }
    }
  }
}