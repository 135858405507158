// Ion Rangeslider Skins
@mixin irs-color-variant($color) {
  .irs-bar,
  .irs-bar-edge,
  .irs-slider::before {
    background-color: $color;
  }

  .irs-from,
  .irs-to,
  .irs-single {
    background-color: $color;
    &::after {
      border-top-color: $color;
    }
  }
}

@mixin irs-modern-slider-variant($color) {
  .irs-slider {
    border-color: $color;
    &::before {
      border-color: $color;
    }
  }
}

@mixin irs-outline-variant($color) {
  .irs-line,
  .irs-slider {
    border-color: $color;
    &:hover,
    &:focus {
      &::before {
        background-color: darken($color, 5%);
      }
    }
  }
}

// $name = class name
// $perc = donut percentage value
// $size = size of donut
// $width = width of chart
// $base = thickness of donut
// $center = background color of the percentage
// $color = background color of donut hole
// $textColor = color of text in center and the percentage
// $textSize = size of text
@mixin donut-chart(
  $name,
  $perc,
  $size,
  $width,
  $base,
  $center,
  $color,
  $textColor: $color,
  $textSize: 40px
) {
  $color2: $color;
  $base2: $base;
  $deg: ($perc/100 * 360) + deg;
  $deg1: 90deg;
  $deg2: $deg;

  // If percentage is less than 50%
  @if $perc < 50 {
    $base: $color;
    $color: $base2;
    $color2: $base2;
    $deg1: ($perc/100 * 360+90) + deg;
    $deg2: 0deg;
  }

  .az-donut-chart {
    &#{$name} {
      width: $size;
      height: $size;
      background: $base;

      .slice {
        &.one {
          clip: rect(0 $size $size/2 0);
          -webkit-transform: rotate($deg1);
          transform: rotate($deg1);
          background: $color;
        }

        &.two {
          clip: rect(0 $size/2 $size 0);
          -webkit-transform: rotate($deg2);
          transform: rotate($deg2);
          background: $color2;
        }
      }

      .chart-center {
        top: $width;
        left: $width;
        width: $size - ($width * 2);
        height: $size - ($width * 2);
        background: $center;

        span {
          font-size: $textSize;
          line-height: $size - ($width * 2);
          color: $textColor;

          &:after {
            content: "#{$perc}%";
          }
        }
      }
    }
  }
}

@mixin checkbox {
  input[type="checkbox"] {
    height: $az-checkbox-height-base !important;
    margin-top: 0 !important;
    margin-bottom: 6px;
    position: relative;
    }
}

@mixin h2 {
  font-weight: 500;
  color: $az-light-white;
  font-family: $az-heading-one;
  font-size: 36px;
}

@mixin h3 {
  font-weight: 500;
  color: $az-black;
  font-family: $az-heading-one;
  font-size: $az-font-36;

  @media (max-width: 480px) {
    font-size: $az-font-30;
  }
  @media (max-width: 320px) {
    font-size: $az-font-30;
  }
}

@mixin heading4 {
  color: $az-white;
  font-family: $az-heading-two;
  font-size: $az-font-20;
  text-transform: capitalize;
   margin-bottom: 0;
   padding: 5px 30px 7px;
   background: #c89c82;
   border-radius: 50px;

  @media (max-width: 480px) {
    font-size: $az-font-20;
  }
  @media (max-width: 320px) {
    font-size: $az-font-20;
  }
}

@mixin p-dark {
  font-weight: normal;
  color: $az-black;
  font-family: $az-heading-two;
  font-size: $az-font-16;
  font-weight: 400;
}

@mixin p-light {
  font-weight: normal;
  color: $az-black;
  font-family: $az-heading-one;
  font-size: $az-font-16;
}

@mixin green-button {
  font-family: $az-heading-two;
  background-color: $az-eage-color;
  color: $az-black;
  font-size: 16px;
  border-radius: 0px;
  font-weight: normal;
  font-weight: 500;
  border: 2px solid $az-eage-color;

}

@mixin light-gray-font {
  font-family: $az-heading-two;
  color: $az-gray;
}

@mixin p-normal {
  font-weight: normal;
  color: $az-black;
  font-family: $az-heading-para-light;
  font-size: $az-font-16;
}

@mixin h4 {
  font-weight: 500;
  color: $az-black;
  font-family: $az-heading-one;
  font-size: 32px;
}

@mixin h5 {
  font-weight: normal;
  color: $az-black;
  font-family: $az-heading-one;
  font-size: $az-font-18;
}

@mixin navFont {
  font-weight: normal;
  color: $az-black !important;
  font-family: $az-heading-one;
  font-size: $az-font-18;
}

@mixin welcome-para {
  font-weight: normal;
  color: $az-gray;
  font-family: $az-heading-two;
}

/*-----------Custom Form elements------------*/

@mixin aside {
  .nav-pills {
    font-size: $az-font-16;
    text-align: left;
    font-family: $az-heading-para;


    @media (max-width:767px) {
      padding-right: 0 !important;
    }

    .nav-link.active {
      background-color: $az-black; // rgba(50, 27, 99, 0.2);
      color: $az-white;
      border-radius: 2px;
      padding: 14px 20px;
      line-height: 20px;
      &:hover {
        color: $az-white;
      }
    }
    .nav-link {
      padding: 14px 20px;
      line-height: 1.25;
      color : $az-gray;
      &:hover {
        background-color: $az-black; // rgba(50, 27, 99, 0.2);
        color: $az-white;
        border-radius: 2px;

      }
    }
  }
}

@mixin accordion-nav {
  text-align: left;
  font-family: $az-heading-para-light;
  background: none;
  .card {
    background: none;
    margin-bottom: 12px !important;
    .card-header {
      button {
        padding: 14px 20px;
        font-size: $az-font-18;
        color: $az-black;
        border-radius: 2px;
        background: $az-white !important;
        justify-content: space-between;
        display: flex;
        align-items: center;
        font-family: $az-heading-para;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        i {
          font-size: $az-font-16;
        }
        a {
          color: $az-black;
        }

        &:hover {
          background: $az-black !important;
          color: $az-white;
          a {
            color: $az-white;
          }
        }
      }
    }
    .collapse {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 2px;
      .card-body {
        border: none;
        padding-top: 24px;
        table {
          th {
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-gray;
            text-transform: capitalize;
            border-top: 1px solid #cdd4e0;
            padding: 16px 15px;
            font-weight: normal;

          }
          td {
            padding: 16px 15px;
            vertical-align: middle;
            font-size: $az-font-15;
            color: $az-black;
            font-family: $az-heading-two;
            font-weight: normal;

            b {
              @include inactivestatus;
            }
            b.active {
              @include activestatus;
            }

            img {
              background: none !important;
              cursor: pointer;
              border: none;
              outline: none;

              &:first-child {
                margin-right: 0px;
              }
            }
          }
        }

        button.btn {
          @include add-button;
        }
      }
      p {
        font-size: $az-font-16;
        color: $az-black;
        font-family: $az-heading-two;
        font-weight: normal;
      }
    }
    .collapsing {
      margin-top: 9px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      background: $az-white;
      border-radius: 2px;
      .card-body {
        border: none;
        padding-top: 24px;
        table {
          th {
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-gray;
            text-transform: capitalize;
            border-top: 1px solid #cdd4e0;
            padding: 16px 15px;
            font-weight: normal;

          }
          td {
            padding: 16px 15px;
            vertical-align: middle;
            font-size: $az-font-14;
            color: $az-black;
            font-family: $az-heading-two;
            font-weight: normal;
            b {
              @include inactivestatus;
            }
            b.active {
              @include activestatus;
            }

            img {
              background: none !important;
              cursor: pointer;
              border: none;
              outline: none;

              &:first-child {
                margin-right: 0px;
              }
            }
          }
        }

        button.btn {
          @include add-button;
        }
        p {
          font-size: $az-font-16;
          color: $az-black;
          font-family: $az-heading-two;
          font-weight: normal;
        }
      }
    }
  }
}

@mixin add-button {
  min-width: 90px;
  max-width: 90px;
  height: auto;
  padding: 4px 10px;
  border-radius: 0px;
  border: 2px solid $az-eage-color;
  font-size: $az-font-14;
  font-family: $az-heading-two;
  color: $az-black;
  font-weight: 500;
  line-height: inherit;
  background: none;

  img {
    padding-right: 0px;
    vertical-align: baseline;
    width: 10px;
    filter: contrast(2);
    margin-right: 6px;
  }

  &:hover {
    color: $az-white !important;
    font-weight: normal;
    outline: none !important;
    border: 2px solid $az-black !important;
    background: $az-black !important;
    color: $az-white !important;
    box-shadow: none !important;
    img {
      filter: invert(8) contrast(3) !important;
    }

  }
  &:focus {
    color: $az-white !important;
    font-weight: normal;
    outline: none !important;
    border: 2px solid $az-black !important;
    background: $az-black !important;
    color: $az-white !important;
    box-shadow: none !important;
    img {
      filter: invert(8) contrast(3) !important;
    }
  }
  &:active {
    color: $az-white !important;
    font-weight: normal;
    outline: none !important;
    border: 2px solid $az-black !important;
    background: $az-black !important;
    color: $az-white !important;
    box-shadow: none !important;
    img {
      filter: invert(8) contrast(3) !important;
    }
  }
}

@mixin dashboard-background {
  background: $az-white !important;
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 72px);
}

@mixin pageHeadertitle {
  background: $az-eage-color;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 5px 30px !important;
  min-height: 52px !important;
  h3 {
    font-size: $az-font-18;
    font-family: $az-heading-para;
    color: $az-black;
    margin-bottom: 0;
    @media (max-width: 767px) {
      font-size: $az-font-18;
      margin-bottom: 15px;
      text-align: center;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
  .btn-success-outline-small {
    @include btn-success-outline-small;
  }
  .btn-success {
    @include common-green-btn;
  }
  .btn-success-outline {
    @include btn-success-outline;

  }
  .btn-danger-outline {
    @include btn-danger-outline;
  }

  b {
    margin-left: 10px;
    line-height: 1.5;
    padding: 2px 10px;
  }
  b.active {
      margin-left: 10px;
      line-height: 1.5;
      padding: 2px 10px;
  }

  @media(max-width:767px){
    padding: 15px !important;
    flex-direction: column;
    width: 100%;
    align-items: center !important;
    justify-content: flex-start !important;
    min-height: 92px;

  }

  .d-flex {

    @media(max-width:767px){
      width: 100%;
      align-items: center;
      justify-content: center !important;
    }
  }

  a {
    display: contents;
    &:first-child {

      @media (max-width: 767px) {
        // margin-right: 5px !important;
      }
    }
  }
}

@mixin common-green-btn {
  font-family: $az-heading-two;
  background-color: $az-eage-color !important;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color !important;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  min-width: 90px;
  max-width: 90px;
  justify-content: center;
  img{
    padding-right: 5px;
    vertical-align: baseline;
  }
  &:focus {
    color: $az-white !important;
    background-color: $az-black !important;
    border: 2px solid  $az-black !important;
  }
  &:active {
    color: $az-white !important;
    background-color: $az-black !important;
    border: 2px solid  $az-black !important;
  }
  &:hover {
    color: $az-white !important;
    background-color: $az-black !important;
    border: 2px solid  $az-black !important;
  }
  span {
    display: flex;
    align-items: center;
  }

  @media(max-width:767px){
    min-width: 113px;
    max-width: 113px;
    font-size: $az-font-12;
    margin-right: 5px;
    padding: 7px 20px !important;
    align-items: center;
  }
}

@mixin activestatus {
  font-weight: normal;
  font-family: $az-heading-para;
  color: $az-white;
  font-size: 0px;
  background: $az-active-status-bg !important;
  padding: 0;
  border-radius: 50px;
  text-transform: uppercase;
  margin-left: 10px !important;
  display: inline-block;
  width: 12px;
  height: 12px;
  line-height: 12px;
  vertical-align: middle;
}

@mixin inactivestatus {
  font-weight: normal;
  font-family: $az-heading-para;
  color: $az-white;
  font-size: 0px;
  background: $az-inactive-status-bg;
  padding: 0;
  border-radius: 50px;
  text-transform: uppercase;
  width: 12px;
  height: 12px;
  line-height: 12px;
  margin-left: 10px !important;
  display: inline-block;
  vertical-align: middle;

}

@mixin modalbox {
  .modal-dialog {
    margin: auto;
    max-width: 400px;
    .modal-content {
      border-radius: 2px;
      @media(max-width:767px){
        width: 95%;
        margin: auto;
      }
      .modal-body {
        position: relative;
        @include dashbaordprofilecontent;

        padding: 32px 24px;
        img {
          margin-bottom: 24px;
          width: 46px;
        }
        h3 {
          font-weight: normal;
          font-family: $az-heading-one;
          color: $az-black;
          font-size: $az-font-24;
          text-transform: inherit;
          margin-bottom: 24px;
        }
        p {
          font-weight: normal;
          font-family: $az-heading-two;
          color: $az-black;
          font-size: $az-font-15;
          text-transform: inherit;
          margin-bottom: 32px;
        }
        label {
          font-weight: normal;
          font-family: $az-heading-two;
          color: $az-gray;
          font-size: $az-font-14;
          text-transform: inherit;
          margin-bottom: 8px;
        }

        .react-datepicker-wrapper{
          width: 100% !important;
          height: 42px !important;
          padding: 0 !important;
          border-radius: 4px !important;
          font-family: $az-heading-two;
          color: $az-black;
          font-size: $az-font-14;

          input {
              height: 43px !important;
          }
        }
        @include fileUploadeditpage;
        .infosize{
          font-size: $az-font-12;
          color: $az-gray;
          font-family: $az-heading-two;
          line-height: 1.25;
          margin-top: 11px;
          width: 100%;

          img{
            margin-top: 1px;
            width: auto;
            @media (max-width: 767px) {
              vertical-align: middle;
            }
          }
            p{
             margin: 0;
             font-size: $az-font-12;
             color: $az-gray;
             font-family: $az-heading-two;
             line-height: 1.25;
          }
        }
        .welcomeClose {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 1;
          img {
            cursor: pointer;
            width: 24px;
             margin-bottom: 0;
            }
        }
      }
      button.btn-success {
        // @include modalbtn-stroke;
        // min-width: max-content !important;
        // max-width: max-content !important;
          @include modalbtn-delete;
      }
      button.btn-outline-light {
        @include modalbtn-outline;
      }
      button.btn-danger {
        @include modalbtn-delete;
      }
    }
  }
}

@mixin inputfield {
  border-radius: 2px !important;
  color: $az-black !important;
  font-weight: normal !important;
  font-family: $az-heading-two !important;
  font-size: $az-font-14 !important;
  height: 44px !important;
  width: 100% !important;

  border: 1px solid $az-form-input-bdr !important;
  padding: 0.375rem 0.75rem;
  background-color: $az-white !important;
  &:focus {
    // border: 1px solid $az-border-color !important;
    box-shadow: none;
    outline: 0px solid $az-border-color !important;
  }
}

@mixin selectfield {
  border-radius: 4px;
  padding: 10px 6px;
  width: 100% !important;
  border: 1px solid $az-border-color !important;
  color: $az-black !important;
  font-weight: normal !important;
  font-family: $az-heading-two !important;
  font-size: $az-font-14 !important;
  height: 42px !important;
  margin-top: 0px;
  position: relative;
  // background: $az-white !important;


  // background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
  // background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 14px;
  background-repeat: no-repeat;
  background-image: url(../../images/down-a.svg);
  background-position: center right 15px;
  display: inline-block;
  padding: 0.5em 2.5em 0.5em 1em;
   -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;


  option {
    border-radius: 0px !important;
    color: $az-black;
    font-family: $az-heading-two !important;
    font-size: $az-font-14;
    padding: 5px 0;
    height: 20px;
    -moz-appearance: none;
    &:hover {
      background-color: $az-light-gray !important;
      color: $az-black !important;

    }
  }
  option.form-control {
    color: $az-black;
    font-family: $az-heading-two !important;
    font-size: $az-font-14;
    padding: 5px 0;
    height: 20px;
    -moz-appearance: none;
  }



  &:focus {
    outline: none;
    box-shadow: none;
   border: 1px solid $az-border-color !important;

   background-size: 14px;
   background-repeat: no-repeat;
   background-image: url(../../images/down-u.svg);
   background-position: center right 15px;
   outline: 0;


  }
  &:hover {
    outline: none;
    box-shadow: none;
   border: 1px solid $az-border-color !important;
  }
  &:active {
    outline: none;
    box-shadow: none;
    border: 1px solid $az-border-color !important;
  }

}

@mixin modalbtn-stroke {
  font-family: $az-heading-two;
  background-color: $az-eage-color;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-left: 15px !important;
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    color: $az-white !important;
  }
  &:hover {
    border: 2px solid $az-eage-color;
    box-shadow: none;
    outline: none;
    background-color: $az-black !important;
    color: $az-white !important;

  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    color: $az-white !important;

  }
  @media(max-width:767px){
    min-width: 100px;
    max-width: 100px;
    margin-right: 15px !important;
  }
  span {
      display: flex; justify-content: center; align-items: center;
  }
}
@mixin modalbtn-outline {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width:115px;
  max-width:115px;
  outline: none;
  height: auto;
  margin-right: 15px !important;
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;

  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background:  $az-black !important;
    color: $az-white!important;
  }
  @media(max-width:767px){
    min-width: 100px;
    max-width: 100px;
    margin-left: 0px !important;
  }
}

@mixin modalbtn-delete {
  font-family: $az-heading-two;
  // background-color: $az-inactive-status-bg;
  background-color: $az-eage-color;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: none;
  padding: 4px 10px;
  max-width:115px;
  min-width:115px;
  outline: none;
  height: auto;
  margin-left: 15px !important;
  &:focus {
    border: 2px solid $az-inactive-status-bg;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-inactive-status-bg;
  }
  &:hover {
    border: 2px solid $az-inactive-status-bg;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-inactive-status-bg;
  }
  &:active {
    border: 2px solid $az-inactive-status-bg;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-inactive-status-bg;
  }
}

@mixin pagetabNavigation {
  .nav-tabs {
    background: none;
    padding: 0;
    font-size: $az-font-16;
    font-family: $az-heading-one;
    border-bottom: 1px solid $az-border-color;
    display: none;
    a {
      background: none;
      padding-left: 0;
      padding-right: 0;
      margin-right: 30px;
      color: $az-gray;

      @media (max-width: 767px) {
      border: 1px solid $az-border-color;
      width: 100%;
      margin: 0 0 15px !important;
      text-align: center;
      }

      &:hover {
        background: none;
        color: $az-black;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
    .nav-link.disabled {
      color: $az-disbled;
    }
    a.active {
      border-bottom: 1px solid $az-black;
      background: none;
      padding-left: 0;
      padding-right: 0;
      color: $az-black;

      @media (max-width: 767px) {
        border: 1px solid $az-black;
        width: 100%;
        margin: 0 0 15px !important;
        text-align: center;
        }

      &:hover {
        background: none;
      }
    }
  }
  .tab-content {
    background: none;
    border: none;
    padding: 0;
    .tab-pane {
      .row {
        border-bottom: 1px solid $az-border-color;

        &:last-child {
          border-bottom: none;
        }
        @include fileUploadeditpage;
        .d-flex {
          justify-content: space-between;
          @media (max-width: 767px) {
            // justify-content: center !important;
            justify-content: start !important
          }
          @include activetoggle;
        }

        // select option[value=""] {
        //   color: $az-input-placeholder;

        // }

        .infosize{
          font-size: $az-font-12;
          color: $az-gray;
          font-family: $az-heading-two;
          line-height: 1.25;
          margin-top: 11px;

          img{
            margin-top: 1px;
            @media (max-width: 767px) {
              vertical-align: middle;
            }
          }
            p{
             margin: 0;
          }
        }

        label {
          font-weight: normal;
          font-family: $az-heading-two;
          color: $az-gray;
          font-size: $az-font-14;
          text-transform: inherit;
          margin-bottom: 0px;

          .optional {
            @include optional;
          }

        }
        button.btn-success {
          @include modalbtn-stroke;
        }
      }

      @include tableformate;
    }
  }
}

@mixin spinnerloaderonpage {
  display: block;
}

@mixin mastertable {
  tbody {
    tr {
      background: $az-white;
      &:hover {
        background: $az-table-hover !important;
      }
    }
  }
}


// @mixin maincontainer {
//  max-width: 1390px;
//   margin: auto;
// }

@mixin maincontainer {
  max-width: 100%;
  margin: auto;
  padding: 0 30px;
 }


@mixin dashboardLeftcard {

    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 16px 24px;
    height: 100%;
    min-height: 550px;
    max-height: 850px;

    @include dashbaordprofilecontent;

}

@mixin dashboardRightcard {
  .copyClass{
    width: 18px;
  }

  background: #ffffff;
  box-shadow: none;
  border-radius: 2px;
  padding: 46px 9px;
  height: 100%;
  .text-align-left{
    thead{
      th{
        text-align: left !important;
      }
    }
  }

  @include dashbaordprofilecontent;
  .az-profile-name-text {
    padding-left: 15px;
    .az-profile-name-text {
      padding-left: 0px;
    }
  }
  .form-label {
    text-align: right;
    color: $az-gray;
    font-family: $az-heading-two;
    font-size: 14px;
    font-weight: normal;
    text-transform: inherit;
    margin-bottom: 0px;

    .optional {

      @include optional;
    }



    @media (max-width: 1366px) {
      font-size: $az-font-14;
    }

    @media (max-width: 1280px) {
      font-size: $az-font-14;
    }

    @media (max-width:991px){
      text-align: left;
    }
    @media (max-width:767px){
      text-align: left;
    }
    b {
      font-family: $az-heading-para;
      font-weight: normal;
      color: $az-black;
    }
  }
  hr {
    max-width: 98%;
  }

  .infosize{
    font-size: $az-font-12;
    color: $az-gray;
    font-family: $az-heading-two;
    line-height: 1.25;
    margin-top: 11px;


    img{
      margin-top: 1px;
      max-width: 11px;
      @media (max-width: 767px) {
        vertical-align: middle;
      }
    }
      p{
       margin: 0;
    }
  }

  label {
    font-weight: normal;
    font-family: $az-heading-two;
    color: $az-gray;
    font-size: $az-font-14;
    text-transform: inherit;
    margin-bottom: 8px;

    @media (max-width: 1366px) {
      font-size: $az-font-13;
    }

    @media (max-width: 1280px) {
      font-size: $az-font-13;
    }

  }

}


@mixin dashbaordprofilecontent {
  .link-url{
    color: #36f !important;
    text-decoration: none;
    &:hover{
      color: #0039e6 !important;
    }
  }
  .az-profile-name-text {
    font-size: $az-font-14 !important;
    color: $az-gray !important;
    font-family: $az-heading-two;
    font-weight: normal;
    margin-bottom: 0;
  }
  .media-body {
      font-size: $az-font-16;
      color: $az-black;
      // font-family: $az-heading-two;
      font-family: $az-heading-para;
      font-weight: normal;
      padding-bottom: 6px;
      margin-bottom: 1rem;
      // font-weight: 600;

    span{
      font-size: $az-font-15;
      color: $az-black;
      font-family: $az-heading-two;
      margin-right: 15px;
    }
    span.maplink {
      white-space: break-spaces;
      word-break: break-all;
      color: $az-black;
      font-family: $az-heading-two;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
        text-underline-offset: 0.3em;
      }
    }

    button{
      font-size: $az-font-14;
      color: $az-black;
      font-family: $az-heading-two;
      background: none;
      box-shadow: none;
      padding: 7px 17px 6px;
      border-radius: 4px;
      border: solid 1px #4a4a4a;
      line-height: 1.36;
      font-weight: 500;
    }
  }
  .col-md-12, .d-flex {
    .az-profile-name-text {
      font-size: $az-font-14 !important;
      color: $az-optional !important;
      font-family: $az-heading-two;
      font-weight: normal;
      margin-bottom: 0;

      @media(max-width:767px){
        text-align: left;
      }

  }
  .para{
    font-size: $az-font-15;
    color: $az-black;
    font-family: $az-heading-two;
    font-weight: normal;
    margin: 9px 0;

    a {
      color: $az-black;
      &:hover{
        text-decoration: underline;
        text-underline-offset: 0.3em;
      }
    }
  }
  .brand-sm-logo{
    figure {
      width: 42px;
      height: 42px;
      border: 2px solid $az-border-color;
      border-radius: 50%;
      margin: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: none;

      }
    }
  }
 }

 .multiImgBuuton {

  @media(max-width:767px){

    margin:20px auto;
    text-align: center;

  }
 }
.az-content-body-contacts {
  .media-body {
    font-family:$az-heading-two !important;
  }
}

}

@mixin uploaladfilechange {

  input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    background: $az-white !important;
    height: 20px !important;
  }

}


@mixin breadcrumbs {

  a {
    font-family: $az-heading-two;
    color: $az-black;
    font-size: $az-font-13;
    text-decoration: none;
    margin: 0 0px 0 0;
    text-transform: uppercase;
  }
  span {
    font-size: $az-font-13;
    color: $az-gray;
    font-family: $az-heading-two;
    margin: 0 0 0 3px;
    text-transform: uppercase;
    &:hover {
      color: $az-gray;
    }

    &:first-child {
      margin: 0;
    }
  }
}


@mixin fileUploadeditpage {
  .fileUpload{

    span {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: $az-white;
      border: 2px solid $az-border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 0px;
      img {
        max-width: 100px;
        min-width: 100px;
        max-height: 100px;
        min-height: 100px;
        object-fit: cover;
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
      label {
        margin-bottom: 0;
      }
    }
  @include fileupldoadinput;

  @media(max-width:767px) {
    justify-content: start !important;
  }
 }
}

@mixin btn-danger-outline {
    font-family: $az-heading-two;
    background: none;
    color: $az-inactive-status-bg;
    font-size: $az-font-16;
    border-radius: 0px;
    font-weight: 500;
    border: 2px solid $az-inactive-status-bg !important;
    padding: 10px 20px;
    min-width: 160px;
    max-width: 160px;
    outline: none !important;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: baseline;

    img{
      padding-right: 0px;
      vertical-align: baseline;
      padding-top: 1px;
      // filter: contrast(2);
      margin-right: 8px;
      position: relative;
      top: 1px;
    }
  &:focus {
    box-shadow: none;
    outline: none;
    border: 2px solid transparent !important;
    background: transparent;
    color: $az-white;
    img {
      filter: invert(0) contrast(1) brightness(5);
    }
  }
  &:active {
    box-shadow: none;
    outline: none;
    border: 2px solid transparent !important;
    background: transparent;
    color: $az-white;
    img {
      filter: invert(0) contrast(1) brightness(5);
    }
  }
  &:hover {
    box-shadow: none;
    outline: none;
    border: 2px solid transparent !important;
    background: transparent;
    color: $az-white;

    img {
      filter: invert(0) contrast(1) brightness(5);
    }
  }

}

@mixin btn-success-outline {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;

  img{
    display: none;
    padding-right: 0px;
    vertical-align: baseline;
    width: 10px;
    filter: contrast(2);
    margin-right: 6px;
    @media (max-width: 767px) {
      margin-right: 4px;
    }
  }
  // &:focus {
  //   border: 2px solid $az-black;
  //   box-shadow: none;
  //   outline: none;
  //   background-color: $az-black;
  //   color: $az-white;
  //   img {
  //     filter: invert(8) contrast(3);

  //   }
  // }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img {
      filter: invert(8) contrast(3);
      // filter: contrast(0.5);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img {
      filter: invert(8) contrast(3);
      // filter: contrast(0.5);
    }
  }
  @media (max-width: 767px) {
     font-size: $az-font-12;
     padding: 10px 0px;
     width: calc(100% / 3 - 5px);
     max-width: calc(100% / 3 - 5px);
     min-width: calc(100% / 3 - 5px);
     margin-right: 5px !important;
  }
  @media (max-width: 360px) {
    min-width: 110px;
    max-width: 110px;
    margin: 5px !important;
  }


  // &:first-child {

  //   @media (max-width: 767px) {
  //     margin-right: 5px !important;
  //   }
  // }
  &:nth-child(3) {
      @media (max-width: 767px) {
        margin-right: 0px !important;
    }
  }

}
@mixin common-green-btn-small {
  font-family: $az-heading-two;
  background-color: $az-eage-color !important;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color !important;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    padding-right: 5px;
    vertical-align: baseline;
  }
  &:focus {
    box-shadow: none ;
    outline: none;
    background: $az-black !important;
    border: 2px solid $az-black !important;
    color: $az-white !important;
  }
  &:active {
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    border: 2px solid $az-black !important;
    color: $az-white !important;
  }
  &:hover {
    box-shadow: none;
    outline: none;
    background: $az-black !important;
    border: 2px solid $az-black !important;
    color: $az-white !important;
  }
  @media (max-width:767px) {
    min-width: 113px;
    max-width: 113px;
    font-size: $az-font-12;
    margin-right: 5px;
    padding: 7px 20px !important;
  }

}

@mixin btn-success-outline-small {

  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-14;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  align-items: baseline;
  justify-content: center;

  img{
    display: none;
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    width: 10px;
    margin-right: 6px;
    @media (max-width:767px) {
      width: 8px;
    }
  }
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
       filter: invert(8) contrast(3);
      //  filter: contrast(0.5);
    }
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img{
      filter: invert(8) contrast(3);
      // filter: contrast(0.5);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
      // filter: contrast(0.5);
    }
  }
  @media (max-width:767px) {
    min-width: 120px;
    max-width: 120px;
    font-size: $az-font-12;
    margin-right: 5px;
    padding: 7px 15px !important;
    align-items: center;
  }
  @media (max-width:767px) {
    min-width: 110px;
    max-width: 110px;
  }
}

@mixin scrolling-carousel-swiper {
  h3{
    font-family: $az-heading-para;
    color: $az-black;
    font-size: $az-font-16;
    text-align: left;
    margin-bottom: 22px;
  }

  .styles-module_sliderBase__swkx1.swipesection {
      padding-left: 30px;
      padding-right: 30px;
    // border-top: 1px solid #72818e33;
    @media(max-width: 991px) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media(max-width: 767px) {
      padding-left: 5px;
      padding-right: 5px;
    }

   .styles-module_slider__o0fqa {
     position: relative;
     border-bottom: 1px solid #72818e33;


        // border-bottom: 1px solid $az-border-color;
        // border-top: 1px solid $az-border-color;
        .nav-item{

          a{
            background: none;
            border-radius: 0 !important;
            font-family: $az-heading-para;
            color: $az-gray;
            font-size: $az-font-16;
            padding: 11px 15px;
            margin-right: 5px;
            border-bottom: 3px solid transparent;

            @media(max-width: 767px) {
              font-size: $az-font-16;
            }

            &:hover {
              color: $az-black;
              border-bottom: 3px solid $az-eage-color;
            }

          }
          a.active{
            background: none;
            border-radius: 0 !important;
            font-family: $az-heading-para;
            color: $az-black;
            font-size: $az-font-16;
            position: relative;

            @media(max-width: 767px) {
              font-size: $az-font-16;
            }

            &::after {
              border-bottom: 3px solid $az-eage-color;
              width: 100%;
              height: 1px;
              content: '';
              position: absolute;
              bottom: -3px;
              left: 50%;
              text-align: center;
              transform: translate(-50%, 0%);
            }
         }

         &:first-child {
          a{
           padding: 11px 15px 11px 0 !important;
           margin-right: 5px;

           @media(max-width: 767px) {
             font-size: $az-font-16;
           }
          }
         }
         &:last-child {
          a{
           padding: 11px 15px;
           margin-right: 0px;
          }
         }
        }
   }
   .dropdown {
    button {
      display: block;
      padding: 0;
      position: absolute;
      right: 0;
      background: $az-light-white !important;
      width: 27px;
      height: 44px;
      z-index: 2;
      top: 1px;
      text-align: right;
      &:focus {
        border: none;
        box-shadow: none !important;
        outline: none !important;
      }
      &:after {
        display: none;
      }
   }
  }
  .show {
    z-index: 9;
   .show {
    display: block;
    opacity: 1;
    transform: inherit !important;
    right: 0 !important;
    left: auto !important;
    top: 0px !important;
    border: none;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 6px 6px;
    a {
      font-family: $az-heading-one;
      color: $az-light-gray;
      font-size: $az-font-14;
      text-transform: capitalize;

      &:hover{
        background-color: rgba(50, 27, 99, 0.2);
        color: $az-purple;
      }
      &:active {
        background-color: rgba(50, 27, 99, 0.2);
        color: $az-purple;
      }
      &:focus {
        background-color: rgba(50, 27, 99, 0.2);
        color: $az-purple;
      }
      &:last-child {
        border-radius: 0px 0px 6px 6px;
      }
    }
    }
  }


    div[data-arrow=right] {
        bottom: 0;
        height: 26px;
        margin-bottom: auto;
        margin-top: auto;
        width: 34px;
        position: absolute;
        top: 0px;
        opacity: 1;
        cursor: pointer;
        background: linear-gradient(270deg,$az-light-white 10%,hsla(0,0%,100%,0));
        right: 0;
        z-index: 1;
        @media(max-width: 767px) {
          background:transparent !important;
          
        }
        button{
            display: block;
            border: none;
            cursor: pointer;
            background-image: url(../../images/swipe-right-arrow.svg);
            height: 34px;
            width: 34px;
            background-color: transparent ;
            background-position: center;
            @media(max-width: 767px) {
              width: 40px;

            }
        }
      }
      div[data-arrow=left] {
          bottom: 0;
          height: 26px;
          margin-bottom: auto;
          margin-top: auto;
          width: 34px;
          position: absolute;
          top: 0px;
          opacity: 1;
          cursor: pointer;
          background: linear-gradient(90deg,$az-light-white 10%,hsla(0,0%,100%,0));
          left: 0;
          z-index: 1;
          @media(max-width: 767px) {
            background:transparent !important;
            
          }
          button{
            display: block;
            border: none;
            cursor: pointer;
            background-image: url(../../images/swipe-left-arrow.svg);
            height: 34px;
            width: 34px;
            background-color: transparent ;
            background-position: center;

            @media(max-width: 767px) {
              width: 40px;
            }
        }

        @media(max-width: 767px) {

          left: -15px;

        }
     }
  }
}

@mixin activetoggle {

      b {
        font-family: $az-heading-para;
        font-size: $az-font-14;
        color: $az-gray;
        text-transform: capitalize;
        margin-right: 10px;
        font-weight: normal;
        display: none;
    }
    b.active {
        font-family: $az-heading-para;
        font-size: $az-font-14;
        color: $az-active-status-bg;
        text-transform: capitalize;
        margin-right: 10px;
        font-weight: normal;
        display: none;

    }

}

@mixin aminitiescard {
  .left {
      max-height: 445px;
      overflow: auto;
      background: $az-light-white;
      width: 100% !important;
      max-width: 100% !important;
      position: initial;
      min-height: auto;
      height: auto;

    @media(max-width:991px) {
      margin-bottom: 58px;
    }

    @media(max-width:767px) {
      margin-bottom: 127px;
    }

    ul{
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      margin: 0;
      position: relative;
    li{
        width: 100%;
        padding: 0px 58px 15px 31px;
        min-height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

       @include selectcard;

       @media(max-width:991px) {
        width: 100%;
        padding: 0px 31px 58px;
       }

       @media(max-width:767px) {
        width: 100%;
        padding: 0px 31px 58px;
      }
    }
  }
  .move-btn {
    width: 100px;
    position: absolute;
    top: 41%;
    right: -2%;
    transform: translate(50%, 0);
    z-index: 1;

    @media(max-width:991px) {
      left: 50%;
      transform: translate(-50%, 2%);
      top: 89%;
    }

    @media(max-width:767px) {
      left: 50%;
      transform: translate(-50%, 7%);
      top: 79%;
    }

    button.green-btn {
      background: $az-black;
      border-radius: 4px;
      padding: 7px 15px;
      color: $az-white;
      font-weight: 500;
      margin-bottom: 8px;
      border: none;
      width: 75px;
      margin-top: 20px;
    }
    button.green-btn:disabled {
      background: #aca9a9;
      cursor:not-allowed;
    }
    button.basic-btn:disabled {
      cursor:not-allowed;
      background: #aca9a9;
    }
    button.basic-btn {
      background: $az-black;
      border-radius: 4px;
      padding: 7px 15px;
      color: $az-white;
      font-weight: 500;
      border: none;
      width: 75px;
    }
  }

  @include customscrollbar;

}

  .right{
      max-height: 445px;
      overflow: auto;
      width: 100% !important;
      max-width: 100% !important;
      position: initial;
      min-height: auto;
      height: auto;
    ul{
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      margin: 0;
      position: relative;
    li{
       width: 100%;
       padding: 0px 31px 15px 58px;
       min-height: 88px;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;

       @include selectcard;

       @media(max-width:991px) {
        width: 100%;
        padding: 0px 31px 0;
       }

       @media(max-width:767px) {
        width: 100%;
        padding: 0px 31px 0px;
       }
     }
    }

    @include customscrollbar;

  }
}

@mixin selectcard {

  .selectcard {
    background: $az-white;
    padding: 13px 16px;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    margin: 15px 0 0;
    width: 100%;

    @media(max-width:991px) {
      padding: 13px 33px 13px 16px;
     }

     @media(max-width:767px) {
      padding: 13px 33px 13px 16px;
     }

    label {
      font-size: $az-font-16;
      font-family: $az-heading-two;
      color: $az-black;
      padding-left: 15px;
      padding-bottom: 2px;
      padding-right: 19px;
      line-height: 1.2;
      margin-top: -2px;
      padding-top: 0;
      margin-bottom: 5px;

    }
    span{
      margin-left: 30px;
      font-size: $az-font-14;
      font-family: $az-heading-two;
      color: $az-gray;
      padding-bottom: 2px;
    }
    h6{
      margin-left: 30px;
      font-size: $az-font-12;
      font-family: $az-heading-two;
      color: $az-gray;
      margin-bottom: 0;
    }
    img {
      position: absolute;
      right: 16px;
      top: 16px;
      z-index: 1;
      cursor: pointer;
      @media(max-width:991px) {
         right: 8px;
         top: 8px;
       }

       @media(max-width:767px) {
          top: 8px;
          top: 8px;
       }
    }
  }

}

@mixin optional {

    color: $az-optional;
    font-family: $az-heading-two;
    font-size: 11px;
    font-weight: normal;
    margin-left: 3px;
    background: transparent;
}

@mixin tableformate {

  table {
    thead{
        th{
            padding: 16px 5px;
            font-size: $az-font-14;
            font-family: $az-heading-two;
            color: $az-gray;
            font-weight: normal;
            text-transform: initial;
            &:last-child{
                text-align: center;
            }
        }
        .asc{
         // background-image:none !important;
          background-image:url(../../images/asc.svg) !important;
          padding-right: 25px;
        }
        .desc{
          // background-image:none !important;
          background-image:url(../../images/desc.svg) !important;
          padding-right: 25px;
        }
    }
    tbody{
        tr{
            background: none;
             td{
                 padding: 16px 5px;
                 font-size: $az-font-15 !important;
                 font-family: $az-heading-two !important;
                 color: $az-black;
                 font-weight: normal !important;
                 &:last-child{
                     text-align: center;
                 }
                 a {
                     color: $az-black;
                 &:hover {
                     text-decoration: underline;
                     text-underline-offset: 0.3em;
                   }
                 }

             }
             .status_id {
              b {
                @include inactivestatus;
                margin-left: 18px;
                @media (max-width:767px){
                  display: block;
                }
              }
              b.active {
                @include activestatus;
                margin-left: 18px;
              }
             }
         .brand_list_image{
                 height: 32px;
                 width: 32px;
                 border-radius: 50%;
                 object-fit: cover;
                 border: 1px solid $az-border-color;
             }
         }
         .td_clickable a{
          width: 100%;
          display: block;
          text-decoration: none !important;
        }
        .td_clickable span{
         width: 100%;
         display: block;
       }
     }

 }
}


@mixin fileupldoadinput {

  ul.ruLink {
    display: inline-flex;
    li {
      list-style: none;
      margin-right: 15px;
      padding-right: 15px;
      position: relative;
      text-transform: uppercase;

      color: $az-gray;
      font-family: $az-heading-para;
      text-decoration: none;
      font-size: $az-font-14;
      display: none;

      &::after {
        position: absolute;
        content: "";
        background: $az-border-color;
        height: 10px;
        width: 1px;
        right: 0;
        top: 5px;
      }
      &:last-child {
        margin-right: 0px;
        padding-right: 0px;
        &::after {
          display: none;
        }


      }

      a {
        color: $az-inactive-status-bg;
        text-decoration: none;
        font-family: $az-heading-para;
        font-size: $az-font-14;
        &:hover {
          color: $az-inactive-status-bg;
          text-decoration: none;
        }
      }
    }
  }

}

@mixin viewavtar {

  .demo-avatar-group {
    position: relative;
    @media(max-width:767px) {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .avatar-md {
        width: 100px;
        height: 100px;

       &:after {
            width: 14px;
            height: 14px;
            right: 20px;
            top: 8px;
            display: none;
        }
        figure {
          width: 100px;
          height: 100px;
          border: 1px solid $az-border-color;
          border-radius: 50%;
        }
    }
    .avatar-round {
      margin-right: 186px;
      @media(max-width:767px) {
        margin:30px auto;
        width: 100%;
        text-align: center;
      }

      figure{
        margin:0;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid $az-border-color;
        @media(max-width:767px) {
          margin:auto;
        }
        img{
             max-width: 100%;
             width: 100px;
             height: 100px;
             object-fit: cover;
        }
      }
    }
    .avatar-squre {
      @media(max-width:767px) {
        margin:auto;
        width: 100%;
        text-align: center;
      }

      figure{
        margin:0;
        width: 100px;
        height: 100px;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid $az-border-color;
        @media(max-width:767px) {
          margin:auto;
        }
        img{
             max-width: 100%;
             width: 100px;
             height: 100px;
             object-fit: cover;
       }

      }
    }
      @include statuschange;
  }

}



@mixin btn-success-outline-x-small {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid transparent;
  padding: 12px 20px;
  min-width: 100%;
  max-width: 100%px;
  outline: none;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  align-items: baseline;
  justify-content: center;

  img{
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    width: 14px;
    margin-right: 0px;
  }
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img{
      filter: invert(8) contrast(3);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
}


@mixin btn-danger-outline-x-small {
  font-family: $az-heading-two;
  background: none;
  color: $az-inactive-status-bg;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid transparent !important;
  padding: 9px 20px;
  min-width: 100%;
  max-width: 100%;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: baseline;

  img{
    padding-right: 0px;
    vertical-align: baseline;
    padding-top: 1px;
    filter: contrast(2);
    margin-right: 0px;

  }
&:focus {
  box-shadow: none;
  outline: none;
  border: 2px solid transparent !important;
  background: transparent;
  color: $az-inactive-status-bg;
  img {
    filter: invert(0) contrast(1) brightness(5);
  }
}
&:active {
  box-shadow: none;
  outline: none;
  border: 2px solid transparent !important;
  background: transparent;
  color: $az-inactive-status-bg;
  img {
    filter: invert(0) contrast(1) brightness(5);
  }
}
&:hover {
  box-shadow: none;
  outline: none;
  border: 2px solid transparent !important;
  background: transparent;
  color: $az-inactive-status-bg;

  img {
    filter: invert(0) contrast(1) brightness(5);
  }
}

}




@mixin btn-multilist-outline {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-black;
  padding: 4px 10px;
  min-width: 90px;
  max-width: 90px;
  outline: none;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  align-items: baseline;
  justify-content: center;

  img{
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    width: 14px;
    margin-right: 0px;

  }
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img{
      filter: invert(8) contrast(3);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
  @media (max-width:767px) {
    min-width: 113px;
    max-width: 113px;
    font-size: $az-font-12;
    margin-right: 5px;
    padding: 7px 20px !important;
  }
}


@mixin btn-multilist-outline-danger {
  font-family: $az-heading-two;
  background: $az-inactive-status-bg;
  color: $az-inactive-status-bg;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-inactive-status-bg !important;
  padding: 25px 20px;
  min-width: 90px;
  max-width: 90px;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: baseline;

  img{
    padding-right: 0px;
    vertical-align: baseline;
    padding-top: 1px;
    filter: invert(0) contrast(1) brightness(5);
    margin-right: 0px;

  }
&:focus {
  box-shadow: none;
  outline: none;
  border: 2px solid $az-inactive-status-bg !important;
  background: none;
  color: $az-white;
  img {

    filter: contrast(2);
  }

}
&:active {
  box-shadow: none;
  outline: none;
  border: 2px solid $az-inactive-status-bg !important;
  background: none;
  color: $az-white;
  img {
    filter: contrast(2);
  }
}
&:hover {
  box-shadow: none;
  outline: none;
  border: 2px solid $az-inactive-status-bg !important;
  background: none;
  color: $az-white;

  img {
    filter: contrast(2);
  }
}

}


@mixin btn-multiupload-outline {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-black;
  padding: 30px 20px 25px;
  min-width: 160px;
  max-width: 160px;
  outline: none;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img{
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    width: 30px;
    margin-right: 0px;

  }
 span {
   display: block;
   font-size: 14px;
   font-family: $az-heading-two;
   margin-top: 5px;
   b {
     font-weight: normal;
   }
 }

  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img{
      filter: invert(8) contrast(3);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
  @media (max-width:767px) {
    min-width: 150px;
    max-width: 150px;
    font-size: $az-font-20;
    margin-right: 5px;
    padding: 7px 20px !important;
    min-height: 150px;
    max-height: 150px;
  }
}

@mixin accordion {
//for accordion
.closed .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(-180deg);
  opacity: 0;
}
.closed .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(-180deg);
  opacity: 1;
}

.opened {
  opacity: 1;
}
.opened .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
}
.opened .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);
  opacity: 0;
}
.circle-plus {
  width: 4em;
  font-size: 1em;
}

.circle-plus .circle {
  position: relative;
  width: 2.55em;
  height: 2.5em;
}

.circle-plus .circle .horizontal {
  position: absolute;
  left: 50%;
  margin-left: 0;
  top: 33%;
  margin-top: 0;

}

.circle-plus .circle .vertical {
  position: absolute;
  left: 50%;
  margin-left: 0;
  top: 33%;
  margin-top: 0;
  font-size: 16px;
}


}




@mixin btn-success-outline-small-link {
  font-family: $az-heading-two;
  background: none;
  color: $az-black;
  font-size: $az-font-16;
  border-radius: 0px;
  font-weight: 500;
  border: 2px solid $az-eage-color;
  padding: 7px;
  min-width: 110px;
  max-width: 110px;
  outline: none;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  align-items: unset;
  justify-content: center;

  img{
    padding-right: 0px;
    vertical-align: baseline;
    filter: contrast(2);
    width: 13px;
    margin-right: 4px;
  }
  &:focus {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
  &:hover {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;

    img{
      filter: invert(8) contrast(3);
    }
  }
  &:active {
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: $az-white;
    img{
      filter: invert(8) contrast(3);
    }
  }
  @media (max-width:767px) {
    min-width: 113px;
    max-width: 113px;
    font-size: $az-font-12;
    margin-right: 5px;
    padding: 7px 20px !important;
  }
}

@mixin tumbImagemodal {

  .modal-dialog {
    justify-content: center;
    min-height: calc(100% - 1rem);

    @media (max-width:1366px) {

         min-height: calc(100% - 1rem);
    }
    @media (max-width:1280px) {

        min-height: calc(100% - 1rem);

    }
    @media (max-width:1199px) {

        min-height: calc(100% - 1rem);

    }
    @media (max-width:991px) {

        min-height: calc(100% - 1rem);

    }
    @media (max-width:767px) {

        max-width: 70%;
        min-height: calc(100% - 1rem);

    }

    .modal-content {
        border-radius: 8px;
        background: none !important;
        width: auto;

        .modal-body {
            padding: 0 !important;
            background: $az-white !important;
            .welcomeClose{
                position: absolute;
                right: 25px;
                top: 20px;
                z-index: 1;
                @media (max-width:767px) {
                  right: 15px;
                  top: 12px;
                }
                img {
                    cursor: pointer;
                    width: 24px;
                  @media (max-width:767px) {
                      width: 24px;
                  }
                }
              }

              img{
                margin-bottom: 0;
                opacity: 1;
                border-radius: 8px 0 0;
                text-align: left;
                width: 100% !important;
                height: 100%;
                object-fit: contain;
            }

        }
    }
}
}






@mixin customscrollbar {


    scrollbar-width: 3px;
    scrollbar-color: $az-black $az-light-gray;
    -webkit-scrollbar-width: 3px;
    -moz-scrollbar-width: 3px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-clip: content-box;
      border: 0px solid transparent;
      width: 3px;
      background-color: $az-light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $az-black;

      &:hover {
        background-color: $az-black;
      }



    }

    [class^=message-container] {
      /* height: 200px; */
      overflow-y: scroll;
      width: 3px;
    }
    [class^=message-container] div {
      /* height: 400px;
      width: 200%;
      padding: 4rem;
      background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
    }


  }




  @mixin form-control-focus($ignore-warning: false) {
    &:focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $input-focus-box-shadow;
      }
    }
    @include deprecate("The `form-control-focus()` mixin", "v4.4.0", "v5", $ignore-warning);
  }

  // This mixin uses an `if()` technique to be compatible with Dart Sass
  // See https://github.com/sass/sass/issues/1873#issuecomment-152293725 for more details
  @mixin form-validation-state-selector($state) {
    @if ($state == "valid" or $state == "invalid") {
      .was-validated #{if(&, "&", "")}:#{$state},
      #{if(&, "&", "")}.is-#{$state} {
        @content;
      }
    } @else {
      #{if(&, "&", "")}.is-#{$state} {
        @content;
      }
    }
  }

  @mixin form-validation-state($state, $color, $icon) {
    .#{$state}-feedback {
      display: none;
      width: 100%;
      margin-top: $form-feedback-margin-top;
      @include font-size($form-feedback-font-size);
      color: $color;
    }

    .#{$state}-tooltip {
      position: absolute;
      top: 100%;
      z-index: 5;
      display: none;
      max-width: 100%; // Contain to parent when possible
      padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
      margin-top: .1rem;
      @include font-size($form-feedback-tooltip-font-size);
      line-height: $form-feedback-tooltip-line-height;
      color: color-yiq($color);
      background-color: rgba($color, $form-feedback-tooltip-opacity);
      @include border-radius($form-feedback-tooltip-border-radius);
    }

    @include form-validation-state-selector($state) {
      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }

    .form-control {
      @include form-validation-state-selector($state) {
        border-color: $color;

        @if $enable-validation-icons {
          padding-right: $input-height-inner;
          background-image: escape-svg($icon);
          background-repeat: no-repeat;
          background-position: right $input-height-inner-quarter center;
          background-size: $input-height-inner-half $input-height-inner-half;
        }

        &:focus {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }

    // stylelint-disable-next-line selector-no-qualifying-type
    textarea.form-control {
      @include form-validation-state-selector($state) {
        @if $enable-validation-icons {
          padding-right: $input-height-inner;
          background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
        }
      }
    }

    .custom-select {
      @include form-validation-state-selector($state) {
        border-color: $color;

        @if $enable-validation-icons {
          padding-right: $custom-select-feedback-icon-padding-right;
          background: $custom-select-background, escape-svg($icon) $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
        }

        &:focus {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }
      }
    }

    .form-check-input {
      @include form-validation-state-selector($state) {
        ~ .form-check-label {
          color: $color;
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: block;
        }
      }
    }

    .custom-control-input {
      @include form-validation-state-selector($state) {
        ~ .custom-control-label {
          color: $color;

          &::before {
            border-color: $color;
          }
        }

        &:checked {
          ~ .custom-control-label::before {
            border-color: lighten($color, 10%);
            @include gradient-bg(lighten($color, 10%));
          }
        }

        &:focus {
          ~ .custom-control-label::before {
            box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
          }

          &:not(:checked) ~ .custom-control-label::before {
            border-color: $color;
          }
        }
      }
    }

    // custom file
    .custom-file-input {
      @include form-validation-state-selector($state) {
        ~ .custom-file-label {
          border-color: $color;
        }

        &:focus {
          ~ .custom-file-label {
            border-color: $color;
            box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
          }
        }
      }
    }
  }


  @mixin  statuschange {

    b.status-inactive {
      @include inactivestatus;
      font-size: $az-font-11;
      margin-left: 0;
      position: absolute;
      top: 10px;
      right: 1px;
      background:url(../../images/offline-tick.svg) !important;
      width: 21px;
      height: 21px;
      display: none;

    }
    b.status-active {
      @include activestatus;
      font-size: $az-font-11;
      background:url(../../images/online-tick.svg) !important;
      margin-left: 0;
      position: absolute;
      top: 10px;
      right: 1px;
      width: 21px;
      height: 21px;
    }

  }





  @mixin materalltables {

          border: none;
          padding: 30px 30px 30px 45px;

          @media(max-width: 1199px){
            padding: 30px 30px 30px 45px;
          }

          @media(max-width: 767px){
            padding: 30px 30px 30px 45px;
          }
          table {
            margin-top: 30px;
            th {
              font-size: $az-font-14;
              font-family: $az-heading-two;
              color: $az-gray;
              text-transform: capitalize;
              border-top: 1px solid #cdd4e0;
              padding: 16px 15px;
              font-weight: normal;

            }
            td {
              padding: 16px 15px;
              vertical-align: middle;
              font-size: $az-font-15;
              color: $az-black;
              font-family: $az-heading-two;
              font-weight: normal;

              b {
                @include inactivestatus;
              }
              b.active {
                @include activestatus;
              }

              img {
                background: none !important;
                cursor: pointer;
                border: none;
                outline: none;

                &:first-child {
                  margin-right: 0px;
                }
              }
            }
          }

          button.btn {
            @include add-button;
          }

          // button {
          //   padding: 14px 20px;
          //   font-size: $az-font-18;
          //   color: $az-black;
          //   border-radius: 2px;
          //   background: none !important;
          //   justify-content: space-between;
          //   display: flex;
          //   align-items: center;
          //   font-family: $az-heading-para;
          //   box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
          //   -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
          //   -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
          //   -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
          //   i {
          //     font-size: $az-font-16;
          //   }
          //   a {
          //     color: $az-black;
          //   }

          //   &:hover {
          //     background: $az-black !important;
          //     color: $az-white;
          //     a {
          //       color: $az-white;
          //     }
          //   }
          // }

          p {
            font-size: $az-font-16;
            color: $az-black;
            font-family: $az-heading-two;
            font-weight: normal;
          }
 }



@mixin deleteicon {

    img{
      padding-right: 0px;
      vertical-align: baseline;
      padding-top: 1px;
      // filter: contrast(2);
      margin-right: 8px;
      position: relative;
      top: 1px;
      cursor: pointer;
    }
  &:focus {
    color: $az-inactive-status-bg;
  }
  &:active {
    color: $az-inactive-status-bg;
  }
  &:hover {
    color: $az-inactive-status-bg;
  }

}



@mixin addmultiImages {

.addImg {

      figure{
          position: relative;
          max-width: 100%;
          max-height: 180px;
          min-height: 180px;
          background: $az-white;
          border-radius: 4px;
          display: block;
          border: 1px solid $az-border-color;

          @media(max-width:1800px){
            max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }

          @media(max-width:1680px){
             max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }

          @media(max-width:1440px){
             max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }


          @media(max-width:1360px){
             max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }

          @media(max-width:1280px){
             max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }
          @media(max-width:1024px){
             max-width: 100%;
            max-height: 180px;
            min-height: 1807px;
          }

          @media(max-width:767px){
            max-width: 100%;
            max-height: 180px;
            min-height: 180px;
          }

        img {  max-height: 180px; }

        img.plus{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px !important;
            height: 50px !important;
            display: block;
            filter: contrast(0.1);
            opacity: 0.3;
        }
      }
      h5{
        font-size: $az-font-15;
        color: $az-gray;
        font-family: $az-heading-two;
        text-align: center;
        text-transform: capitalize;
        margin-bottom: 0;
      }
}
.addImg.uploaded {

      figure{
        overflow: hidden;
        // position: relative;
        // max-width: 100%;
        // max-height: 180px;
        background: $az-white;
        border-radius: 8px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // border: 1px solid $az-border-color;
        text-align: center;


        @media(max-width:1800px){
          max-width: 100%;
          max-height: 180px;
        }

        @media(max-width:1680px){
          max-width: 100%;
          max-height: 180px;
        }

        @media(max-width:1440px){
          max-width: 100%;
          max-height: 180px;
        }

        @media(max-width:1360px){
          max-width: 100%;
          max-height: 180px;
        }

        @media(max-width:1280px){
          max-width: 100%;
          max-height: 180px;
        }
        @media(max-width:1024px){
          max-width: 100%;
          max-height: 180px;
        }

        @media(max-width:767px){
          max-width: 100%;
          max-height: 180px;
        }

        img {
          // max-width: 100%;
          max-height: 180px;

        }

        img.plus{
          display: none;
      }
      }

}

}

@mixin as-react-table {
  display: flex;
  flex-direction: column;
  position: relative;
  .table-head {
    .col-md-6 {

      &:first-child {
        position: absolute;
        bottom: 19px !important;
        right: 258px;
        max-width: max-content;
        border-right: 1px solid $az-border-color;
        z-index: 1;
        @media (max-width:1366px){

          right: 258px;

        }

        @media (max-width:1280px){

          right: 258px;

        }
        @media (max-width:1199px){

          right: 258px;

        }
        @media (max-width:991px){

          right: 280px;

        }
        @media (max-width:767px){

          left: 50%;
          transform: translate(-50%, -50%);
          bottom: 40px !important;
          border-right: 0;
        }
      }

      &:last-child {
        max-width: 100%;
        flex: 0 0 100%;

      }

    }

  }
  .table-body {
    // thead{
    //   .asc {
    //     background-image: none !important;
    //     span{
    //       position: relative;

    //       &::before{
    //          position: absolute;
    //          right: -25px;
    //          background-image:url(../../images/asc.svg) !important;

    //          background-size: 16px;
    //          content: '';
    //          top: 1px;
    //          height: 16px;
    //          width: 16px;
    //          left: auto;
    //       }
    //     }
    //   }
    //   .desc {
    //      background-image: none !important;
    //     span{
    //       position: relative;

    //       &::before{
    //          position: absolute;
    //          right: -25px;
    //         background-image:url(../../images/desc.svg) !important;
    //          background-size: 16px;
    //          content: '';
    //          top: 1px;
    //          height: 16px;
    //          width: 16px;
    //          left: auto;
    //       }
    //     }
    //   }
    // }

    table{
      margin-bottom: 70px;

      @media(max-width:767px) {
        margin-bottom: 160px;
      }
    }
  }
  .table-foot {

    display: flex;
    flex-direction: row-reverse;
    margin-right: 15px;
    .col-md-6 {

      &:first-child {
       text-align: right;

      }
      &:last-child {
        .pagination {
            display: flex;
            align-items: center;
       }

      }

    }

  }
}



// OH mixin


@mixin OHtopHead {

  background: none !important;

    .topAleart{
    background: $az-oh-primary;
      h3 {
        color: $az-white;
        font-family: $az-heading-one;
        padding: 0;
        margin: 0;
        font-size: $az-font-30;

      }
      span {
        border-left: 1px solid $az-oh-seconday;
         h4 {
          color: $az-white;
          font-family: $az-heading-two;
          padding: 0;
          margin: 0;
          font-size: $az-font-18;
          font-weight: normal;
          margin-bottom: -3px;

        }
        p {
          color: $az-white;
          font-family: $az-heading-light;
          padding: 0;
          margin: 0;
          font-size: $az-font-16;
          font-weight: normal;

        }
      }

      button {
         @include OHprimaybutton;
      }
      button.close {
        background: none;
        color:$az-white;
        padding: 0;
        opacity: 1;
        top: 30%;
        right: -38px;
        span {
          border:none;
          font-size: 0;
          background-image: url(../../images/oh/icon-close.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 20px;
          width: 20px;

        }
       }
      }

      header{
        background: $az-oh-seconday;
        padding: 40px 0;
        img.logo{
          max-width: 150px;

        }
        h5.toll-free{
          color: $az-oh-primary;
          font-family: $az-heading-one;
          padding: 0;
          margin: 0;
          font-size: $az-font-18;
          font-weight: normal;
          margin-bottom: -3px;
        }

        button {
          @include OHsecondarybutton;
        }
        .menu {
            a{
              border-top: 2px solid  $az-oh-primary;
              border-bottom: 2px solid  $az-oh-primary;
              padding: 4px;
              width: 60px;
              display: block;
              position: relative;
              margin-top: -10px;
              &::after {
                position: absolute;
                padding: 4px;
                width: 60px;
                content: '';
                border-bottom: 2px solid  $az-oh-primary;
                left: 0;
                top: 10px;
              }
            }

        }
      }

}



@mixin Banner {
  min-height: 821px;
  height: 821px;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  h1 {
      color: $az-white;
      font-family: $az-heading-one;
      padding: 0;
      margin: 0;
      font-size: $az-font-76;
      text-align: center;
      line-height: 1;
      padding: 0 170px;
      margin-bottom: 60px;
  }
  button {
      @include OHprimaybutton-lg;
  }
}


@mixin blockOne {
  .Left {
  background: $az-oh-seconday;
  padding: 0 155px;
  min-height: 655px;
  height: 655px;

  @media (max-width:1366px){
    min-height: 520px;
    height: 520px;
  }

  @media (max-width:1440px) {
      padding: 0 50px;
  }

  h2 {
    color: $az-oh-primary;
    font-family: $az-heading-one;
    padding: 0;
    margin: 0;
    font-size: $az-font-76;
    text-align: left;
    line-height: 1;
    padding: 0;
    margin-bottom: 30px;

    sup {     font-size: 45%;
      top: -5px;
      vertical-align: revert;
      left: 0;
    }
  }

  p {
      color: $az-oh-primary;
      font-family: $az-heading-two;
      padding: 0;
      margin: 0;
      font-size: $az-font-16;
      text-align: left;
      line-height: 1.2;
      padding: 0px;
      margin-bottom: 50px;
  }

    button {
      @include OHprimaybutton-md;
    }
  }
  .right {
    background-image: url(../../images/oh/professionally-img.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: 100%;

    @media (max-width:1366px){
      background-size: 100% 100%;
    }

  }
}
 @mixin fullblock {
   background: $az-oh-primary;
   padding: 175px 119px ;

   @media (max-width:1366px) {
      padding: 120px 50px ;
    }

    h2 {
      color: $az-white;
      font-family: $az-heading-one;
      padding: 0;
      margin: 0;
      font-size: $az-font-75;
      text-align: center;
      line-height: 1.2;
      padding: 0;
      margin-bottom: 30px;

      @media (max-width:1366px) {
          font-size: $az-font-60;
        }

    }

 }

 @mixin footer {
  background: $az-oh-seconday;
  padding: 70px 0 5px;
   .f-logo{
      max-width: 125px;
   }
   .f-border{
     border-right: 1px solid $az-oh-primary;
     padding-right: 15px;
     display: flex;
     height: 100%;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;

      ul{
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        li {
            margin: 0 10px;
            color: $az-oh-primary;
            font-family: $az-heading-two;
            font-size: $az-font-18;
          a {
            color: $az-oh-primary;
            font-family: $az-heading-two;
            font-size: $az-font-18;
          }
        }
      }
   }
   .footer-bottom {
      background: $az-oh-primary;
      padding: 27px 0;
      width: 100%;
      margin-top: 60px;
   }
 }

@mixin OHprimaybutton {
  background: $az-oh-seconday;
  font-size: $az-font-18;
  color:$az-oh-primary;
  border-radius: 18px;
  padding: 9px 15px 10px;
  border: 0;
  outline: none;
  height: 36px;
  display: flex;
  align-items: center;
  font-family: $az-heading-one;

  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }

}

@mixin OHsecondarybutton {
  background:none;
  font-size: $az-font-18;
  color:$az-oh-primary;
  border-radius: 12px;
  padding: 9px 15px 10px;
  border: 1px solid  $az-oh-primary;
  outline: none;
  height: 36px;
  display: flex;
  align-items: center;
  font-family: $az-heading-one;

  &:active {
    border: 1px solid  $az-oh-primary;
    outline: none;
  }
  &:focus {
    border: 1px solid  $az-oh-primary;
    outline: none;
  }
  &:hover {
    border: 1px solid  $az-oh-primary;
    outline: none;
  }

}

@mixin OHprimaybutton-lg {
  background: $az-oh-seconday;
  font-size: $az-font-28;
  color:$az-oh-primary;
  border-radius: 12px;
  padding: 10px 20px 12px;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  font-family: $az-heading-one;

  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }
}

  @mixin OHprimaybutton-md {
    background: $az-oh-primary;
    font-size: $az-font-28;
    color:$az-white;
    border-radius: 12px;
    padding: 10px 20px 12px;
    border: 0;
    outline: none;
    display: flex;
    align-items:center;
    justify-content: center;
    font-family: $az-heading-one;

    &:active {
      border: 0;
      outline: none;
    }
    &:focus {
      border: 0;
      outline: none;
    }
    &:hover {
      border: 0;
      outline: none;
    }

}

@mixin OHprimaybutton-sm {
  background: $az-oh-primary;
  font-size: $az-font-28;
  color:$az-white;
  border-radius: 12px;
  padding: 10px 20px 12px;
  border: 0;
  outline: none;
  display: flex;
  align-items:center;
  justify-content: center;
  font-family: $az-heading-one;
  min-width: 170px;

  &:active {
    border: 0;
    outline: none;
  }
  &:focus {
    border: 0;
    outline: none;
  }
  &:hover {
    border: 0;
    outline: none;
  }

}

@mixin calendarNewformate {

  .react-datepicker__input-container {
    .borderInput {

        &:focus, &:active {
        border: none;
        outline: 1px solid;
        }
    }
}


.react-datepicker-popper {
    z-index: 33333 !important;
    .react-datepicker {
        min-width: 316px;
        button.react-datepicker__navigation {
            background: none;
            padding: inherit;
            min-width: auto;
        }
    }
    .react-datepicker--time-only{
      min-width: auto !important;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 38px;
        line-height: 38px;

    }
    .react-datepicker__day--keyboard-selected,  .react-datepicker__day--selected:hover,   .react-datepicker__day--selected {
        background: #000;
        border-radius: 50%;
    }
    .react-datepicker__day:hover {
        border-radius: 50%;
    }
    .react-datepicker__header {
        background: $az-eage-color;

        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            color: $az-black !important;
        }
        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {

            color: $az-black !important;
        }

        .react-datepicker__navigation-icon--previous::before, .react-datepicker__navigation-icon--next::before {
            border-color: $az-black !important;
        }

    }
    .react-datepicker__month-container {
        width: 100% !important;
    }

    .react-datepicker__day--keyboard-selected:hover {
        background-color: $az-black !important;
    }


}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    border-top-color: #aeaeae !important;
}

// .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
//     border-bottom: none;
//     border-top-color: $az-black !important;
// }
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color:  #aeaeae !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  border-bottom-color:  $az-eage-color !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color:  #aeaeae !important;
}
.react-datepicker__navigation-icon::before {
    color: $az-white;
}
.react-datepicker__navigation--next:hover{
  border-left-color: $az-black !important;
}
.react-datepicker__navigation--next {
  border-left-color: $az-black;
}
.react-datepicker__navigation--previous {
  border-right-color: $az-black;
}

.react-datepicker__month-read-view--down-arrow {
  border-top-color: $az-black;
  border-width: 0.25rem;
}
.react-datepicker__year-read-view--down-arrow {
  border-top-color: $az-black;
  border-width: 0.25rem;
}

}

// @mixin flashSavebtn {
// .flashSavebtn {
//   position: relative;
//   background: $az-eage-color;
//   color: $az-black;
//   border: none !important;
//   font-family: $az-heading-two;
//   font-size: $az-font-14;
//   border-radius: 0px;
//   font-weight: 500;
//   padding: 6px 10px;
//   display: flex;
//   align-items: center;
//   outline: none !important;
//   box-shadow: none !important;
//   min-width: 90px;
//   max-width: 90px;
//   justify-content: center;
//   position: relative;
//   z-index: 1;

//  &:hover {
//   z-index: 1;
//   color: $az-white;
//    &::after {
//     box-shadow: inset 0 0 0 0px $az-eage-color;

//    }
//  }
//  &::after {
//   background: $az-black;
//   box-shadow: inset 0 0 0 60px $az-eage-color;
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   transition: all 0.25s ease;
//   border-radius: 0px;
//   z-index: -1;
//   color: $az-white;
// }
// }
// }

@mixin flashSavebtn {
  .flashSavebtn {
    position: relative;
    background: $az-eage-color !important;
    color: $az-black;
    border: none !important;
    font-family: $az-heading-two;
    font-size: $az-font-14;
    border-radius: 0px;
    font-weight: 500;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    outline: none !important;
    box-shadow: none !important;
    min-width: 100px !important;
    max-width: 100px !important;
    justify-content: center;
    z-index: 1;
    box-shadow: inset 0 0 0 60px $az-eage-color !important;
    transition: all 0.55s ease;
    transform: scale(1);

  }
  .flashSavebtn1 {
    background: $az-black !important;
    box-shadow: inset 0 0 0 0px $az-eage-color !important;
    content: "";
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.55s ease;
    border-radius: 0px;
    z-index: 0;
    color: $az-white !important;
    transform: scale(1);
    border: none !important;
  }
  }

  .fout_btn_w{
    @media (max-width:767px){
    display: inline-flex !important;
    flex-wrap: wrap;
    }
  }
  .modal.serch_coperate_modal .modal-dialog .modal-content button.btn-success:disabled{
    opacity: 1;
  }

  @mixin OHlittlebtn{
    background: $az-eage-color;
    color:$az-black;
    border: 0;
    text-decoration: none ;
    text-underline-offset: 0.1em;
    font-family: $az-heading-two;
    font-size: $az-font-14;
    max-width: 90px;
    min-width: 90px;
    padding: 4.5px 10px;
    margin-left: 2px;
    &:hover{
      background: $az-black;
      color: $az-white;
    }
  }

    @mixin OHlittlebtn{
    background: $az-eage-color;
    color:$az-black;
    border: 0;
    text-decoration: none ;
    text-underline-offset: 0.1em;
    font-family: $az-heading-two;
    font-size: $az-font-14;
    max-width: 90px;
    min-width: 90px;
    padding: 6px 10px;
    margin-left: 2px;
    &:hover{
      background: $az-black;
      color: $az-white;
    }
  }