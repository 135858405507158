// Home page

main.oh-template {
    .container{    max-width: 1200px; }

    // header
     @include OHtopHead;

    // Middle

    .banner {
            background-image: url(../../images/oh/banner.jpg);
            @include Banner;
            &::after {
                    position: absolute;
                    bottom: -60px;
                    background-color: $az-white;
                    width: 2px;
                    height: 260px;
                    content: '';
                    z-index: 9;
                    left: 50%;
                    transform: translate(-50%, 0px);
                    
            }

    }

    .howdoesitwork {
                padding: 70px 15px;
                background: $az-oh-seconday;
                h2 {
                    color: $az-black;
                    font-family: $az-heading-one;
                    padding: 0;
                    margin: 0;
                    font-size: $az-font-76;
                    text-align: center;
                    line-height: 1;
                    padding: 0 190px;
                    margin-bottom: 60px;
                }

                .howwork {
                        padding: 0 80px;

                        @media (max-width:1440px) {
                            padding: 0 50px; 
                        }
                    h5 {
                        color: $az-white;
                        font-family: $az-heading-one;
                        padding: 0;
                        margin: 0;
                        font-size: $az-font-76;
                        text-align: left;
                        line-height: 1;
                        padding: 0px;
                        margin-bottom: 30px;
                    }
                    h6 {
                        color: $az-oh-primary;
                        font-family: $az-heading-one;
                        padding: 0;
                        margin: 0;
                        font-size: $az-font-25;
                        text-align: left;
                        line-height: 1;
                        padding: 0;
                        margin-bottom: 30px;
                    }
                    p {
                        color: $az-oh-primary;
                        font-family: $az-heading-two;
                        padding: 0;
                        margin: 0;
                        font-size: $az-font-16;
                        text-align: left;
                        line-height: 1.2;
                        padding: 0px;
                        margin-bottom: 15px;
                    }

                }
                .bd-r {
                    border-right: 1px solid $az-white;
                }
        }

    .we-actually {
        background-image: url(../../images/oh/banner-2.jpg);

        @include Banner;
            h1 {
                margin-bottom: 20px;
            }

            p {
                color: $az-white;
                font-family: $az-heading-two;
                padding: 0 110px;
                font-size: $az-font-35;
                line-height: 1;
                margin-bottom: 40px;
                text-align: center;
                line-height: 1.2;
            }
        }

    .city-reimagined {
        background-image: url(../../images/oh/banner-3.png);
        background-repeat: no-repeat;
        position: relative;
        @include Banner;
            h1 {
                margin-bottom: 20px;
            }

            p {
                color: $az-white;
                font-family: $az-heading-two;
                padding: 0 110px;
                font-size: $az-font-35;
                line-height: 1;
                margin-bottom: 40px;
                text-align: center;
                line-height: 1.2;
            }

            .chat-calender-icons{
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                a {
                    background: $az-oh-primary;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 15px 37px 20px;
                    margin: 15px 15px 30px 0;
                    color: $az-white;
                        text-align: center;
                }
            }
        }
    
    .blockOne {
        @include blockOne;
    }
    .blockTwo {
        @include blockOne;
        .Left {
            padding: 0 155px;
            background: $az-white;
            @media (max-width:1366px){
                padding: 0 40px;
                }
            h2 {
                font-size: $az-font-56;

                @media (max-width:1366px){
                    font-size: $az-font-45;
                    }
            }
        }
        .Right {
            background-image: url(../../images/oh/homesharing.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px){
            background-size: 100% 100%;
            }
        }

    }
    .blockThree {

        @include blockOne;

        .Right {
            background-image: url(../../images/oh/hosts.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px){
            background-size: 100% 100%;
            }
        }

    }
    .blockFour {
        @include blockOne;
        .Left {
            padding: 0 270px 0 155px;
            background: $az-white;

            @media (max-width:1366px){
                padding: 0 40px;
                }

                button {
                    @include OHprimaybutton-sm;
                }
        }
        .Right {
            background-image: url(../../images/oh/live_here.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px){
            background-size: 100% 100%;
            }
        }
    }
    .blockFive {
        @include blockOne;
        .Left {
                button {
                    @include OHprimaybutton-sm;
                    min-width: 216px;
                    max-width: 230px;
                }
        }
        .Right {
            background-image: url(../../images/oh/hosting_features.png);
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;
            background-size: 100% 100%;

            @media (max-width:1366px){
            background-size: 100% 100%;
            }
        }
    }

    .fullblock {
        @include fullblock;
    }

    // Footer

    footer{
        @include footer;
    }
}


