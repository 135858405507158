////////////////////////////////////////////////////////////
//     							                          //
//  Program: custom.scss                                  //
//  Application: scss                                     //
//  Option: Used to create custom css                     //
//  Developer: CV  						                  //
//  Date: 2021-02-01                                      //
//                                                        //
////////////////////////////////////////////////////////////

/* ############### Variables ############### */
@import "./custom/variables";

@import "../fonts/fonts";

@font-face {
  @include headingBold;
}

@font-face {
  @include headingRg;
}

@font-face {
  @include headingMd;
}

@font-face {
  @include headingRgbd;
}

@font-face {
  @include headingLight;
}

.container {
  @include maincontainer;
}

.col-xxl-4 {
  @media (min-width: 1440px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

.col-xxl-8 {
  @media (min-width: 1440px) {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

.col-xxl-6 {
  @media (min-width: 1440px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.az-logo {
  text-transform: none;
}

.az-header-menu-header {
  height: auto;
}

.az-header-top {
  padding: 0 20px 12px 20px;
}

.az-header-menu-header .az-logo img {
  margin: 0 auto;
  width: 50%;
}

.az-card-signin {
  height: auto;
}

.az-2fa-signin-wrapper {
  min-height: 80vh !important;
}

.alert-model-message {
  font-size: 18px !important;
}

.az-2fa-signin-wrapper .az-card-2fafp {
  height: max-content;
}

.timerText,
.negnum {
  color: #dc3545;
}

.align-item-center {
  align-items: center;
}

.az-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.az-content-breadcrumb {
  margin-bottom: 20px;
}

.media-body p {
  margin-bottom: 0;
}

.az-contact-info-body {
  padding-left: 0;
}

// Pagination
.pagination .page-item.disabled {
  padding-right: 13px;
}

.page-item+.page-item {
  margin-left: 2px;
}

.pagination .page-item .page-link {
  width: unset;
  min-width: 36px;
}

// Alert modal
.alert-model-message {
  white-space: pre-wrap;
}

// Media css
@media (min-width: 480px) {
  .az-card-signin {
    padding: 30px 30px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 12rem auto;
  }
}

@media (min-width: 992px) {
  .az-sidebar {
    z-index: 1000;
  }

  .az-content-title {
    margin-bottom: 15px;
  }

  .az-contact-info-header {
    padding-left: 0;
    padding-bottom: 10px;
  }

  .az-contact-info-header .media-body {
    margin-left: 0px;
  }

  .az-contact-info-body .media-body {
    margin-left: 0px;
  }
}

.spinnerLoader {
  background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  text-align: center;
  vertical-align: center;
}

.spinnerLoader .toploader {
  margin: 20% auto;

  img {
    max-width: 80px;
  }
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  position: relative;
}

.form-group span.text-danger {
  padding-left: 2px;
  position: absolute;
}

.asrt-page-length {
  display: inline-block !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

//  **Chrome, Safari, Edge, Opera**
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//  **Firefox**
input[type="number"] {
  -moz-appearance: textfield;
}

.nav-link-custom i {
  width: 23px;
}

.accordion .card-body {
  background: unset;
  border-bottom: 1px solid #f4f5f8;
}

.accordion>.card {
  overflow: unset;
}

.form-wizard .container ol.progtrckr li.progtrckr-doing {
  background-color: #16a2b8;
}

.form-wizard .container ol.progtrckr li.progtrckr-done {
  background-color: #aad3da;
}

.az-content-title {
  text-transform: uppercase;
}

.tx-capital {
  text-transform: uppercase;
}

.prefered-broker {
  max-height: 85px;
  overflow: auto;
  margin-bottom: 0;
  padding-left: 25px;
}

.current-price-holding-qty {
  background-color: #a8dde1;
}

.form-wizard .container .az-content-body {
  text-align: left !important;
}

.btn-primary,
.form-wizard .container>div:not(.content)>button {
  background-color: #16a2b8;
  border-color: #16a2b8;

  // @include hover-focus() {
  //   background-color: #117a8b;
  //   border-color: #117a8b;
  // }

  &:active,
  &:focus {
    background-color: #117a8b;
    border-color: #117a8b;
  }
}

.az-contact-info-body .media+.media::before {
  left: 0px;
}

.badge-status {
  margin-left: 20px;
  position: absolute;
  top: 4px;
}

.badge-status-image {
  margin-left: 20px;
  position: absolute;
  top: 20px;
}

#dropdown-basic {
  background-color: #16a2b8;
}

.az-contact-action a {
  margin-left: 0 !important;
}

.az-contact-action {
  a {
    margin-left: 0 !important;

    // @include hover-focus() {
    //   color: #f4f5f8;
    //   background-color: #16a2b8;
    // }
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.form-wizard .container>div:not(.content)>button:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.form-wizard .container>div:not(.content)>button:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle,
.form-wizard .container>div.show:not(.content)>button.dropdown-toggle {
  background-color: #117a8b;
  border-color: #117a8b;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.form-wizard .container>div:not(.content)>button:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.form-wizard .container>div:not(.content)>button:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.form-wizard .container>div.show:not(.content)>button.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #a8dde1;
}

.az-contact-info-header .az-img-user img {
  width: 80%;
  height: 80%;
}

// .table-striped tbody tr:nth-of-type(2n+1) {
//     background-color: #A8DDE1;
// }
.csv-upload {
  background-color: #ddddff;
}

.green-odd,
.green-even {
  background-color: #208b2c;
  padding: 5%;
}

span.green-odd,
span.green-even {
  color: white;
}

[class*="btn-outline-"]:focus {
  color: #17a2b8;
}

[class*="btn-outline-"]:hover {
  color: white;
}

th.sortable.asc,
th.sortable.desc {
  padding-right: 20px;
}

tr.row-bold:last-child,
tr.header-bold:first-child,
.table-last-row-bold tr:last-child,
.table-row-bold {
  font-weight: bold !important;
}

.text-white {
  color: #fff !important;
}

.grey {
  background-color: #717471;
  color: white;
  padding: 5%;
}

.table-row-grey {
  background-color: #cdd4e0 !important;
}

.table-row-grey td {
  border: 1px solid #fff !important;
}

span.warning {
  font-weight: bold;
  color: red;
}

.lightGreen {
  color: #090;
}

.no-wrap {
  white-space: nowrap;
}

.react-datepicker-popper {
  z-index: 33333 !important;
}

.tail {
  background-color: #a2bde3 !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  border: 1px solid;
  border-radius: 3px;
}

td {
  position: relative;
}

td>span.green-even {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 12px;
}

td>span.green-even:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

td>a.green-even {
  color: #fff;
  padding-left: 12px;
}

td>span.green-odd {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 12px;
}

td>span.green-odd:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

td>a.green-odd {
  color: #fff;
  padding-left: 12px;
}

.trans_type_message>a.green-odd {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}

.trans_type_message>a.green-even {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
}

.quantity>span.green-odd {
  padding-right: 15%;
}

.buy_or_sell_price>span.green-odd {
  padding-right: 15%;
}

.total_cost_or_proceeds>span.green-odd {
  padding-right: 15%;
}

.contract_note_number>span.green-odd {
  padding-right: 10%;
}

.quantity>span.green-even {
  padding-right: 15%;
}

.buy_or_sell_price>span.green-even {
  padding-right: 15%;
}

.total_cost_or_proceeds>span.green-even {
  padding-right: 15%;
}

.contract_note_number>span.green-even {
  padding-right: 10%;
}

td>span.grey {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 12px;
}

td>span.grey:after {
  content: "";
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}

td>a.grey {
  color: #fff;
  padding-left: 12px;
}

// .newCGT {
//     color: #fff;
// }
.discretionaryWarning,
td.alert {
  background-color: #ff5050 !important;
  color: #fff;
}

.isActive input {
  width: 20px;
  height: 20px;
}

.chip,
.singleChip {
  white-space: unset;
}

.search-wrapper {
  max-height: 100px;
  overflow-y: auto;
}

.reviews label {
  padding-right: 15px;
  width: 55px;
}

.reviews select {
  padding-left: 15px;
  padding-right: 15px;
}

.btn-uppercase {
  text-transform: uppercase;
}

.measureperform {
  table-layout: fixed;
}

.measureperform th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0.5em 0.6em 0.4em 0.6em !important;
}

.measureperform th:first-child {
  width: 230px;
}

.performanceUp {
  table-layout: fixed;
}

.performanceUp th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0.5em 0.6em 0.4em 0.6em;
}

.initial {
  table-layout: initial;
  width: 100%;
}

.initial th {
  text-overflow: inherit;
  overflow: inherit;
  white-space: inherit;
}

#as-react-datatable td {
  vertical-align: middle;
}

.textUppercase {
  text-transform: uppercase;
}

@include checkbox;

table {
  thead {
    .bg-info {
      th {
        color: #fff !important;
        background: transparent;

        b {
          color: #fff !important;
        }
      }
    }
  }

  tbody {
    .bg-info {
      th {
        color: #fff !important;
        background: transparent;

        b {
          color: #fff !important;
        }
      }
    }
  }

  .bg-info {
    .header {
      th {
        color: #fff !important;
        background: transparent;

        b {
          color: #fff !important;
        }
      }
    }
  }
}

.peformance-report-table input {
  text-align: right;
}

.border-red {
  border: red !important;
  border-style: solid !important;
}

.border-blue {
  border: blue !important;
  border-style: solid !important;
}

.naColor {
  background-color: #faa4d3;
}

.specialColor {
  background-color: #ffff00;
}

.noSpecialColor {
  background-color: #e4b0cc;
}

.emptyColor {
  background-color: #ff00ff;
}

.emptyColor a {
  color: white;
}

.headerWhite td {
  color: white !important;
  background-color: #17a2b8 !important;
}

.az-header-menu .nav-item>.nav-link.text-danger:hover {
  color: #17a2b8 !important;
}

@media (min-width: 768px) {

  .col-md-12,
  .form-wizard.form-wizard-vertical .container>div:not(.content) {
    flex: 0 0 auto;
  }
}

.customeNegnumColor span {
  color: white !important;
}

.customLoad {
  width: 100%;
  position: fixed;
  height: 100%;
  // background: rgb(255, 4, 4);
  z-index: 9999999;
  background-color: white;
  overflow: hidden;
}

.customLoad .div1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customLoad .div1 .customLoadimg {
  width: 200px;
}

// .customLoad .div1::after{
// content: '';
// width: 200px;
// height: 10px;
// background-color: #256b8b;
// position: absolute;
// animation: cLoad 7s linear 1;
// margin-top: 215px;
// border-radius: 5px 5px;
// }

#preload1 {
  margin-top: 17%;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  border: 10px solid #e5e5e5;
  animation: 3s pre1 linear infinite;
  position: absolute;
  left: 43.5%;
  top: 3.8%;
}

@keyframes pre1 {
  0% {
    transform: rotate(0deg);
    border-top: 10px solid #256b8b;
  }

  100% {
    transform: rotate(360deg);
    border-top: 10px solid #256b8b;
  }
}

@keyframes cLoad {
  0% {
    width: 0;
  }

  100% {
    width: 200px;
  }
}

.customTableDiv .table td {
  padding: 0px !important;
}

.customTableDiv .table td>p {
  position: unset !important;
  margin: 0px;
  height: 50px;
  // padding: 10%;
  padding: 10px;
}

.forgot-text {
  display: flex;
  justify-content: center;
}

.back-button {
  margin-right: 12px;
}

.bg-success {
  background-color: green !important;
}

.bg-danger {
  background-color: red !important;
}

.hand-cursor {
  cursor: pointer;
}

.status-active {
  @include activestatus;
}

.status-inactive {
  @include inactivestatus;
}

.modal-large {
  .modal-dialog {
    max-width: 800px !important;
  }
}

.Nodatafound {
  padding: 100px 0;

  @media (max-width:767px) {
    padding: 50px 20px !important;
    text-align: center;
  }
}

// .accordion {
//   @include accordion;

//   padding: 14px 20px;
//   font-size: $az-font-18;
//   color: $az-black;
//   border-radius: 2px;
//   background: $az-white !important;
//   justify-content: space-between;
//   display: flex;
//   align-items: center;
//   font-family: $az-heading-para;
//   box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
//   -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
//   -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
//   -o-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
// }

.closed .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
}

.closed .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}

.opened {
  opacity: 1;
}

.opened .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}

.opened .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}

.circle-plus {
  width: 4em;
  font-size: 1em;
}

.circle-plus .circle {
  position: relative;
  width: 2.55em;
  height: 2.5em;
}

.circle-plus .circle .horizontal {
  position: absolute;
  background-color: #de947f;
  width: 30px;
  height: 5px;
  left: 50%;
  margin-left: -15px;
  top: 50%;
  margin-top: -2.5px;
}

.circle-plus .circle .vertical {
  position: absolute;
  background-color: #de947f;
  width: 5px;
  height: 30px;
  left: 50%;
  margin-left: -2.5px;
  top: 50%;
  margin-top: -15px;
}

.innter-container {
  padding-left: 0 !important;

  .left {
    width: calc(182px - 50px);
    max-width: 182px;
    flex: 0 0 182px;

    @media (max-width: 1360px) {
      width: calc(182px - 50px);
      max-width: 182px;
      flex: 0 0 182px;
    }

    // @media(max-width:991px) {

    // }
    // @media(max-width:767px) {
    //     position: relative;
    //     left: 150px;
    //     background: #eeedeb;
    //     width: 100%;
    //     height: 100%;
    //  }

    // box-shadow: 4px 5px 18px #dddd;
    overflow-y: auto;
    height: auto;
    min-height: calc(100vh - 44px);
    position: relative;
    overflow-x: hidden;
  }

  .right {
    width: calc(100% - 182px);
    max-width: calc(100% - 182px);
    flex: 0 0 100%;

    @media (max-width: 1360px) {
      width: calc(100% - 182px);
      max-width: calc(100% - 182px);
      flex: 0 0 100%;
    }

    .prop-head {
      aside {
        h2 {
          font-family: $az-heading-one;
          padding: 0 0px 30px;
        }
      }
    }
  }

  .child-menu-section {
    height: 100%;
    padding-top: 41px;
    padding-left: 10px;
    position: absolute;
    width: 100%;

    ul {
      padding: 0;

      li {
        list-style: none;

        font-size: $az-font-16;
        // margin-bottom: 12px;

        span {
          padding: 8px 15px 8px;
          display: block;
          // border-bottom: 1px solid #b9b9b9;
          font-family: $az-heading-para;
          letter-spacing: 0.19px;
          font-size: $az-font-14;
          color: $az-black;
        }

        a {
          padding: 10px 20px;
          letter-spacing: 0.19px;
          line-height: 1.5;
          font-size: $az-font-14;
          font-family: $az-heading-para;
          color: $az-gray;

          &:hover {
            background: $az-black;
            color: $az-white;
          }
        }

        ul {
          padding-left: 0px;

          li {
            margin-bottom: 0px;

            .nav-link {
              padding-left: 40px;
              font-family: $az-heading-two;
              padding: 8px 15px 8px 15px;
            }
          }
        }
      }
    }

    aside {
      .mobilesidebarsettingNavbar {
        display: none;

        @media (max-width: 1199px) {
          display: block;
          position: fixed;
          top: 65px;
          width: 100%;
        }

        @media (max-width: 767px) {
          display: block;
          position: fixed;
          top: 65px;
          width: 100%;
        }

        .dropdown {
          button {
            @media (max-width: 1199px) {
              background: $az-white !important;
              width: 100%;
              text-align: right;
            }

            @media (max-width: 767px) {
              background: $az-white !important;
              width: 100%;
              text-align: right;
            }

            &:after {
              @media (max-width: 1199px) {
                display: none;
              }

              @media (max-width: 767px) {
                display: none;
              }
            }

            i {
              color: $az-black;
            }

            &:focus,
            &:active {
              box-shadow: none;
            }
          }

          .dropdown-menu {
            @media (max-width: 1199px) {
              transform: translate3d(0px, 38px, 0px) !important;
              box-shadow: none !important;
              border-top: 1px solid #ddd;
              width: 100%;
              height: 90%;
              position: fixed !important;
              top: 66px !important;
              overflow: auto;
              padding-top: 10px;
            }

            @media (max-width: 767px) {
              transform: translate3d(0px, 38px, 0px) !important;
              box-shadow: none !important;
              border-top: 1px solid #ddd;
              width: 100%;
              height: 90%;
              position: fixed !important;
              top: 66px !important;
              overflow: auto;
              padding-top: 10px;
            }
          }
        }
      }

      .desksidebarsettingNavbar {
        display: block;

        @media (max-width: 1199px) {
          display: none;
        }

        @media (max-width: 767px) {
          display: none;
        }
      }

      nav {
        .nav-link.active {
          background: $az-eage-color;
          color: $az-black;
          line-height: 1.5;

          &:hover {
            background: $az-black;
            color: $az-white;
          }
        }
      }
    }

    @media (max-width: 1199px) {
      padding-left: 0;
      position: relative !important;
    }

    @media (max-width: 767px) {
      padding-left: 0;
      position: relative !important;
    }
  }
}

.left1 {
  width: calc(182px - 50px);
  max-width: 182px;
  flex: 0 0 182px;

  @media (max-width: 1360px) {
    width: calc(182px - 50px);
    max-width: 182px;
    flex: 0 0 182px;
  }

  // box-shadow: 4px 5px 18px #dddd;
  overflow-y: auto;
  height: auto;
  // min-height: calc(100vh - 44px);
  position: relative;
  overflow-x: hidden;
}

.az-sidebar {
  border: none;
  // box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);
  // -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);
  width: 180px;
  padding-top: 0px;
  background: $az-white;

  .az-header-menu {
   
    ul.nav {
      .menu_bdr_btm{
        width: 100%;
        border-bottom: 1px solid $az-border-color;
        margin: 3px 0;
      }
      li.nav-item {
        

        &:first-child {
          // border-bottom: none;
        }

        a.nav-link {
          position: relative;
          padding: 9px 15px;
          display: block;
          height: 100%;
          font-weight: normal;
          letter-spacing: 0.19px;
          color: $az-gray !important;
          font-family: $az-heading-para;
          font-size: $az-font-14;
          border-bottom: none;

          .rightArrow {
            // display: none;

            @media(max-width: 991px) {
              display: block;
            }

            @media(max-width: 767px) {
              display: block;
            }

          }

          @media(max-width: 991px) {
            display: flex;
            justify-content: space-between;
          }

          @media(max-width: 767px) {
            display: flex;
            justify-content: space-between;
          }

          &:hover, &:active, &:focus {
            i {
              color: $az-white;
            }
          }

        }

        &:hover {
          background-color: $az-black;

          a.nav-link {
            color: $az-white !important;
          }
        }

        &:first-child {
          // border: inherit;
        }

        &:last-child {
          border: none;
        }

        @media (max-width: 991px) {
          margin-top: 0;
        }

        @media (max-width: 767px) {
          margin-top: 0;
        }
      }

      li.nav-item.active {
        position: relative;
        display: block;
        height: 100%;
        font-weight: normal;
        letter-spacing: 0px;
        color: $az-black !important;
        font-family: $az-heading-one;
        font-size: $az-font-16;
        background-color: $az-eage-color;

        a.nav-link {
          color: $az-black !important;
        }

        &:hover {
          background-color: $az-black;

          a.nav-link {
            color: $az-white !important;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    position: fixed;
    transform: translateX(0);
    visibility: hidden;
    width: 100%;
    top: 65px;
    transition: inherit;
    display: none;
  }

  @media (max-width: 767px) {
    position: fixed;
    transform: translateX(0);
    visibility: hidden;
    width: 100%;
    top: 65px;
    transition: inherit;
    display: none;
  }
}

.adminHeadertop {
  .leftNavbar {
    // width: calc(100% - 40%) !important;
    // padding-left: 100px;
    width: 100%;
    justify-content: center;
    padding: 0;

    @media (max-width: 1360px) {
      // width: calc(100% - 40%) !important;
      // padding-left: 5px;
    }
  }

  .prop-logo-name {
    display: flex;
    align-items: center;
    text-align: left;

    .avatar-md {
      img {
        width: 48px;
        height: 48px;
      }

      @include statuschange;

      b.status-active {
        top: 1px;
        right: -1px;
        width: 14px;
        height: 14px;
        background-size: 100% !important;
      }

      b.status-inactive {}

      &::after {
        display: none;
      }
    }

    h4 {
      font-family: $az-heading-one;
      font-size: $az-font-32;
      margin-left: 0px;
      margin-bottom: 0;

      @media (max-width: 991px) {
        font-size: $az-font-20;
      }

      @media (max-width: 767px) {
        font-size: $az-font-20;
      }
    }
  }

  .global-search {
    img {
      width: 24px;
    }
  }

  .oh-logo {
    width: 100%;
    text-align: left;
    display: block;

    @media (max-width: 991px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: none;
    }

    h4 {
      font-family: $az-heading-one;
      font-size: $az-font-32;
      color: $az-black;
      margin: auto;
      padding: 10px;
      text-align: center !important;
      letter-spacing: 0.9px;
      // border-radius: 2px;
      // background: #2C5046;
      padding-left: 0;

      @media (max-width: 991px) {
        font-size: $az-font-20;
      }

      @media (max-width: 767px) {
        font-size: $az-font-20;
      }

      @media (max-width: 360px) {
        font-size: $az-font-18;
      }
    }

    p {
      font-family: $az-heading-two;
      font-size: $az-font-14;
      color: $az-gray;
      margin-bottom: 5px;
    }
  }

  // .search-box{
  //   width: fit-content;
  //   height: fit-content;
  //   position: relative;
  //   margin-right: 20px;

  //   .btn-search{
  //     width: 50px !important;
  //     height: 50px !important;
  //     border-style: none;
  //     font-size: 20px;
  //     font-weight: bold;
  //     outline: none;
  //     cursor: pointer;
  //     border-radius:0;
  //     position: absolute;
  //     right: 0px;
  //     color:$az-gray ;
  //     background-color:transparent;
  //     pointer-events: painted;
  //     z-index: 1;
  //   }
  //   .btn-search:focus ~ .input-search{
  //     width: 300px !important;
  //     border-radius: 0px;
  //     background-color: transparent;
  //     border-bottom:1px solid  #b9b9b9;
  //     transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  //     visibility: visible;
  //   }
  // }
  // .input-search{
  //   height: 50px !important;
  //   width: 50px !important;
  //   border-style: none;
  //   padding: 10px;
  //   font-size: 18px;
  //   letter-spacing: 2px;
  //   outline: none;
  //   border-radius: 0 !important;
  //   transition: all .5s ease-in-out;
  //   background-color: $az-white !important;
  //   padding-right: 40px;
  //   color: $az-gray !important;
  //   border-top: none !important;
  //   border-left: none !important;
  //   border-right: none !important;
  //   border-bottom:none !important;
  //   visibility: hidden;
  // }
  // .input-search::placeholder{
  //   color: $az-gray ;
  //   font-size: 18px;
  //   letter-spacing: 2px;
  //   font-weight: 100;
  // }

  // .input-search:focus{
  //   width: 100% !important;
  //   border-radius: 0px;
  //   background-color: transparent;
  //   border-bottom:none;
  //   transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  //   visibility: visible;
  // }

  .searchboxai {
    width: 100%;
    text-align: center;

    h4 {
      font-family: $az-heading-one;
      font-size: $az-font-21;
      color: $az-white;
      padding: 20px;
      margin: 0;
      letter-spacing: 1.5px;
      width: 179px;
      border-radius: 0px;
      background: #2c5046;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1199px) {
        font-size: $az-font-18;
        padding: 15px 0;
        height: 100%;
        min-height: 65px;
      }

      @media (max-width: 767px) {
        font-size: $az-font-18;
        width: 100%;
        padding: 15px 0;
        height: 100%;
        min-height: 65px;

      }

      @media (max-width: 360px) {
        font-size: $az-font-15;
      }
    }

    h6 {
      font-family: $az-heading-one;
      font-size: $az-font-15;
      color: $az-white;
      padding-bottom: 15px;
      margin: 0;
      letter-spacing: 1.5px;
      width: 179px;
      border-radius: 0px;
      background: #2c5046;
      // min-height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1199px) {
        display: none;
      }

      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.innter-container .left {
  scrollbar-width: 3px;
  scrollbar-color: #b7b7b7 #e9e9e9;
  -webkit-scrollbar-width: 3px;
  -moz-scrollbar-width: 3px;
}

.innter-container .left::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.innter-container .left::-webkit-scrollbar-track {
  background-clip: content-box;
  border: 0px solid transparent;
  width: 3px;
  background-color: #e9e9e9;
}

.innter-container .left::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
}

.innter-container .left::-webkit-scrollbar-thumb:hover {
  background-color: #b7b7b7;
}

.innter-container .left::-webkit-scrollbar-corner,
.innter-container .left::-webkit-scrollbar-track {
  background-color: #e9e9e9;
  width: 3px;
}

[class^="message-container"] {
  /* height: 200px; */
  overflow-y: scroll;
  width: 3px;
}

[class^="message-container"] div {
  /* height: 400px;
width: 200%;
padding: 4rem;
background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
}

.master table tbody tr {
  background: none !important;
}

.az-header {
  position: relative;
  height: 100%;

  .headertwoback {
    display: block;
    // background: #2C5046;
    background: $az-black;
    color: #fff;
    text-decoration: none;
    padding: 9px 10px 9px 15px;
    text-align: left;
    justify-content: flex-start;
    font-size: $az-font-14;
    font-family: "Lato-medium-bold";
    letter-spacing: 0.19px;
    margin: 0px;
    width: 100%;
    text-transform: inherit;

    i {
      font-size: $az-font-13;
      margin-right: 5px;
    }
  }
}

.rightNavbar {
  .messges-alert {
    a {
      position: relative;
      color: $az-black;
      // height: 24px;
      display: flex;
      align-items: center;

      i {
        color: $az-black;
        font-size: $az-font-30;
      }

      .active-msg {
        position: absolute;
        top: 0px;
        width: 12px;
        height: 12px;
        line-height: 22px;
        color: $az-white;
        background-color: $az-bg-danger;
        text-align: center;
        font-size: 10px;
        font-family: $az-heading-two;
        right: 0px;
        border-radius: 50%;


      }

      .active-notification {
        position: absolute;
        top: 0px;
        width: 17px;
        height: 17px;
        line-height: 17px;
        color: $az-white;
        background-color: $az-black;
        text-align: center;
        font-size: 10px;
        font-family: $az-heading-two;
        right: -4px;
        border-radius: 50%;


      }

      @media (max-width:360px) {
        margin-right: 0px;
      }
    }

    button {
      position: relative;
      color: $az-black;
      height: 24px;
      display: flex;
      align-items: center;
      border: none;
      box-shadow: none;
      background: none;

      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }

      i {
        color: $az-black;
        font-size: $az-font-30;
      }

      .active-msg {
        position: absolute;
        top: 0px;
        width: 17px;
        height: 17px;
        line-height: 17px;
        color: $az-white;
        background-color: $az-btn-green;
        text-align: center;
        font-size: 10px;
        font-family: $az-heading-two;
        right: -11px;
        border-radius: 50%;


      }

      .active-notification {
        position: absolute;
        top: 0px;
        width: 17px;
        height: 17px;
        line-height: 17px;
        color: $az-white;
        background-color: $az-black;
        text-align: center;
        font-size: 10px;
        font-family: $az-heading-two;
        right: 2px;
        border-radius: 50%;


      }
    }
  }
}

.dashbaord-pageHeadertitle {
  .deleteicon {
    @include deleteicon;

  }
}

.as-react-table {
  @include as-react-table;

  @media (max-width:767px) {
    padding: 0 5px !important;
  }
}

.overview h3 {
  font-size: 16px;
  font-family: $az-heading-para;
  color: $az-black;
  text-transform: capitalize;
}

.nav-pills .nav-link:focus {
  color: $az-gray;
}

.background-none {
  background: none !important;
}

.background-head {
  background: transparent !important;
  font-size: $az-font-16 !important;
  padding: 10px 0px;
  border-bottom: 2px solid $az-eage-color !important;
  margin-bottom: 20px !important;
  width: 100%;
  margin-left: 0 !important;

  @media (max-width:767px) {
    margin-bottom: 20px !important;
  }
}

.overview {

  // background: transparent !important;
  .overview-title {
    background: $az-white !important;
    position: relative;
    padding: 16px 20px;

    &::after {
      position: absolute;
      width: 100%;
      height: 30px;
      // background: $az-light-gray;
      content: "";
      bottom: -30px;
      left: 0;
    }
  }

  .overview-section {
    background: $az-white;
    // min-height: 275px;
    // max-height: 275px;
    min-height: 550px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  }
}

.swipersectionBtns {
  position: relative;
  padding: 24px 30px !important;
  min-height: 68px;

  .swiperBtns {
    position: absolute;
    top: -127px;
    right: 15px;
    width: 265px;

    @media (max-width: 767px) {
      top: -35px;
      left: 50%;
      transform: translate(-50%, -100px);
      width: 100%;
      display: block;
      justify-content: center !important;
    }
  }

  .alertsettingBtn {
    top: -81px;

    @media (max-width: 767px) {
      top: -35px;
    }
  }
}

.table_search_hide .table_filter {
  display: none !important;
}

.aminityList {
  -moz-column-count: 4;
  column-count: 4;
  width: 100%;

  ul {
    //  display: table-caption !important;
    padding-left: 20px;

    li {
      display: table-header-group;
    }

    @media (max-width: 767px) {
      padding-left: 20px;
    }
  }

  @media (max-width: 820px) {
    -moz-column-count: 3;
    column-count: 3;
  }

  @media (max-width: 767px) {
    -moz-column-count: 1;
    column-count: 1;
  }
}

.aminityListView {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  width: 100%;
  break-inside: avoid;

  ul {
    display: block !important;
    padding-left: 20px;

    @media (max-width: 767px) {
      padding-left: 20px;
    }

    li {
      margin-bottom: 5px !important;
      // display: table-header-group;
      display: inherit;
      line-height: 2.5;
      position: relative;
      margin: 0 !important;
      font-size: $az-font-15;

      &::before {
        content: "";
        background: $az-black;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        margin: 16.5px -18px;
      }
    }
  }

  @media (max-width: 767px) {
    -moz-column-count: 2;
    column-count: 2;
  }
}

.break-word {
  white-space: break-spaces;
  word-break: break-all;
}

.asrt-td-loading {
  background-color: #eeedeb !important;
}

.right-sidebar {
  width: calc(100% - 180px);

  @media (max-width: 1199px) {
    width: 100%;
    padding-top: 90px;
    background: $az-light-white;
  }

  @media (max-width: 991px) {
    width: 100%;
    padding-top: 90px;
    background: $az-light-white;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding-top: 90px;
    background: $az-light-white;
  }

  .container {
    @media (max-width: 991px) {
      padding: 0 15px;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }


  .breadcrumbssection {

    display: none !important;

    @media(max-width: 767px) {
      display: block !important;
    }

    justify-content: center;

    .breadcrumb-style2 {
      ol.breadcrumb {
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 0;

        @media(max-width: 767px) {
          margin-top: 10px;
        }

        .breadcrumb-item {
          a {
            font-family: $az-heading-two;
            color: $az-gray;
            letter-spacing: normal;
            font-size: $az-font-12;
          }


        }

        li.active {
          font-family: $az-heading-two;
          color: $az-black !important;
          letter-spacing: normal;
        }
      }
    }

  }


  .brandName {
    h4 {
      display: none;

      @media (max-width: 991px) {
        display: block;
        font-family: $az-heading-one;
        color: $az-black;
        margin-bottom: -15px;
      }

      @media (max-width: 767px) {
        display: block;
        font-family: $az-heading-one;
        color: $az-black;
        margin-bottom: -15px;
      }
    }
  }
}

.brandList .asrt-table-body {
  overflow: auto;
  width: 100%;
  margin-top: 0 !important;

  @media (max-width: 767px) {
    margin-left: 0;
  }

  .col-md-12 {
    @media (max-width: 767px) {
      padding-left: 0;
    }
  }
}

.add-no-wrap {
  thead {
    white-space: nowrap;
  }
}

.calendar-container {
  width: calc(100% - 182px) !important;
  max-width: calc(100% - 182px) !important;
  min-width: calc(100% - 182px) !important;
  // flex: 0 0 991px !important;
  overflow: auto;
  height: 100%;

  .scheduler {
    width: 100% !important;
    max-width: 100% !important;

    .ant-col {
      display: flex;
      align-items: center;
    }

    tbody {
      td {
        &:first-child {
          .resource-table {
            td {
              .slot-cell {
                .expander-space {
                  display: none;
                }

                //       //.slot-text{
                //       // display:block !important;
                //       // width:150px !important;
                //       // white-space: break-spaces;
                //       // padding: 0 6px;

                //         // width: 120px;
                //         // white-space: initial;
                //         // word-wrap: break-word;
                //         // overflow: hidden;

                //         // white-space: nowrap !important;
                //         // overflow: hidden !important;
                //         // text-overflow: ellipsis !important;
                //       //}
              }
            }
          }
        }
      }
    }

    .ant-row-flex-middle {
      @media (max-width: 767px) {
        justify-content: center;
      }
    }
  }

  // .resource-table {
  //   tbody{
  //     tr{
  //       &:first-child {
  //            width:150px !important;
  //            overflow: hidden !important;
  //       }
  //     }
  //   }
  // }
  #RBS-Scheduler-root {
    .event-item {
      color: $az-black !important;
    }

    #RBS-Scheduler-root {
      .event-item {
        color: $az-black !important;
      }
    }

    .ant-col {
      .header2-text {
        align-items: center !important;
        display: flex !important;

        .icon-nav {
          position: relative !important;
          top: 1px !important;
          margin-left: 2px !important;
          margin-right: 2px !important;
        }

        .header2-text-label {
          align-items: center !important;
          display: flex !important;
          margin-bottom: 0px !important;
        }
      }
    }

    // table.resource-table tr, table.scheduler-bg-table tr, table.scheduler-table tr {
    //     border-right: 1px solid #7e3939 !important;
    //     border-bottom: 1px solid #7e3939 !important;
    //     border-top: 1px solid #7e3939 !important;
    // }
    // table.resource-table th, table.scheduler-table th, table.resource-table td, table.scheduler-bg-table td, table.scheduler-table td {
    //     border-right: 1px solid #7e3939 !important;
    //     border-bottom: 1px solid #7e3939 !important;
    //     border-top: 1px solid #7e3939 !important;
    // }
    // table.resource-table tr{
    //   table.scheduler-bg-table tr{
    //     table.scheduler-content-table tr{
    //       border-right: 1px solid #7e3939 !important;
    //       border-bottom: 1px solid #7e3939 !important;
    //       border-top: 1px solid #7e3939 !important;
    //     }
    //   }
    // }

    // table.resource-table{
    //   table.scheduler-table{
    //     table.scheduler-content-table{
    //       tr{
    //         border-bottom: 1px solid #7e3939 !important;
    //         td{
    //           border-right: 1px solid #7e3939 !important;
    //         }
    //       }
    //     }
    //   }
    // }

    .scheduler-content {
      z-index: inherit !important;
    }

    .event-container {
      z-index: 2 !important;
    }
  }
}

#RBS-Scheduler-root {
  .slot-cell {
    .slot-text {
      font-size: $az-font-15;
    }
  }

  th.header3-text {
    font-size: $az-font-14;
    color: $az-gray;
  }
}

// .listingView {
//   .styles-module_sliderBase__swkx1.swipesection {
//     padding-left: 0 !important;
//     padding-right: 0 !important;
//   }
// }

.custom-top-header {
  @media (max-width: 1199px) {
    padding-top: 0 !important;
  }

  @media (max-width: 767px) {
    padding-top: 0 !important;
  }
}

.custom-wrapper {
  @media (max-width: 1199px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.affortable {
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.addproperty {
  @media (max-width: 767px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.allpanding {
  padding: 0 15px 0 30px;

  @media (max-width: 1199px) {
    padding: 0 30px;
  }

  @media (max-width: 767px) {
    padding: 0 30px;
  }
}

.alltablepanding {
  padding: 0 30px;

  @media (max-width: 1199px) {
    padding: 0 30px;
  }

  @media (max-width: 767px) {
    padding: 0 30px;
  }
}

.import-checklist-button {
  min-width: 170px !important;
  max-width: 170px !important;
}

// Reservation caledar design
.calendar-container {
  overflow: inherit;

  .scheduler {
    width: 100% !important;

    thead {
      .ant-row-flex {
        .ant-col {
          :last-child {
            .ant-radio-button-wrapper-checked {
              border: 1px solid $az-black !important;
              color: $az-black !important;
              -webkit-box-shadow: none !important;
              box-shadow: none !important;

              &:hover {
                color: $az-black !important;
              }
            }

            label {
              outline: none !important;
              transition: none !important;

              &:hover {
                color: $az-black !important;
              }
            }
          }
        }
      }
    }

    tbody {
      td {
        &:first-child {

          // width: auto !important;
          .resource-view {
            div {
              &:last-child {
                width: auto !important;
              }
            }
          }
        }
      }
    }
  }

  .scheduler-view {
    display: grid;
    width: 100% !important;

    div {
      &:first-child {

        // width: auto !important;
        div {
          &:first-child {
            overflow: inherit !important;

            div {
              &:first-child {

                // width: 100% !important;
                table {
                  th {

                    //  width: auto !important;
                    div {
                      // width: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .week-width {
    div {
      &:first-child {
        div {
          &:first-child {
            div {
              &:first-child {
                width: 100% !important;
              }
            }

            .scheduler-bg-table {
              thead {
                th {
                  width: 200px !important;
                }
              }
            }
          }
        }
      }

      &:last-child {
        div {
          &:first-child {
            width: 100% !important;

            .scheduler-bg {
              .scheduler-bg-table {
                width: 100% !important;
              }
            }
          }

          &:last-child {
            .scheduler-bg {
              tbody {
                td {
                  width: 200px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  tbody {
    td {
      &:first-child {
        // width: 160px !important;
      }

      &:last-child {
        width: calc(100% - 160px);

        .scheduler-view {
          div {
            &:first-child {
              div {
                &:first-child {
                  .scheduler-bg-table {
                    thead {
                      th {
                        // width: 161px !important;
                      }
                    }
                  }
                }
              }
            }

            &:last-child {
              div {
                &:first-child {

                  // width: 100% !important;
                  .scheduler-content {
                    .scheduler-content-table {
                      td {
                        width: auto !important;

                        .event-container {
                          height: auto !important;
                        }
                      }
                    }
                  }

                  .scheduler-bg {
                    .scheduler-bg-table {

                      // width: 100% !important;
                      tbody {
                        td {
                          // width: 161px !important;
                          position: inherit !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// End Reservation caledar design
#left-tabs-example-tabpane-listingPreview {
  .ReactGridGallery_tile-bottom-bar {
    display: none;
  }
}

.react-tel-input {
  input {
    padding: 17px 48px 17px !important;
    height: auto;
    border: 1px solid #72818e33;
    border-radius: 12px;

    &:active,
    &:focus {
      border: 1px solid $az-black;
      outline: none;
      box-shadow: none;
    }
  }

  .flag-dropdown {
    border-radius: 12px 0 0 12px;
    border-right: 1px solid #72818e33;
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-radius: 12px 0 0 12px;
    border-right: 1px solid #72818e33;
    left: 2px;
    height: 82%;
    top: 5px;
    background: none;

    .country-list {
      input {
        padding: 5px 10px 5px !important;
        border-radius: 3px;
        margin-left: 0;
      }

      li {
        padding: 7px 20px !important;
        text-align: left !important;
      }
    }
  }
}

.viewfile.preview {
  img {
    width: 100px;
  }
}

.marvel-device {
  .screen {
    position: relative;

    img.background-image {
      position: relative;
      z-index: 1;
      height: 100%;
    }

    figure {
      position: absolute;
      z-index: 3;
      top: 90px;
      width: auto;
      height: 150px;
      overflow: hidden;
      left: 50%;
      transform: translate(-50%, 0%);

      img {
        width: auto;
        height: 150px;
      }
    }

    &::before {
      position: absolute;
      content: "";
      background: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
    }
  }
}

.addmultiImages {
  .list {
    // display: flex;
    // flex-wrap: wrap;
    // user-select: none;
    user-select: none;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 16px;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(84, 84, 241);
      color: white;
      height: 150px;
      cursor: grab;
      font-size: 20px;
      user-select: none;
    }
  }
}

.ptagcolor {
  p {
    color: #606060;
  }
}

//calendar custom scrollbar

.innter-container .right .mbsc-calendar {
  scrollbar-width: 3px;
  scrollbar-color: #b7b7b7 #e9e9e9;
  -webkit-scrollbar-width: 3px;
  -moz-scrollbar-width: 3px;

}

.innter-container .right .mbsc-calendar .mbsc-timeline-grid-scroll::-webkit-scrollbar {
  width: 3px;
  height: 10px;
  cursor: pointer;
}

.innter-container .right .mbsc-calendar .mbsc-timeline-grid-scroll::-webkit-scrollbar-track {
  background-clip: content-box;
  border: 0px solid transparent;
  width: 3px;
  background-color: #e9e9e9;
  cursor: pointer;
}

.innter-container .right .mbsc-calendar .mbsc-timeline-grid-scroll::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  cursor: pointer;
}

.innter-container .right .mbsc-calendar .mbsc-timeline-grid-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #b7b7b7;
  cursor: pointer;
}

.innter-container .right .mbsc-calendar .mbsc-timeline-grid-scroll::-webkit-scrollbar-corner,
.innter-container .right .mbsc-calendar .mbsc-timeline-grid-scroll::-webkit-scrollbar-track {
  background-color: #e9e9e9;
  width: 3px;
  cursor: pointer;
}

[class^="message-container"] {
  /* height: 200px; */
  overflow-y: scroll;
  width: 3px;
  cursor: pointer;
}

[class^="message-container"] div {
  /* height: 400px;
width: 200%;
padding: 4rem;
background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
}

// End calendar custom scrollbar

//  custom calendar
.calendar-section {
   // margin-top: -42px;
   margin-top: -15px;
   // height: calc(100vh - 160px);
   height: calc(100vh - 330px);
   z-index: 0;
   position: relative;
 
}

.innter-container .right .mbsc-calendar {
  // margin: 42px 0px !important;
  margin:  0px !important;
}

.mbsc-ios.mbsc-button-flat {
  color: $az-black !important;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  border-color: $az-eage-color !important;
  background: $az-eage-color !important;
  color: $az-black !important;
  border: none;
}

.mbsc-calendar-cell.mbsc-hover .mbsc-calendar-cell-text {
  background: $az-black !important;
  color: $az-white !important;
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-text {
  color: $az-black !important;
}

.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
  color: $az-white !important;
}

// End custom calendar
.checklist-table {
  textarea {
    resize: none;
  }
}

//for spp setting device preview
.marvel-device .screen {
  justify-content: center;
  display: flex !important;
}

.marvel-device {
  display: block !important;
}

.dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.row.main-reservation-info {
  column-count: 7;
  background-color: #fff;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 3px 5px 17px 0px #ddd !important;
  padding: 15px;

  ul {
    list-style: none;
    display: flex;
  }
}

.overviewSection {
  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;

    @media(max-width: 1280px) {
      max-width: 50%;
      flex: 0 0 50%;
    }

  }
}

.table_filter {
  display: none !important;
}

.roles {
  overflow: inherit !important;

  .copyClass {
    width: 18px;
    margin-right: 10px !important;
    background-color: transparent !important;
  }
}

.chat_para p {
  text-align: left !important;
  font-size: 14px;
  color: #606060 !important;
}

.message {

  ul.tab {
    list-style: none;
    border-radius: 10px;
    overflow: hidden;
    padding: 0;
    display: inline-flex;

    li {
      button {
        background: #F4F4F4 !important;
        margin-right: 0 !important;
        border: 0 !important;
        color: $az-black !important;
        box-shadow: none;
        outline: none;
        min-width: auto !important;
        max-width: initial !important;
        padding: 12px 15px;
        border-radius: 0px !important;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
          background: $az-black !important;
          color: $az-white !important;
        }

        @media (max-width:767px) {
          min-width: 100% !important;
        }
      }

      button.active {
        background: $az-eage-color !important;
        color: $az-black !important;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          outline: none;
          background: $az-black !important;
          color: $az-white !important;
        }

      }


    }
  }
}

.reservation-optional {
  color: $az-gray;
  font-family: $az-heading-two;
  font-size: $az-font-11;
  padding-left: 3px;
}

.txt-clr {
  color: $az-black !important;
  font-weight: bold;
}

.status_reserve_box {
  @media(max-width:676px) {
    padding-left: 15px;
    margin-left: 15px;
  }

  .reservation_satatus_box {
    margin-bottom: 20px !important;
    margin-top: 20px !important;

    .status_list {
      padding-right: 100px;

      @media(max-width:1700px) {
        padding-right: 0px;
      }
    }
  }

  .resertavion_status_info {
    width: 100%;
    margin: 0;
    padding-left: 15px;
    display: contents !important;

    li {
      width: 20%;
      float: left;
      display: block;
      padding-right: 150px;

      @media(max-width:1280px) {
        padding-right: 50px;
      }

      @media(max-width: 991px) {
        width: 33.3333%;
      }

      @media(max-width: 767px) {
        width: 100%;
        padding: 0 15px;
      }

      .media-body {
        margin-bottom: 0;
      }
    }

  }
}


.Comment-box {
  height: 100px;
  overflow-y: auto;
}

.pd-xl-r-22 {
  @media (min-device-width:1441px) and (max-device-width:3000px) {
    padding-right: 22px;
  }

}

.pd-xl-l-38 {
  @media (min-device-width:1441px) and (max-device-width:3000px) {
    padding-left: 38px;
  }
}

.multiselect {
  .css-1r5gbi5-menu {
    z-index: 99999 !important;
    @media (max-width:1366px) {
      bottom: 100% !important;
      top: inherit !important;
    }

    @media (max-width:600px) {
      top: 100% !important;
      bottom: inherit !important;
    }
  }

  .css-4ljt47-MenuList {
    @media (max-width:1366px) {
      max-height: 195px !important;
    }

  }

  .css-11unzgr {
    @media (max-width:1366px) {
      max-height: 195px !important;
    }

  }
}

.message_img {
  img {
    max-height: 70px;
    margin: 5px 5px 5px 0;
  }

  span {
    bottom: 2px;
    right: 2px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 2px;
  }

}

.propertyNamemobview {
  display: none;

  @media (max-width:991px) {
    display: block;
    margin-top: 0px;
    padding-top: 0px;
    text-align: center;
    font-family: $az-heading-one;
    margin-bottom: 0px;
    width: 100%;
    justify-content: center;

    h4 {
      text-align: center;
    }
  }

  @media (max-width:767px) {
    display: block;
    margin-top: 20px;
    padding-top: 0px;
    text-align: center;
    font-family: $az-heading-one;
    margin-bottom: 15px;
  }

}

.publish_btn {
  .flashSavebtn {
    padding: 6px 10px !important;
    margin-top: 1px;

    &:hover {
      background-color: $az-black !important;
    }

    &:active {
      background-color: $az-black !important;
    }
  }
}

main {
  .scroll-slide {
    @media (max-width:767px) {
      padding-left: 0px !important;
    }
  }
}


.scrolling-carousel {
  .addCheckmob {
    @media (max-width:767px) {
      display: block !important;
      margin-bottom: 0;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}


.dashboardRightcard {
  .min-rent {
    @media (max-width:1366px) {
      margin-bottom: 0 !important;
    }

    @media (max-width:767px) {
      position: unset !important;
      margin-bottom: 15px !important;
    }
  }
}

.scrolling-carousel {
  .addcheckproperty {
    // @media (max-width:1280px) {
    //   margin-bottom: 15px !important;
    // }

    @media (max-width:767px) {
      li {
        margin-bottom: 20px !important;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.master {
  padding-top: 0 !important;
}

.table_right_padd {
  .col-md-12 {
    @media (max-width:767px) {
      padding-right: 0;
    }
  }

  .as-react-table {
    @media (max-width:767px) {
      padding: 0 !important;
    }
  }
}

.scrolling-carousel .styles-module_sliderBase__swkx1.swipesection div[data-arrow=left] {
  @media (max-width:676px) {
    left: 15px !important;

  }
}

.scroll_nav_mob .styles-module_slider__o0fqa {
  @media (max-width:676px) {
    margin-left: 15px;
  }
}

.scrolling-carousel {
  .dashboardRightcard {
    .propname {
      @media (max-width:767px) {
        margin: 0 !important;
      }
    }
  }
}

.scrolling-carousel .styles-module_sliderBase__swkx1.swipesection div[data-arrow=left] {
  @media (max-width:676px) {
    left: 0 !important;

  }
}

.scroll_nav_mob .styles-module_slider__o0fqa {
  @media (max-width:676px) {
    margin-left: 15px;
  }
}

.button-width {
  max-width: 120px !important;
  padding: 4px 6px !important;
}


.side_top_navtab {
  .az-nav-line .nav-link.active::after {
    @media (max-width:676px) {
      border-bottom: 3px solid #e1ccbe;
      width: 100%;
      height: 1px;
      content: '';
      position: absolute;
      bottom: -7px;
      left: 50%;
      text-align: center;
      transform: translate(-50%, 0%);
    }
  }

  .az-nav-line .nav-link.active::before {
    @media (max-width:676px) {
      display: none;
    }
  }

  .az-nav-line-chat {
    padding-left: 0;
  }

  .az-nav-line .nav-link+.nav-link {
    @media (max-width:676px) {
      margin-top: 0;
    }
  }

}

.nav_tab_cnt {
  @media (max-width:676px) {
    display: block;
    // width: 100%;
    // position: relative;
    // left: -100%;
    // margin-top: 65%;
    // flex:none;
    // padding-left: 0px !important;
    margin-top: 35px;
    padding-left: 0 !important;
  }

  .btn-success-outline {
    @media (max-width:676px) {
      display: block;
      width: 100%;
      min-width: 100%;
      padding: 7px 40px;
    }
  }

  .media-list .media-body {
    margin-left: 15px;
  }

  .az-contact-info-header {
    @media (max-width:676px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.over-fl {
  overflow: hidden;
}

.vendor_tab_body .media-body {
  margin-left: 0 !important;

  @media (max-width:767px) {
    margin-left: 15px !important;
  }
}

.media-list .media-body {
  margin-left: 0 !important;

  @media (max-width:767px) {
    margin-left: 15px !important;
  }
}

.btn-with-icon {
  img {
    display: none;
  }
}

.brnd_heading {
  font-size: $az-font-18 !important;
}

.master {
  @media (max-width:767px) {
    padding: 0 0 15px 15px !important;
  }
}

.table_issue_roles {
  .col-md-12 {
    padding-left: 0;

    @media (max-width:767px) {
      padding-left: 15px;
    }
  }
}

.info_box_model {
  @media (max-width:676px) {
    height: 92px !important;
  }
}


.deleteicon {
  @media (max-width:676px) {
    min-width: 110px;
    max-width: 110px;
    text-align: center;
  }
}

.table_issue_roles_rg {
  .col-md-12 {
    padding-right: 15px;

    @media (max-width:767px) {
      padding-right: 0px;
    }
  }
}

.unit_new_table {
  margin-top: -40px;

  @media (max-width:767px) {
    padding: 0 0 0 15px !important;
  }
}


.scrolling-carousel {
  .tab-content {
    .btn-success-outline-small {
      @media (max-width:767px) {
        margin-right: 0 !important;
      }
    }
  }
}

.table_cnt_top {
  vertical-align: top;

  .text-danger {
    padding-left: 5px;
    font-size: 12px;
  }
}

.Report_table {
  margin-bottom: 60px;

  @media (max-width:676px) {
    margin-bottom: 0;
  }

  .asrt-table-body {
    overflow: auto !important;
    width: 100% !important;

    table {
      margin-bottom: 0px;

      @media (max-width:676px) {
        margin-bottom: 160px !important;
      }

      thead {
        background: $az-black !important;

        th {
          padding: 5px 10px !important;
          border-left: 1px solid #cdd4e0 !important;
          color: $az-white !important;
          min-width: 120px;
          word-break: normal;
          word-break: break-word;
          white-space: pre-wrap;
          vertical-align: inherit;
          // width: 120px;
          // max-width: 120px;
          //text-transform: capitalize !important;
          text-align: center;

          &:last-child {
            text-align: center !important;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 7px 10px !important;
            border-left: 1px solid #cdd4e0 !important;
            min-width: 120px;
            word-break: break-word;
            white-space: pre-wrap;
            // width: 120px;


          }

          .total_earning,
          .adr {
            text-align: center !important;
          }
        }
      }
    }
  }

  .table-head {
    .col-md-6:first-child {
      position: absolute !important;
      transform: translate(0%, 220%) !important;
      top: inherit !important;

      @media(max-width:767px) {
        top: initial !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;

      }
    }
  }

  .table-foot {
    position: relative !important;
    top: 76px !important;

    @media(max-width:767px) {
      top: initial !important;
    }
  }

}

.payout-report-tbl {
  margin-bottom: 60px;

  @media (max-width:676px) {
    margin-bottom: 0;
  }

  tbody {
    background-color: white;
  }

  .asrt-table-body {
    overflow: auto !important;
    width: 100% !important;

    table {
      margin-bottom: 0px;

      @media (max-width:676px) {
        margin-bottom: 160px !important;
      }

      thead {
        background: $az-black !important;

        th {
          padding: 12px 10px !important;
          border-left: 1px solid #cdd4e0 !important;
          color: $az-white !important;
          word-break: normal;
          word-break: break-word;
          white-space: pre-wrap;
          vertical-align: inherit;
          width: 120px;
          max-width: 120px;
          min-width: 120px !important;
          //text-transform: capitalize !important;
          text-align: center;

          &:last-child {
            text-align: center !important;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 12px 10px !important;
            border-left: 1px solid #cdd4e0 !important;
            word-break: break-word;
            white-space: pre-wrap;
            width: 120px;
            max-width: 120px;
            text-align: center !important;
          }

          .resident_payout {
            text-align: right !important;
          }
        }
      }
    }
  }

  .table-head {
    .col-md-6:first-child {
      position: absolute !important;
      transform: translate(0%, 220%) !important;
      top: inherit !important;

      //display: none;
      @media(max-width:767px) {
        top: initial !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;

      }
    }
  }

  .table-foot {
    position: relative !important;
    top: 76px !important;

    @media(max-width:767px) {
      top: initial !important;
    }
  }

  /* .asrt-table-foot .col-md-6 {

    &:first-child {
      display: none;
    }
  } */

}


// .propertytable {
//   .property_name {
//     @media (max-width:1366px) {
//       word-break: break-word;
//       white-space: pre-wrap;
//       min-width: 200px;
//     }
//     @media (max-width:1250px) {
//       word-break: inherit;
//       white-space: inherit;
//     }
//   }
// }

// .brand_name {
//   @media (max-width:1366px) {
//     word-break: break-word;
//     white-space: pre-wrap;
//   }

//   @media (max-width:1250px) {
//     word-break: inherit;
//     white-space: inherit;
//   }
// }

.keycafe-header-sticky {
  position: sticky;
  top: 1px;
  z-index: 1;
}

.keycafe-alert-sticky {
  position: sticky;
  top: 60px;
  z-index: 1;
}

.star-container {
  @media (max-width:1280px) {
    padding-right: 2px !important;
  }
}

.scrolling-carousel {
  .formcheck-section {
    li {
      @media (max-width:1366px) {
        display: table-cell;
        word-break: break-word;
      }

      // @media (max-width:767px) {
      //   display: inline-block !important;
      // }
    }
  }
}

.house_label {
  @media (max-width:1280px) {
    width: 115%;
    position: relative;
  }

  @media (max-width:767px) {
    width: 100%;
    position: unset;
  }

}

.p_wrap1 {
  white-space: pre-line;
}

.pets_no_text {
  font-size: $az-font-15;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.pet_detail_mb {
  margin-bottom: 20px !important;
}


.brandtable {
  tbody {
    td {
      a {
        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}

.model-lg_custome {
  .modal-dialog {
    max-width: 60% !important;

    .modal-body ul li {
      padding-right: 15px !important;
    }
  }
}

.drop_user {
  min-height: 90vh;

  @media (max-width:767px) {
    min-height: auto;
    height: auto;
  }
}

.table_last_row {
  thead {
    th {
      &:last-child {
        text-align: left !important;
      }
    }

    td {
      &:last-child {
        text-align: left !important;
      }
    }
  }

  tbody {
    th {
      &:last-child {
        text-align: left !important;
      }
    }

    td {
      &:last-child {
        text-align: left !important;
      }
    }
  }
}

.btn_send_mail {
  min-width: 95px !important;
  max-width: 95px !important;
}

.mro-checkbox {
  li {
    max-width: 100% !important;
    min-width: 100% !important;

    @media (max-width:767px) {
      padding-left: 15px;
      padding-bottom: 10px;
    }
  }
}

.aminityList {
  ul {
    li {
      display: inline-block !important;

      @media (max-width:1360px) {
        display: inline-block !important;
      }

      @media (max-width:767px) {
        max-width: 100% !important;
        min-width: 100% !important;
      }

    }
  }
}


.drop_cust_w {
  width: 25%;

  @media (max-width:1400px) {
    width: 40%;
  }
}

.drop_cust_w {
  .multiselect {
    text-align: left;

    .css-1r5gbi5-menu {
      z-index: 99999 !important;
      @media (max-width:1366px) {
        bottom: auto !important;
        top: inherit !important;
      }
    }
  }
}

.lookup_table {
  .col-md-12 {
    @media (max-width:767px) {
      padding: 0 !important;
    }
  }

  // tbody {
  //   tr {
  //     background: $az-white !important;
  //   }
  //   tr.to_total {
  //     background: #c2c0c0 !important;
  //     font-weight: bold !important;
  //   }
  //   tr.grand_total {
  //     background: $az-btn-green !important;
  //     font-weight: bold !important;

  //   }
  //   tr.to_total td {
  //     font-weight: bold !important;
  //   }
  //   tr.grand_total td {
  //     font-weight: bold !important;
  //     color: $az-white !important;
  //   }
  // }

  // .table-body {
  //   .col-md-12 {
  //     padding-right: 0;
  //   }
  // }
}

.checkbox_align_role {
  flex: 0 0 25% !important;
  max-width: 25% !important;

  @media (max-width:1400px) {
    flex: 0 0 33% !important;
    max-width: 33% !important;
  }

  @media (max-width:1280px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  @media (max-width:767px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.role_permiss_td_fst {
  min-width: 185px;

  @media (max-width:1280px) {
    min-width: 150px;
  }
}

.no-data-image {
  margin-bottom: 30px;
  width: 92px;
}

.earning-report-tbl {
  .as-react-table {
    @media (max-width:1024px) {
      padding: 0 !important;
    }

    @media (max-width:991px) {
      padding: 0 15px !important;
    }

    @media (max-width:767px) {
      padding: 0 !important;
    }
  }

  tbody {
    tr {
      background: $az-white !important;
    }

    tr.to_total {
      background: #f3f3f3 !important;
      font-weight: bold !important;
      border-bottom: 2px solid $az-black;
    }

    tr.to_diff {
      background: black !important;
      font-weight: bold !important;
    }

    tr.grand_total {
      background: #2c5046 !important;
      font-weight: bold !important;

    }

    tr.grand_total_future {
      background: #000000 !important;
      font-weight: bold !important;

    }

    tr.grand_total_past {
      background: #bababa !important;
      font-weight: bold !important;
    }

    tr.to_total td {
      font-weight: bold !important;
    }

    tr.grand_total td {
      font-weight: bold !important;
      color: $az-white !important;
    }

    tr.grand_total_future td {
      font-weight: bold !important;
      color: $az-white !important;
    }

    tr.grand_total_past td {
      font-weight: bold !important;
      color: $az-white !important;
    }

    tr.to_future td {
      font-weight: bold !important;
    }
  }

  .table-body {
    .col-md-12 {
      padding-right: 0;
    }
  }
}

.payout-details-tbl {
  margin: auto;

  .asrt-table-body {
    margin: auto;

    .col-md-12 {
      padding-left: 0;
    }
  }

  .table-foot,
  .asrt-table-head {
    display: none;
  }
}

.new_list_inquiry {
  padding-left: 10px;
  margin: 0 0 22px 0;
  list-style: none;
  padding: 0;

  li {
    p {
      margin-bottom: 5px;
    }
  }
}

.loader-container.body {
  z-index: 9999999;
}

.ft_15 {
  font-size: $az-font-15;
}

.text_btn_both {
  display: flex;

  .btn-sync {
    background: $az-black;
    color: $az-white;
  }
}

.amty_map_tbl {
  th {
    text-align: left !important;
  }

  td {
    text-align: left !important;
  }
}

.resolution_fee_tbl {
  .w-1 {
    width: 15% !important;
    max-width: 15% !important;
    min-width: 15% !important;
  }

  .w-3 {
    width: 20% !important;
    max-width: 20% !important;
    min-width: 20% !important;

  }
}

.smart_price_row {
  .row {
    border: 0 !important;
  }

  .col-md-12 {
    padding-left: 0;

    @media (max-width:767px) {
      padding: 0;
    }
  }
}

.resident-payout-table {
  margin-bottom: 70px !important;

  table {
    // display: flex;
    // flex-direction: column;
    // width: fit-content;
    margin-bottom: 0px !important;

    thead {

      // position: relative;
      // top: 0;
      // background: $az-black !important;
      th {
        // white-space: nowrap !important;
        // min-width: none !important;
        // max-width: inherit !important;
        // width: auto !important;
        color: $az-white !important;
        background: $az-black;
        padding: 5px 10px !important;
        border-left: 1px solid #cdd4e0 !important;
        min-width: 166px;
        white-space: normal;
        text-align: center;
        vertical-align: inherit;
        text-transform: none !important;

        &:last-child {
          padding: 5px 10px !important;
          min-width: 166px;
          white-space: normal;
          text-align: center !important;
          vertical-align: inherit;
        }
      }
    }

    tbody {
      // max-height: 600px;
      // min-height: 600px;
      // overflow: auto;
      // display: table-caption;


      td {
        // white-space: nowrap !important;
        // min-width: none !important;
        // max-width: inherit !important;
        // width: auto !important;
        padding: 7px 10px !important;
        border-left: 1px solid #cdd4e0 !important;

        &:last-child {
          padding: 5px 10px !important;
          min-width: 170px;
          white-space: normal;
          text-align: center !important;
          vertical-align: inherit;
        }

      }
    }


  }

  .table-foot {
    position: relative !important;
    top: 76px !important;
  }

  .table-head .col-md-6:first-child {
    position: absolute !important;
    transform: translate(0%, 220%) !important;
    top: inherit !important;
  }

  .to_diff {
    display: none;

    td {
      padding: 0 !important;
      line-height: normal !important;
    }
  }

}

.earning_reports {
  table {
    th {
      text-transform: none !important;
    }
  }
}

.p_tag_color {
  color: #ff5b5b;
}

.resolution_fee_tbl {
  .w-1 {
    width: 15% !important;
    max-width: 15% !important;
    min-width: 15% !important;
  }

  .w-3 {
    width: 20% !important;
    max-width: 20% !important;
    min-width: 20% !important;

  }
}

.smart_price_row {
  .row {
    border: 0 !important;
  }

  .col-md-12 {
    padding-left: 0;

    @media (max-width:767px) {
      padding: 0;
    }
  }

  .asrt-table-head {
    height: 0;
  }
}

.resol_total_fee {
  text-align: center;
  padding-left: 20px !important;
}

.pay-out-tbl {
  tr {
    td {
      vertical-align: middle;
    }
  }
}

/*Report Table Scroll Css*/
.scroll-report-table {
  .col-md-12 {
    overflow: auto;
    //min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);

    table {
      position: relative;

      thead {
        position: sticky;
        top: -1px;
        z-index: 9;
        width: 100%;
        //height: 100px;
      }
    }
  }
}


.td_header_left {
  thead {
    tr {
      th {
        text-align: left !important;
      }
    }
  }
}

//copy button in reservation calendar popup
.calendar_event_copy {
  .media-body {
    span {
      img {
        width: 18px;
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.xs-w-100 {
  @media (max-width:767px) {
    width: 100%;
  }
}

.table_heading_top {
  thead {
    tr {
      th {
        padding-top: 0 !important;
      }
    }
  }
}


.profile-img-upload {
  .fileUpload {
    position: relative;

    span {
      label {
        // &:after {
        //   background: $az-white !important;
        //   bottom: -20px;
        //   content: url(../images/edit-pen-icon.svg);
        //   left: 10px;
        //   position: absolute;
        //   text-decoration: underline;
        //   -webkit-transform: translate(0, -50%);
        //   transform: translate(0, -50%);
        //   width: 28px;
        //   height: 28px;
        //   color: $az-black;
        //   border-radius:30px ;
        //   box-shadow: 0px 0px 2px#000;
        //   text-align: center;
        //   padding: 5px;
        //   @media (max-width:1600px) {}
        // }
      }
    }
  }
}


.payout_table_w {
  tr {
    .w-20 {
      width: 90px;
      min-width: 90px;
      max-width: 90px;
    }
  }
}

.master {
  table {
    margin-top: 20px !important;

    th {
      border-top: 0 !important;
      padding: 16px 5px !important;
    }

    tr {
      padding: 16px 5px !important;
    }
  }
}

.blue-link-cust {
  color: #36f !important;
  font-size: $az-font-15;
  &:hover{
    color: #0039e6 !important;
  }
}

.instruction_key_cafe {
  h4 {
    padding-bottom: 20px;
    margin: 0;

    strong {
      font-size: $az-font-18;
    }
  }

  ol {
    padding-left: 15px;

    li {
      font-weight: 700 !important;
    }

    p {
      margin-bottom: 15px !important;
    }
  }
}

.resolution-fees-new {

  .w-30 {
    max-width: 120px;
    min-width: 120px;

    label {
      position: relative;
      left: -2px;
    }

    @media (max-width:1366px) {
      max-width: 200px;
      min-width: 200px;
    }

    @media (max-width:767px) {
      max-width: 300px;
      min-width: 300px;
    }
  }

  .w-20 {
    max-width: 50px;
    min-width: 50px;

    label {
      position: relative;
      left: -2px;
    }

    @media (max-width:1366px) {
      max-width: 100px;
      min-width: 100px;
    }

    @media (max-width:767px) {
      max-width: 150px;
      min-width: 150px;
    }
  }

  .w-35 {
    max-width: 80px;
    min-width: 80px;

    label {
      position: relative;
      left: -2px;
    }

    .paytm_rcvd {
      left: -15px;
    }

    .recv_paytm_info {
      transform: translate(20%, 0);
    }

    @media (max-width:1366px) {
      max-width: 120px;
      min-width: 120px;
    }

    @media (max-width:767px) {
      max-width: 150px;
      min-width: 150px;
    }
  }

  .w-60 {
    max-width: 120px;
    min-width: 120px;

    label {
      position: relative;
      left: -2px;
    }

    @media (max-width:767px) {
      max-width: 220px;
      min-width: 220px;
    }
  }

  .w-40 {
    max-width: 100px;
    min-width: 100px;

    label {
      position: relative;
      left: -2px;
    }

    .fst_resol_label {
      left: 0 !important;
    }

    @media (max-width:767px) {
      max-width: 150px;
      min-width: 150px;
    }
  }

  .w-90 {
    max-width: 120px;
    min-width: 120px;

    label {
      position: relative;
      left: -2px;
    }

    .fst_resol_label {
      left: 0 !important;
    }

    @media (max-width:1366px) {
      max-width: 150px;
      min-width: 150px;
    }

    @media (max-width:767px) {
      max-width: 120px;
      min-width: 120px;
    }
  }
}

.keycafebtn {
  .btn-success-outline-small {
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width:767px) {
    top: -110px !important;
  }
}

.keycafe-header-mob {
  .dashbaord-pageHeadertitle {
    @media (max-width:767px) {
      min-height: 100px !important;
    }
  }
}

.cleaning_label {
  position: relative;
  top: -2px;

  @media (max-width:767px) {
    top: 0px;
    margin-bottom: 15px !important;
  }
}

.resolution_table_form {
  @media (max-width:1600px) and (min-width:768px) {
    overflow-y: auto;
  }
}

.prefix-dollar {
  position: relative;

  span {
    position: absolute;
    border-right: 0;
    height: 100%;
    background: transparent;
    border-right: 0;
    padding-right: 0
  }

  input {
    padding-left: 22px;
  }

}


.percanteg-prefix {
  input {
    padding-left: 12px;
  }

  .percanteg_symbl {
    padding-right: 12px;
    right: 0;
    border-left: 0;
  }
}


.google_icon_pre {
  input {
    padding-left: 30px !important;
  }
}


.completeBookigHover {
  padding: 10px;
  font-family: $az-heading-two;
  font-size: $az-font-13;
}

.blue-link-cust {
  color: #3366ff !important;
  font-size: $az-font-15;
}


.calendar-section {
  .mbsc-calendar-button-today {
    @media (max-width:767px) {
      display: block !important;
      position: absolute !important;
      left: 50%;
      bottom: -25px;
      transform: translate(-50%, 0%);
    }
  }

  .mbsc-calendar-header {
    @media (max-width:767px) {
      height: 80px;
    }
  }

  .md-event-listing-picker {
    @media (max-width:767px) {
      margin: 0 15px;
    }
  }
}

.snap-heading {
  h3 {
    font-size: $az-font-18;
    color: $az-black;
    margin-bottom: 0;
    margin-top: 15px;
    font-family: $az-heading-para ;
  }
}

.border_hr {
  margin-top: 0;
  border-color: #000;
  max-width: 100% !important;

}


.btn-copy_bg {
  background: transparent !important;
  border-color: transparent !important;
  padding: 0;
  min-height: auto;
  height: auto;

  &:hover {
    background: transparent !important;
    border-color: transparent !important;
  }

  &:active,
  &:focus {
    background: transparent !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0.2rem #a8dde100 !important;
  }
}

.copide_box {
  background-color: $az-btn-green !important;
  padding: 12px;
  z-index: 99999;
  .arrow {
    &::after {
      border-top-color: $az-btn-green !important;
      top: -2px !important;
    }
  }

  .popover-header {
    text-transform: capitalize !important;
  }
}

.instruction_key_cafe {
  h4 {
    padding-bottom: 20px;
    margin: 0;

    strong {
      font-size: $az-font-18;
    }
  }

  ol {
    padding-left: 15px;

    li {
      font-weight: 700 !important;
    }

    p {
      margin-bottom: 15px !important;
    }
  }
}


.swipersectionBtns {
  .swiperBtns {
    .btn-primary {
      min-height: auto !important;
    }

    .btn-block {
      min-height: auto !important;
    }
  }
}

.h-40-cust {
  height: 40px;
}


.snap_section {
  .table-responsive {
    min-height: 270px;
    max-height: 270px;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 12px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 10px;
      border: 2px solid #ffffff;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
    }



    @media (max-width:767px) {
      width: 100% !important;
    }

    table {
      th {
        position: sticky;
        top: 0;
        background: $az-white;
        z-index: 99;
      }
    }
  }

  .text-center {
    height: 270px;
    display: grid;
    place-items: center;
  }

  .card-table-two {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  }

}

.w-9_form_check {
  @media (max-width:1280px) {
    li {
      max-width: 180px !important;
      min-width: 180px !important;
    }
  }
}

.problem_img_new {
  top: 8px !important;
  right: 10px !important;
}

.multi-gallery {
  width: 100%;

  .multi-gallery-item {
    width: 33.3333%;
    margin-bottom: 30px;
    box-shadow: none;
    padding: 0 10px;
    overflow: inherit;
    float: left;
    max-height: 100px;

    .multi-gallery-image-div {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100% !important;
        object-fit: cover;
        height: 100px;
      }

      .button {
        background: rgba(255, 255, 255, 0.7);
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        left: -6px;
        right: inherit;
        top: -8px;
        z-index: 0;
        position: absolute;
        transform: translate(0, 0%);
        box-shadow: 0px 0px 2px 0px black;
        cursor: pointer;

        span {
          color: #000000;
          display: block;
          font-size: 17px;
          line-height: unset;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Horizontal line */
.collapsible-link::before {
  content: '';
  width: 14px;
  height: 2px;
  background: #333;
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
}

/* Vertical line */
.collapsible-link::after {
  content: '';
  width: 2px;
  height: 14px;
  background: #333;
  position: absolute;
  top: calc(50% - 7px);
  right: calc(1rem + 6px);
  display: block;
  transition: all 0.3s;
}

.collapsible-link[aria-expanded='true']::after {
  transform: rotate(90deg) translateX(-1px);
}

.collapsible-link[aria-expanded='true']::before {
  transform: rotate(180deg);
}

.faq_section {
  .accordion {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    .accordion__panel {
      padding: 20px 0;
      font-size: $az-font-16;
      font-family: $az-heading-two !important;
    }

    a {
      color: #000000;
      text-decoration: none;
    }
  }

  .accordion__button {
    font-family: $az-heading-two !important;
    background: transparent !important;
    padding: 20px 0;
    color: $az-gray;

    &::before {
      float: right;
      margin-top: 5px;
      transform: rotate(225deg);
    }
  }

}

.faq_sub_header {
  font-size: larger;
  margin-top: 27px;
  font-weight: 600;
}

.amty_map_tbl {
  th {
    text-align: left !important;
  }

  td {
    text-align: left !important;
  }
}

.p_wrap5 {
  white-space: "pre-line";
  background-color: #F5F5F5;
  border-radius: 3px;
  padding-left: 24px;
  padding-top: 21px;
  padding-bottom: 14px;
}

.faq-bg {
  background-color: #F5F5F5;
  border-radius: 3px;
  padding: 10px;
}

.amty_map_tbl {
  th {
    text-align: left !important;
  }

  td {
    text-align: left !important;
  }
}

.resident-payout-table {
  margin-bottom: 70px !important;

  table {
    // display: flex;
    // flex-direction: column;
    // width: fit-content;
    margin-bottom: 0px !important;

    thead {

      // position: relative;
      // top: 0;
      // background: $az-black !important;
      th {
        // white-space: nowrap !important;
        // min-width: none !important;
        // max-width: inherit !important;
        // width: auto !important;
        color: $az-white !important;
        background: $az-black;
        padding: 5px 10px !important;
        border-left: 1px solid #cdd4e0 !important;
        min-width: 166px;
        white-space: normal;
        text-align: center;
        vertical-align: inherit;
        text-transform: none !important;

        &:last-child {
          padding: 5px 10px !important;
          min-width: 166px;
          white-space: normal;
          text-align: center !important;
          vertical-align: inherit;
        }
      }
    }

    tbody {
      // max-height: 600px;
      // min-height: 600px;
      // overflow: auto;
      // display: table-caption;


      td {
        // white-space: nowrap !important;
        // min-width: none !important;
        // max-width: inherit !important;
        // width: auto !important;
        padding: 7px 10px !important;
        border-left: 1px solid #cdd4e0 !important;

        &:last-child {
          padding: 5px 10px !important;
          min-width: 170px;
          white-space: normal;
          text-align: center !important;
          vertical-align: inherit;
        }

      }
    }


  }

  .table-foot {
    position: relative !important;
    top: 76px !important;
  }

  .table-head .col-md-6:first-child {
    position: absolute !important;
    transform: translate(0%, 220%) !important;
    top: inherit !important;
  }

  .to_diff {
    display: none;

    td {
      padding: 0 !important;
      line-height: normal !important;
    }
  }

}

.earning_reports {
  table {
    th {
      text-transform: none !important;
    }
  }
}


.resolution_fee_tbl {
  .w-1 {
    width: 15% !important;
    max-width: 15% !important;
    min-width: 15% !important;
  }

  .w-3 {
    width: 20% !important;
    max-width: 20% !important;
    min-width: 20% !important;

  }
}

.smart_price_row {
  .row {
    border: 0 !important;
  }

  .col-md-12 {
    padding-left: 0;

    @media (max-width:767px) {
      padding: 0;
    }
  }

  .asrt-table-head {
    height: 0;
  }
}

.resol_total_fee {
  text-align: center;
  padding-left: 20px !important;
}

.pay-out-tbl {
  tr {
    td {
      vertical-align: middle;
    }
  }
}

/*Report Table Scroll Css*/
.scroll-report-table {
  .col-md-12 {
    overflow: auto;
    //min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);

    table {
      position: relative;

      thead {
        position: sticky;
        top: -1px;
        z-index: 9;
        width: 100%;
        //height: 100px;
      }
    }
  }
}


.td_header_left {
  thead {
    tr {
      th {
        text-align: left !important;
      }
    }
  }
}

.calendar_event_copy {
  .media-body {
    span {
      img {
        width: 18px;
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.xs-w-100 {
  @media (max-width:767px) {
    width: 100%;
  }
}

.table_heading_top {
  thead {
    tr {
      th {
        padding-top: 0 !important;
      }
    }
  }
}


.profile-img-upload {
  .fileUpload {
    position: relative;

    span {
      label {
        // &:after {
        //   background: $az-white !important;
        //   bottom: -20px;
        //   content: url(../images/edit-pen-icon.svg);
        //   left: 10px;
        //   position: absolute;
        //   text-decoration: underline;
        //   -webkit-transform: translate(0, -50%);
        //   transform: translate(0, -50%);
        //   width: 28px;
        //   height: 28px;
        //   color: $az-black;
        //   border-radius:30px ;
        //   box-shadow: 0px 0px 2px#000;
        //   text-align: center;
        //   padding: 5px;
        //   @media (max-width:767px) {
        //     bottom: 0;
        //   }
        // }
      }
    }
  }
}


.payout_table_w {
  tr {
    .w-20 {
      width: 90px;
      min-width: 90px;
      max-width: 90px;
    }
  }
}

.master {
  table {
    margin-top: 20px !important;

    th {
      border-top: 0 !important;
      padding: 16px 5px !important;
    }

    tr {
      padding: 16px 5px !important;
    }
  }
}

.resolution-fees-new {
  table {
    tr {
      th {
        padding-top: 0 !important;
      }
    }
  }


  .w-30 {
    max-width: 120px;
    min-width: 120px;

    label {
      position: relative;
      left: -2px;
    }

    @media (max-width:1440px) {
      max-width: 200px;
      min-width: 200px;
    }

    @media (max-width:767px) {
      max-width: 300px;
      min-width: 300px;
    }
  }

  .w-20 {
    max-width: 50px;
    min-width: 50px;

    label {
      position: relative;
      left: -2px;
    }

    @media (max-width:1440px) {
      max-width: 100px;
      min-width: 100px;
    }

    @media (max-width:767px) {
      max-width: 150px;
      min-width: 150px;
    }
  }

  .w-35 {
    max-width: 80px;
    min-width: 80px;

    label {
      position: relative;
      left: -2px;
    }

    .paytm_rcvd {
      left: -15px;
    }

    .recv_paytm_info {
      transform: translate(20%, 0);
    }

    @media (max-width:1440px) {
      max-width: 120px;
      min-width: 120px;
    }

    @media (max-width:767px) {
      max-width: 150px;
      min-width: 150px;
    }
  }

  .w-60 {
    max-width: 120px;
    min-width: 120px;

    label {
      position: relative;
      left: -2px;
    }

    @media (max-width:767px) {
      max-width: 220px;
      min-width: 220px;
    }
  }

  .w-40 {
    max-width: 100px;
    min-width: 100px;

    label {
      position: relative;
      left: -2px;
    }

    .fst_resol_label {
      left: 0 !important;
    }

    @media (max-width:767px) {
      max-width: 150px;
      min-width: 150px;
    }
  }

  .w-90 {
    max-width: 120px;
    min-width: 120px;

    label {
      position: relative;
      left: -2px;
    }

    .fst_resol_label {
      left: 0 !important;
    }

    @media (max-width:1440px) {
      max-width: 150px;
      min-width: 150px;
    }

    @media (max-width:767px) {
      max-width: 120px;
      min-width: 120px;
    }
  }

  .reasolution_final_pay {}

  .text-right {
    span {
      padding-right: 4px;
    }
  }
}

.keycafebtn {
  .btn-success-outline-small {
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width:767px) {
    top: -110px !important;
  }
}

.keycafe-header-mob {
  .dashbaord-pageHeadertitle {
    @media (max-width:767px) {
      min-height: 100px !important;
    }
  }
}

.cleaning_label {
  position: relative;
  top: -2px;

  @media (max-width:767px) {
    top: 0px;
    margin-bottom: 15px !important;
  }
}

.resolution_table_form {
  @media (max-width:1600px) and (min-width:768px) {
    overflow-y: auto;
  }
}

.prefix-dollar {
  position: relative;

  span {
    position: absolute;
    border-right: 0;
    height: 100%;
    background: transparent;
    border-right: 0;
    padding-right: 0
  }

  input {
    padding-left: 22px;
  }

}


.percanteg-prefix {
  input {
    padding-left: 12px;
  }

  .percanteg_symbl {
    padding-right: 12px;
    right: 0;
    border-left: 0;
    border: 1px solid $az-form-input-bdr !important;
    border-left: 0 !important;
    background: $az-white;
  }
}
.percanteg-prefix .percanteg_symbl {
  padding-right: 12px!important;
  right: 0;
}
.percanteg_symbl2 {
  padding-right: 12px!important;
  right: 0;
}

.google_icon_pre {
  input {
    padding-left: 30px !important;
  }
}


.completeBookigHover {
  padding: 10px;
  font-family: $az-heading-two;
  font-size: $az-font-13;
}

.blue-link-cust {
  color: #3366ff !important;
  font-size: $az-font-15;
}



.import_model_big{
  .modal-dialog {
    max-width: 1400px !important;
    overflow: auto;
    @media (max-width:1360px) {
      max-width: 1150px !important;
    }
  }
}

.view_tbl_exp {
  max-height: 300px;
  overflow-y: auto;

  .file_preview_tbl {
    thead {
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;

      tr {
        th {
          padding: 5px;
          font-size: $az-font-13;
          font-family: $az-heading-two;
          color: $az-gray;
          font-weight: normal;
          text-transform: initial;
          vertical-align: top;
          max-height: 50px;
        }
      }
    }

    tbody {
      tr {
        th {
          padding: 5px !important;
          font-size: $az-font-14 !important;
          font-family: $az-heading-two !important;
          color: $az-black !important;
          font-weight: normal !important;
          text-transform: initial !important;
        }

        td {
          padding: 5px !important;
          font-size: $az-font-14 !important;
          font-family: $az-heading-two !important;
          color: $az-black !important;
          font-weight: normal !important;
          text-transform: initial !important;
        }
      }
    }


  }
}

.dwon_imp_file{
  margin-top: 15px;
  display: inline-block;
}

.view_tbl_bdr{
     border: 1px dashed #a9a9a9;
     min-height: 196px;
     max-height: 400px;
     margin-top: 10px;
     padding: 10px;
     border-radius: 5px;
}


.export_tbl_fst {
  // border: 1px solid #33333382;
  // margin-top: 5px;
  // max-height: 275px;
  overflow: auto;
  position: relative;
  p{
    margin: 0 !important;
    background:$az-black;
    padding: 5px 0;
    text-align: center;
    color: $az-white !important;
    font-size: $az-font-18;
    position: sticky;
    top: 0;
    z-index: 9;
  }
  ul{
    padding: 0px;
    margin: 0;
    list-style: none;
    li{
      border: 1px solid #72818e33 !important;
      font-size: $az-font-14;
      color: $az-black;
      padding: 10.5px 12px;
      position: relative;
      margin: 5px;
      &::after{
        background-image: url(../images/rightarrow.svg);
        content: '';
        right: 10px;
        width: 14px;
        height: 11px;
        background-size: 100%;
        position: absolute;
        top: 35%;
        transform: rotate(265deg);
      }
    }
  }
}

.export_tbl_sec {
  // border: 1px solid #a9a9a9;
  // margin-top: 5px;
  // max-height: 275px;
  position: relative;
  overflow: auto;
  p{
    margin: 0 !important;
    background:$az-black;
    padding: 5px 0;
    text-align: center;
    color: $az-white !important;
    font-size: $az-font-18;
    position: sticky;
    top: 0;
    z-index: 9;
  }
  ul{
    padding: 0px;
    list-style: none;
    margin: 0;
    li{
      margin: 5px ;
      font-size: $az-font-14;
      color: $az-black;
      .multiselect {
        font-size: $az-font-14;
      }
    }
  }
}


.calendar-section {
  .mbsc-calendar-button-today {
    @media (max-width:767px) {
      display: block !important;
      position: absolute !important;
      left: 50%;
      bottom: -25px;
      transform: translate(-50%, 0%);
    }
  }

  .mbsc-calendar-header {
    @media (max-width:767px) {
      height: 80px;
    }
  }

  .md-event-listing-picker {
    @media (max-width:767px) {
      margin: 0 15px;
    }
  }
}

.snap-heading {
  h3 {
    font-size: $az-font-18;
    color: $az-black;
    margin-bottom: 0;
    margin-top: 15px;
    font-family: $az-heading-para ;
  }
}

.border_hr {
  margin-top: 0;
  border-color: #000;
  max-width: 100% !important;

}


.btn-copy_bg {
  background: transparent !important;
  border-color: transparent !important;
  padding: 0;
  min-height: auto;
  height: auto;

  &:hover {
    background: transparent !important;
    border-color: transparent !important;
  }

  &:active,
  &:focus {
    background: transparent !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0.2rem #a8dde100 !important;
  }
}

.copide_box {
  background-color: $az-btn-green !important;
  padding: 12px;
  z-index: 99999;
  .arrow {
    &::after {
      border-top-color: $az-btn-green !important;
      top: -2px !important;
    }
  }

  .popover-header {
    text-transform: capitalize !important;
  }
}

.instruction_key_cafe {
  h4 {
    padding-bottom: 20px;
    margin: 0;

    strong {
      font-size: $az-font-18;
    }
  }

  ol {
    padding-left: 15px;

    li {
      font-weight: 700 !important;
    }

    p {
      margin-bottom: 15px !important;
    }
  }
}


.swipersectionBtns {
  .swiperBtns {
    .btn-primary {
      min-height: auto !important;
    }

    .btn-block {
      min-height: auto !important;
    }
  }
}



.h-40-cust {
  height: 40px;
}

.snap_section {
  .table-responsive {
    min-height: 270px;
    max-height: 270px;
    position: relative;

    span.loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    table {
      th {
        position: sticky;
        top: 0;
        background: $az-white;
        z-index: 99;
      }

      td {
        img {
          width: 18px;
          margin-left: 5px;
        }
      }
    }
  }

  .text-center {
    height: 270px;
    display: grid;
    place-items: center;
    place-content: center;
  }

  .card-table-two {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    border: transparent;
  }

}

.w-9_form_check {
  @media (max-width:1280px) {
    li {
      max-width: 180px !important;
      min-width: 180px !important;
    }
  }
}

.problem_img_new {
  top: 8px !important;
  right: 10px !important;
}

.listing_fee_price {
  margin-top: 15px !important;
}


.listing_detail {
  .media-body {
    min-height: 33px;
  }
}


.rent_tbl {
  .asrt-table-body {
    table {
      thead {
        th {
          &:last-child {
            text-align: center !important;
          }
        }
      }
    }
  }
}

.houskeeping_tbl {
  th {
    width: 150px;
    min-width: 150px;
  }

  td {
    width: 150px;
    min-width: 150px;
  }

  .issue_tbl_cols {
    width: 300px;
    min-width: 300px;
  }

  .tnbr_schdl_date {
    width: 250px;
    min-width: 250px;
  }
}

.userr_page_btn {
  .btn {
    min-height: 33px !important;
  }
}

.dashbaord-pageHeadertitle {
  .btn {
    max-height: 33px !important;
    min-height: 33px !important;
  }

  .button {
    max-height: 33px !important;
    min-height: 33px !important;
  }

  @media (max-width:767px) {
    button {
      margin: 0px 5px 5px 5px !important
    }

    a {
      margin: 0px 5px 5px 5px !important;
    }
  }

}

.table_th_last {
  th {
    &:last-child {
      text-align: left !important;
    }
  }
}

.completeBookigHover {
  padding: 5px;
}

.dashboardRightcard table tbody .td_clickable span {
  width: 100%;
  display: inline !important;
}


.modal-dialog-centered {
  @media (min-width: 576px){
    min-height: 100% !important;
  }
}

.modal-backdrop{
  z-index: 9999;
}

.modal{
  z-index: 99999;
}


#lightboxBackdrop{
  z-index: 99999;
}

.report_fst_th {
    thead {
      tr {
        th {
          min-width: 150px !important;
          &:first-child{
            min-width: 60px !important;
          }
        }
      }
    }
    tbody {
      tr {
        th {
          min-width: 150px !important;
          &:first-child{
            min-width: 60px !important;
          }
        }
      }
    }
}

.selfi_img {
  min-height: 250px;
  max-height: 250px;
  width: 200px !important;
  border-radius: 12px;
  object-fit: cover;

  @media (max-width:991px) {
    min-height: 250px;
    max-height: 250px;
    width: 180px !important;
    margin-top: 10px;
  }

  @media (max-width:767px) {
    margin-bottom: 100px;
    min-height: 210px;
    width: 150px !important;
    margin-top: 0;
  }
}

.property_select{
.css-1r5gbi5-menu {
    bottom: auto !important;
    top: inherit !important;
    z-index: 222 !important;
}
}
.doc_upld_guest {
  width: 230px !important;
  min-height: 120px;
  max-height: 120px;
  border-radius: 12px;
  object-fit: cover;

  @media (max-width:991px) {
    width: 200px !important;
    min-height: 120px;
    max-height: 120px;
  }

  @media (max-width:767px) {
    min-height: 105px;
    max-height: 105px;
    width: 155px !important;

  }
}

.report_fst_th {
  thead {
    tr {
      th {
        min-width: 150px !important;
        &:first-child{
          min-width: 60px !important;
        }
      }
    }
  }
  tbody {
    tr {
      th {
        min-width: 150px !important;
        &:first-child{
          min-width: 60px !important;
        }
      }
    }
  }
}


.mail-right-sym{
  .percanteg_symbl{
   overflow: hidden;
   background: $az-white;
   border: 1px solid #33333382 !important;
   border-left: 0 !important;
  }
 }
 
 #lightboxBackdrop{
   z-index: 99999;
 }
 
 .autommation_icon{
   img{
     max-height: 16px;
   }
 }
 
 .exp_filter_slect{
   div{
     &:nth-child(3){
      z-index: 99999;
     }
   }
 }
 
 .elx_pdf_epo{
   label{
     max-width: 100%;
     min-width: 100%;
     width: 100%;
   }
 }
 
 .automation_pop_drop{
   .multiselect{
     div{
       &:nth-child(3){
        z-index: 99999;
       }
     }
   }
 }

.no_link_under{
  a{
    text-decoration: none !important;
  }
}

.mapp_head_desk{
  @media (max-width:767px) {
    display: none;
  }
}

.mapp_head_mob{
  display: none;
  @media (max-width:767px) {
    display: block;
  }
}

.property_select{
  .css-1r5gbi5-menu {
      bottom: auto !important;
      top: inherit !important;
      z-index: 999999 !important;
  }
}


.logs_popup{
  .post__content{
    max-height: 70vh;
    overflow: auto;
    p{
      margin-bottom: 5px !important;
    }
  }
}
.drop_disable{
  .css-botuwb-option{
    color:  $az-placeholder  !important;
    padding-left: 0px !important;
  }
}
.housecln_tbl{
  th {
    width: auto !important;
    min-width:  auto !important;

  }
  td {
    width:  auto !important;
    min-width:  auto !important;
    
  }
}

.overview_fst {
  background: $az-btn-green;
  padding: 20px 0;
  margin: 0;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  // margin-bottom: 30px;

  .overview_cnt {
    color: $az-white;
    text-align: center;
    flex: 0 0 12.3%;
    max-width: 12.3%;
    padding: 0 10px;

    @media (max-width:991px) {
      flex: 0 0 25%;
      max-width: 25%;
      margin-bottom: 5px;
    }

    @media (max-width:768px) {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 15px;
    }

    h4 {
      font-size: $az-font-22;
      margin-bottom: 0;
      font-family: $az-heading-para;
      color: $az-white;
      @media (max-width:1280px) {
        font-size: $az-font-20;
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}

.overview_heading {
  h4 {
    color: $az-btn-green;
    font-size: $az-font-16;
    font-family: $az-heading-para;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
}

.overviewLeft {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  margin-bottom: 30px;
  height: 255px;

  @media (max-width:1720px) {
    height: 280px;
  }

  @media (max-width:1600px) {
    height: 255px;
  }

  @media (max-width:1440px) {
    padding: 15px 30px;
  }

  @media (max-width:991px) {
    height: auto;
  }

  @media (max-width:767px) {
    height: auto;
  }

  .col-md-6 {
    @media (max-width:1280px) {
      padding-right: 5px;
    }

    @media (max-width:991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

     @media (max-width:768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

  }

  @media (max-width:991px) {
    padding: 15px;
  }
}

.overview_info {
  margin-bottom: 15px;

  label {
    color: #97A3B9;
    font-size: $az-font-12;
    text-transform: uppercase;
    font-family: $az-heading-para;
    margin-bottom: 0 !important;
  }

  p {
    color: $az-black;
    font-size: $az-font-18;
    text-transform: uppercase;
    font-family: $az-heading-para;

    @media (max-width:1440px) {
      font-size: $az-font-18;
    }
  }
}

.overviewRight {
  .overview_shd {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;

    @media (max-width:1600px) {
      padding: 15px 35px 0 30px;
    }


    @media (max-width:991px) {
      padding: 15px;
    }
    label{
      white-space: nowrap;
    }
  }

  .col-md-3{
   @media (max-width:1440px) {
    flex: 0 0 33.333%;
    max-width: 33.333%;
   }

   @media (max-width:991px) {
    flex: 0 0 50%;
    max-width: 50%;
   }

   @media (max-width:991px) {
    flex: 0 0 100%;
    max-width: 100%;
   }

  }
  
}

.overview_sec {
  // .col-md-4 {
  //   @media (max-width:1440px) {
  //     flex: 0 0 45%;
  //     max-width: 45%;
  //   }

  //   @media (max-width:1280px) {
  //     flex: 0 0 42%;
  //     max-width: 42%;
  //   }

  //   @media (max-width:991px) {
  //     flex: 0 0 35%;
  //     max-width: 35%;
  //   }

  //   @media (max-width:768px) {
  //     flex: 0 0 100%;
  //     max-width: 100%;
  //   }
  // }

  .media-dashboard-one{
    margin-bottom: 30px;
  }

    .dashborad_two_box{
      @media (max-width:1600px) {
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      .dash-dual{
        &:nth-child(1){
          @media (max-width:1600px) {
            flex: 0 0 50%;
            max-width: 50%;  
            padding-right: 15px;        
          }
          @media (max-width:991px) {
            flex: 0 0 100%;
            max-width: 100%;  
            padding: 0px;        
          }

        }
        &:nth-child(2){
          @media (max-width:1600px) {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: 15px;
          }
          @media (max-width:991px) {
            flex: 0 0 100%;
            max-width: 100%;  
            padding: 0px;        
          }
        }
      }
    }

  .col-md-8 {
    @media (max-width:1600px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    @media (max-width:1280px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    // @media (max-width:991px) {
    //   flex: 0 0 65%;
    //   max-width: 65%;
    // }

    // @media (max-width:768px) {
    //   flex: 0 0 100%;
    //   max-width: 100%;
    // }
  }

  .overviewRight {
    .col-md-4 {
      @media (max-width:1440px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      

      @media (max-width:768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.top-per-tbl {
  thead {
    th {
      background-repeat: no-repeat !important;
      background-position: 95% 50% !important;
      background-size: 16px !important;
    }

    .asc {
      background-image: url(../images/asc.svg) !important;
      padding-right: 25px;
    }

    .desc {
      background-image: url(../images/desc.svg) !important;
      padding-right: 25px;
    }
  }
}

.property_menu{
  display: flex;
  padding: 9px 15px;
  place-items: center ;
  background: #ccc;
  letter-spacing: 0.19px;
  figure{
    margin: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  p{
    margin: 0 !important;
    font-size: $az-font-14;
    font-family: $az-heading-para;
    padding-left: 10px;
  }
}

.msg_menu_not{
  position: relative;
  .message_bages{
    background: red;  
    color: #000;
    text-align: center;
    height: 12px;
    width: 12px;
    line-height: 18px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }
}

.css-1uccc91-singleValue {
  max-width: calc(100% - 40px) !important;
}

.cred_form {
  input {
    margin-right: 60px;
    @media (max-width:1280px) {
      margin-right: 30px;
    }
  }
  .plus_tbn {
    img {
      margin-top: 4px !important;
    }
  }
}


.cred_form {
  input {
    margin-right: 60px;

    @media (max-width:1280px) {
      margin-right: 30px;
    }
  }

  .plus_tbn {
    img {
      margin-top: 4px !important;
    }
  }
}


.file_export_hgt {
  max-height: 248px;
  overflow: auto;
  border: 1px solid #33333382;
  display: flex;
  border-top: transparent;

  @media (max-width:767px) {
    display: block;
  }
}

.mob-expo {
  display: none;

  @media (max-width:767px) {
    display: block;
  }
}

.bdr-left {
  border-left: 1px solid $az-white;
}

.desk_expo {
  @media (max-width:767px) {
    display: none;
  }
}

.logs_popup {
  .post__content {
    max-height: 70vh;
    overflow: auto;

    p {
      margin-bottom: 5px !important;
    }
  }
}

.cred_drop_issue {
  min-height: 65vh;

  .multiselect {
    .css-botuwb-option {
      background: #eee;
    }
  }

  @media (max-width:767px) {
    min-height: auto;
    height: auto;
  }
}

.faq_tbl {
  .asrt-table-head {
    border: 0 !important;
  }

  .asrt-table-body {
    border: 0 !important;
  }
}

.front_id_img {
  width: 80px;
  min-height: 50px;
  max-height: 50px;
  object-fit: cover;
  margin-left: 5px;
}

.selfi_id_img {
  width: 60px;
  min-height: 80px;
  max-height: 80px;
  object-fit: cover;
  margin-left: 5px;
}

.receipt_pdf_guest{
      width: 30px;
      margin-left: 5px;
      margin-top: 0px;
      margin-bottom: 0px !important;
      cursor: pointer;
}

.custm-filter {
  .filter_bg_cust {
    span {
      background-color: #E7E7E7;
      color: #333;
      padding: 6px 10px;
      margin: 2px;
      display: inline-block;
      margin-left: 2px;
    }
  }

  .btn-fliter-clear {
    @include OHlittlebtn;
  }
}

.bg_grp_email {
  background-color: #a2bde3;
}

.btn_var_temp {
  background: transparent;
  color:$az-black;
  border: 0;
  text-decoration: underline;
  text-underline-offset: 0.1em;
  font-family: $az-heading-para;
  font-size: $az-font-14;
  padding: 0;
  margin: 0;
  min-height: auto !important;

  &:hover {
    text-decoration: underline;

  }
}

.temp_modal_sel {
  .multiselect {
    .css-botuwb-option {
      background: #eee;
    }
  }
}

.temp_tbl {
  thead {
    tr {
      th {
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}

.var_tmp_modal {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-count: 3;
  column-count: 3;
  padding-bottom: 20px;
  page-break-inside: avoid;
  width: 100%;

  @media (max-width:991px) {
    -webkit-column-count: 2;
    column-count: 2;
}

@media (max-width:767px) {
  -webkit-column-count: 1;
  column-count: 1;
}

  .var_tmp_box{
    display: inline-grid;
    width: 100%;
  }

  .var_pop_head{
    font-size: 14px;
    font-weight: 600;
  }

  ul {
    display: block;
    margin: 0 0 10px;
    padding-left: 0;
    text-align: left;

    li {
      color: #606060;
      display: flex;
      font-size: 16px;
      margin-bottom: 0px;
      margin-left: 0;
      place-items: center;
      label{
        cursor: pointer;
        margin-bottom: 5px;
      }
    }
  }
}

.model-temp_var {
  .modal-dialog {
    max-width: 50% !important;
    @media (max-width:991px) {
      max-width: 75% !important;
    }
    @media (max-width:767px) {
      max-width: 100% !important;
    }
  }
}

.guest_images{
    width: 180px;
    max-width: 180px;
    min-width: 180px;
    img {
      border-radius: 5px;
    }
}

.autommation_icon {
  img {
    max-height: 16px;
  }
}

.ecom_des_word{
  white-space: pre-wrap;
}

.editor_view_text{
  p {
    padding: 0 !important;
    margin: 0 !important;
  }
}


.ckeditor_cnt_box{
  .ck-editor {
    p {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

// all Calender pages in remove main  scrollbar
.custom-calendar-class {
  overflow: hidden;
}


.link_td_click_tbl{
  td{
    padding: 0 !important;
    a{
      padding: 16px 5px;
      display: block;
      width: 100%;
    }
    p{
      padding: 16px 5px;
    }
  }
}

.trun_text_p{
  word-break: break-word;
}
.cms_descp_cnt{
  .para{
      margin-top: 0 !important;
      font-family: $az-heading-para !important;
      font-size: $az-font-16 !important;
     p{
      font-family: $az-heading-para !important;
      font-size: $az-font-16 !important;
     }
     span{
      font-family: $az-heading-para !important;
      font-size: $az-font-16 !important;
     }
     strong{
      font-family: $az-heading-para !important;
     }
     b{
      font-family: $az-heading-para !important;
     }
  }
}
//payout css
.btn-disable-theme {
  cursor: no-drop;
  background-color: #000!important;
  color: #fff!important;
  border: 2px solid #000!important;
}

.hr_cust{
  margin: .5rem 0;
}
.paid_amt_cnt{
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      display: inline-block;
      padding:6px 20px;
      border: 1px solid #cdd4e0;
      &:first-child{
        border-right: 0px;
      }
      p{
        font-size: $az-font-15;
        span{
          background: transparent;
        }
      }
    }
  }
  @media (max-width:767px) {
    margin-top: 20px;
  }
}

.payable_head{
  .button-width-cust{
    max-width: 135px !important;
    min-width: 135px !important;
  }
}

.pb-0-cust{
  padding-bottom: 0 !important;
}

.pl-rm-cust{
  padding-left: 1.5rem !important;
}

.pt-0-cust{
  padding-top: 0 !important;
}

//payout report css
.btn-disable-theme-payout {
  cursor: no-drop;
}

.scdule_sec{
  .multiselect{
    .css-1r5gbi5-menu {
      z-index: 99999 !important;
     @media (max-width:1366px) {
      top: 100% !important;
      bottom: inherit !important;
     }
  }
  }
}

.css-1hwfws3{
  padding-right: 20px !important;
}

.auto_list_sec{
  .master {
    table {
      td {
        b.active{
          margin-left: 0 !important;
        }
        b{
          margin-left: 0 !important;
        }
      }
    }
  }
}


.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -53px;
  margin-left: 0px;
  width: max-content;
}

.tooltip1 .tooltiptext1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

.tooltip1 .tooltiptext2 {
  left: -20px;
}

.tooltip1 .tooltiptext3 {
  left: -30px;
}

.exp_conf_modal{
    background-color: #000000c7;
}

.information-logs-container{
  max-height: 250px;
  overflow: auto;
}

.cms_descp_cnt{
  .para{
      margin-top: 0 !important;
      font-family: $az-heading-para !important;
      font-size: $az-font-16 !important;
     p{
      font-family: $az-heading-para !important;
      font-size: $az-font-16 !important;
     }
  }
}

.master{
  tr{
    td{
      padding:16px 5px!important
    }
  }
}

.ameniy_master_tbl{
  thead{
    tr{
      th{
        &:nth-child(6){
          text-align: center !important;
        }
      }
    }
  }
  tbody{
    .status_id{
      b{
      margin: 0 !important;
    }
  }
  }
}

.cms_var_modal_inner{
  column-count: 1 !important;
}

.faq_img_video{
  .para{
    img{
      max-width: 100%;
    }
    video{
      max-width: 100%;
    }
  }
}

.fob_tbl_align{
  .as-react-table{
    .col-md-12{
    padding: 0 !important;
    }
  }
}


.payout_modal_box{
  margin-bottom: 24px;
  p{
    margin-bottom: 5px !important;
    font-size: $az-font-16 !important;
    span{
      float: right;
      font-family: $az-heading-para;
    }
  }
}

.modal.pay_out_add_pay{
  .modal-dialog {
    .modal-content{
      .btn-add-strp-pay{
        margin:  0 auto !important;
        min-width: 160px !important;
        max-width: 160px !important;
      }
    }
  }
}

.faq_tbl {
  table{
    thead{
      tr{
        th{
          &:last-child{
            text-align: left !important;
          }
        }
      }
    }
  }
  .asrt-table-head {
    border: 0 !important;
  }

  .asrt-table-body {
    border: 0 !important;
  }
  table.brandtable{
    tbody{
      tr{
        td{
          &:first-child{
            max-width: 400px;
            white-space: pre-wrap;
          }
        }
      }
    }
  } 
}

.master{
  tr{
    td{
      padding:16px 5px!important
    }
  }
}

.ameniy_master_tbl{
  thead{
    tr{
      th{
        &:nth-child(6){
          text-align: center !important;
        }
      }
    }
  }
  tbody{
    .status_id{
      b{
      margin: 0 !important;
    }
  }
  }
}

.az-error-wrapper{
  .btn-outline-indigo{
    @include btn-success-outline-small;
    max-width:100%;
  }
}

.cms_var_modal_inner{
  column-count: 1 !important;
}

/*==================================
    TIMELINE
==================================*/


.time_line_sec{
  padding: 80px 150px;
  @media (max-width:991px) {
    padding: 40px;
  }
  @media (max-width:767px) {
    padding: 40px 15px;
  }
}


.example-title {
    text-align: center;
    margin-bottom: 60px;
    padding: 3em 0;
    border-bottom: 1px solid #E4EAEC;
    p {
        margin: 0 auto;
        font-size: 16px;
        max-width: 400px;
    }
}


    /*-- GENERAL STYLES
    ------------------------------*/
    .timeline {
        line-height: 1.4em;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    /*----- TIMELINE ITEM -----*/

    .timeline-item {
        padding-left: 40px;
        position: relative;
        &:last-child {
            padding-bottom: 0;
        }
    }

    /*----- TIMELINE INFO -----*/

    .timeline-info {
        margin: 0 0 .5em 0;
        white-space: nowrap;
        span{
          background: transparent !important;
          color: $az-black;
          font-size:$az-font-15;
          font-family: $az-heading-para;
          position: relative;
          top: -3px;
        }
    }
    /*----- TIMELINE MARKER -----*/

    .timeline-marker {
        position: absolute;
        top: 0;
        bottom: 0; 
        left: 0;
        width: 15px;
        &:before {
            background: $az-eage-color;
            border: 1px solid transparent;
            border-radius: 100%;
            content: "";
            display: block;
            height: 15px;
            position: absolute;
            top: 0px; 
            left: 0;
            width: 15px;
        }
        &:after {
            content: "";
            width: 1px;
            background: #CCD5DB;
            display: block;
            position: absolute;
            border: 1px solid #CDD4E0;
            top: 15px; 
            bottom: 0; 
            left: 7px;
        }
        .timeline-item:last-child &:after {
          content: "";
          width: 1px;
          background: #CCD5DB;
          display: block;
          position: absolute;
          border: 1px solid #CDD4E0;
          top: 15px; 
          bottom: 0; 
          left: 7px;

        }
    }
    .timeline-item:not(.period):hover .timeline-marker:before {
        background: transparent;
        border: 3px solid $az-eage-color
    }

    /*----- TIMELINE CONTENT -----*/

    .timeline-content {
        padding-bottom: 40px;
        h3{
          font-size: $az-font-15 !important;
          margin-bottom: 10px !important;
          color: $az-black;
          span{
            float: right;
            background: transparent !important;
            color: $az-gray;
            font-family: $az-heading-two !important;
            padding-right: 2px;
          }
        }
        p{
          b{
            color: $az-black !important;
          }
        }
        p:last-child {
            margin-bottom: 0;
            text-align: left;
            padding-bottom: 20px;
            color: $az-gray;
        }
    }

    /*----- TIMELINE PERIOD -----*/
    
    .period {
        padding: 0;
        .timeline-info {
            display: none;
        }
        .timeline-marker {
            &:before {
                background: transparent;
                content: "";
                width: 15px;
                height: auto;
                border: none;
                border-radius: 0;
                top: 0;
                bottom: 30px;
                position: absolute;
                border-top: 3px solid #CCD5DB;
                border-bottom: 3px solid #CCD5DB;
            }
            &:after {
                content: "";
                height: 32px;
                top: auto;
            }
        }
        .timeline-content {
            padding: 40px 0 70px;
        }
        .timeline-title {
            margin: 0;
        }
    }

    /*----------------------------------------------
        MOD: TIMELINE SPLIT
    ----------------------------------------------*/

        .timeline-split {
            @media (min-width: 768px) {
                .timeline {
                    display: table;
                }
                .timeline-item {
                    display: table-row;
                    padding: 0;
                }
                .timeline-info,
                .timeline-marker,
                .timeline-content,
                .period .timeline-info {
                    display: table-cell;
                    vertical-align: top;
                }
                .timeline-marker {
                    position: relative;
                }
                .timeline-content {
                    padding-left: 15px;
                }
                .timeline-info {
                    padding-right: 15px;
                }
                .period .timeline-title {
                    position: relative;
                    left: -45px;
                }
            }
        }

    /*----------------------------------------------
        MOD: TIMELINE CENTERED
    ----------------------------------------------*/

        .timeline-centered {
            @extend .timeline-split;
            @media (min-width: 992px) {
                &,
                .timeline-item,
                .timeline-info,
                .timeline-marker,
                .timeline-content {
                    display: block;
                    margin: 0;
                    padding: 0;
                }
                .timeline-item {
                    padding-bottom: 40px;
                    overflow: hidden;
                    &:last-child{
                      padding-bottom: 0 !important;
                    }
                }
                .timeline-marker {
                    position: absolute;
                    left: 50%;
                    margin-left: -7.5px;
                }
                .timeline-info,
                .timeline-content {
                    width: 50%;
                }
                > .timeline-item:nth-child(odd) .timeline-info {
                    float: right;
                    text-align: left;
                    padding-left: 15px;
                }
                > .timeline-item:nth-child(odd) .timeline-content {
                    float: left;
                    text-align: right;
                    padding-right: 15px;
                    margin-top: 50px;
                }    
                > .timeline-item:nth-child(even) .timeline-info {
                    float: left;
                    text-align: right;
                    padding-right:15px;
                    margin-top: 0px;
                }
                > .timeline-item:nth-child(even) .timeline-content {
                    float: right;
                    text-align: left;
                    padding-left: 15px;
                    margin-top: 50px;
                }
                > .timeline-item.period .timeline-content {
                    float: none;
                    padding: 0;
                    width: 100%;
                    text-align: center;
                    margin-top: 50px;
                }
                .timeline-item.period {
                    padding: 50px 0 90px;
                }
                .period .timeline-marker:after {
                    height: 30px;
                    bottom: 0;
                    top: auto;
                }
                .period .timeline-title {
                    left: auto;
                }
            }
        }

    /*----------------------------------------------
        MOD: MARKER OUTLINE
    ----------------------------------------------*/
        
        .marker-outline {
            .timeline-marker {
                &:before {
                    background: transparent;
                    border-color: $az-eage-color;
                }
            }
            .timeline-item:hover .timeline-marker:before {
                background: $az-eage-color;
            }
        }

        .time_line_btn{
          max-width: 120px !important;
          min-width: 120px !important;
        }
        
        .form-label{
          color: $az-gray !important;
        }

        .btn-pdf-view{
          background: transparent;
          color: #000000;
          border: 0;
          text-decoration: underline;
          text-underline-offset: 0.1em;
          font-family: $az-heading-para;
          font-size: 14px;
          padding: 0;
          margin: 0;
          min-height: auto !important;
          position: relative;
          top: -1px;
          padding: 0 3px;
        }

        .table_inside_drop{
          overflow-x: visible !important;
          @media (max-width:991px) {
            overflow-x: auto !important;
          }
        }

        .remove-pic{
          &:after {
            background: $az-white !important;
            bottom: -5px;
            content: url(../images/delete.svg);
            left: 60px;
            position: absolute;
            text-decoration: underline;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            width: 28px;
            height: 28px;
            color: $az-black;
            border-radius:30px ;
            box-shadow: 0px 0px 2px#000;
            text-align: center;
            padding: 5px;
            @media (max-width:1600px) {}
            @media (max-width:767px) {
              bottom: 15px;
              left: 60px;
            }
          }
        }


        .dash-label-max{
          width: max-content;
        }

        .overviewLeft{
          .overview_info{
            p.text-dash-same{
              @media (max-width:1334px) {
                padding-top: 15px;
              }
              @media (max-width:991px) {
                padding-top: 0px;
              }
            }
            p.text-dash-same-two{
              @media (max-width:1326px) {
                padding-top: 15px;
              }
              @media (max-width:991px) {
                padding-top: 0px;
              }
            }
            p.text-dash-same-three{
              @media (max-width:1312px) {
                padding-top: 15px;
              }
              @media (max-width:991px) {
                padding-top: 0px;
              }
            }
          }
        }

        /*----------------------------------------------
           CRM Campaign
        ----------------------------------------------*/
        .tbl_lst_th_left {
          .asrt-table-body {
            table {
              thead {
                th {
                  &:last-child {
                    text-align: left !important;
                  }
                }
              }
            }
          }
        }
    
        .input-tag {
          background: white;
          border: 1px solid #33333382;
          border-radius: 2px;
          display: flex;
          flex-wrap: wrap;
        }
    
        .input-tag input {
          border: none !important;
          width: 100%;
        }
    
        .input-tag__tags {
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          padding: 2px 8px;
          -webkit-overflow-scrolling: touch;
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
          margin: 0;
        }
    
        .input-tag__tags li {
          background-color: hsl(0, 0%, 90%);
          border-radius: 1px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin: 2px;
          min-width: 0;
          box-sizing: border-box;
    
        }
    
    
        .input-tag__tags li span {
          border-radius: 1px;
          color: hsl(0, 0%, 20%);
          font-size: 85%;
          overflow: hidden;
          padding: 3px;
          padding-left: 6px;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
          background: hsl(0, 0%, 90%) !important;
        }
    
        .input-tag__tags li button {
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          border-radius: 1px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          padding-left: 4px;
          padding-right: 4px;
          box-sizing: border-box;
          margin: 0;
          border: 0;
          cursor: context-menu !important;
    
          svg {
            display: inline-block;
            fill: currentColor;
            line-height: 1;
            stroke: currentColor;
            stroke-width: 0;
          }
    
          &:hover {
            background-color: #FFBDAD;
            color: #DE350B;
          }
    
        }
    
        .input-tag__tags li.input-tag__tags__input {
          background: none;
          flex-grow: 1;
          padding: 0;
        }
    
        .input-tag__tags li.input-tag__tags__input input {
          height: 34px !important;
          padding-left: 0 !important;
        }
    
        .dashboardRightcard .media-body .PipeSymbolColor {
          color: #000000;
          margin-right: 0 !important;
        }
    
        .crm_list_header {
          .btn-success-outline {
            max-width: 125px !important;
            min-width: 125px !important;
          }
        }
    
        .list_upload_box {
          form {
            label {
              height: 150px !important;
            }
          }
        }
    
        .unsub_box {
          h3 {
            font-size: $az-font-22 !important;
            text-align: left !important;
          }
    
          .p-dark {
            text-align: left !important;
            margin-bottom: 5px;
          }
    
          .az-signin-header {
            width: 515px !important;
            height: auto !important;
          }
    
          button.btn-block {
            background-color: $az-eage-color;
    
          }
        }
    
        .addCSVListChecked {
          input {
            width: 9% !important;
          }
        }
    
        .list_upload_box {
          form {
            ::after {
              top: -30px !important;
            }
          }
        }
    
        .az-error-wrapper {
          .btn-outline-indigo {
            @include btn-success-outline-small;
            max-width: 100%;
          }
        }
        .jodit_cnt_box {
          .jodit-react-container {
            p {
              padding: 0 !important;
              margin: 0 !important;
            }
          }
        }
        .syn_tbl_scroll{
          @media (max-width:1760px) {
            padding-right: 15px !important;
          }
          @media (max-width:767px) {
            padding-right: 0px !important;
          }
        }

        
.payable_head{
  .button-width-cust{
    max-width: 135px !important;
    min-width: 135px !important;
  }
}

.automation_pop_add{
  .multiselect{
    .css-1r5gbi5-menu{
      z-index: 99999 !important;
    }
  }
}

.auto_list_sec{
  .master {
    table {
      td {
        b.active{
          margin-left: 0 !important;
        }
        b.m-0{
          margin-left: 0 !important;
        }
      }
    }
  }
}


.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -53px;
  margin-left: 0px;
  width: max-content;
}

.tooltip1 .tooltiptext1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

.tooltip1 .tooltiptext2 {
  left: -20px;
}

.tooltip1 .tooltiptext3 {
  left: -30px;
}

.exp_conf_modal{
    background-color: #000000c7;
}

.information-logs-container{
  max-height: 250px;
  overflow: auto;
  padding-left: .5rem;
  margin: 0;
  .media-body{
    label{
      display: block;
      margin-bottom: 0 !important;
      margin-top: 5px !important;
    }
  }
}

.auto_process_top_btn{
  top: -62px !important;
}

.nav-pills {
  li.child-active{
    .nav-link.active {
      background: $az-eage-color;
      color: $az-black;
      line-height: 1.5;

      &:hover {
        background: $az-black;
        color: $az-white;
      }
    }
  }
}

.kdd{
  width: calc(100vw - 180px);
  height: calc(100vh - 110px);
}

.maintance_modify_calander {
  .md-full-event {
    display: flex;
    width: 100%;

    .md-full-event-details {
      display: flex;
      width: 100%;
      place-content: space-between;

      .md-full-event-title {
        width: 35%;
        font-family: $az-heading-para;
        font-size: 1$az-font-14;
        margin-bottom: 5px;
        color: $az-black;

        @media (max-width:991px) {
          width: 33.333%;
        }
      }

      .md-full-event-location {
        width: 15%;
        font-family: $az-heading-para;
        font-size: 1$az-font-14;
        margin-bottom: 5px;
        color: $az-black;

        @media (max-width:1350px) {
          width: 20%;
        }

        @media (max-width:991px) {
          width: 33.333%;
        }
      }

      .md-full-event-time {
        width: 20%;
        text-align: right;
        font-family: $az-heading-para;
        font-size: 1$az-font-14;
        margin-bottom: 5px;
        color: $az-black;

        @media (max-width:991px) {
          width: 33.333%;
        }
      }
    }
  }

  .mbsc-ios.mbsc-event-color {
    background: rgb(244, 209, 0);
  }
}

.syn_tbl_scroll{
  @media (max-width:1760px) {
    padding-right: 15px !important;
  }
  @media (max-width:767px) {
    padding-right: 0px !important;
  }
}

.list_syn_tbl{
  thead{
    tr{
     th{
      text-align: left;
     }
    }
    tr{
      th{
        &:nth-child(1){
          display: none ;
        }
      }
    }
  }
 tbody{
  tr{
    td{
      &:nth-child(1){
        white-space: pre-wrap;
        display: none;
      }
      &:nth-child(2){
        white-space: pre-wrap;
        max-width: 220px;
        min-width: 220px;
        width: 220px;
        @media (max-width:1460px) {
          max-width: 180px;
          min-width:180px;
          width: 180px;
        }
      }
      &:nth-child(3){
        white-space: pre-wrap;
      }
     
      &:nth-child(6){
        max-width: 180px;
        min-width:180px;
        width: 180px;
        white-space: pre-wrap;
      }
      // &:nth-child(7){
      //   max-width: 80px;
      //   min-width:80px;
      //   width: 80px;
      // }
    }
  }
 }
}

.xml_cnt_popup{
  h4{
    font-family: $az-heading-para;
    font-size: $az-font-18;
  }
  p{
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

.campng_text {
  font-size: $az-font-16;
  color: #000000;
  font-family: $az-heading-para;
  font-weight: normal;
  padding-bottom: 6px;
  margin-bottom: 0;
  @media (max-width:767px) {
    margin-bottom: 1rem;
  }
}

.viewfile-section{
  span.viewfile{
    width: 40%;
    p{
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
  span{
    &:nth-child(2){
      width: 40%;
    }
  }
}

.remove-pic-two{
  &:after {
    bottom: -20px;
    @media (max-width:767px) {
      bottom: 0px;
      left: 70px;
    }
  }
}

.remove-pic-three{
  &:after {
    bottom: -20px;
    left: 90px;
    @media (max-width:767px) {
      bottom: 0px;
      left: 70px;
    }
  }
}

.app_file_img{
  position: relative;
}

.remove-img-logos{
  position: absolute;
  bottom: -9px;
  left: 60px;          
  @media (max-width:767px) {
    bottom: 9px;
    left: 60px;
  }
  &:after {
    background: $az-white !important;
    content: url(../images/delete.svg);
    text-decoration: underline;
    width: 28px;
    height: 28px;
    color: $az-black;
    border-radius:30px ;
    box-shadow: 0px 0px 2px#000;
    text-align: center;
    padding: 5px;
    display: block;
  }
}

.remove-pic-four{
  &:after {
    bottom: 0px;
    left: 60px;
    @media (max-width:767px) {
      bottom: 15px;
      left: 60px;
    }
  }
}

.vdo_upload_tbl{
  .table {
    thead {
      th{
          max-width: 400px;
          &:nth-child(3){
            max-width: 200px;
          }
          &:nth-child(4){
            max-width: 200px;
          }
          &:last-child{
            text-align: left !important;
          }
        }
      }
      tbody {
        td{
            max-width: 400px;
            white-space: pre-wrap;
            &:nth-child(3){
              max-width: 200px;
            }
            &:nth-child(4){
              max-width: 200px;
            }
            a{
              white-space: pre-wrap;
              word-wrap: break-word;
            }

          }
        }
      }
    }

    .video_thumb_img{
      video{
        max-width: 300px;
      }
    }

    .clean-method-w{
      width: 60%;
      display: flex;
      @media (max-width:767px) {
        width: 100%;
        display: block;
      }
  }

  .az-img-user{
    img{
      background-color: $az-light-gray;
    }
  } 
  
  .dashboardRightcard {
    .fileUpload {
      span{
        img{
          background-color: $az-light-gray;
        }
      }
    }
  }

  .csv_tbl_formate{
    table {
      thead{
          th{
              padding: 16px 5px;
              font-size: $az-font-14;
              font-family: $az-heading-two;
              color: $az-gray;
              font-weight: normal;
              text-transform: initial;
              &:last-child{
                  text-align: center;
              }
          }
          .asc{
           // background-image:none !important;
            background-image:url(../images/asc.svg) !important;
            padding-right: 25px;
          }
          .desc{
            // background-image:none !important;
            background-image:url(../images/desc.svg) !important;
            padding-right: 25px;
          }
      }
      tbody{
          tr{
              background: none;
               td{
                   padding: 16px 5px;
                   font-size: $az-font-15;
                   font-family: $az-heading-two;
                   color: $az-black;
                   font-weight: normal;
                   &:last-child{
                       text-align: center;
                   }
                   a {
                       color: $az-black;
                   &:hover {
                       text-decoration: underline;
                       text-underline-offset: 0.3em;
                     }
                   }
  
               }
               .status_id {
                b {
                  @include inactivestatus;
                  margin-left: 18px;
                  @media (max-width:767px){
                    display: block;
                  }
                }
                b.active {
                  @include activestatus;
                  margin-left: 18px;
                }
               }
           .brand_list_image{
                   height: 32px;
                   width: 32px;
                   border-radius: 50%;
                   object-fit: cover;
                   border: 1px solid $az-border-color;
               }
           }
           .td_clickable a{
            width: 100%;
            display: block;
            text-decoration: none !important;
          }
          .td_clickable span{
           width: 100%;
           display: block;
         }
       }
  
   }
  }
  
  .csv_preview_tbl{
  thead{
  th{
  text-align: left !important;
  }
  }
  tbody{
  td{
  text-align: left !important;
  }
  }
  }

  .data_export_move{
    font-family: $az-heading-two !important;
    margin-top: 15px !important;
    .ant-transfer-list{
      width: 47%;
      height: 225px;
      border-color: #33333382;
      border-radius: 2px;
      .ant-transfer-list-content{
        input[type="checkbox"]{
          position: absolute !important;
          width:18px !important;
          height: 18px !important;
        }
        .ant-transfer-list-content-item{
          &:hover{
            background-color: transparent;
          }
        }
      }
      .ant-checkbox-checked::after{
        border-color: $az-black;
      }
      .ant-checkbox-checked .ant-checkbox-inner{
        background-color: transparent;
        border-color: transparent;
      }
    }
    .ant-transfer-list-header{
      .ant-checkbox-wrapper{
        margin: 0 !important;
      }
      input[type="checkbox"]{
        position: absolute !important;
        width:18px !important;
        height: 18px !important;
        margin: 0 !important;
      }
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after{
      background-color: $az-black;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $az-black;
    }

    .ant-transfer-list-header{
      border-color: #33333382 !important;
    }

    .ant-input:focus {
      border-color: transparent;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 0 2px rgba(30, 30, 31, 0);
      box-shadow: 0 0 0 2px rgba(24, 143, 255, 0);
  }

  .ant-checkbox-inner{
    border-color: #33333382 !important;
  }
  
  .ant-transfer-list-content-item-text{
    color: $az-black;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after{
    border-color: $az-black;
    border-width: 1px;
    width: 5px;
    height: 9px;
  }
   
  .ant-transfer-operation{
    .ant-btn-primary{
      border-color:$az-black ;
      background-color: $az-black;
      border-radius: 0;
    }
    .ant-btn-primary[disabled]{
      border-color: $az-light-bg;
      background-color: $az-light-bg;
      border-radius: 0;
    }
  }

  .ant-empty-description{
    margin-bottom: 0 !important;
  }

  .ant-transfer-list-search-action{
    display: none;
  }

  .ant-transfer-list-header .ant-checkbox-wrapper + span{
    color: $az-black;
    vertical-align: middle;
  }

  .ant-empty-small .ant-empty-image{
    display: none;
  }

  .ant-btn-icon-only.ant-btn-sm{
    display: grid !important;
    place-content: center;
  }

  .ant-transfer-list-content-item > span{
    vertical-align: middle;
  }

  .ant-transfer-list-header-title {
    top: 8px;
    color: $az-black;
  }

  }

  .arrival_msg_pops{
    img{
      width: auto !important;
      max-width: 100% !important;
    }  
  }

  .lstng_asc_desc{
    .sortable {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 16px;
    }
  .asc{
     background-image:url(../images/asc.svg) !important;
     padding-right: 25px;
   }
   .desc{
    background-image:url(../images/desc.svg) !important;
    padding-right: 25px;
   }
}

.coporate_tbl_res{
  thead{
    tr{
      td{
        &:nth-child(1){
          @media (max-width:1550px) {
            max-width: 300px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        &:nth-child(2){
          @media (max-width:1550px) {
            max-width: 330px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        &:nth-child(3){
          @media (max-width:1550px) {
            max-width: 300px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        &:nth-child(3){
          @media (max-width:1550px) {
            max-width: 120px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        &:nth-child(4){
          @media (max-width:1550px) {
            max-width:228px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
      }
    }
  }
}
  tbody{
    tr{
      td{
        &:nth-child(1){
          @media (max-width:1550px) {
            max-width: 300px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        &:nth-child(2){
          @media (max-width:1550px) {
            max-width: 330px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        &:nth-child(3){
          @media (max-width:1550px) {
            max-width: 120px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
        &:nth-child(4){
          @media (max-width:1550px) {
            max-width:228px;
            white-space: pre-wrap;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.cleaing_mth_error{
  position: relative;
  white-space: pre;
  padding-left: 25px;
}

.card-minimal-two {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  .card-footer {
    background: $az-white;
    label{
      color: #97A3B9;
      font-size: $az-font-12;
      text-transform: uppercase;
      font-family: $az-heading-para;
      margin-bottom: 0 !important;
    }
    p {
      color: $az-black;
      font-size: $az-font-18;
      text-transform: uppercase;
      font-family: $az-heading-para;
  
      @media (max-width:1440px) {
        font-size: $az-font-18;
      }
    }
    .media-icon{
      color: $az-btn-green;
    }
  }
}

.chart_box_fix{
  height:335px;
  position:relative;
  margin-bottom:1%;
  padding:1%;
    
  @media (max-width:1720px) {
    height: 364px;
  }
}

.chart-legend{
  padding-top: 20px;
  display: flex;
}

.chart-legend div{
  display: flex;
  place-items: baseline;
  font-family: $az-heading-para;
  color: #97A3B9;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 10px;
}


.bg-chat-pink{
  background-color: #ea3e91;
  width: 10px;
  height: 10px;
  display: block;
  margin-right: 5px;
}

.bg-chart-green{
  background-color:$az-btn-green ;
  width: 10px;
  height: 10px;
  display: block;
  margin-right: 5px;
}

.resr_activity_tgl{
  margin-left: 10px;
  .az-toggle{
    width: 43px !important;
    height: 20px !important;
    border: 1px solid $az-black ;
    background-color: $az-black;
  }

  .az-toggle span{
    width: 12px;
  }

  .az-toggle.on{
    background: $az-white;
  }

  .az-toggle.on span {
    left: 26px;
    background-color: $az-black;
  }

  .az-toggle span::after{
    content: '';
  }

  .az-toggle span::before{
    content: '';
  }
}

.justify_mob_filter{
  @media (max-width:767px) {
    justify-content: start !important;
  }
}
.star_box_rate{
  height: 25px;
  line-height: initial;
  margin-bottom: 25px;
  @media (max-width:1440px) {
    margin-bottom: 20px;
  }
  .star-container{
    padding-right: 1px !important;
    padding-left: 1px !important;
  }
  a{
    .dashborad_star{
      color: #36f ;
      text-decoration: none;
      &:hover{
        color: #0039e6;
      }
    }
  }
  .dashborad_star{
    font-size: 14px;
    text-transform: uppercase;
    font-family: $az-heading-para;
    line-height: normal;
  }

}

.btn-snap-view {
  @include OHlittlebtn;
  max-height: 33px;
  text-align: center;
}

.star_box_new{
  @media (max-width:1711px) {
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }  

  @media (max-width:991px) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .overview_shd{
    height: 115px;
  }
}

.table_export_move {

  .ant-transfer-list-body-customize-wrapper {
    padding: 10px 0 0 15px !important;
    height: 200px;
    overflow: overlay;
  }

  input[type="checkbox"] {
    position: absolute !important;
  }

  .ant-empty-image {
    display: none !important;
  }

  .ant-table-selection-column {
    padding: 0 !important;
    text-align: left !important;
  }

  .ant-table-thead>tr>th {
    border: 0 !important;
  }

  .ant-table-tbody>tr>td {
    border: 0 !important;
  }

  .ant-transfer-list-header-selected >span:first-child {
    visibility: hidden !important;
  }

  .ant-transfer-list{
    height: 298px !important;
  }

}

.msg_arrival_custom {
  .listSetction {
    img {
      width: 60% !important;
    }
    video{
      max-height: 450px !important;
    }
  }
}

.amenity_img_icon{
  img{
    width:50px;
    height: 50px;
    object-fit: contain;
    padding: 5px;
  }
}

// START CODE FOR Export check box reduce size with label
.table_export_move  .ant-table-tbody > tr > td:first-child {
  max-width: 30px !important;
  width: 30px !important;
  min-width: 30px !important;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small colgroup {
display: block !important;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-thead > tr:first-child > th:first-child {
max-width: 30px !important;
  width: 30px !important;
  min-width: 30px !important;
}
.automation_modal_hgt .export_auto_scroll {
  max-height: 72vh;
  overflow-x: hidden;
}
.ant-transfer-customize-list .ant-table-thead > tr > th:nth-child(2) {
padding: 1px 8px 8px 0 !important;
color: $az-black;
}
.ant-transfer-customize-list .ant-table-tbody > tr > td:nth-child(2) {
padding: 1px 8px 8px 0 !important;
color: $az-black;
}
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr{
border-color:transparent !important;
}
.ant-transfer-customize-list table tr:hover{
background: transparent !important;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th{
background-color: transparent !important;
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
background: transparent !important;
}
// END CODE FOR Export check box reduce size with label


.btn-latch-theme{
  @include modalbtn-delete;
  max-width: 120px;
  min-width: 120px;
  margin: 0 !important;
  &:hover{
    background-color: $az-black;
    color: $az-white;
    border-color:$az-black ;
  }
  &:focus {
    border: 2px solid $az-eage-color;
    box-shadow: none;
    outline: none;
    background: none;
    color: $az-black;
    background-color: $az-eage-color;
}
}

.latch_table_cust.dashboardRightcard{
    table {
      tbody {
        tr {
          td{
            vertical-align: middle;
          }
        }
      }
    }
  } 

  .dash-dual-two{
    .overviewLeft{
      height: 200px;
      @media (max-width:1600px) {
        height: 255px;
      }
  
      @media (max-width:991px) {
        height: auto;
      }
    }
  }
  
  .property_chat{
    .chart_box_fix{
      height:390px;
      @media (max-width:1720px) {
        height: 441px;
      }
      @media (max-width:1600px) {
        height: 364px;
      }
    }
  }

  
.btn-swp-img{
  img{
    height: 20px;
    filter: invert(1);
  }
}

.key-swap-modal-img{
  img{
    width: 70px !important;
  }
}

.tooltip1 .tooltiptext-swap{
  left: 5px;
  bottom: 48px;
}

.reservation_phone{
  .media-body{
    span{
      font-family: $az-heading-para !important;
    }
  }
}
// CRM CSS start

.CRMDynamicListGroup{
  border: 1px dashed $az-eage-color ;
  padding:40px 0 20px 0;
  position: relative;
  margin-top: 50px;
}

.btn-cust-delete{
  padding: 0 10px;
  background: none;
  border: 0;
}

.end_or_cond{
  position: relative;
  &::before{
    border-top: 1px dashed $az-gray ;
    position: absolute;
    content: "";
    width: 98%;
    transform: translate(0 , 38px);
    @media (max-width:1526px) {
      width: 97.8%;
    }
    @media (max-width:1500px) {
      width: 98%;
    }

    @media (max-width:1440px) {
      width:97%;
    }

    @media (max-width:991px) {
      width: 96%;
      left: 18px;
    }

    @media (max-width:767px) {
      width: 91%;
    }

  }
}

.group_heading{
  position: absolute;
  top: -32px;
  z-index: 0;
  h3{
    padding: 6px 20px;
    border: 1px dashed $az-eage-color ;
    background: $az-white;
  }
  .btn-cust-delete{
    padding: 7.5px;
    border: 1px dashed $az-eage-color ;
    background: $az-white;
  }
}

.CRMOnboardingStepAttribute{
  // @media (max-width:991px) {
  //   margin: 0 !important;
  //   padding: 0 15px;
  // }
}

// CRM CSS end

.date_tbl_formate{
  white-space: pre !important;
}

#copy_token{
  position: relative;
  .copide_box{
    position: absolute;
    top: -75%;
    left: 50%;
    transform: translate(-50%, -75%);
  }
  .popover-header{
    font-size: $az-font-12;
    font-family: $az-heading-two;
  }
  .popover{
    .arrow{
      left: 50%;
    transform: translate(-70%);
    }
  }
}


.img_preview_cms{
  img{
    max-height: 150px;
    box-shadow: 0px 0px 1px black;
  }
}
.video_preview_cms{
  video{
    max-height: 150px;
    box-shadow: 0px 0px 1px black;
  }
}
input.read_only_bg{
  background: $az-white !important;
}
.chat_ai_b{
  position: absolute;
  bottom: 80px;
  right: 0;
  .btn-chat-ai{
    background: $az-black;
    color: $az-white;
    padding: 5px 10px;
    line-height: normal;
    font-size: 14px;
    min-height: 30px;
  }
  .btn-chat-ai.active{
    opacity: .5;
  }
}


.my-custom-react-select__menu{
  z-index: 99999 !important;
}

.select_all_tbl{
  display: flex;
}

.onbording-pg{
  
  &.scrolling-carousel {
    padding-bottom: 10px;

    .styles-module_sliderBase__swkx1{
      &.swipesection div[data-arrow=right]{
        top: 17px;
        background: none;
        right: -5px;
      }
      &.swipesection div[data-arrow=left]{
        top: 17px;
        background: none;
        left: -5px;
      }
    }
  }

  .viewfile-section span.viewfile {
    width: 100%;
  }

  .image-upload-wrap{
    z-index: 0;
  }

  .btn-cust-small{
    &:focus{
      background-color:$az-eage-color !important;
      border-color:$az-eage-color !important;
      color: $az-black !important;
    }
  }

  .btn-success-outline-small{
    &:focus{
      background-color:$az-white !important;
      border-color:$az-eage-color !important;
      color: $az-black !important;
    }
  }

  .btn-success-outline-small{
    &:disabled{
      &.onboard_file_save_btn{
        min-width: 105px !important;
        max-width: 105px !important;
      }
    }
  }

  .styles-module_sliderBase__swkx1.swipesection {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .nav-item{
      a{
        img{
          opacity: .7;
        }
      }
      a{
        &:hover {
          img{
            opacity: 1;
          }  
        }
      }

      a.active{
        img{
          opacity: 1;
        }

      }          
    }              
  }

  // .menu-onborad.styles-module_sliderBase__swkx1.swipesection {
  //     .styles-module_slider__o0fqa {
  //       background: $az-eage-color;
  //       border: 0;
  //        
  //           a{
  //             background: none;
  //             border-radius: 0 !important;
  //             font-family: $az-heading-para;
  //             color: $az-gray;
  //             font-size: $az-font-16;
  //             padding: 11px 16px;
  //             margin-right: 5px;
  //             border: 0;
  //             transition: .2s;

  //             img{ 
  //               opacity: 0.6;
  //             }
  
  //             @media(max-width: 767px) {
  //               font-size: $az-font-16;
  //             }
  
  //             &:hover {
  //               color: $az-black;
  //               font-family: $az-heading-para;
  //               border: 0;

  //               img{
  //                 opacity: 1;
  //               }
  //             }

  //           }
  //           a.active{
  //             background: none;
  //             border-radius: 0 !important;
  //             font-family: $az-heading-para;
  //             color: $az-black;
  //             font-size: $az-font-16;
  //             position: relative;
  //             border: 0;
  
  //             @media(max-width: 767px) {
  //               font-size: $az-font-16;
  //             }

  //             img{
  //               opacity: 1;
  //             }
  
  //           }
  
  //           &:first-child {
  //           a{
  //             padding: 11px 20px!important;
  //             margin-right: 5px;
  //             border: 0;
  
  //             @media(max-width: 767px) {
  //               font-size: $az-font-16;
  //             }
  //           }
  //           }
  //           &:last-child {
  //           a{
  //             padding: 11px 20px;
  //             margin-right: 0px;
  //             border: 0;
  //           }
  //           }
  //         }
  //     }
  // }

  .brandList.onbord_tbl_file {
    padding: 0 0px 0 20px;
}

  .steps_count{
    display: flex;
    justify-content: space-between;
    place-items: center;
    gap: 10px;
    .step-way{
      background: $az-disbled;
      padding:16px 18px;
      font-size: $az-font-14;
      color: $az-black;
      width: 85%;
      line-height: 14px;
      margin-bottom: 10px;

      &.onboard_stap_way{
        background-color: #f1f1f1;
        font-family: $az-heading-para;
        span{
          display: inline-block;
          min-width: 25px;
        }

        &:hover{
          background-color: $az-black;
          color: $az-white;
        }
      }
    }

    .step-process-info{
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.onboard_stap_process{
        margin-bottom: 15px;
      }
    }
    .step-succes{
      label.form-label{
        color: $az-btn-green;
      }
    }
  }

  .mark_as_complete_box{
    display: flex;
    justify-content: space-between;
    place-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .mark_as_lbox{
      visibility: hidden;
      width: 85%;
      line-height: 14px;
      margin-bottom: 10px;
    }

    .step-process-info{
      width: 15%;
      text-align: center;
      label{
        // color: $az-update-txt-color;
      }
    }
  }

  .onborad_review{
    margin-bottom: 20px;
    h3{
      // font-size: $az-font-18;
      // margin-bottom: 15px;
      // font-family: $az-heading-para-light;
      // text-transform: uppercase;
    }

    p{
      color: $az-black;
      word-wrap: break-word;
    }
  }

  .wizd-btn-fix{
    background-color: $az-white;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    width: calc(100% - 210px);
    position: fixed;
    bottom: 0;
    .wizard-flex{
      display: flex;
      justify-content: space-between;
      place-items: center;
      gap: 10px;
      padding: 20px 30px;
      .wiz__btn_50{
        width:50%;
        // .home-btn{
        //   background-color: $az-btn-green !important;
        //   color: $az-white;
        //   border: 1px solid $az-btn-green !important;
        // }

        // .next-btn{
        //   background-color: $az-eage-color !important;
        //   border: none !important;
        //   color: $az-black !important;

        //   &:hover{
        //     border: 2px solid $az-btn-green !important;
        //     color: $az-white !important;
        //     background: $az-btn-green !important;
        //   }
        // }

        // .back-btn{
        //   border: 2px solid $az-eage-color !important;
        //   color: $az-black;
        //   &:hover, &:focus{
        //     background: $az-btn-green !important;
        //     color: #fff !important;
        //     border: 2px solid $az-btn-green !important;
        //   }
        // }

        &:last-child{
          text-align: end;
          text-align: -webkit-right;
        }
      }
    }
  }

  .onbording_heading{
    font-size: $az-font-16;
    margin-bottom: 15px;
    font-family: $az-heading-para;
  }

  .onbording_heading_two{
    font-family: $az-heading-para;
  }

  .onboarding_amnentity_sec{
    .amenity_list_box{
      .background-head{
        &.onbording_heading{
          margin-top: 25px;
        }
      }
        ul{
          li{
            color: $az-black;
          }
        }

        .amenity_col{
          &:first-child{
            .background-head{
              &.onbording_heading{
                margin-top: 0;
              }
            }
          }
        }
    }
  }

  .onbording_file {
    .onboard_file_list{
      color: $az-black;
      font-size: $az-font-15;
    }

    .brandList {
      .as-react-table{
        z-index: 0;
      }

      .asrt-table-body{
        .col-md-12{
          padding: 0 !important;
        }
      }
    }
  }

  .onbaord_bank_info_link{
    color: #36f;
    text-decoration: underline;
    display: inline-block;
  }

  .onbaord_bank_info , ol {
    padding-left: 17px;

    li{
      color: $az-black;
      font-size: $az-font-14;
    }
  }

  #left-tabs-example-tabpane-BankingInfoTab{
    .onbaord_bank_info{
      .bank_info_link{
        color: #36f;
        text-decoration: underline;
      }
    }
  }

  .lease_card_row{
    .onbord_lease_card{
      border-right: 1px solid #E2CCBF;
      padding-right: 30px;
      height: 100%;
    }
    .col-md-4{
      &:last-child{
        .onbord_lease_card{
          border-right: none;
        }
      }
    }

  }

  .staff_train_list{
    padding-left: 0;

    li{
      list-style: none;
    }
  }

  .CSVImportTab{
    .lease_card_row{
      margin-bottom: 67px;

      .onbord_lease_card{
        border-right: none;
        padding-right: 50px;

        .instuction_title{
          font-family: $az-heading-para;
          color: $az-black;
        }

        // .onbaord_bank_info_link{
        //   color: $az-gray;
        // }

        .step-process-info{
          label{
            color: $az-placeholder;
          }
        }
      }
    }

    .drag-text {
      h5{
        &::before{
          background-image: url('../../assets/images/on_baord_file_upload.svg')!important;
        }
      }
    }

    .dashboardRightcard {
      .title {
        display: block !important;
        left: 60px;
        padding: 0;
        top: 5px;
        background-color: unset;
        width: 350px;
        @media (max-width:1366px) {
          width: 300px;
        }
        strong{
          width: 70%;
          display: block;
          word-wrap: break-word;
          font-weight: 400;
        }
      }

      .onbording_csv_uploadedfile{
        .container {
          .gallery {
            display: flex;
            width: 100%;
            grid-template-columns: inherit;
            grid-gap: inherit;
            flex-direction: row;
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: 15px;
  
            .gallery-item {
              max-width: 70px;
              margin-bottom: 30px;
              min-height: 50px;
              max-height: 50px;
              padding: 0 10px;
              box-shadow: none;
              overflow: inherit;
  
              // .title {
              //   display: block !important;
              //   left: 60px !important;
              //   padding: 0 !important;
              //   top: 5px;
              //   background-color: unset !important;
              // }
  
              .gallery-image{
                transition: none;
                background: transparent;

                &:hover{
                  transform: none;
                }
              }

              .gallery-image-div{
                .button{
                  background: rgba(255, 255, 255, 0.7);
                  width: 15px;
                  height: 15px;
                  line-height: 15px;
                  border-radius: 50%;
                  left: -6px;
                  right: inherit;
                  top: -8px;
                  z-index: 0;

                  span{
                    color: #000000;
                    display: block;
                    font-size: 17px;
                    line-height: unset;
                    text-align: center;
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1520px){
    .mark_as_complete_box{
      .mark_as_lbox{
        width: 80%;
      }

      .step-process-info{
        width: 20%;
      }
    }

    .steps_count {
      .step-way{
        width: 80%;
      }
      .step-process-info{
        width: 20%;
      }
    }

    .CSVImportTab{
      .lease_card_row {
        .onbord_lease_card, #unit_import_csv, #current_leases_csv, #rent_collection_csv {
          padding-right: 0;
        }
      }
    }
  }

  @media (max-width: 1199px){
  .wizd-btn-fix{
    width: 100%;
    left: 0;
  }

  .steps_count {
    .step-way{
      font-size: $az-font-14;
    }
  }

  .onbording_heading{
    font-size: $az-font-16;
  }

  #left-tabs-example-tabpane-FilesTab{
    .brandList {
      padding: 0 0px 0 15px;
    }
  }

  #left-tabs-example-tabpane-ParkingPlanTab{
    .mg-t-20{
      &.pd-l-30{
        &.pd-r-30{
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .CSVImportTab {
    .lease_card_row {
      .onbord_lease_card{
        padding-right: 10px;
      }
    }
  }
}

@media (max-width: 991px){
  .CSVImportTab {
    .lease_card_row {
      margin-bottom: 0;
      .onbord_lease_card{
        margin-top: 15px;
      }
      .onboard_stap_process{
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 767px){
  &.scrolling-carousel{
    .styles-module_sliderBase__swkx1{
      &.swipesection{
        padding-left: 0;
        padding-right: 0;
        div[data-arrow=right]{
          right: -5px !important;
        }
        div[data-arrow=left]{
          left: -5px !important;
        }
      }
    }
  }

  #left-tabs-example-tabpane-InstallKeyLockerTab{
    .mg-t-40, .mg-t-20 {
      &.pd-l-30 {
        &.pd-r-30{
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }

    .mg-t-40{
      &.pd-l-30 {
        &.pd-r-30{
          .col-md-8 {
            &.pd-md-l-35{
              padding: 0;
            }
          }
        }
      }
    }

    .mg-t-20{
      &.pd-l-30 {
        &.pd-r-30{
          .col-md-8 {
            &.pd-md-l-35{
              padding: 0;
            }
          }
        }
      }
    }
  }

  .lease_card_row {
    .onbord_lease_card{
      border-right: none;
      padding-right: 0;
      margin-bottom: 15px;
      height: auto;
      border-bottom: 1px solid #E2CCBF;
    }

    .col-md-4{
      &:last-child{
        .onbord_lease_card{
          border-bottom: none;
        }
      }
    }
  }

  .wizd-btn-fix{
    .wizard-flex{
      padding-left: 15px;
      padding-right: 15px;

      .wiz__btn_50{
        &:first-child{
          display: flex;
        }

        &:last-child{
          .btn-cust-small{
            margin-right: 0;
          }
        }

        .btn-cust-small{
          min-width: 80px;
          max-width: 80px;
        }

        .btn-cust-small-o{
          margin-left: 0 !important;
        }
      }
    }
  }

  .CSVImportTab{
    .lease_card_row{
      .onbord_lease_card{
        border-bottom: none;
      }
    }

    .onboard_stap_process{
      margin-bottom: 15px;
    }
  }
}
} 

.remove-pic{
&.image-error-club{
  &::after{
    bottom: 5px;
  }
}
}

.dashboardRightcard {
.upload {
  .form-group {
    .container {
      .gallery {
        .gallery-item {
          .title-view-pic {
            .title{
              left: 60px;
              width: 350px;
              top: 0;
              padding: 0;
              @media (max-width:1366px) {
                width: 300px;
              }
              strong{
                display: block;
                width: 350px;
                overflow-wrap: break-word;
                font-weight: 400;
                @media (max-width:1366px) {
                  width: 300px;
                }
              }
            }
          }
        }
      }
    }
  }
}
}

#homeTab {
img{
  height: 20px;
}
}

#left-tabs-example-tabpane-FilesTab {
.scrolling-carousel {
  padding-left: 10px;
}
}

#left-tabs-example-tabpane-AmenitiesTab{
.scrolling-carousel {
  padding-left: 0;
}
}

.onbord_file_preview_icon{
width:40px;
height: fit-content;
}

#as-react-datatable{
td.file_count {
  .viewfile-section {
    .viewfile{
      padding: 0 !important;
    }
  }
}
}

.btn-new-cust-o{
  font-family:$az-heading-two;
    background: none;
    color: #000;
    font-size: 14px;
    border-radius: 0;
    font-weight: 500;
    border: 2px solid $az-eage-color;
    padding: 4px 10px;
    min-width: 90px;
    max-width: 90px;
    outline: none;
    display: flex;
    align-items: center;
    outline: none !important;
    box-shadow: none !important;
    align-items: baseline;
    justify-content: center;
    min-height: 33px;
}

.btn-new-cust-o:hover{
    border: 2px solid $az-black;
    box-shadow: none;
    outline: none;
    background-color: $az-black;
    color: #fff;
}

.btn-new-cust{
  font-family:$az-heading-two;
  background-color: $az-eage-color !important;
  color: #000;
  font-size: 14px;
  border-radius: 0;
  font-weight: 500;
  border: 2px solid $az-eage-color !important;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  outline: none !important;
  box-shadow: none !important;
  min-width: 90px;
  max-width: 90px;
  justify-content: center;
  min-height: 33px;
}

.btn-new-cust:hover{
  color: #fff !important;
  background-color: $az-black !important;
  border: 2px solid $az-black !important;
}

.onbording-pg td.file_name .viewfile-section span.viewfile{
  padding: 0 !important;
}

.btn-snap-view.onboard_file_save_btn.btn-cust-small{
    max-width: 100px;
    min-width: 90px;
  span{
    &:first-child{
      display: flex;
    place-items: center;
    place-content: center;
    }
  }
}
.password_popup {
  span{
    position: relative;
    top: 4px;
  }
  i{
    padding-left: 5px;
  }
}
.passwod_input{
  position: relative;
  .input-group-append{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    span{
      cursor: pointer;
      img{
        width: 18px;
      }
    }
  }
}

.cred_form {
  .passwod_input{
    margin-right: 60px;
    @media (max-width:1280px) {
      margin-right: 30px;
    }
  }
}

.remove-read-only input:read-only {
  background-color: #fff !important;
}


.modal .modal-dialog .modal-content button.btn-success.btn-pass{
  max-width: 160px;
  margin: 20px auto 0 !important;
}

.pass-show{
  top: 0 !important;
}

.word_no_wrap{
  a{
    word-break: keep-all;
  }
}

.pass-model{
  .passwod_input{
    .input-group-append {
      span {
        line-height: initial !important;
        .fa {
          color: $az-black;
        }
      }
    }
  }
}

.password-button-submit{
  color: #ffffff !important;
  font-weight: normal;
  outline: none !important;
  border: 2px solid #2c5046 !important;
  background: #2c5046 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.text-black{
  color: $az-black;
}