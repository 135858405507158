/* ###### Global Fonts ###### */

@mixin headingBold {
        font-family: $az-heading-one;
        src: url(../fonts/OrpheusPro-Bold.woff);
}

/* ###### Global Lato Fonts ###### */

@mixin headingLight {
        font-family: $az-heading-light;
        src: url(../fonts/Lato-Light.woff);
}

@mixin headingRg {
        font-family: $az-heading-two;
        src: url(../fonts/Lato-Regular.woff);
 }


@mixin headingMd {
        font-family: $az-heading-para;
        src: url(../fonts/Lato-Medium-Bold.woff);
}

@mixin headingRgbd {
        font-family: $az-heading-para-light;
        src: url(../fonts/Lato-Dark-Bold.woff);
}