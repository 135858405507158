.brandList {
    position: relative;
    padding: 0 0px 0 32px;
    .asrt-table-foot{
        .col-md-6 {
        &:first-child {
        position: absolute;
        bottom: 25px !important;
        font-size: $az-font-14;
        font-family: $az-heading-two;
        letter-spacing: 0.22px;
        color: $az-gray;
        max-width: max-content;

        @media(max-width:767px){
            text-align: center;
            margin-bottom: 0px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        }   
        &:last-child {
          position: absolute;
          max-width: max-content;
          right: 500px;
          transform: translate(0%, -150%) !important;
          border-right: 1px solid $az-border-color;
          bottom: inherit !important;

          @media (max-width:1366px){

            right: 500px;
            transform: translate(0%, -150%) !important;

          }

          @media (max-width:1280px){

            right: 500px;
            transform: translate(0%, -150%) !important;

          }

          @media (max-width:1199px){

            right: 500px;
            transform: translate(0%, -150%) !important;

          }

          @media (max-width:991px){

            right: 525px;
            transform: translate(0%, -150%) !important;

          }
          @media (max-width:767px){

            left: 50%;
            transform: translate(-50%, -50%) !important;
            bottom: 80px !important;
            border-right: 0;

          }

            .pagination {
                li{
                    a { background: none; }
                    &:first-child {
                        padding-right: 13px;
                    }
                    &:last-child {
                        padding-left: 13px;
                        padding-right: 0px;
                    }
                }
                li.active
                a {
                   border: 2px solid $az-black;
                   width: 30px;
                   height: 30px;
                   text-align: center;
                   line-height: 30px;
                   color: $az-black !important;
                   border-radius: 50%;
                   min-width: 30px;
                }
               
                @media(max-width:767px){
                   justify-content: center !important;
                   flex-wrap: wrap !important;
                }

                .page-item.disabled {.page-link {
                    color: $az-gray;
                 }
              }
            }
        }  
      }
     
    }
    .asrt-table-head {
    .table_filter {
        position: relative !important;
        width: 313px !important;
        margin-right: 30px !important;
        // display: none !important;

        @media (max-width:767px){
            width: 100% !important;
        }

          input[type="search"] {
            padding-left: 35px;
            border: none !important;
            border-bottom: 1px solid $az-black !important;
            border-radius: 0 !important;
            background-color: transparent !important;
            outline-offset: 3px;
            -webkit-appearance: none;

          }
          &:before{
            position: absolute;
            left: 10px;
            background: url(./../../images/Search.svg);
            background-repeat: no-repeat;
            content: '';
            width: 18px;
            height: 18px;
            z-index: 0;
            top: 13px;
            background-size: 100% 100%;
       }
      }
      .asrt-page-length {
          .input-group-addon { 
            background: none !important;
              .input-group-text {
              color: $az-gray;
              font-size: $az-font-14;
              letter-spacing: 0.22px;
              border-color: $az-form-input-bdr !important;
              }
            }
          select {
              width: auto !important;
              -webkit-appearance: none;
              -moz-appearance: none;
              border-radius: 2px !important;
              text-align: center;
              background: none;
              height: 35px !important;

              option:checked {
                background: $az-black;
                color: $az-white;
               }
               option:hover {
                background: $az-black;
                color: $az-white;
               }
               option:selected {
                background: $az-black;
                color: $az-white;
               }
            }
            select.decorated option:hover {
              box-shadow: 0 0 10px 100px #1882A8 inset;
          }

           
           

      }
   }
   .asrt-table-body {

    @media (max-width:1366px){
      overflow: auto;
      width: 100%;
     }

      @media (max-width:1199px){
        overflow: auto;
        width: 100%;
       }
       @media (max-width:991px){
        overflow: auto;
        width: 100%;
       }
       @media (max-width:767px){
        overflow: auto;
        width: 100%;
       }
      
  @include tableformate;

  th {
    @media (max-width:767px){
          padding: 16px 34px 16px 5px !important;
      }
    
  }
   }
   .as-react-table{

    @media (max-width:1199px){
       padding: 0 15px;
       }
       @media (max-width:991px){
       padding: 0 15px;
       }
       @media (max-width:767px){
       padding: 0 0 0 0px;
       }
       @media (max-width:360px){
        padding: 0 0 0 0px;
        }
       .asrt-table-head {

        @media (max-width:991px){
          margin-right: 0px !important;
          }
          @media (max-width:767px){
            margin-right: 0px !important;
          }

          .table_filter {

            @media (max-width:991px){
              margin-right: 0px !important;
              }
              @media (max-width:767px){
                margin-right: 0px !important;
              }
          }
       }
   }

   @media (max-width:991px) {
      padding: 0;
   }

  @media (max-width:767px) {
     padding: 0;
  }
  .scrolling-carousel {
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
    padding-right: 30px;
  p {
    font-size: $az-font-14;
    font-family: $az-heading-two;
    margin-bottom: 0;
  }
}

.reservationTable {
  tbody {
    td {
      &:last-child {
        text-align: left !important;
        padding-left: 10px;
      }
    }
  }
}
}

.baradForm { 
    .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid $az-border-color;
        margin-bottom: 0;
        input[type=file] {
            border: none;
           padding-left: 0;
           padding-right: 0;
           background: none !important;
        }
        .col-form-label {
            margin-bottom: 0;
            padding-left: 0;
        }
        &:last-child { border-bottom: none;}

        @include fileUploadeditpage;
    }
}
.brandSection {
    position: relative;

    .order_form{
        position: relative;
        left: 0;
        width: 100%;
        background: none;
        margin: 0 auto 10px;
        z-index: 1;
        border-radius: 8px;
        padding: 15px 30px;
        top: 20px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;

        .btn-success {
            @include common-green-btn-small;
          }
          .btn-success-outline {
            @include btn-success-outline-small;
          }

          input {
              border-bottom: 1px solid $az-black;
              border-left:none;
              border-right:none;
              border-top:none;
              border-radius: none !important;
              background: none !important;
          }
         
          @media (max-width:767px) {
                top: 5px;
                padding:  0;
           }

    }

    .form-label {
      color: $az-gray;
      font-family: $az-heading-two;
      font-size: 14px;
      font-weight: normal;
      text-transform: inherit;
      margin-bottom: 0px;
  
      .optional {
  
        @include optional;
      }
  
  
  
      @media (max-width: 1366px) {
        font-size: $az-font-13;
      }
  
      @media (max-width: 1280px) {
        font-size: $az-font-13;
      }
  
      @media (max-width:991px){
        text-align: left;
      }
      @media (max-width:767px){
        text-align: left;
      }
    }
}

.dashbaord-pageHeadertitle {  
    @include pageHeadertitle;

    .bothBtn{
        .btn-success-outline {
            @media(max-width:767px){
                min-width: 95px;
                max-width: 95px;
                margin-right: 0px;
              }
          }
          .btn-danger-outline {
            @media(max-width:767px){
                min-width: 95px;
                max-width: 95px;
                margin-right: 10px;
              }
          }

    } 
    .form-group {
        @media(max-width:767px){
           margin: 0;
        }
        label{
            text-align: right;
            color: $az-gray;
            @media(max-width:767px){
                text-align: left;
            }
        }
    }
    
}

.page-item.disabled .page-link {
    color: $az-light-gray;
}

.brandtable {
        td.status_id {
          b {
            @include inactivestatus;
            margin-left: 0px !important;
          }
          b.active {
            @include activestatus;
            margin-left: 0px !important;
          }
        }
    }


.rolepermission {
 font-family: $az-heading-two; 
}

.syncTable {
  border: 1px solid $az-border-color;
  th, td {
    padding:7px 16px !important;
    border-right: 1px solid $az-border-color;
    &:last-child {
      border-right: 0;
    }
  }
}

.querySupporttable{
  td{
    &:nth-child(5){
      max-width: 300px;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
}


.neighbor_des{
  td{
    &:nth-child(3){
      max-width: 200px;
      word-break: break-word;
      white-space: pre-wrap;
    }
    &:nth-child(4){
      max-width: 300px;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
}
