.file-upload {
    background-color: #ffffff;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.file-upload-btn {
    width: 100%;
    margin: 0;
    color: #fff;
    background: #b2901f;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #29d6e2;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.file-upload-btn:hover {
    background: #1AA059;
    color: #ffffff;
    transition: all .2s ease;
    cursor: pointer;
}

.file-upload-btn:active {
    border: 0;
    transition: all .2s ease;
}

.file-upload-content {
    display: show;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100% !important;
    height: 100% !important;
    outline: none;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.image-upload-wrap {
    margin-top: 20px;
    border: 2px dashed #a9a9a9;
    position: relative;
    background-color: $az-white;
    border-radius: 5px;
    width: 200px;
    height: 200px;
    margin: auto;
}

// .image-dropping,
// .image-upload-wrap:hover {
//     background-color: $az-light-gray;
//     border: 1px solid $az-border-color;
//     opacity: 0.8;
// }

.image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #222;
}

input.form-range{
   margin: 0;
}
.reactEasyCrop_Container {
    .reactEasyCrop_Image {
        width: 100% !important;
    }
}
// .cropperImg{
//     img{
//         width: unset !important;
//     }
// }
textarea.crop-text { 
    border: 1px solid $az-border-color  !important;
    color: $az-black !important;
    font-size: $az-font-14 !important;
    font-family: $az-heading-two !important;
    border-radius: 2px !important;

    &:focus, &:hover {

        border: 1px solid $az-border-color !important;
        color: $az-black !important;
    }
}

.drag-text {
    text-align: center;

    
    h5 {
        font-weight: 100;
        text-transform:capitalize;
        color: $az-gray;
        padding: 117px 0 78px;
        position: relative;
        margin-bottom: 0;
        font-size: $az-font-14;

        &::after {
            position: absolute;
            content: '';
            color: $az-black;
            bottom: 30%;
            transform: translate(-50%, -50%);
            left: 50%;
            font-size: 14px;
            text-transform: inherit;
                } 
        &::before {
            position: absolute;
            content: '';
            top: 40%;
            background-image: url(../../assets/images/add-img.png);
            background-repeat: no-repeat;
            height: 36px;
            background-size: 100% 100%;
            width: 36px;
            left: 50%;
            transform: translate(-50%, -65%);
        } 
       
    }
}


.file-upload-image {
    max-height: 200px;
    max-width: 200px;
    margin: auto;
    padding: 20px;
}

.remove-image {
    width: 200px;
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 4px;
    border-bottom: 4px solid #b02818;
    transition: all .2s ease;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
}

.remove-image:hover {
    background: #c13b2a;
    color: #ffffff;
    transition: all .2s ease;
    cursor: pointer;
}

.remove-image:active {
    border: 0;
    transition: all .2s ease;
}

.shadow-box:hover {
    transition: all .2s ease;
    box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
}

.gallery-item {
    /* Minimum width of 24rem and grow to fit available space */
    flex: 1 0 24rem;
    /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
    margin: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

.gallery-image {
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    transition: transform 400ms ease-out;
}

.gallery-image:hover {
    transform: scale(1.15);
}

.list-group-flush {
    .list-group-item {
        img {
            border-radius: 50%;
            border:2px solid $az-border-color;
            object-fit: cover;
            object-position: top;
            height: 32px;
            // width: 32px;
        }
        button{
            padding: 5px 0px;
            img { border: none; border-radius: 0;
                height: 16px;
                width: 16px;
            }
        }
        span {
            .progress{
                background: white;
                .progress-bar {
                    background: $az-black;
                    height: 15px;
                }
            }

        }
    }
}
.reactEasyCrop_CropArea {
    color: rgba(255, 255, 255, 0.7) !important;
}
