.modal {
    @include modalbox;
    @include activetoggle;
}

.demo-static-toast-success {
    width: 300px;
    top: 73px;
    .toast-header{
        background: $az-active-status;
        padding: 8px 10px;
        border-radius: 4px 4px 0px 0px;
        h6 {
            color: $az-white;
            font-size: $az-font-16;
            i{
                font-size: $az-font-18;
            }
        }
    }
    .toast-body {
        background: $az-active-status-bg;
    }
}

.demo-static-toast-danger {
    width: 300px;
    top: 73px;
    .toast-header{
        background: $az-inactive-status-bg;
        padding: 8px 10px;
        border-radius: 4px 4px 0px 0px;
        h6 {
            color: $az-white;
            font-size: $az-font-16;
            i{
                font-size: $az-font-18;
            }
        }
    }
    .toast-body {
        background: $az-inactive-status-bg;
    }
}

.infomodal {
    .modal-dialog {
        max-width: 651px;
     .modal-content {
        .media-body {

         }
     }
    }
  }

.propertylistmodal {

    .modal-dialog {
        // max-width: 1024px;
        // max-height: 100%;
        max-width: 1024px;
        // max-width: inherit;
        justify-content: center;
        min-height: calc(100% - 1rem);

        @media (max-width:1366px) {

            // max-width: 850px;
            max-width: inherit;
             min-height: calc(100% - 1rem);
        }
        @media (max-width:1280px) {

            // max-width: 850px;
            max-width: inherit;
             min-height: calc(100% - 1rem);

        }
        @media (max-width:1199px) {

            // max-width: 600px;
            max-width: inherit;
             min-height: calc(100% - 1rem);

        }
        @media (max-width:991px) {

            // max-width: 500px;
            max-width: inherit;
             min-height: calc(100% - 1rem);

        }
        @media (max-width:767px) {

            // max-width: 80%;
            max-width: inherit;
             min-height: calc(100% - 1rem);

        }

        .modal-content {
            border-radius: 0px;
            background: none !important;
            width: auto;
            .modal-body {
                padding: 0 !important;
                background: none !important;
                .welcomeClose{
                    position: fixed;
                    right: 25px;
                    top: 20px;
                    z-index: 1;
                    @media (max-width:767px) {
                      right: 15px;
                      top: 12px;
                    }
                    img {
                        cursor: pointer;
                        width: 24px;
                      @media (max-width:767px) {
                          width: 24px;
                      }
                    }
                  }
                .carousel {
                    position: relative;
                    height: fit-content;
                    .carousel-inner {
                        border-radius: 8px;
                        background: transparent !important;
                        overflow: inherit;
                        width: auto !important;
                        justify-content: center;
                        .carousel-item{
                        //     height: fit-content;
                        //     width: 1024px !important;
                        //    transition: transform 0.6s ease-in-out !important;
                            img{
                                margin-bottom: 0;
                                opacity: 1;
                                border-radius: 0;
                                // border-radius: 8px 0 0;
                                text-align: left;
                                // width: 100% !important;
                                // height: 100%;
                                // width: 1024px !important;
                                // height: 683px !important;
                                // max-height: 768px !important;
                                // object-fit: contain;

                                text-align: left;
                                max-height: 512px !important;
                                width: auto !important;
                                margin: auto !important;
                                background: #fff;
                            }

                            .carousel-caption {
                                // position: relative;
                                // left: 0;
                                // right: 0;
                                // bottom: 0;
                                // padding: 18px 15px;
                                // background: $az-light-gray;
                                // border-radius: 0;

                                width: 100%;
                                left: 0;
                                bottom: 0;
                                padding: 15px;
                                background: $az-white;
                                // overflow: hidden;
                                box-shadow: 0px 3px 5px #000;
                                // position: relative;
                                position:absolute;
                                p{
                                    font-size: $az-font-14;
                                    letter-spacing: 0.32px;
                                    font-family: $az-heading-para;
                                    color: $az-black;
                                    font-weight: normal;
                                    line-height: 20px;
                                    margin-bottom: 0;
                                    text-align: center;
                                }
                            }
                            .captionImg {
                                margin: 0;
                            }
                        }
                        // .carousel-item.active {

                        //     display: flex !important;
                        //     align-items: center;
                        //     justify-content: center;
                        //     align-items: center !important;
                        //     margin: auto !important;
                        //     transform: translate( inherit) !important;
                        //     width: auto !important;
                        // }
                    }
                    .carousel-control-prev{
                        left: 10%;
                        position: fixed;
                        display: inline-table;
                        width: auto;
                        height: auto;
                        top: 50%;
                        transform: translate(0, -50%);
                        .carousel-control-prev-icon {
                            background-image:url(../../images/left-slide-arrow.svg);
                            width: 35px;
                            height: 35px;
                        }

                        @media (max-width:767px) {
                              left: 3%;
                        }

                    }
                    .carousel-control-next {
                        right: 10%;
                        position: fixed;
                        display: inline-table;
                        width: auto;
                        height: auto;
                        top: 50%;
                        transform: translate(0, -50%);
                        .carousel-control-next-icon{
                            background-image:url(../../images/right-slide-arrow.svg);
                            width: 35px;
                            height: 35px;
                        }

                        @media (max-width:767px) {
                            right: 3%;
                      }

                    }
                    .carousel-indicators {
                        display: none;
                    }
                }
            }
        }
    }
}



.singlemodal {

    @include tumbImagemodal;

    .modal-dialog {
        max-width: 512px;
        @media (max-width:1366px) {

             max-width: 512px;
       }
       @media (max-width:1280px) {

                max-width: 512px;

       }
       @media (max-width:1199px) {

                max-width: 512px;

       }
       @media (max-width:991px) {

                max-width: 512px;

       }
       @media (max-width:767px) {

           max-width: 70%;

       }
    }
}

.propsinglemodal {

    @include tumbImagemodal;

    .modal-dialog {
        max-width: 1024px;
        @media (max-width:1366px) {

             max-width: 1024px;
       }
       @media (max-width:1280px) {

            max-width: 1024px;

       }
       @media (max-width:1199px) {

            max-width: 1024px;

       }
       @media (max-width:991px) {

            max-width: 512px;

       }
       @media (max-width:767px) {

           max-width: 70%;

       }
    }
}

.cropModal{
    .modal-dialog {
        max-width: 950px;

        .modal-header h2{
            font-family: $az-heading-para;
            font-size: $az-font-18;
        }
        .modal-body {
            display: flex;
            flex-wrap: wrap;

            figure {
                margin: 0;
                max-width: 100%;
                max-height: 384px;
                min-height: 384px;
                max-width: 512px;
                min-width: 512px;
                border-radius: 8px;
                overflow: hidden;
                position: relative;
                outline: 1px solid #72818e33;
                background: $az-light-white;
                text-align: center;

                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-width: 100%;
                    max-height: 384px;
                    // min-height: 384px;
                    width: auto !important;
                    margin-bottom: 0px !important;
                }

                @media(max-width:991px) {
                    max-width: 100%;
                    min-width: 100%;
                }
                @media(max-width:767px) {
                    max-width: 100%;
                    min-width: 100%;
                }
            }

            .nav-pills {
                display: flex;
                justify-content: start;
                border-bottom: 1px solid $az-border-color;
                margin-bottom: 30px;
                .nav-item {
                    font-family: $az-heading-para;
                    font-size: $az-font-16;
                    color: $az-black;

                    .nav-link.active {
                        background: none;
                        color: $az-black!important;
                        font-family: $az-heading-para;
                        padding-left: 0;
                        padding-right: 10px;
                        position: relative;
                        margin-right: 15px;
                        border-bottom: 2px solid transparent;
                        border-radius: 0;

                        &::after {
                            border-bottom: 2px solid $az-eage-color;
                            width: 100%;
                            height: 1px;
                            content: '';
                            position: absolute;
                            bottom: -3px;
                            left: 50%;
                            text-align: center;
                            transform: translate(-50%, 0%);
                        }

                        &:hover {

                            color: $az-black;

                            &::after {
                                border-bottom: 2px solid $az-eage-color;
                                width: 100%;
                                height: 1px;
                                content: '';
                                position: absolute;
                                bottom: -3px;
                                left: 50%;
                                text-align: center;
                                transform: translate(-50%, 0%);
                            }

                        }
                    }
                    .nav-link {
                        padding-left: 0;
                        padding-right: 10px;
                        margin-right: 15px;
                        color: $az-gray !important;
                        position: relative;
                        border-bottom: 2px solid transparent;
                        border-radius: 0;
                        &:hover {

                            border-bottom: 2px solid transparent;
                            color: $az-black;

                            &::after {
                                border-bottom: 2px solid $az-eage-color;
                                width: 100%;
                                height: 1px;
                                content: '';
                                position: absolute;
                                bottom: -3px;
                                left: 50%;
                                text-align: center;
                                transform: translate(-50%, 0%);
                            }

                        }
                    }

                }

            }

            .tab-content {

                label { color: $az-gray !important;}


                .controls {
                     position: relative;
                     align-items: flex-start;
                    .MuiSlider-colorPrimary {
                        color: $az-black;
                        width: 98%;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.recordDetailmodalbox {
    .msg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding-right: 15px;
        P {
            text-align: center;
            font-family: $az-font-14;
            font-family: $az-heading-two;
            color: $az-black;
        }
    }
    .moreMsg {
        max-height: 400px;
        // min-height: 400px;
        overflow-y: auto;

        scrollbar-width: 5px;
        scrollbar-color: $az-eage-color #e9e9e9;
        -webkit-scrollbar-width: 5px;
        -moz-scrollbar-width: 5px;

        P {
            text-align: left;
            font-family: $az-font-14;
            font-family: $az-heading-two;
            color: $az-black;
            padding-right: 0px;
        }
    }
    
    .moreMsg::-webkit-scrollbar {
        width: 5px;
        height: 7px;
      }
      .moreMsg::-webkit-scrollbar-track {
        background-clip: content-box;
        border: 0px solid transparent;
        width: 5px;
        background-color: #e9e9e9;
      }
      .moreMsg::-webkit-scrollbar-thumb {
        background-color: $az-eage-color;
      }
      .moreMsg::-webkit-scrollbar-thumb:hover {
        background-color: $az-eage-color;
      }
    
      .moreMsg::-webkit-scrollbar-corner,
      .moreMsg::-webkit-scrollbar-track
      {
        background-color: #e9e9e9;
        width: 5px;
      }
      [class^="message-container"] {
        /* height: 200px; */
        overflow-y: scroll;
        width: 5px;
      }
      [class^="message-container"] div {
        /* height: 400px;
      width: 200%;
      padding: 4rem;
      background-image: linear-gradient(130deg, #c8c8c8 0%, #fefefe 100%); */
      }
      
    .modal-body {
        padding: 30px 0px 10px 15px !important;
    }
}

