/* ###### 4.4 Forms   ###### */

/* Checkbox */
.ckbox {
  font-weight: normal;
  position: relative;
  line-height: 1;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  label {
    padding-left: 10px;
    color: $az-gray;
    font-size: $az-font-14;
    font-family: $az-heading-two !important;
    margin-top: 1px;
    text-transform: inherit !important;
    cursor: pointer;

    &:empty { float: left; }

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: '';
      width: $ckbox-w-size;
      height: $ckbox-w-size;
      background-color: #fff;
      border: 1px solid $checkbox-border;
      top: -1px;
      left: 0;
      border-radius: 2px;
    }

    &:after {
      top: -1px;
      left: 0;
      width: $ckbox-w-size;
      height: $ckbox-h-size;
      content: '';
      // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      background-image: url("../../images/c-tick.svg");
      background-size: 65%;
      background-repeat: no-repeat;
      background-position: 55% 45%;
      background-color: $az-white;
      line-height: 17px;
      display: none;
      border-radius: 2px;
      border: 1px solid $checkbox-border;
    }

    &:empty {
      padding-left: 0;
      width: 3px;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    margin: 0;
    width: 16px !important;
    &:checked {
      + label:after { display: block; }
    }

    &[disabled] {
      + label,
      + label:before,
      + label:after {
        opacity: .75;
      }
    }
  }
}

.ckbox-inline { display: inline-block; }

/* Radio Box */
.rdiobox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;

  label {
    padding-left: 5px;

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: '';
      width: $rdiobox-size;
      height: $rdiobox-size;
      background-color: $az-white;
      border: 1.5px solid $checkbox-border;
      border-radius: 50px;
      top: 0px;
      left: 0;
    }

    &:after {
      content: '';
      width: $rdiobox-size - 10px;
      height: $rdiobox-size - 10px;
      background-color: $az-white;
      border-radius: 50px;
      top: 5px;
      left: 5px;
      display: none;
    }

    &:empty {
      padding-left: 0;
      width: 16px;
      display: block;
    }
  }

  input[type='radio'] {
    opacity: 0;
    margin: 0;

    &:checked {
      + label:before {
        border-color: transparent;
        background-color: $az-black;
      }
      + label:after { display: block; }
    }

    &[disabled] {
      + label,
      + label:before,
      + label:after {
        opacity: .75;
      }
    }
  }
}

.rdiobox-inline { display: inline-block; }

.form-group-rdiobox {
  display: flex;
  align-items: center;

  .rdiobox {
    margin-bottom: 0;

    + .rdiobox { margin-left: 30px; }

    span { padding-left: 0; }
  }
}

.az-toggle {
  width: 90px !important;
  height: 26px;
  background-color: $az-inactive-status-bg;
  padding: 2px;
  position: relative;
  overflow: hidden;
  border-radius: 50px;

  span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    width: 20px;
    background-color: $az-white;
    cursor: pointer;
    border-radius: 50%;
    @include transition($transition-base);

    &::before,
    &::after {
      position: absolute;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: .03em;
      text-transform: uppercase;
      color: $az-white;
      top: 3px;
      line-height: 1.38;
    }

    &::before {
      content: 'ACTIVE';
      left: -50px;
    }

    &::after {
      content: 'INACTIVE';
      right: -60px;
    }
  }

  &.on {
    background-color: $az-active-status-bg;

    span { left: 66px; }
  }
}

.az-toggle-secondary.on { background-color: $az-purple; }
.az-toggle-success.on { background-color: $green; }
.az-toggle-dark.on { background-color: $gray-900; }


.az-form-group {
  padding: 12px 15px;
  border: 1px solid $input-border-color;

  &.focus {
    border-color: $gray-400;
    box-shadow: 0 0 0 2px rgba($az-color-primary, .16);
  }

  .form-label {
    font-size: $font-size-sm;
    margin-bottom: 2px;
    font-weight: 400;
    color: $gray-500;
  }

  .form-control {
    padding: 0;
    border-width: 0;
    height: 25px;
    color: $gray-900;
    font-weight: 500;

    &:focus { box-shadow: none; }
  }
}

input {
  @include inputfield;
  &::placeholder {
    color: $az-placeholder !important;

  }
}
textarea {
  // @include inputfield;
  &::placeholder {
    color: $az-placeholder !important;
  }
  color: $az-black !important;
  outline: none; 
  border: 1px solid $az-form-input-bdr !important;
  &:hover, &:focus{
  outline: none; 
  border: 1px solid $az-form-input-bdr !important;
  }
}
textarea.form-control {
  min-height:42px !important;
  color: $az-black;
  &:hover, &:focus {
     border: 1px solid $az-form-input-bdr;
    font-family: $az-heading-two;
    font-size: $az-font-14;
  }
}
input[type=radio] {
  height: 20px !important;
  width: 0px !important;
}
input:read-only{
  // background: $az-white !important;
  background: #e6e6e6 !important;
}

select:disabled { 
  background: $az-light-gray !important;
}

select {
  @include selectfield;
 
}
select[multiple]{
   background-image: none;
   padding: 0.375rem 0.75rem;
}
input[type=file] {
  background: none !important;
  border: none !important;
  padding: 0;
}

input[type=file]::-ms-browse {
  border: none;
  padding: 10px 15px;
  border-radius: .2em;
  background-color: #e3e7ed;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  font-family: $az-heading-two;
  font-size: $az-font-14;
}

input[type=file]::-webkit-file-upload-button {
  border: none;
  padding: 10px 15px;
  border-radius: .2em;
  background-color: #e3e7ed;
  transition: 1s;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  font-family: $az-heading-two;
  font-size: $az-font-14;
}

input[type=file]::file-selector-button {
  border: none;
  padding: 10px 15px;
  border-radius: .2em;
  background-color: #e3e7ed;
  transition: 1s;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  font-family: $az-heading-two;
  font-size: $az-font-14;
}

input[type=file]::-ms-browse:hover {
  background-color: #e3e7ed;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  font-family: $az-heading-two;
  font-size: $az-font-14;
}

input[type=file]::-webkit-file-upload-button:hover {
  background-color: #e3e7ed;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  font-family: $az-heading-two;
  font-size: $az-font-14;
}

input[type=file]::file-selector-button:hover {
  background-color: #e3e7ed;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
  font-family: $az-heading-two;
  font-size: $az-font-14;
}


input[name='year_id'] {

   border: 1px solid $az-border-color !important;
 
}
input#react-select-2-input, input#react-select-3-input, input#react-select-4-input, input#react-select-7-input {
   border: none !important;
   height: 28px !important;
   width: 50px ;
}
.css-1okebmr-indicatorSeparator {
  background: none;
  width: 0 !important;
}

.css-yk16xz-control {
    position: relative;
    border: 1px solid $az-form-input-bdr !important;
    background: $az-white !important;
    border-radius: 4px;
    min-height: 42px !important;

    &:after { 
      position: absolute;
      top: 13px;
      background-image: url(../../images/down-a.svg);
      content: '';
      right: 15px;
      width: 14px;
      height: 15px;
      background-size: 100%;
  }
  input {
    border: none !important;
    height: auto !important;
  }
  input:focus {
    border: none !important;
    outline: none !important;
  }
    .css-1wy0on6 {
      display: none;
    }
    .css-12jo7m5 {
      font-family: $az-heading-two;
      font-size: $az-font-12;
      color: $az-black;
    }
    .css-1rhbuit-multiValue {
      background: $az-light-gray;
      font-family: $az-heading-two !important;
      font-size: $az-font-12 !important;
      color: $az-black !important;
    }
    .css-xb97g8{
    &:hover {
      background-color: #cc3b3b36;
      color: $az-inactive-status-bg;
    }
    }
    
   }
   .css-1rhbuit-multiValue {
    background: $az-light-gray;
    font-family: $az-heading-two !important;
    font-size: $az-font-14 !important;
    color: $az-black !important;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
    height: auto;
    background: #fff;
    width: 35px;
    svg.css-6q0nyr-Svg { 
      // display: none;  
    }
    
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border: 1px solid $az-border-color !important;
  min-height: 42px !important;
  &:hover {
    box-shadow: none !important;
    border: 1px solid $az-border-color !important;
  }
  input {
    border: none !important;
    height: auto !important;
  }

  .css-tlfecz-indicatorContainer {
    display: none;
    svg { 
      display: none;
    }

    &:last-child {
      padding: 8px 15px;
      svg { display: none !important;}
    }
  }

  &:after { 
    position: absolute;
    top: 14px;
    background-image: url(../../images/down-a.svg);
    content: '';
    right: 15px;
    width: 14px;
    height: 15px;
    background-size: 100%;
}
}

.css-b8ldur-Input {
  margin: 0 !important;
  padding: 0 !important;
}
.css-g1d714-ValueContainer {
  padding: 0 10px !important;
}


input[name='start_date'], input[name='end_date'] {
  height: 42px !important;
  border: 1px solid $az-border-color !important;

}


.input-group {
  .react-datepicker-wrapper {
    height: 42px;
    padding: 0;
    border: 1px solid $az-border-color !important;
  }
}

.multiselect {
  color: $az-black !important;
  background-color: #fff !important;
  input {
    border: none !important;
    outline: none !important;
    min-height: 30px !important;
    max-height: 30px !important;
  }
  input:focus {
    border: none !important;
    outline: none !important;
  }
  .css-1n7v3ny-option{
    background-color: $az-black;
    font-family: $az-heading-two !important;
    color: $az-white !important;
    font-size: $az-font-14 !important;

    &:hover {
      background-color: $az-black;
      font-family: $az-heading-two !important;
      color: $az-white !important;
      font-size: $az-font-14 !important;
    }
    &:focus {
      background-color: $az-black;
      font-family: $az-heading-two !important;
      color: $az-white !important;
      font-size: $az-font-14 !important;
    }
    &:active {
      background-color: $az-black;
      font-family: $az-heading-two !important;
      color: $az-white !important;
      font-size: $az-font-14 !important;
    }
    &:visited {
      background-color: $az-black;
      font-family: $az-heading-two !important;
      color: $az-white !important;
      font-size: $az-font-14 !important;
    }
  }
  // .css-1n7v3ny-option[selected] {
  //   background-color: $az-black !important;
  //   color: $az-white !important;
  // }

  .css-1n7v3ny-option[selected] {
    background-color: black !important;
    color: $az-black !important;
  }

  .css-1r5gbi5-menu{
    z-index: 2;
  }

  .css-cj7im2-control {
    min-height: 42px !important;
    height: auto !important;
    border: 1px solid $az-border-color !important; outline: none !important; box-shadow: none !important;
    position: relative;
    &:hover { border: 1px solid $az-border-color !important; outline: none !important; box-shadow: none !important;}
    &:focus { border: 1px solid $az-border-color !important; outline: none !important; box-shadow: none !important;}
    &:active { border: 1px solid $az-border-color !important; outline: none !important; box-shadow: none !important; }

    &:after { 
      position: absolute;
      top: 14px;
      background-image: url(../../images/down-a.svg);
      content: '';
      right: 15px;
      width: 14px;
      height: 15px;
      background-size: 100%;
  }

  }
  
  .css-1gtu0rj-indicatorContainer { display: none !important;}
  .css-tlfecz-indicatorContainer { 
    // display: none !important;
    &:last-child {
      padding: 8px 15px;
      svg { display: none !important;}
    }
  }


  .css-qg19kw-control {

    // min-height: 42px !important;
    // height: auto !important;
    border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important;
    position: relative;
    &:hover { border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important;}
    &:focus { border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important;}
    &:active { border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important; }

    &:after { 
      position: absolute;
      top: 14px;
      background-image: url(../../images/down-a.svg);
      content: '';
      right: 10px;
      width: 14px;
      height: 15px;
      background-size: 100%;
     }

     .css-1hb7zxy-IndicatorsContainer {
       
      .css-tlfecz-indicatorContainer {
        &:last-child {
          padding: 8px 15px;
          svg { display: none !important;}
        }
        
      }

     }
  }

  // .css-1r5gbi5-menu {
  //   text-align: left !important;
  //   position: absolute;
  // }
  .css-1bd6tu-control{

    // min-height: 42px !important;
    // height: auto !important;
    border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important;
    position: relative;
    &:hover { border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important;}
    &:focus { border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important;}
    &:active { border: 1px solid $az-form-input-bdr !important; outline: none !important; box-shadow: none !important; }

    &:after { 
      position: absolute;
      top: 14px;
      background-image: url(../../images/down-a.svg);
      content: '';
      right: 10px;
      width: 14px;
      height: 15px;
      background-size: 100%;
     }

  }
}

.single_select {

    .css-2b097c-container {
      .css-yk16xz-control {
        .css-1hb7zxy-IndicatorsContainer {
          background: #fff !important;
          &:hover {   background-color: $az-black !important;}
        }
      }
    }
}



.form-control.custom-disable {
  background-color: $az-light-gray !important;
  color: $az-black !important;
}

// .css-cj7im2-control:hover { border: none !important; outline: none !important; box-shadow: none;}
// .css-cj7im2-control:focus { border: none !important; outline: none !important; box-shadow: none;}

// .e-dropdownbase .e-list-item.e-item-focus, .e-dropdownbase .e-list-item.e-active, .e-dropdownbase .e-list-item.e-active.e-hover, .e-dropdownbase .e-list-item.e-hover {
//   background-color: #1f9c99;
//   color: #2319b8;
// }