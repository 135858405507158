/*

All grid code is placed in a 'supports' rule (feature query) at the bottom of the CSS (Line 77). 
        
The 'supports' rule will only run if your browser supports CSS grid.

Flexbox is used as a fallback so that browsers which don't support grid will still recieve an identical layout.

*/

@import url(https://fonts.googleapis.com/css?family=Montserrat:500);
*,
*::before,
*::after {
    box-sizing: border-box;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    /* Compensate for excess margin on outer gallery flex items */
    margin: -1rem -1rem;
}

.gallery-item {
    /* Minimum width of 24rem and grow to fit available space */
    flex: 1 0 9rem;
    max-width: 12rem;
    /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
    margin: 1rem;
    box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

.gallery-image-div {
    position: relative;
    width: 100%;
    height: 100%;
}

.gallery-image {
    display: block;
    width: 15rem;
    height: 180px;
    object-fit: cover;
    transition: transform 400ms ease-out;
    background-color: white;
}

.gallery-image-div .title {
    padding: 10px;
    position: absolute;
    color: black;
    width: 100%;
    bottom: 0%;
    left: 0%;
    font-size: small;
    background-color: rgba(255, 255, 255, 0.7);
    transform: translate(-0%, -0%);
}

.gallery-image-div .button {
    position: absolute;
    color: white;
    top: 0%;
    right: 0%;
    background-color: rgb(255, 0, 0);
    transform: translate(0, 0%);
    cursor: pointer;
}

.gallery-image-div .hpreview {
    position: absolute;
    color: white;
    bottom: 0%;
    right: 0%;
    width: 100%;
    transform: translate(0, 0%);
}

.gallery-image:hover {
    transform: scale(1.15);
}


/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 

*/

@supports (display: grid) {
    .gallery {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
        grid-gap: 2rem;
    }
    .gallery,
    .gallery-item {
        margin: 0;
    }
}