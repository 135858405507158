.dashbaord-pageHeadertitle {  
    @include pageHeadertitle;
    
}
.tabNavigation { @include pagetabNavigation; }
.dashboardLeftcard {
    @include dashboardLeftcard;

    
}

.dashboardRightcard {
    @include dashboardRightcard;
   
}

.fileUpload {

    @include uploaladfilechange;
}

.cardsection {
    .cardLeft {
        min-width: 280px;
        max-width: 280px;

        @media(max-width: 767px) {
            min-width: 100%;
            max-width: 100%;
            margin-bottom: 15px;
        }
       
    }
    .cardRight {
  
        min-width:calc(100% - 280px);
        max-width: calc(100% - 280px);

        @media(max-width: 767px) {
            min-width: 100%;
            max-width: 100%;
        }
        
    }
  }