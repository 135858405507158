.dashWelcome {
    align-items: center;
    justify-content: space-around;
    min-height: calc(100vh - 72px);
    background-image: url(https://did1d8jg9ar6w.cloudfront.net/images/welcome_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 45%, center 100%;
    z-index: 0;

    @media (max-width:767px) {
        width:100%;
        height: auto;
        padding:  20px 15px;
        background-size: cover;
        background-position: inherit;
      }

    // &::before {
    //     position: absolute;
    //     background-color: $az-black-color;
    //     min-height: calc(100vh - 72px);
    //     content: '';
    //     width: 100%;
    // }
    
    .welcome-card {
      width: 807px;
      height: 600px;
      padding: 40px 15px 40px 15px;
      border-radius: 2px;
      box-shadow: 0 4px 16px 0 rgba(0,0,0,0.4);
      background-color: $az-white;
      position: relative;
      z-index: 0;
          
      @media (max-width:1440px) {
        height:585px;
        padding: 30px 15px 30px 15px;

      }
        @media (max-width:1366px) {
            width: 807px;
            height:520px;
            padding: 20px 15px 5px 15px;
          }
  
          @media (max-width:1280px) {
            width: 807px;
            height: 500px;
            padding: 20px 15px 0px 15px;
          }
  
          @media (max-width:1199px) {
    
            width: 807px;
            height:525px;
            padding: 30px 15px 40px 15px;
          }
          @media (max-width:991px) {
            width: 700px;
            height:550px;
            padding: 30px 15px 20px 15px;

          }

          @media (max-width:767px) {
            width:100%;
            height: auto;
            padding: 0;
          }
  
          @media (max-width:480px) {
            width:100%;
            height: auto;
            padding: 0;
            }
          @media (max-width:320px) {
            width:100%;
            height: auto;
            padding: 0;
            }

        .card-body {

          @media (max-width:767px) {
              padding: 10px 10px 30px;

          }

            h4 { @include h4;
            
              @media (max-width:767px) {
                padding: 35px 5px 0px;
                font-size: $az-font-28;
  
            }
            
            }
            .card-text {
                @include h5;
                padding-left: 57px;
                padding-right: 57px;

                @media (max-width:767px) {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
           .welcome-txt {
                @include welcome-para;
                padding-left: 30px;
                padding-right: 30px;

                @media (max-width:1366px) {
                  padding-left: 20px;
                  padding-right: 20px;
                }
                
                 @media (max-width:1280px) {
                    padding: 5px 15px 0px 15px;
                  }
          
                  @media (max-width:1199px) {
                     padding: 5px 15px 0px 15px;
                  }
                  @media (max-width:991px) {
                     padding: 5px 15px 0px 15px;
        
                  }
            }
            
            p{
                b{
                    font-family: $az-heading-para-light;
                    color: $az-black;
                    font-weight: 300;
                }
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 0;
                li {
                    list-style: none;
                    font-family: $az-heading-two;
                    color: $az-black;
                    font-size: $az-font-18;
                    width: 50%;
                    padding: 20px;
                    border-bottom: 1px solid $az-light-gray;
                    border-right: 1px solid $az-light-gray;

                      @media (max-width:1366px) {
                        padding: 10px;
                        font-size: $az-font-16;
                      }

                      @media (max-width:1280px) {
                        padding: 7px 15px;
                      }
              
                      @media (max-width:1199px) {
                          padding: 7px 15px;
                      }
                      @media (max-width:991px) {
                          padding: 7px 15px;
                          font-size: $az-font-14;
            
                      }

                      @media (max-width:767px) {
                        width: 100%;
                        border-right: none;
                    }

                    &:last-child {

                        border-right: none;
                        border-bottom:none;

                        @media (max-width:767px) {
                            border-right: none;
                            border-bottom:none;
                        }
                    }
                    &:nth-child(2) {

                        border-right: none;

                        @media (max-width:767px) {
                            border-right: none;
                        }
                    }
                    &:nth-child(3) {

                        border-bottom:none;

                        @media (max-width:767px) {
                            border-bottom: 1px solid #ddd;
                            border-right: none;
                        }
                    }
                }
                
            }
        }
        .welcomeClose{
          position: absolute;
          right: 25px;
          top: 20px;
          z-index: 1;
          @media (max-width:767px) {
            right: 15px;
            top: 12px;
          }
          img {
            @media (max-width:767px) {
                width: 24px;
            }
          }
        }
      }
}