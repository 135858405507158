/* ###### 3.11 Input Group ###### */

.input-group-prepend,
.input-group-append {
  @include border-radius(0);
}

.input-group-text {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  border: $az-form-input-bdr !important;

  @include border-radius(0);
}

.prefix-dollar {
  span {
    color: $az-black;
    background-color: $az-eage-color;
  }
}
