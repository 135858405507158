
$az-height-base:                  36px;
$az-height-header:                64px;
$az-height-header-mobile:         64px;
$az-height-navbar:                48px;
$az-height-navbar-compact:        42px;
$az-height-footer:                40px;
$az-height-content:               calc(100vh - #{$az-height-header + $az-height-footer});

$az-width-sidebar:                240px;
$az-width-iconbar-aside:          230px;

$az-border-color:                 rgba($gray-900, .2);
$az-border-base:                  #dee2ef;

$ckbox-w-size:                    18px;
$ckbox-h-size:                    18px;
$rdiobox-size:                    18px;
$checkbox-border:                 #6e6e6e;

$az-color-primary:                $indigo;
$az-color-secondary:              $blue;
$az-color-headline:               #031b4e;
$az-color-text:                   #4e5f83;

$font-size-sm:                    13px;
$font-size-md:                    $font-size-base + 0.0625;
$font-weight:                     500;

$input-btn-focus-color:           rgba($az-color-primary, .25);
$az-checkbox-height-base:         15px;
$az-black-color:                  rgba(0,0,0,0.55);
$az-light-white:                  #eeedeb;
$az-profile-border:               #e8e8e8;


$az-purple:                       #321b63;
$az-button-outline-border-color:  #4a4a4a;
$az-btn-green:                    #2c5046;
$az-active-status:                #2e7d32;
// $az-inactive-status:              #cc0000;
// $az-active-status-bg:             rgba(46, 125, 50,0.2);
// $az-inactive-status-bg:           rgba(204,0,0,0.2);
$az-active-status-bg:             #40c100; // #3bb001;
$az-inactive-status-bg:           #ff5b5b;// #cc3b3b;
$az-active-text:                  #250a33;
$az-border-color:                 #72818e33;
$az-update-txt-color:             #658958;
//$az-input-placeholder:            #979797;
$az-input-bg-color:               #fafafa;
$az-onfoucs-datepicker:           #97a3b9;

$az-white:                        #ffffff;
$az-black:                        #000000;
$az-gray:                         #606060;
$az-light-gray:                   #eeedeb;
$az-disbled:                      #B7B7B7;
$az-table-hover:                  #6e6e6e14;
$az-optional:                     #878787;
$az-placeholder:                  #979797;
$az-eage-color:                   #e1ccbe;
$az-light-bg:                     #F4F4F4;
$az-light-black:                     #333333;
$az-bg-danger:                     #FF0000;
// $az-heading-one:                  OrpheusPro;
// $az-heading-two:                  NHaasGroteskTXPro-55Rg;
// $az-heading-para:                 NHaasGroteskTXPro-65Md;
// $az-heading-para-light:           NHaasGroteskTXPro-75Bd;

$az-heading-one:                  OrpheusPro;
$az-heading-two:                  Lato-Regular;
$az-heading-para:                 Lato-medium-bold;
$az-heading-para-light:           Lato-dark-bold;

$az-font-10:                     10px;
$az-font-11:                     11px;
$az-font-12:                     12px;
$az-font-13:                     13px;
$az-font-14:                     14px;
$az-font-15:                     15px;
$az-font-16:                     16px;
$az-font-17:                     17px;
$az-font-18:                     18px;
$az-font-19:                     19px;
$az-font-20:                     20px;
$az-font-21:                     21px;
$az-font-22:                     22px;
$az-font-24:                     24px;
$az-font-26:                     26px;
$az-font-28:                     28px;
$az-font-30:                     30px;
$az-font-32:                     32px;
$az-font-36:                     36px; 
$az-dash-mt:                     46px;


// OH veriables

$az-oh-primary:                 #1d1d1b; 
$az-oh-seconday:                #f3e1cf;
$az-oh-black:                   #231f20;
$az-heading-light:              Lato-Light;


$az-font-17:                     17px; 
$az-font-25:                     25px; 
$az-font-35:                     35px; 
$az-font-45:                     45px; 
$az-font-56:                     56px; 
$az-font-60:                     60px;
$az-font-75:                     75px; 
$az-font-76:                     76px; 

$az-form-input-bdr:#33333382;
